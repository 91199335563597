const SAFETY_TRUCK_MODAL_DEFAULT_VALUE = {
  seq: null,
  div: '',
  truck_no: '',
  truck_make: '',
  truck_model: '',
  make_year: '',
  vin_no: '',
  plate_no: '',
  state: '',
  status: '',
  emodal: '',
  ctp: '',
  ctp_exp: null,
  pdtr: null,
  rfid: '',
  bank: '',
  purchase_amt: '',
  total_loan_amt: '',
  sche_payment: '',
  interest_rate: '',
  loan_terms: '',
  loan_status: '',
  loan_start_date: null,
  loan_end_date: null,
  extra_payment: '',
  depreciation_amt: '',
  depreciation_start_date: null,
  depreciation_end_date: null,
  depreciable_life: '',
  depreciation_status: '',
  remarks: '',
  active: true,
  created_by: '',
  created_date: null,
  updated_by: '',
  updated_date: null,
  driver: '',
  doc: [
    {
      updated_date: null,
      document: '',
      exp_date: null,
      file_url: null,
      updated_by: '',
    },
  ],
};

export default SAFETY_TRUCK_MODAL_DEFAULT_VALUE;
