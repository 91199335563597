import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const getAccidentList = async () => {
  try {
    const response = await safetyDefaultAPI.get('accident');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAccidentList;
