import React from 'react';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import { COMMON_EMODAL } from '../../../../../../constant/Common/Option/Emodal';
import { COMMON_CTP } from '../../../../../../constant/Common/Option/CTP';
import {
  changeDate,
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { createOptionalSelectOption } from '../../../../../../util/Common/Option/Generator/selectOptionCreator';

const Operation = ({ ...props }) => {
  const { data, isEditable } = props;

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Operation
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="EMODAL"
              inputName="emodal"
              option={createOptionalSelectOption({ items: COMMON_EMODAL })}
              defaultValue={data?.emodal || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="CTP"
              inputName="ctp"
              option={createOptionalSelectOption({ items: COMMON_CTP })}
              defaultValue={data?.ctp || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label="CTP EXP"
              inputName="ctp_exp"
              defaultValue={data?.ctp_exp || null}
              onChangeHandler={({ ...changeProps }) => {
                changeDate({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label="PDTR"
              inputName="pdtr"
              defaultValue={data?.pdtr || null}
              onChangeHandler={({ ...changeProps }) => {
                changeDate({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="RFID"
              inputName="rfid"
              maxLength={8}
              defaultValue={data?.rfid || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default Operation;
