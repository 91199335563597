import styled from 'styled-components';

const StyledDivisionSelectorLabelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: 100%;

  position: absolute;

  right: 13px;
`;

export default StyledDivisionSelectorLabelButton;
