import React, { useEffect, useRef, useState } from 'react';
import DivisionSelectorLabel from './DivisionSelectorLabel';
import DivisionSelectorOption from './DivisionSelectorOption';
import handleOutsideClick from '../../../../../util/Common/Header/DivisionSelector/Handler/handleOutsideClick';
import StyledDivisionSelectorWrapper from '../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorWrapper';

/**
 * @description
 * Component of DivisionSelector in the application.
 * @returns {React.ReactElement}
 */
const DivisionSelector = () => {
  const divisionSelectorRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', event =>
      handleOutsideClick?.({
        ref: divisionSelectorRef,
        event,
        isOpen,
        setIsOpen,
      }),
    );
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]);

  return (
    <StyledDivisionSelectorWrapper ref={divisionSelectorRef}>
      <DivisionSelectorLabel setIsOpen={setIsOpen} />
      {isOpen && <DivisionSelectorOption setIsOpen={setIsOpen} />}
    </StyledDivisionSelectorWrapper>
  );
};

export default DivisionSelector;
