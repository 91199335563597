const commonDataKeys = {
  all: ['COMMON_DATA'],
  defaultDivisionList: () => [...commonDataKeys?.all, 'DIVISION_LIST'],
  divisionList: ({ keyword }) => [
    ...commonDataKeys?.defaultDivisionList(),
    keyword,
  ],
  driverList: () => [...commonDataKeys?.all, 'DRIVER_LIST'],
  defaultChassisSizeList: () => [...commonDataKeys?.all, 'CHASSIS_SIZE_LIST'],
  chassisSizeList: ({ keyword }) => [
    ...commonDataKeys?.defaultChassisSizeList(),
    keyword,
  ],
  defaultChassisTypeList: () => [...commonDataKeys?.all, 'CHASSIS_TYPE_LIST'],
  chassisTypeList: ({ keyword }) => [
    ...commonDataKeys?.defaultChassisTypeList(),
    keyword,
  ],
  defaultPoolList: () => [...commonDataKeys?.all, 'POOL_LIST'],
  poolList: ({ keyword }) => [...commonDataKeys?.defaultPoolList(), keyword],
  safetyTruck: () => [...commonDataKeys?.all, 'SAFETY_TRUCK'],
  safetyChassis: () => [...commonDataKeys?.all, 'SAFETY_CHASSIS'],
  defaultSizeList: () => [...commonDataKeys?.all, 'SIZE_LIST'],
  sizeList: ({ keyword }) => [...commonDataKeys?.defaultSizeList(), keyword],
  defaultSSLList: () => [...commonDataKeys?.all, 'SSL_LIST'],
  sslList: ({ keyword }) => [...commonDataKeys?.defaultSSLList(), keyword],
  defaultTypeList: () => [...commonDataKeys?.all, 'TYPE_LIST'],
  typeList: ({ keyword }) => [...commonDataKeys?.defaultTypeList(), keyword],
  ymsYard: () => [...commonDataKeys?.all, 'YMS_YARD'],
};

export default commonDataKeys;
