import React from 'react';

const DamageFormModalHeader = ({ modalType }) => {
  return (
    <div className="ml-[10px] mt-[10px] font-[700] text-[14px] text-[#001E5F]">
      {modalType ? 'Edit' : 'Add'} Damage Report
    </div>
  );
};

export default DamageFormModalHeader;
