import styled from 'styled-components';

const StyledSignInBackgroundImageWrapper = styled.div`
  background-image: url('/asset/Image/signIn/background.svg');
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 894px;
  height: 569px;
  background-size: cover;
`;

export default StyledSignInBackgroundImageWrapper;
