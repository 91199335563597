import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  STYLE_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import { createMandatorySelectOption } from '../../../../util/Common/Option/Generator/selectOptionCreator';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';

const Container = ({
  selectedDailyInOut,
  isAdd,
  isOut,
  inventoryContainerList,
  sizeOption,
  typeOption,
  sslOption,
  inputCategory,
  setInputCategory,
  isBare,
  selectedYard,
  isLoadingInventoryContainerList,
  existingData,
}) => {
  const { setValue, getValues } = useFormContext();
  const [selectedItem, setSelectedItem] = useState({});

  const resetFieldsData = () => {
    setValue('cont_no', '');
    setValue('cont_size', '');
    setValue('cont_type', '');
    setValue('cont_ssl', '');
    setValue('cont_seal', '');
    setValue('cont_color', '');
    setValue('cont_yard_location', '');
    setValue('cont_remarks', '');
  };

  useEffect(() => {
    setSelectedItem({});
  }, [isOut]);

  useEffect(() => {
    if (inputCategory === 'trailer') {
      resetFieldsData();
    }
  }, [inputCategory]);

  return (
    <div className="w-full h-[203px] bg-[#f9f9f9] mt-[10px] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[10px]">
      {/* Header */}
      <div
        className={`w-full h-fit ${inputCategory === 'trailer' || isBare ? 'text-[#A7A7A7]' : 'text-[#264B9F]'} text-[14px] font-bold`}
      >
        Container
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            {isOut ? (
              <CommonAutoComplete
                label="CONT #"
                inputName="cont_no"
                width="w-[140px]"
                disabled={
                  !isAdd ||
                  inputCategory === 'trailer' ||
                  (isOut && !selectedYard)
                }
                defaultValue={
                  !isAdd ? selectedDailyInOut?.cont_no : existingData?.cont_no
                }
                option={createMandatorySelectOption({
                  items: inventoryContainerList,
                  labelKey: 'cont_no',
                  valueKey: 'cont_no',
                })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  resetFieldsData();
                  onChange?.(selectProps?.value);
                  setSelectedItem(
                    inventoryContainerList.find(
                      item => item.cont_no === selectProps?.value,
                    ),
                  );
                  if (selectProps?.value) {
                    setInputCategory('container');
                  } else if (getValues('ch_no')) {
                    setInputCategory('chassis');
                  } else {
                    setInputCategory('');
                  }
                }}
                isLoading={isLoadingInventoryContainerList}
              />
            ) : (
              <CommonTextInput
                label="CONT #"
                inputName="cont_no"
                width="w-[140px]"
                disabled={
                  !isAdd || isOut || inputCategory === 'trailer' || isBare
                }
                defaultValue={
                  !isAdd ? selectedDailyInOut?.cont_no : existingData?.cont_no
                }
                maxLength={11}
                onChangeHandler={({ e, onChange }) => {
                  onChange(e?.target?.value?.toUpperCase());
                  if (e?.target?.value) {
                    setInputCategory('container');
                  } else if (getValues('ch_no')) {
                    setInputCategory('chassis');
                  } else {
                    setInputCategory('');
                  }
                }}
              />
            )}
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SIZE"
              inputName="cont_size"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_size) ||
                (isOut ? selectedItem?.size : existingData?.cont_size)
              }
              required={isAdd && getValues('cont_no')}
              option={sizeOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="cont_type"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_type) ||
                (isOut ? selectedItem?.type : existingData?.cont_type)
              }
              required={isAdd && getValues('cont_no')}
              option={typeOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SSL"
              inputName="cont_ssl"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_ssl) ||
                (isOut ? selectedItem?.ssl : existingData?.cont_ssl)
              }
              option={sslOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="SEAL #"
              inputName="cont_seal"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_seal) ||
                (isOut ? selectedItem?.seal : existingData?.cont_seal)
              }
              maxLength={20}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="COLOR"
              inputName="cont_color"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_color) ||
                (isOut ? selectedItem?.color : existingData?.cont_color)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="LOCATION"
              inputName="cont_yard_location"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_yard_location) ||
                (isOut
                  ? selectedItem?.yard_location
                  : existingData?.cont_yard_location)
              }
              maxLength={5}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="cont_remarks"
              width="w-[140px]"
              disabled={
                !isAdd || isOut || inputCategory === 'trailer' || isBare
              }
              defaultValue={
                (!isAdd && selectedDailyInOut?.cont_remarks) ||
                (isOut ? selectedItem?.remarks : existingData?.cont_remarks)
              }
              maxLength={50}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Container;
