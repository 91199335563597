import { ALERT_MODAL_STATUS } from '../../../../../constant/Common/Modal/Alert/status';

const createAlertHeader = ({ status, header }) => {
  const { ERROR, SUCCESS } = ALERT_MODAL_STATUS;

  if (status === ERROR) return 'Error';
  if (status === SUCCESS) return 'Success';
  return header;
};

export default createAlertHeader;
