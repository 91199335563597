import React from 'react';

import { PATH_MODAL_CLOSE_ICON } from '../../../../../../../constant/Common/Path/Asset/Icon/Button';
import StyledCommonMessageModalCloseButtonWrapper from '../../../../../../../style/Common/Modal/Message/Frame/Header/CloseButton/StyledCommonMessageModalCloseButton/StyledCommonMessageModalCloseButtonWrapper';
import StyledCommonMessageModalCloseButtonContent from '../../../../../../../style/Common/Modal/Message/Frame/Header/CloseButton/StyledCommonMessageModalCloseButtonContent';

const CommonMessageModalCloseButton = ({ ...props }) => {
  const { setOpen, onCloseButtonClick } = props;

  return (
    <StyledCommonMessageModalCloseButtonWrapper
      onClick={() => {
        if (onCloseButtonClick) {
          onCloseButtonClick();
          return;
        }
        setOpen?.(prev => false);
      }}
    >
      <StyledCommonMessageModalCloseButtonContent
        src={PATH_MODAL_CLOSE_ICON}
        alt="Common message modal close button"
        data-testid="messageModalCloseButton"
      />
    </StyledCommonMessageModalCloseButtonWrapper>
  );
};

export default CommonMessageModalCloseButton;
