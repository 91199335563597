import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCheckBoxWrapper from '../../../../style/Safety/DamageReport/StyledCheckBoxWrapper';

const CommonDamageCheckBox = ({ options, onChangeHandler, ...props }) => {
  const { register } = useFormContext();

  const [disabled, setDisabled] = useState(props?.disabled || false);

  useEffect(() => {
    setDisabled(props?.disabled || false);
  }, [props?.disabled]);
  return (
    <div className="w-full flex flex-wrap gap-y-[9px]">
      {options?.map(option => (
        <div key={option?.key}>
          <div className="flex flex-col justify-end h-full w-full">
            {option?.subLabel && (
              <div className="ml-[-2px] font-[400] text-[9px] text-[#AAAAAA] mb-[3px]">
                {option?.subLabel}
              </div>
            )}
            <div className="w-full flex items-center">
              <input
                type="checkbox"
                className="w-[12px] h-[12px]"
                disabled={disabled}
                defaultChecked={option?.defaultValue || false}
                {...register(option?.inputName, {
                  onChange: e =>
                    onChangeHandler && onChangeHandler({ e, option, ...props }),
                })}
              />
              <StyledCheckBoxWrapper width={option?.width}>
                {option?.label}
              </StyledCheckBoxWrapper>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommonDamageCheckBox;
