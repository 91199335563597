export const generateDivisionCheckboxOption = ({ divList }) => {
  const divisionCheckboxOption = divList?.map(div => {
    return {
      optionLabel: div?.div,
      inputName: div?.div,
    };
  });

  divisionCheckboxOption?.unshift({
    optionLabel: 'ALL',
    inputName: 'ALL',
  });

  return divisionCheckboxOption;
};

export const generateDivisionSelectOption = ({ selectedDivList }) => {
  const optionList = selectedDivList
    ?.filter(div => div?.value !== false)
    ?.map((div, idx) => ({ key: idx, label: div?.name, value: div?.name }));

  return optionList;
};
