import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getDailyInOutList from '../../../service/DailyInOut/Get/getDailyInOutList';

const useGetDailyInOutList = ({ div, fromDate, toDate, yard }) => {
  const {
    isLoading: isLoadingDailyInOutList,
    data,
    isRefetching: isRefetchingDailyInOutList,
    isError: isErrorDailyInOutList,
  } = useQuery({
    queryKey: dailyInOutKeys?.list({ div, fromDate, toDate, yard }),
    queryFn: () => getDailyInOutList({ div, fromDate, toDate, yard }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingDailyInOutList,
    dailyInOutList: data?.data,
    isRefetchingDailyInOutList,
    isErrorDailyInOutList,
  };
};

export default useGetDailyInOutList;
