export const COMMON_TRUCK_DOCUMENT = [
  {
    seq: 0,
    label: 'Registration',
    value: 'Registration',
  },
  {
    seq: 1,
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    seq: 2,
    label: 'Annual',
    value: 'Annual',
  },
  {
    seq: 3,
    label: 'Title',
    value: 'Title',
  },
  {
    seq: 4,
    label: 'MC',
    value: 'MC',
  },
  {
    seq: 5,
    label: '90 Days',
    value: '90 Days',
  },
  {
    seq: 6,
    label: 'IFTA',
    value: 'IFTA',
  },
  {
    seq: 7,
    label: 'Hazmat',
    value: 'Hazmat',
  },
  {
    seq: 8,
    label: 'New mexico',
    value: 'New mexico',
  },
  {
    seq: 9,
    label: 'TAX 2290',
    value: 'TAX 2290',
  },
  {
    seq: 10,
    label: 'UCR',
    value: 'UCR',
  },
  {
    seq: 11,
    label: 'Kentucky',
    value: 'Kentucky',
  },
];

export const COMMON_CHASSIS_DOCUMENT = [
  {
    seq: 0,
    label: 'Registration',
    value: 'Registration',
  },
  {
    seq: 1,
    label: 'Title',
    value: 'Title',
  },
  {
    seq: 2,
    label: 'Annual',
    value: 'Annual',
  },
  {
    seq: 3,
    label: '90 Days',
    value: '90 Days',
  },
];

export const COMMON_VAN_DOCUMENT = [
  {
    seq: 0,
    label: 'Registration',
    value: 'Registration',
  },
  {
    seq: 1,
    label: 'Title',
    value: 'Title',
  },
  {
    seq: 2,
    label: 'Annual',
    value: 'Annual',
  },
];

export const COMMON_ACCIDENT_DOCUMENT = [
  {
    seq: 0,
    label: 'Photo',
    value: 'Photo',
  },
  {
    seq: 1,
    label: 'Video',
    value: 'Video',
  },
  {
    seq: 2,
    label: 'Report',
    value: 'Report',
  },
  {
    seq: 3,
    label: 'Police Report',
    value: 'Police Report',
  },
  {
    seq: 4,
    label: 'Towing',
    value: 'Towing',
  },
  {
    seq: 5,
    label: 'Repair',
    value: 'Repair',
  },
  {
    seq: 6,
    label: 'Close Letter',
    value: 'Close Letter',
  },
  {
    seq: 7,
    label: 'Other',
    value: 'Other',
  },
];
