import { PATH_DATAGRID_GROUP_FOLD_WHITE_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createAdornmentGroup = () => {
  return [
    {
      headerName: 'Group01 - Adornment',
      backgroundColor: '#cfb1ff',
      icon: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      isColumnGroup: true,
      children: [
        {
          headerName: 'Start Adornment (₩)',
          field: 'won',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'start',
              },
            }),
        },
        {
          headerName: 'Start Adornment (₩)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'start',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'Start Adornment (₩)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'start',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'End Adornment (₩)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'end',
              },
            }),
        },
        {
          headerName: 'End Adornment (₩)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'end',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'End Adornment (₩)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '₩',
                position: 'end',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'Start Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'start',
              },
            }),
        },
        {
          headerName: 'Start Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'start',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'Start Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'start',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'End Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'end',
              },
            }),
        },
        {
          headerName: 'End Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'end',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'End Adornment ($)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: '$',
                position: 'end',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'Start Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'start',
              },
            }),
        },
        {
          headerName: 'Start Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'start',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'Start Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'start',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
        {
          headerName: 'End Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'end',
              },
            }),
        },
        {
          headerName: 'End Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'end',
              },
              styles: {
                textAlign: 'center',
              },
            }),
        },
        {
          headerName: 'End Adornment (MILE)',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              adornment: {
                content: 'MILE',
                position: 'end',
              },
              styles: {
                textAlign: 'right',
              },
            }),
        },
      ],
    },
  ];
};

export default createAdornmentGroup;
