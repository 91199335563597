import React from 'react';
import CommonDataGrid from '../../Common/Datagrid';
import StyledInventoryDatagridWrapper from '../../../style/Inventory/StyledInventoryDatagridWrapper';
import clickGridRow from '../../../util/Inventory/Common/Handler/clickGridRow';

const InventoryDatagrid = ({ ...props }) => {
  const { gridRef, columnDefs, data, setSpecificSeq, setModalOpen } = props;

  return (
    <StyledInventoryDatagridWrapper>
      <CommonDataGrid
        gridRef={gridRef || null}
        columnDefs={columnDefs}
        data={data}
        onRowClicked={row =>
          clickGridRow?.({
            setSpecificSeq,
            setModalOpen,
            ...row,
          })
        }
      />
    </StyledInventoryDatagridWrapper>
  );
};

export default InventoryDatagrid;
