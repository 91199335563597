import { useMutation, useQueryClient } from '@tanstack/react-query';
import patchSpecificMRData from '../../../../service/Safety/MR/Patch/patchSpecificMRData';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import safetyMRKeys from '../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchSpecificMRData = ({ gridRef, ...props }) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const { setAlertModalStatus, setAlertOpen, setModalOpen } = props;

  const mutator = useMutation({
    mutationFn: patchSpecificMRData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      setModalOpen?.(prev => false);
      const { div, ...successData } = data?.data;
      updateGridData({
        gridRef,
        newData: { seq: variables?.seq, ...variables?.data, ...successData },
        isUpdate: true,
      });
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
  });

  return { ...mutator };
};

export default usePatchSpecificMRData;
