import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import inventoryKeys from '../../keys';
import getSpecificEquipData from '../../../../service/Inventory/Common/Get/getSpecificEquipData';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const useGetSpecificEquipData = ({ equip, seq, ...props }) => {
  const { setAlertOpen, setAlertModalStatus } = props;

  const [specificData, setSpecificData] = useState({});

  const query = useQuery({
    queryKey: inventoryKeys?.specificEquipData({ equip, seq }),
    queryFn: () => getSpecificEquipData({ equip, seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq,
  });

  useEffect(() => {
    if (query?.data) {
      setSpecificData(prev => query?.data?.data);
    } else {
      setSpecificData({});
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { specificData, ...query };
};

export default useGetSpecificEquipData;
