import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getYMSYard from '../../../service/Common/Get/getYMSYard';

const useGetYMSYard = () => {
  const [ymsYard, setYMSYard] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys?.ymsYard(),
    queryFn: () => getYMSYard(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setYMSYard(prev => query?.data?.data);
    } else {
      setYMSYard(prev => []);
    }
  }, [query?.data]);

  return { ymsYard, ...query };
};

export default useGetYMSYard;
