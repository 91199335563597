import styled, { css } from 'styled-components';

const StyledCustomTextarea = styled.textarea`
  /* Pure setting */
  width: ${props => props?.$styles?.width || '100%'};
  height: ${props => props?.$styles?.height || '30px'};

  resize: none;

  background-color: ${props => props?.$styles?.backgroundColor || '#fff'};
  font-size: ${props => props?.$styles?.fontSize || '12px'};
  font-weight: ${props => props?.$styles?.fontWeight || '500'};
  font-style: ${props => props?.$styles?.fontStyle || 'normal'};
  color: ${props => props?.$styles?.color || '#383535'};

  outline: ${props => props?.$styles?.outline || 'none'};

  border-width: ${props => props?.$styles?.borderWidth || '1px'};
  border-color: ${props => props?.$styles?.borderColor || '#D9D9D9'};
  border-style: ${props => props?.$styles?.borderStyle || 'solid'};
  border: ${props => props?.$styles?.border || '1px solid #D9D9D9'};

  border-radius: ${props => props?.$styles?.borderRadius || '5px'};

  padding: 8px;

  /** Hover case */
  &:not(:focus):not(:disabled):hover {
    border-width: ${props =>
      !props?.error && (props?.$styles?.hoverBorderWidth || '1px')};
    border-color: ${props =>
      !props?.error && (props?.$styles?.hoverBorderColor || '#8E9396')};
    border-style: ${props =>
      !props?.error && (props?.$styles?.hoverBorderStyle || 'solid')};
    border: ${props =>
      !props?.error && (props?.$styles?.hoverBorder || '1px solid #8E9396')};
  }

  /** Focus case */
  &:focus {
    border-color: ${props =>
      !props?.error && (props?.$styles?.focusBorderColor || '#264B9F')};
    border-width: ${props =>
      !props?.error && (props?.$styles?.focusBorderWidth || '1.2px')};
    border-style: ${props =>
      !props?.error && (props?.$styles?.focusBorderStyle || 'solid')};
    border: ${props =>
      !props?.error && (props?.$styles?.focusBorder || '1.2px solid #264B9F')};
    box-shadow: ${props =>
      props?.$styles?.focusBoxShadow || '0px 0px 4px 0px #8BBCE9'};
  }

  /** Disabled case */
  &:disabled {
    border-color: ${props => props?.$styles?.disabledBorderColor || '#D9D9D9'};
    border-width: ${props => props?.$styles?.disabledBorderWidth || '1px'};
    border-style: ${props => props?.$styles?.disabledBorderStyle || 'solid'};
    border: ${props => props?.$styles?.disabledBorder || '1px solid #D9D9D9'};

    background-color: ${props =>
      props?.$styles?.disabledBackgroundColor || '#F9F9F9'};
  }

  /** Error case */
  ${props =>
    props?.error &&
    css`
      border-color: ${props?.$styles?.errorBorderColor || '#C24D4D'};
      border-width: ${props?.$styles?.errorBorderWidth || '1.2px'};
      border-style: ${props?.$styles?.errorBorderStyle || 'solid'};
      border: ${props?.$styles?.errorBorder || '1.2px solid #C24D4D'};
    `}
`;

export default StyledCustomTextarea;
