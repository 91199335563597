import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const getListData = async () => {
  try {
    const response = await safetyDefaultAPI.get('chassis');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getListData;
