import dayjs from 'dayjs';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
  CELL_TYPE_TIME,
} from '../../../../constant/Common/DataGrid/cellType';
import generateCellEditor from '../../../Common/Datagrid/Generator/generateCellEditor';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';
import { PATH_DATAGRID_GROUP_FOLD_WHITE_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const createAddGroup = () => {
  return [
    {
      headerName: 'Group03 - Add',
      backgroundColor: '#9265ff',
      color: '#ffffff',
      icon: {
        fold: PATH_COMMON_BUTTON_ICON?.cancelRed?.hover,
        unfold: PATH_COMMON_BUTTON_ICON?.cancelRed?.default,
      },
      isColumnGroup: true,
      children: [
        {
          headerName: 'Text',
          field: 'add_text',
          cellRendererSelector: gridParam =>
            generateCellRenderer({ gridParam, cellType: CELL_TYPE_TEXT }),
        },
        {
          headerName: 'Auto Complete',
          field: 'add_auto_complete',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  seq: 0,
                  label: 'YGB',
                  value: 'YGB',
                },
                {
                  seq: 1,
                  label: 'BCSD',
                  value: 'BCSD',
                },
                {
                  seq: 2,
                  label: 'JSG DDRT',
                  value: 'JSG DDRT',
                },
              ],
            }),
        },
        {
          headerName: 'Select',
          field: 'add_select',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                {
                  seq: 0,
                  label: 'YGB',
                  value: 'YGB',
                },
                {
                  seq: 1,
                  label: 'BCSD',
                  value: 'BCSD',
                },
                {
                  seq: 2,
                  label: 'JSG DDRT',
                  value: 'JSG DDRT',
                },
              ],
            }),
        },
        {
          headerName: 'Date',
          field: 'add_date',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_DATE,
            }),
          valueGetter: params => {
            return params.data?.add_date &&
              dayjs(params.data?.add_date)?.isValid()
              ? dayjs(params.data?.add_date)?.format('MM/DD/YY')
              : null;
          },
        },
        {
          headerName: 'Time',
          field: 'add_time',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_TIME,
            }),
          valueGetter: params => {
            return params.data?.add_time &&
              dayjs(params.data?.add_time)?.isValid()
              ? dayjs(params.data?.add_time)?.format('HH:mm')
              : null;
          },
        },
      ],
    },
  ];
};

export default createAddGroup;
