import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import safetyVANKeys from '../keys';
import getSpecificVANData from '../../../../service/Safety/VAN/Get/getSpecificVANData';
import SAFETY_VAN_MODAL_DEFAULT_VALUE from '../../../../constant/Safety/VAN/Modal/defaultValue';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const useGetSpecificVANData = ({ seq, isModalOpen, ...props }) => {
  const { setAlertOpen, setAlertModalStatus } = props;

  const [specificData, setSpecificData] = useState({
    ...SAFETY_VAN_MODAL_DEFAULT_VALUE,
  });

  const query = useQuery({
    queryKey: safetyVANKeys?.specificData({ seq }),
    queryFn: () => getSpecificVANData({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq && !!isModalOpen,
  });

  useEffect(() => {
    if (query?.data) {
      setSpecificData(prev => query?.data?.data);
    } else {
      setSpecificData(prev => {
        return { ...SAFETY_VAN_MODAL_DEFAULT_VALUE };
      });
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { specificData, ...query };
};

export default useGetSpecificVANData;
