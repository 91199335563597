import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomText from '../../../../../style/Common/Controller/Common/Text/Custom/StyledCustomText';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import ControllerLabel from '../../Common/Label';
import ControllerError from '../../Common/Error';
import ComponentController from '../../Common/ComponentController';
import generateAdornmentContent from '../../../../../util/Common/Datagrid/Generator/generateAdornmentContent';

const CustomTextInput = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
    ...formController
  } = useFormContext();

  const { styles, disabled, required, maxLength, ...compState } =
    useControlComponentState({ ...props });

  const {
    inputName,
    defaultValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'text',
    setValue,
  });

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />
        <ComponentController
          Component={StyledCustomText}
          inputName={inputName}
          defaultValue={defaultValue}
          componentProps={{
            styles,
            disabled,
            required,
            InputProps: {
              startAdornment:
                props?.adornment?.position === 'start' &&
                generateAdornmentContent({
                  adornment: props?.adornment,
                }),
              endAdornment:
                props?.adornment?.position === 'end' &&
                generateAdornmentContent({
                  adornment: props?.adornment,
                }),
              inputProps: {
                maxLength,
                autoComplete: 'off',
              },
            },
            onChange: props?.onChange,
            onClick: props?.onClick,
            onKeyDown: props?.onKeyDown,
            onBlur: props?.onBlur,
          }}
        />
      </StyledCustomControllerWrapper>
      <ControllerError
        errors={errors}
        inputName={inputName}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </>
  );
};

export default CustomTextInput;
