import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import useGetDivisionList from '../Data/useGetDivisionList';
import {
  setChassisSizeList,
  setChassisTypeList,
  setDivisionList,
  setDriverList,
  setPoolList,
  setSSLList,
  setSafetyChassis,
  setSafetyTruck,
  setSizeList,
  setTypeList,
  setYmsYard,
} from '../../../redux/Feature/Common';
import useGetDriverList from '../Data/useGetDriverList';
import useGetChassisSizeList from '../Data/useGetChassisSizeList';
import useGetChassisTypeList from '../Data/useGetChassisTypeList';
import useGetPoolList from '../Data/useGetPoolList';
import useGetSafetyTruck from '../Data/useGetSafetyTruck';
import useGetSizeList from '../Data/useGetSizeList';
import useGetSSLList from '../Data/useGetSSLList';
import useGetTypeList from '../Data/useGetTypeList';
import useGetYMSYard from '../Data/useGetYMSYard';
import useGetSafetyChassis from '../Data/useGetSafetyChassis';

const useSetCommonData = () => {
  const dispatch = useDispatch();

  /** Division */
  const { divisionList } = useGetDivisionList();
  useEffect(() => {
    dispatch(setDivisionList(divisionList));
  }, [divisionList]);

  /** Driver */
  const { driverList } = useGetDriverList();
  useEffect(() => {
    dispatch(setDriverList(driverList));
  }, [driverList]);

  /** Chassis size */
  const { chassisSizeList } = useGetChassisSizeList();
  useEffect(() => {
    dispatch(setChassisSizeList(chassisSizeList));
  }, [chassisSizeList]);

  /** Chassis type */
  const { chassisTypeList } = useGetChassisTypeList();
  useEffect(() => {
    dispatch(setChassisTypeList(chassisTypeList));
  }, [chassisTypeList]);

  /** Pool */
  const { poolList } = useGetPoolList();
  useEffect(() => {
    dispatch(setPoolList(poolList));
  }, [poolList]);

  /** Safety truck */
  const { safetyTruck } = useGetSafetyTruck();
  useEffect(() => {
    dispatch(setSafetyTruck(safetyTruck));
  }, [safetyTruck]);

  /** Safety chassis */
  const { safetyChassis } = useGetSafetyChassis();
  useEffect(() => {
    dispatch(setSafetyChassis(safetyChassis));
  }, [safetyChassis]);

  /** Size */
  const { sizeList } = useGetSizeList();
  useEffect(() => {
    dispatch(setSizeList(sizeList));
  }, [sizeList]);

  /** SSL */
  const { sslList } = useGetSSLList();
  useEffect(() => {
    dispatch(setSSLList(sslList));
  }, [sslList]);

  /** Type */
  const { typeList } = useGetTypeList();
  useEffect(() => {
    dispatch(setTypeList(typeList));
  }, [typeList]);

  /** YMS Yard */
  const { ymsYard } = useGetYMSYard();
  useEffect(() => {
    dispatch(setYmsYard(ymsYard));
  }, [ymsYard]);
};

export default useSetCommonData;
