import React from 'react';
import CommonBlur from '../Controller/Blur';
import { PATH_MOVING_LOGO } from '../../../constant/Common/Path/Asset/Logo';
import StyledLoadingLogoWrapper from '../../../style/Common/Loading/StyledLoadingLogoWrapper';

/**
 * @description
 * Common loading component.
 * @param {boolean} isOpen
 * Loading open state.
 * @returns {React.ReactElement}
 */
const CommonLoading = ({ isOpen }) => {
  return (
    isOpen && (
      <CommonBlur isOpen={isOpen}>
        <StyledLoadingLogoWrapper
          data-testid="progressbar"
          alt="Loading"
          src={PATH_MOVING_LOGO}
        />
      </CommonBlur>
    )
  );
};

export default CommonLoading;
