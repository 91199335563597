export const ACCIDENT_DEFAULT_VALUE = {
  div: '',
  status: '',
  driver: '',
  op: '',
  responsibility: '',
  accident_date: null,
  detail: '',
  amt_to_pay: '',
  who_will_pay: '',
  paid_amt: '',
  paid_date: '',
  balance_due: '',
  claim_no: '',
  claim_date: null,
  adjustor: '',
  remarks: '',
  created_by: '',
  created_date: null,
  updated_by: '',
  updated_date: null,
  doc: [
    {
      updated_date: null,
      document: '',
      exp_date: null,
      file_url: null,
      updated_by: '',
    },
  ],
};
