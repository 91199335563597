export const STATUS_OPTION_LIST = [
  { key: 0, label: 'COMPLETED', value: 'SSA001' },
  { key: 1, label: 'ON TRACK', value: 'SSA002' },
];

export const OP_OPTION_LIST = [
  { key: 0, label: 'COM', value: 'AO0001' },
  { key: 1, label: 'OP', value: 'AO0002' },
];

export const RESPONSIBILITY_OPTION_LIST = [
  { key: 0, label: 'AT-FALUT', value: 'AR0001' },
  { key: 1, label: 'NO-FALUT', value: 'AR0002' },
];
