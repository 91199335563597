import styled from 'styled-components';

const StyledCommonMessageModalHeaderContent = styled.div`
  font-size: 14px;
  font-weight: bold;

  display: flex;
  align-items: center;
`;

export default StyledCommonMessageModalHeaderContent;
