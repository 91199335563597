import React from 'react';
import { AxiosError } from 'axios';
import { useRouteError } from 'react-router-dom';
import StyledErrorPageWrapper from '../../../style/Error/StyledErrorPageWrapper';
import StyledErrorPageContentsWrapper from '../../../style/Error/StyledErrorPageContentsWrapper';

const ErrorBoundary = () => {
  const error = useRouteError();

  if (error instanceof AxiosError) {
    /**
     * Please do not delete this console.log.
     * This ErrorBoundary is used to test the error handling.
     * If the error occured and this component is rendered, the error message will be logged in the console.
     * If you have any question, please contact to the James.
     * @see https://reactrouter.com/en/main/route/error-element
     */
    console.error('Error in boundary : ', error?.response);
  }

  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageContentsWrapper>
        <div>
          <p className="text-[#00235D] text-[75px] font-semibold">OOPS!</p>
        </div>
        <div className="pt-[36px]">
          <p className="text-[#00235D] text-[35px] font-semibold">
            SOMETHING WENT WRONG
          </p>
        </div>
        <div className="text-center pt-[30px] flex gap-[10px] flex-col">
          <p className="text-[#00235D] text-[22px] font-normal">
            Please try again or feel free to contact us.
          </p>
          <p className="text-[#00235D] text-[22px] font-normal">
            If you think something is broken, report a problem.
          </p>
        </div>
      </StyledErrorPageContentsWrapper>
    </StyledErrorPageWrapper>
  );
};

export default ErrorBoundary;
