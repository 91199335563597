import React from 'react';
import dayjs from 'dayjs';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonDate from '../../../../../Common/Controller/Date';
import CommonTime from '../../../../../Common/Controller/Time';
import StyledModalInputContainer from '../../../../../../style/Inventory/Modal/EditModal/StyledModalInputContainer';
import {
  changeText,
  changeSelect,
  changeDay,
} from '../../../../../../util/Inventory/Common/Handler/controllerChangeHandler';
import {
  DAILY_IN_OUT_CATEGORY_TYPE,
  DAILY_IN_OUT_TYPE,
} from '../../../../../../constant/DailyInOut/elements';

const BasicInfo = ({ equip, data, selectOptions, isEditable }) => {
  return (
    <StyledModalInputContainer className="bg-[#F9F9F9] rounded-[10px] pt-[11px] mb-[6px]">
      <div>
        <CommonDate
          label="DATE"
          inputName="in_out_date"
          defaultValue={data?.in_out_date || null}
          onChangeHandler={changeDay}
          disabled
        />
      </div>
      <div>
        <CommonTime
          label="TIME"
          inputName="in_out_time"
          defaultValue={
            dayjs(data?.in_out_time, 'HH:mm:ss')?.isValid()
              ? dayjs(data?.in_out_time, 'HH:mm:ss')
              : null
          }
          onChangeHandler={changeDay}
          disabled
        />
      </div>
      <div>
        <CommonSelect
          label="YARD"
          inputName="yard"
          defaultValue={data?.yard || ''}
          onChangeHandler={changeSelect}
          option={selectOptions.yardOption}
          disabled
        />
      </div>
      <div>
        <CommonTextInput
          label="LOCATION"
          inputName="yard_location"
          defaultValue={data?.yard_location || null}
          onChangeHandler={changeText}
          maxLength={5}
          disabled={!isEditable}
        />
      </div>
      {equip === 'container' && (
        <>
          <div>
            <CommonTextInput
              label="WO DIV"
              inputName="wo_div"
              defaultValue={data?.wo_div || null}
              disabled
            />
          </div>
          <div>
            <CommonTextInput
              label="CATEGORY"
              inputName="category"
              defaultValue={
                DAILY_IN_OUT_CATEGORY_TYPE?.[data?.category] || null
              }
              disabled
            />
          </div>
          <div>
            <CommonTextInput
              label="STATUS"
              inputName="status"
              defaultValue={DAILY_IN_OUT_TYPE?.[data?.status] || null}
              disabled
            />
          </div>
        </>
      )}
    </StyledModalInputContainer>
  );
};

export default BasicInfo;
