import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BasicInfo from './BasicInfo';
import StyledModalContentWrapper from '../../../../../style/Safety/Common/Modal/StyledModalContentWrapper';
import Depreciation from './Depreciation';
import LoanInfo from './LoanInfo';
import OtherInfo from './OtherInfo';

const ModalContent = ({ formController, isEditable, ...props }) => {
  return (
    <FormProvider {...formController}>
      <form>
        <StyledModalContentWrapper>
          <BasicInfo data={props?.data} isEditable={isEditable} />
          <LoanInfo data={props?.data} isEditable={isEditable} />
          <Depreciation data={props?.data} isEditable={isEditable} />
          <OtherInfo data={props?.data} isEditable={isEditable} />
        </StyledModalContentWrapper>
      </form>
    </FormProvider>
  );
};

export default ModalContent;
