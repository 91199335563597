import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import accidentKeys from '../keys';
import getAccidentList from '../../../../service/Safety/Accident/Get/getAccidentList';

const useGetAccidentList = () => {
  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: accidentKeys.accidentList(),
    queryFn: () => getAccidentList(),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const [accidentList, setAccidentList] = useState([]);

  useEffect(() => {
    if (data?.data) {
      setAccidentList(prev => data?.data);
    } else {
      setAccidentList([]);
    }
  }, [data?.data]);

  return { accidentList, isLoading, isFetching, isError };
};

export default useGetAccidentList;
