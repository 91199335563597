import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getSelectedDailyInOut from '../../../service/DailyInOut/Get/getSelectedDailyInOut';

const useGetSelectedDailyInOut = ({ seq }) => {
  const {
    isLoading: isLoadingSelectedDailyInOut,
    data,
    isRefetching: isRefetchingSelectedDailyInOut,
    isError: isErrorSelectedDailyInOut,
  } = useQuery({
    queryKey: dailyInOutKeys?.selected({ seq }),
    queryFn: () => getSelectedDailyInOut({ seq }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingSelectedDailyInOut,
    selectedDailyInOut: data?.data,
    isRefetchingSelectedDailyInOut,
    isErrorSelectedDailyInOut,
  };
};

export default useGetSelectedDailyInOut;
