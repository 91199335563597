import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getInventoryContainerList from '../../../service/DailyInOut/Get/getInventoryContainerList';

const useGetInventoryContainerList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryContainerList,
    data,
    isRefetching: isRefetchingInventoryContainerList,
    isError: isErrorInventoryContainerList,
  } = useQuery({
    queryKey: dailyInOutKeys?.containerList({ div, yard }),
    queryFn: () => getInventoryContainerList({ div, yard }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingInventoryContainerList,
    inventoryContainerList: data?.data,
    isRefetchingInventoryContainerList,
    isErrorInventoryContainerList,
  };
};

export default useGetInventoryContainerList;
