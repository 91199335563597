import React, { memo, useEffect, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import StyledFileTableWrapper from '../../../../../style/Safety/Common/Modal/FileTable/StyledFileTableWrapper';
import FileTableHeader from './FileTableHeader';
import FileTableRow from './FileTableRow';
import { ALERT_MODAL_STATUS } from '../../../../../constant/Common/Modal/Alert/status';
import CommonAlert from '../../../../Common/Modal/Alert';
import clickOKButton from '../../../../../util/Safety/Common/Handler/Save/okButtonHandler';

const FileTable = ({ ...props }) => {
  const { control, getValues, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'doc',
  });

  const { page, isEditable } = props;

  /** Message modal opener */
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertModalStatus, setAlertModalStatus] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });
  const alertDataSetter = { setAlertOpen, setAlertModalStatus };

  return (
    <>
      <StyledFileTableWrapper>
        {/* Header */}
        <FileTableHeader />
        {/* Content */}
        {fields?.map((field, index) => {
          return (
            <FileTableRow
              key={field?.id}
              rowIdx={index}
              type={index === 0 ? 'add' : 'modify'}
              data={field}
              append={append}
              remove={remove}
              page={page}
              isEditable={isEditable}
              alertDataSetter={alertDataSetter}
            />
          );
        })}
      </StyledFileTableWrapper>
      {isAlertOpen && (
        <CommonAlert
          isOpen={isAlertOpen}
          setOpen={setAlertOpen}
          status={alertModalStatus?.status}
          content={alertModalStatus?.message}
          onOKButtonClick={() => {
            clickOKButton?.({
              setAlertOpen,
              status: alertModalStatus?.status,
            });
          }}
        />
      )}
    </>
  );
};

export default FileTable;
