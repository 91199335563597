import styled from 'styled-components';

const StyledDivisionSelectorLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 28px;
  width: 90px;

  padding: 5px 10px;

  border-radius: 5px;
  background-color: #fff;

  cursor: pointer;
`;

export default StyledDivisionSelectorLabelWrapper;
