import React, { useState } from 'react';
import { PATH_DATAGRID_FOLD_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Datagrid';

const FoldHeaderRenderer = ({ ...props }) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <div
      className="h-full w-full flex justify-center items-start mt-[30px] cursor-pointer"
      onClick={() => {
        props?.api?.forEachNode(node => {
          node?.setExpanded(!isExpand);
        });
        setIsExpand(prev => !prev);
      }}
    >
      {isExpand ? (
        <img src={PATH_DATAGRID_FOLD_ICON?.fold} alt="gridFoldIcon" />
      ) : (
        <img src={PATH_DATAGRID_FOLD_ICON?.unfold} alt="gridUnfoldIcon" />
      )}
    </div>
  );
};

export default FoldHeaderRenderer;
