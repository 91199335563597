/** Error codes that should be handled specially */
export const ERROR_SPECIFIC_CODES = [
  'permission_denied',
  'not_found',
  'inactive_user',
  'dependent',
  'yard_with_inventory_deactivation_failed',
  'inventory_insert_failed',
  'inventory_delete_failed',
  'empty_fields',
  'group_has_user',
  'in_out_daily_permission_missing',
  'yard_with_daily_in_out_deletion_failed',
  'empty_fields',
  'username_creation_failed',
  'login_failed',
  'out_of_range',
];
