import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { isBoolean } from 'lodash';
import StyledCommonButton from '../../../../style/Common/Controller/Button/StyledCommonButton';
import StyledCommonButtonContent from '../../../../style/Common/Controller/Button/StyledCommonButtonContent';
import StyledCommonButtonIconWrapper from '../../../../style/Common/Controller/Button/StyledCommonButtonIcon/StyledCommonButtonIconWrapper';
import StyledCommonButtonIconContent from '../../../../style/Common/Controller/Button/StyledCommonButtonIcon/StyledCommonButtonIconContent';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

/**
 * @description
 * Common button component.
 * @param {object} children
 * Render the children inside the button.
 * @param {object} styles
 * Custom style for the button.
 * @param {object} onClick
 * Function to handle the button click event.
 * @returns {React.ReactElement}
 */
const CommonButton = forwardRef(
  ({ children, styles, onClick, type, ...props }, ref) => {
    const { icon } = props;
    const buttonRef = ref || useRef(null);

    const [buttonIcon, setButtonIcon] = useState(icon?.default || null);
    const [isHovered, setHovered] = useState(false);
    const [isDisabled, setDisabled] = useState(props?.disabled || false);
    useEffect(() => {
      setButtonIcon(prev => (isHovered ? icon?.hover : icon?.default));
    }, [isHovered]);
    useEffect(() => {
      setDisabled(props?.disabled);
    }, [props?.disabled]);
    useEffect(() => {
      if (isDisabled) setButtonIcon(icon?.disabled);
    }, [isDisabled]);

    useEffect(() => {
      setButtonIcon(prev => icon?.default || null);
    }, [icon]);

    /* The disabled state should be updated when the property is changed. */
    useEffect(() => {
      if (props?.disabled !== null && props?.disabled !== undefined)
        setDisabled(props?.disabled);
    }, [props?.disabled]);

    /** The disabled state should be synced when the ref's disabled is changed.
     *  The priority should be the passed property. */
    useLayoutEffect(() => {
      if (isBoolean(buttonRef?.current?.disabled))
        setDisabled(props?.disabled || buttonRef?.current?.disabled);
    }, [buttonRef?.current?.disabled]);

    return (
      <StyledCommonButton
        ref={buttonRef}
        styles={{ ...styles }}
        {...props}
        onClick={e => {
          props?.preventDupClick &&
            updateButtonDisabled({ ref: buttonRef, disabled: true });
          onClick?.({ e, buttonRef });
        }}
        onMouseEnter={() => setHovered(prev => true)}
        onMouseLeave={() => setHovered(prev => false)}
        type={type || 'button'}
        disabled={isDisabled}
      >
        {/* Icon */}
        {icon && (
          <StyledCommonButtonIconWrapper styles={{ ...styles }}>
            <StyledCommonButtonIconContent
              src={buttonIcon}
              styles={{ ...styles }}
            />
          </StyledCommonButtonIconWrapper>
        )}
        {/* Content */}
        {children && (
          <StyledCommonButtonContent>{children}</StyledCommonButtonContent>
        )}
      </StyledCommonButton>
    );
  },
);

export default CommonButton;
