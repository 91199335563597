import dayjs from 'dayjs';

export const changeText = ({ e, ...props }) => {
  const { onChange, regex } = props;
  if (regex && !regex.test(e?.target?.value)) {
    return false;
  }
  onChange?.(e?.target?.value);
  return true;
};

export const changeSelect = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.value);
  return true;
};

export const changeAutoComplete = ({ e, ...props }) => {
  const { onChange, selectProps } = props;
  onChange?.(selectProps?.value);
  return true;
};

export const changeDay = ({ e, ...props }) => {
  const { onChange } = props;
  const dateValue = e && dayjs(e)?.isValid() ? dayjs(e) : null;
  onChange?.(dateValue);
  return true;
};

export const changeCheckbox = ({ e, ...props }) => {
  const { onChange } = props;
  onChange(e?.target?.checked);
  return true;
};

export const blurUpperCaseChange = ({ e, ...props }) => {
  const { onChange } = props;
  onChange(e?.target?.value?.toUpperCase());
  return true;
};
