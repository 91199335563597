import styled from 'styled-components';
import { ERROR_BACKGROUND_COLOR } from '../../../constant/Common/Style/color';

const StyledErrorPageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${ERROR_BACKGROUND_COLOR};
`;

export default StyledErrorPageWrapper;
