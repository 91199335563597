import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import StyledEditCellRenderer from '../../../../../../style/Common/Datagrid/EditCellRenderer/StyledEditCellRenderer';
import convertFilterValue from '../../../../../../util/Common/Datagrid/Handler/convertFilterValue';
import generateAdornmentContent from '../../../../../../util/Common/Datagrid/Generator/generateAdornmentContent';

/**
 * @description
 * Input grid filter renderer
 * @param {object} props
 * Grid props
 * @param {object} ref
 * Grid reference
 */
const FloatingFilterInput = forwardRef((props, ref) => {
  /** Get grid parameter */
  const {
    adornment,
    placeholder,
    isCancelBeforeStartHandler,
    inputType,
    maxLength,
  } = props;

  /** Component reference */
  const compRef = useRef(null);

  /**
   * Fetch function to give delay and enhance performance
   * @see https://stackoverflow.com/questions/36294134/lodash-debounce-with-react-input
   * @see https://github.com/hyuabot-developers/hyuabot-campus-map/blob/main/src/features/search/RoomSearchBar.tsx
   */
  const fetch = useMemo(
    () =>
      debounce(({ value }) => {
        if (!props?.api?.isDestroyed()) {
          props?.onModelChange(
            value === ''
              ? null
              : {
                  ...(props?.model || {
                    type: 'contains',
                  }),
                  filter: value,
                },
          );
        }
      }, 400),
    [props?.onModelChange],
  );

  /** Input component property */
  const [value, setDataValue] = useState(
    props?.value || (props?.model && props?.model?.filter) || '',
  );
  useEffect(() => {
    fetch({ value });
  }, [value]);

  const [disabled, setDisabled] = useState(false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <StyledEditCellRenderer>
      <TextField
        variant="outlined"
        ref={compRef}
        value={value}
        onChange={e => {
          setDataValue(prev =>
            convertFilterValue({
              value: e?.target?.value,
              inputType,
            }),
          );
        }}
        sx={{
          width: '100%',
          input: {
            border: 'unset !important',
            zIndex: '9',
            WebkitTextFillColor: disabled && '#222 !important',
            paddingLeft: '2px !important',
            paddingRight: '2px !important',
            fontSize: '12px',
          },
          '& input::placeholder': {
            fontSize: '12px !important',
          },
          '.MuiInputBase-root': {
            height: '24px',
            fontSize: '12px',
            fontWeight: '500',
            '&.Mui-focused fieldset': {
              border: '1px solid #D9D9D9 !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
            },
            '&.Mui-disabled fieldset': {
              color: 'red !important',
              backgroundColor: 'unset !important',
            },
          },
          fieldset: {
            borderColor: '#D9D9D9 !important',
            backgroundColor: `#fff !important`,
          },
        }}
        disabled={disabled}
        placeholder={placeholder || ''}
        InputProps={{
          startAdornment:
            adornment?.position === 'start' &&
            generateAdornmentContent({
              adornment,
            }),
          endAdornment:
            adornment?.position === 'end' &&
            generateAdornmentContent({
              adornment,
            }),
          inputProps: {
            maxLength,
            autoComplete: 'off',
          },
        }}
      />
    </StyledEditCellRenderer>
  );
});

export default FloatingFilterInput;
