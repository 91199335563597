import React from 'react';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import CommonTextarea from '../../../../../Common/Controller/Textarea';
import { changeText } from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import FileTable from '../../../../Common/Modal/FileTable';
import { STYLE_EACH_ROW_WRAPPER } from '../../../../../../style/Safety/Common/style';
import CommonToggle from '../../../../../Common/Controller/Toggle';

const OtherInfo = ({ ...props }) => {
  const { data, isEditable } = props;
  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Other Info
      </div>
      {/* Content */}
      <div className={`${STYLE_EACH_ROW_WRAPPER} pt-[22px] flex-col`}>
        <div className="w-full pr-[22px]">
          <FileTable page="truck" isEditable={isEditable} />
        </div>
        <div className="w-full pr-[22px]">
          <div className="w-full flex justify-end flex-row">
            <div className="flex justify-center items-center text-[11px] text-[#264B9F] font-bold">
              ACTIVE
            </div>
            <div>
              <CommonToggle
                labelVisible={false}
                helpTextVisible={false}
                onChangeHandler={({ ...controlProps }) => {
                  const { onChange, value } = controlProps;
                  onChange?.(!value);
                }}
                disabled={!isEditable}
                options={[
                  {
                    inputName: 'active',
                    defaultValue: data?.active,
                  },
                ]}
              />
            </div>
          </div>
          <CommonTextarea
            label="REMARKS"
            inputName="remarks"
            maxLength={120}
            onChangeHandler={({ ...changeProps }) => {
              changeText({ ...changeProps });
            }}
            defaultValue={data?.remarks}
            disabled={!isEditable}
          />
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default OtherInfo;
