import safetyDefaultAPI from '../../api';
import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';

const patchCompleted = async ({ seq }) => {
  try {
    const response = await safetyDefaultAPI.patch(
      `damage-report/${seq}/completed`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchCompleted;
