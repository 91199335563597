import dayjs from 'dayjs';
import { DAMAGE_STATUS } from '../../../../constant/Safety/DamageReport/standardCodes';

export const createStatusData = status => {
  const value = Object?.keys(DAMAGE_STATUS)?.find(
    key => DAMAGE_STATUS[key] === status,
  );

  return value;
};

export const createDateData = date => {
  const value =
    date && dayjs(date)?.isValid() ? dayjs(date)?.format('MM/DD/YY') : '';

  return value;
};

export const createTimeData = time => {
  const value =
    time && dayjs(time, 'HH:mm:ss')?.isValid()
      ? dayjs(time, 'HH:mm:ss').format('hh:mm')
      : '';
  return value;
};

export const createBooleanData = data => {
  const value = data ? 'Y' : 'N';
  return value;
};
