import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonSelect from '../../../Common/Controller/Select';
import {
  STATUS_FORM_OPTION_LIST,
  TRUCK_DAMAGE_BODY_CHECKBOX_OPTION,
  TRUCK_DAMAGE_CHECKBOX_FIRST_OPTION_LIST,
  TRUCK_DAMAGE_CHECKBOX_SECOND_OPTION_LIST,
} from '../../../../constant/Safety/DamageReport/options';
import StyledCheckBoxArea from '../../../../style/Safety/DamageReport/StyledCheckBoxArea';
import CommonDamageCheckBox from '../CommonDamageCheckBox';
import CustomTextInput from '../../../Common/Controller/Text/Custom';
import { handleInputChange } from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';
import {
  OTHER_INPUT_STYLE,
  STYLE_TRUCK_STATUS_CHECKBOX_AREA,
} from '../../../../style/Safety/DamageReport/styles';
import { DAMAGE_STATUS } from '../../../../constant/Safety/DamageReport/standardCodes';

const TruckDamagedInfoFields = ({ isCompleted, damageReport, isEditable }) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    if (!watch('body_condition')) {
      setValue('body_condition_remarks', '');
    }
  }, [watch('body_condition')]);
  return (
    <div className="flex flex-col w-full">
      <div className="w-full">
        <div className="w-[221px]">
          <CommonSelect
            label="STATUS"
            option={STATUS_FORM_OPTION_LIST}
            inputName="status"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.status || DAMAGE_STATUS?.DAMAGED}
          />
        </div>
      </div>
      <div className="w-full mt-[-8px]">
        <StyledCheckBoxArea styles={{ ...STYLE_TRUCK_STATUS_CHECKBOX_AREA }}>
          <div>
            <CommonDamageCheckBox
              options={TRUCK_DAMAGE_CHECKBOX_FIRST_OPTION_LIST}
              disabled={isCompleted || !isEditable}
            />
          </div>
          <div className="mt-[11px] flex">
            <div className="w-[110px] mt-[2px]">
              <CommonDamageCheckBox
                options={TRUCK_DAMAGE_BODY_CHECKBOX_OPTION}
                disabled={isCompleted || !isEditable}
              />
            </div>
            <div className="mt-[-1px]">
              <CustomTextInput
                inputName="body_condition_remarks"
                disabled={
                  isCompleted || !watch('body_condition') || !isEditable
                }
                required={watch('body_condition')}
                maxLength={20}
                suppressLabelVisible
                suppressLabelContentVisible
                suppressErrorVisible
                suppressErrorContentVisible
                styles={{ height: '20px', width: '319px' }}
                onChange={handleInputChange}
                defaultValue={damageReport?.body_condition_remarks || ''}
              />
            </div>
          </div>
          <div className="mt-[9px]">
            <CommonDamageCheckBox
              disabled={isCompleted || !isEditable}
              options={TRUCK_DAMAGE_CHECKBOX_SECOND_OPTION_LIST}
            />
          </div>
          <div className="flex mt-[14px] items-center gap-x-[7px]">
            <div className="font-[400] text-[12px] text-[#494949]">Other</div>
            <div>
              <CustomTextInput
                inputName="trk_others"
                maxLength={120}
                suppressLabelVisible
                suppressLabelContentVisible
                suppressErrorVisible
                suppressErrorContentVisible
                styles={OTHER_INPUT_STYLE}
                disabled={isCompleted || !isEditable}
                onChange={handleInputChange}
                defaultValue={damageReport?.trk_others || ''}
              />
            </div>
          </div>
        </StyledCheckBoxArea>
      </div>
    </div>
  );
};

export default TruckDamagedInfoFields;
