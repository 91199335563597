/**
 * @description
 * Function that handle unauthorized status code (401)
 * @returns {string}
 * Error message
 */
export const handleErrorUnauthorized = () => {
  return 'Unauthorized. Please try again.';
};

/**
 * @description
 * Function that handle forbidden status code (403)
 * @returns {string}
 * Error message
 */
export const handleErrorForbidden = () => {
  window.location.replace('/error/403');
  return 'Permission Denied. Please try again.';
};

/**
 * @description
 * Function that handle internal server error status code (500)
 * @returns {string}
 * Error message
 */
export const handleErrorInternalServerError = () => {
  window.location.replace('/error/500');
  return 'Oops! Something went wrong. Please contact the IT team for assistance.';
};

/**
 * @description
 * Function that handle not found error status code (404)
 * @returns {string}
 * Error message
 */
export const handleNotFound = () => {
  return `Please try again. Cannot found from your request`;
};
