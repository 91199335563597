import { PATH_COMMON_BUTTON_ICON } from '../../Common/Path/Asset/Icon/Button';
import {
  PRESET_GREEN,
  PRESET_GREY,
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../Common/Style/preset';

export const PRESET_MAPPER = [
  { name: 'PRESET_PRIMARY', styles: PRESET_PRIMARY },
  { name: 'PRESET_RED', styles: PRESET_RED },
  { name: 'PRESET_GREEN', styles: PRESET_GREEN },
  { name: 'PRESET_GREY', styles: PRESET_GREY },
];

export const PRESET_ICON_MAPPER = [
  {
    name: 'checkBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
  },
  {
    name: 'editGrey',
    styles: PRESET_GREY,
    icon: PATH_COMMON_BUTTON_ICON?.editGrey,
  },
  {
    name: 'saveBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON?.saveBlue,
  },
  {
    name: 'cancelRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON?.cancelRed,
  },
  {
    name: 'deleteGrey',
    styles: PRESET_GREY,
    icon: PATH_COMMON_BUTTON_ICON?.deleteGrey,
  },
  {
    name: 'deleteBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON?.deleteBlue,
  },
  {
    name: 'deleteRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON?.deleteRed,
  },
  {
    name: 'plusBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON?.plusBlue,
  },
  {
    name: 'clearRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON?.clearRed,
  },
  {
    name: 'clearBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON?.clearBlue,
  },
  {
    name: 'pdfRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON?.pdfRed,
  },
  {
    name: 'excelGreen',
    styles: PRESET_GREEN,
    icon: PATH_COMMON_BUTTON_ICON.excelGreen,
  },
  {
    name: 'syncBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.syncBlue,
  },
  {
    name: 'circleCheckBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.circleCheckBlue,
  },
  {
    name: 'banRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.banRed,
  },
  {
    name: 'editBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.editBlue,
  },
  {
    name: 'exportBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.exportBlue,
  },
  {
    name: 'importBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.importBlue,
  },
  {
    name: 'exchangeExportBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.exchangeExportBlue,
  },
  {
    name: 'exchangeImportBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.exchangeImportBlue,
  },
  {
    name: 'uploadBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.uploadBlue,
  },
  {
    name: 'leaveBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.leaveBlue,
  },
  {
    name: 'inactiveRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.inactiveRed,
  },
  {
    name: 'activeGreen',
    styles: PRESET_GREEN,
    icon: PATH_COMMON_BUTTON_ICON.activeGreen,
  },
  {
    name: 'trackingGrey',
    styles: PRESET_GREY,
    icon: PATH_COMMON_BUTTON_ICON.trackingGrey,
  },
  {
    name: 'trackingBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.trackingBlue,
  },
  {
    name: 'searchBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.searchBlue,
  },
  {
    name: 'searchGrey',
    styles: PRESET_GREY,
    icon: PATH_COMMON_BUTTON_ICON.searchGrey,
  },
  {
    name: 'bcDetailRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.bcDetailRed,
  },
  {
    name: 'qbGreen',
    styles: PRESET_GREEN,
    icon: PATH_COMMON_BUTTON_ICON.qbGreen,
  },
  {
    name: 'scanBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.scanBlue,
  },
  {
    name: 'backRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.backRed,
  },
  {
    name: 'assignBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.assignBlue,
  },
  {
    name: 'createBillBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.createBillBlue,
  },
  {
    name: 'clipFileRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.clipFileRed,
  },
  {
    name: 'paperPlaneBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.paperPlaneBlue,
  },
  {
    name: 'invoiceBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.invoiceBlue,
  },
  {
    name: 'overWriteBlue',
    styles: PRESET_PRIMARY,
    icon: PATH_COMMON_BUTTON_ICON.overWriteBlue,
  },
  {
    name: 'skipRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.skipRed,
  },
  {
    name: 'exclamationRed',
    styles: PRESET_RED,
    icon: PATH_COMMON_BUTTON_ICON.exclamationRed,
  },
];
