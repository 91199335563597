import React from 'react';
import StyledInputWrapper from '../../../style/Labs/Input/StyledInputWrapper';
import InputContents from '../../../component/Labs/Input/InputContents';

const Input = () => {
  return (
    <StyledInputWrapper>
      <InputContents type="text" />
      <InputContents type="textAdornmentStart" />
      <InputContents type="textAdornmentEnd" />
      <InputContents type="autoComplete" />
      <InputContents type="select" />
      <InputContents type="date" />
      <InputContents type="time" />
      <InputContents type="textarea" />
      <InputContents type="file" />
      <InputContents type="checkbox" />
      <InputContents type="toggle" />
      <InputContents type="radio" />
      <InputContents type="multipleInput" />
      <InputContents type="textSelect" />
      <InputContents type="phoneNumber" />
    </StyledInputWrapper>
  );
};

export default Input;
