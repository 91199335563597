import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDefaultAPI from '../../api';

const postGroup = async ({ data }) => {
  try {
    const response = await adminDefaultAPI.post(`group`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postGroup;
