import React, { useState } from 'react';
import LabsContentsClassification from '../../LabsContentsClassification';
import CommonButton from '../../../Common/Controller/Button';
import handleTriggerActive from '../../../../util/Labs/Trigger/Handler/handleTriggerActive';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import CommonAlert from '../../../Common/Modal/Alert';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const AlertTrigger = () => {
  const BUTTON_COMMON_STYLES = {
    backgroundColor: '#001E5F',
    color: '#fff',
    width: '100px',
    height: '40px',
    fontSize: '16px',
  };

  const BUTTON_STABLE_STYLES = {
    ...BUTTON_COMMON_STYLES,
    backgroundColor: '#27a617',
  };
  const BUTTON_ERROR_STYLES = {
    ...BUTTON_COMMON_STYLES,
    backgroundColor: '#d51d36',
  };
  const BUTTON_SUCCESS_STYLES = {
    ...BUTTON_COMMON_STYLES,
    backgroundColor: '#27a617',
  };

  const [trigger, setTrigger] = useState({
    messageFrame: false,
    customMessageFrame: false,
    alertStable: false,
    alertError: false,
    alertSuccess: false,
    alertHorizontalLongContent: false,
    alertVerticalLongContent: false,
  });

  return (
    <>
      <div>
        <LabsContentsClassification className="Alert Trigger" />
        <div className="flex items-center p-[10px] gap-[20px]">
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'messageFrame',
                cancelTime: 0,
              })
            }
          >
            Message Frame
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'customMessageFrame',
                cancelTime: 0,
              })
            }
          >
            Custom Message
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_STABLE_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'alertStable',
                cancelTime: 0,
              })
            }
          >
            Alert Stable
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_ERROR_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'alertError',
                cancelTime: 0,
              })
            }
          >
            Alert Error
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_SUCCESS_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'alertSuccess',
                cancelTime: 0,
              })
            }
          >
            Alert Success
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'alertHorizontalLongContent',
                cancelTime: 0,
              })
            }
          >
            Alert Horizontal
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() =>
              handleTriggerActive({
                setTrigger,
                target: 'alertVerticalLongContent',
                cancelTime: 0,
              })
            }
          >
            Alert Vertical
          </CommonButton>
        </div>
      </div>
      {trigger?.messageFrame && (
        <CommonMessageModalFrame
          isOpen={trigger?.messageFrame}
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, messageFrame: false };
            })
          }
        />
      )}
      {trigger?.customMessageFrame && (
        <CommonMessageModalFrame
          isOpen={trigger?.customMessageFrame}
          header={<div>Hi</div>}
          content={<div>My name is...</div>}
          button={<div>James</div>}
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, customMessageFrame: false };
            })
          }
        />
      )}

      {trigger?.alertStable && (
        <CommonAlert
          isOpen={trigger?.alertStable}
          content="This is stable status alert modal."
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertStable: false };
            })
          }
          onOKButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertStable: false };
            })
          }
        />
      )}
      {trigger?.alertError && (
        <CommonAlert
          isOpen={trigger?.alertError}
          content="This is error status alert modal."
          status={ALERT_MODAL_STATUS.ERROR}
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertError: false };
            })
          }
          onOKButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertError: false };
            })
          }
        />
      )}
      {trigger?.alertSuccess && (
        <CommonAlert
          isOpen={trigger?.alertSuccess}
          content="This is success status alert modal."
          status={ALERT_MODAL_STATUS.SUCCESS}
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertSuccess: false };
            })
          }
          onOKButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertSuccess: false };
            })
          }
        />
      )}
      {trigger?.alertHorizontalLongContent && (
        <CommonAlert
          isOpen={trigger?.alertHorizontalLongContent}
          content="This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. This is very long long long contents. "
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertHorizontalLongContent: false };
            })
          }
          onOKButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertHorizontalLongContent: false };
            })
          }
        />
      )}
      {trigger?.alertVerticalLongContent && (
        <CommonAlert
          isOpen={trigger?.alertVerticalLongContent}
          content={
            <div className="flex flex-col gap-[1px]">
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
              <div>This is very long long long contents.</div>
            </div>
          }
          onCloseButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertVerticalLongContent: false };
            })
          }
          onOKButtonClick={() =>
            setTrigger(prev => {
              return { ...prev, alertVerticalLongContent: false };
            })
          }
        />
      )}
    </>
  );
};

export default AlertTrigger;
