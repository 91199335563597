import { FormControlLabel, Switch } from '@mui/material';
import React, {
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';

const GridSwitchCell = forwardRef((props, ref) => {
  const {
    setValue,
    onValueChange,
    onChangeHandler,
    isCancelBeforeStartHandler,
  } = props;

  /** Input inner component reference */
  const compRef = useRef(null);

  /** Input component property */
  const [value, setDataValue] = useState(props?.value || false);
  const [disabled, setDisabled] = useState(false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <div className="flex justify-center items-center h-full">
      <Switch
        ref={compRef}
        checked={value}
        disabled={disabled}
        onChange={e => onChangeHandler?.({ e, setDataValue, ...props })}
        sx={{
          '&.MuiSwitch-root': {
            '.Mui-checked': {
              color: '#14357D',
            },
            '.Mui-checked+.MuiSwitch-track': {
              backgroundColor: '#556DA1',
              opacity: '0.7',
            },
          },
        }}
      />
    </div>
  );
});

export default GridSwitchCell;
