import React from 'react';
import CommonButton from '../../../Common/Controller/Button';
import {
  PRESET_GREEN,
  PRESET_PRIMARY,
} from '../../../../constant/Common/Style/preset';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import StyledUtilityBarWrapper from '../../../../style/Safety/Common/UtilityBar/StyledUtilityBarWrapper';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import exportToExcel from '../../../../util/Common/Datagrid/Handler/exportToExcel';

const UtilityBar = ({ ...props }) => {
  const { setModalOpen, setEditMode, gridRef, isEditable, subPage } = props;

  return (
    <StyledUtilityBarWrapper>
      <div className="flex justify-end items-center gap-[20px]">
        <CommonButton
          styles={PRESET_GREEN}
          icon={PATH_COMMON_BUTTON_ICON.excelGreen}
          onClick={() => exportToExcel({ gridRef, fileName: subPage })}
        >
          EXCEL
        </CommonButton>
        {isEditable && (
          <CommonButton
            styles={PRESET_PRIMARY}
            icon={PATH_COMMON_BUTTON_ICON.plusBlue}
            onClick={() => {
              setEditMode(prev => EDIT_MODE?.add);
              setModalOpen(prev => true);
            }}
          >
            Add
          </CommonButton>
        )}
      </div>
    </StyledUtilityBarWrapper>
  );
};

export default UtilityBar;
