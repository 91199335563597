import React from 'react';
import StyledCommonMessageModalContentWrapper from '../../../../../../style/Common/Modal/Message/Frame/Content/StyledCommonMessageModalContentWrapper';

const CommonMessageModalFrameContent = ({ children, ...props }) => {
  return (
    <StyledCommonMessageModalContentWrapper>
      {children}
    </StyledCommonMessageModalContentWrapper>
  );
};

export default CommonMessageModalFrameContent;
