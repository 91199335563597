import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchITTabletActive from '../../../../service/Safety/ITTablet/Patch/patchITTabletActive';
import useControlToast from '../../../Common/Toast/useControlToast';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchITTabletActiveMutation = ({
  gridRef,
  disabledRef,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchITTabletActive,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { seq: variables?.seq, active: !data?.data?.data },
        isUpdate: true,
      });
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: (error, variables) => {
      variables?.setChecked(checked => !checked);
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error?.message);
      setIsMessageOpen(true);
    },
    onSettled: () => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
    },
  });
};

export default usePatchITTabletActiveMutation;
