import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import deleteGroup from '../../../../service/Admin/Auth/Delete/deleteGroup';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminAuthKeys from '../keys';

const useDeleteGroupMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteGroup,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: adminAuthKeys?.group() });
      addToast?.({ message: SUCCESS_DELETE });
    },
    onError: error => {
      queryClient.refetchQueries({ queryKey: adminAuthKeys?.group() });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteGroupMutation;
