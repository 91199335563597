import { useMemo } from 'react';
import generateModificationColumn from '../../../util/Common/Datagrid/Generator/generateModificationColumn';
import generateDefaultColumnDefs from '../../../util/Common/Datagrid/Generator/generateDefaultColumnDefs';

/**
 * @description
 * Custom hook to get column definitions for the data grid
 * If need reference, please see this article.
 * @see 1. https://www.ag-grid.com/react-data-grid/grid-options/#reference-columns
 * @see 2. https://www.ag-grid.com/react-data-grid/column-definitions/
 *
 * @param {object} columnDefs: paramColumnDefs
 * Array of Column / Column Group definitions.
 * @param {object} defaultColDef: paramDefaultColDef
 * Contains properties that all columns will inherit.
 * @param {object} defaultColGroupDef: paramDefaultColGroupDef
 * Contains properties that all column groups will inherit.
 * @param {*} columnTypes: paramColumnTypes
 * Specific column types containing properties that column definitions can inherit.
 * @param {function} onDeleteClicked
 * Function when delete button clicked.
 * @param {function} onAddClicked
 * Function when add button clicked.
 * @param {string} modType
 * Patameter that indicate the type of modification of the grid
 * GRID_MOD_BOTH: Enable edit and delete
 * GRID_MOD_EDIT: Enable edit only
 * GRID_MOD_DELETE: Enable delete only
 * @returns {object}
 * Memoed column definitions for the data grid
 */
const useGetColumnDefs = ({
  paramColumnDefs,
  paramDefaultColDef,
  paramDefaultColGroupDef,
  paramColumnTypes,
  onDeleteClicked,
  onAddClicked,
  modType,
  addable,
  foldable,
  ...props
}) => {
  /**
   * Reference 01: https://www.ag-grid.com/react-data-grid/column-definitions/
   * Reference 02: https://www.ag-grid.com/react-data-grid/column-properties/
   */
  const columnDefs = useMemo(() => {
    return generateModificationColumn({
      columns: paramColumnDefs,
      modType,
      onDeleteClicked,
      onAddClicked,
      addable,
      foldable,
      ...props,
    });
  }, [paramColumnDefs]);

  /** Reference: https://www.ag-grid.com/react-data-grid/column-definitions/#default-column-definitions */
  const defaultColDef = useMemo(
    () => paramDefaultColDef || generateDefaultColumnDefs(),
    [],
  );
  const defaultColGroupDef = useMemo(() => paramDefaultColGroupDef, []);
  const columnTypes = useMemo(() => paramColumnTypes, []);

  return {
    columnDefs,
    defaultColDef,
    defaultColGroupDef,
    columnTypes,
  };
};

export default useGetColumnDefs;
