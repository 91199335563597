import { CELL_TYPE_TEXT } from '../../../../constant/Common/DataGrid/cellType';
import { PATH_DATAGRID_GROUP_FOLD_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createAddAdornmentGroup = () => {
  return [
    {
      headerName: 'Group05 - Add Adornment',
      backgroundColor: '#673dff',
      color: '#ffffff',
      icon: PATH_DATAGRID_GROUP_FOLD_ICON,
      isColumnGroup: true,
      children: [
        {
          headerName: '₩ - Start Adornment',
          field: 'won',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '₩',
                position: 'start',
              },
            }),
        },
        {
          headerName: '₩ - End Adornment',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '₩',
                position: 'end',
              },
              styles: { textAlign: 'right' },
            }),
        },
        {
          headerName: '$ - Start Adornment',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '$',
                position: 'start',
              },
            }),
        },
        {
          headerName: '$ - End Adornment',
          field: 'won',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '$',
                position: 'end',
              },
              styles: { textAlign: 'right' },
            }),
        },
      ],
    },
  ];
};

export default createAddAdornmentGroup;
