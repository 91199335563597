export const COMMON_LOAN_STATUS = [
  {
    seq: 0,
    label: 'Finish',
    value: 'LS0001',
  },
  {
    seq: 1,
    label: 'Continue',
    value: 'LS0002',
  },
];
