import { useEffect, useState } from 'react';

const useControlLoadingStatus = ({ ...props }) => {
  const { isFetching, isLoading, isPending } = props;

  const [isLoadingOpen, setLoadingOpen] = useState(false);

  useEffect(() => {
    if (
      isFetching.includes(true) ||
      isLoading.includes(true) ||
      isPending.includes(true)
    )
      setLoadingOpen(true);
    else setLoadingOpen(false);
  }, [isFetching, isLoading, isPending]);

  return { isLoadingOpen, setLoadingOpen };
};

export default useControlLoadingStatus;
