import { GRID_MOD_COL_ID } from '../../../../constant/Common/DataGrid/setting';

/**
 * @description
 * Function that refresh the modification column according to the editing status.
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 */
const refreshModificationCell = ({ gridParam, ...props }) => {
  /** Refresh modification column according to editing status */
  gridParam.api.refreshCells({
    columns: [GRID_MOD_COL_ID],
    rowNodes: [gridParam?.node],
    force: true,
  });
};
export default refreshModificationCell;
