import dayjs from 'dayjs';
import {
  ACCIDENT_STATUS_CODE,
  OP_CODE,
  RESPONSIBILITY_CODE,
} from '../../../../constant/Safety/Accident/standardCodes';

export const generateDateValue = date => {
  const value =
    date && dayjs(date).isValid() ? dayjs(date).format('MM/DD/YY') : '';
  return value;
};

export const generateStatusValue = status => {
  const value = Object?.keys(ACCIDENT_STATUS_CODE)?.find(
    key => ACCIDENT_STATUS_CODE[key] === status,
  );

  return value;
};

export const generateOPValue = op => {
  const value = Object?.keys(OP_CODE)?.find(key => OP_CODE[key] === op);

  return value;
};

export const generateResponsibility = responsibility => {
  const value = Object?.keys(RESPONSIBILITY_CODE)?.find(
    key => RESPONSIBILITY_CODE[key] === responsibility,
  );

  return value;
};
