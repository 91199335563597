/** Main category according to type */
export const SAFETY_COMMON_TRK_TYPE_CATEGORY = [
  {
    seq: 0,
    label: 'MAINT',
    value: 'MRT001',
  },
  {
    seq: 1,
    label: 'PART',
    value: 'MRT002',
  },
  {
    seq: 2,
    label: 'TIRE',
    value: 'MRT003',
  },
  {
    seq: 3,
    label: 'OTHER',
    value: 'MRT004',
  },
];

export const SAFETY_COMMON_CHS_TYPE_CATEGORY = [
  {
    seq: 0,
    label: 'TIRE',
    value: 'MRC001',
  },
  {
    seq: 1,
    label: 'MAINT',
    value: 'MRC002',
  },
  {
    seq: 2,
    label: 'PART',
    value: 'MRC003',
  },
  {
    seq: 3,
    label: 'OTHER',
    value: 'MRC004',
  },
];

export const SAFETY_COMMON_TRL_TYPE_CATEGORY = [
  {
    seq: 0,
    label: 'TIRE',
    value: 'MRL001',
  },
  {
    seq: 1,
    label: 'MAINT',
    value: 'MRL002',
  },
  {
    seq: 2,
    label: 'PART',
    value: 'MRL003',
  },
  {
    seq: 3,
    label: 'OTHER',
    value: 'MRL004',
  },
];

export const SAFETY_COMMON_OTHER_TYPE_CATEGORY = [
  {
    seq: 0,
    label: 'SERVICE TRUCK',
    value: 'MRO001',
  },
  {
    seq: 1,
    label: 'OTHER',
    value: 'MRO002',
  },
];

/** Sub category according to type and category */

export const SAFETY_COMMON_SUB_CATEGORY = {
  MR0001: {
    MRT001: [
      {
        seq: 0,
        label: 'ENGINE',
        value: 'MRTM01',
      },
      {
        seq: 1,
        label: 'SUSPENSION',
        value: 'MRTM02',
      },
      {
        seq: 2,
        label: 'BRAKE',
        value: 'MRTM03',
      },
      {
        seq: 3,
        label: 'TRANSMISSION',
        value: 'MRTM04',
      },
    ],
    MRT002: [
      {
        seq: 0,
        label: 'AIR VALVE',
        value: 'MRTP01',
      },
      {
        seq: 1,
        label: 'BOLT',
        value: 'MRTP02',
      },
      {
        seq: 2,
        label: 'ELECTRIC',
        value: 'MRTP03',
      },
      {
        seq: 3,
        label: 'FILTER',
        value: 'MRTP04',
      },
      {
        seq: 4,
        label: 'BATTERY',
        value: 'MRTP05',
      },
      {
        seq: 5,
        label: 'AIR HOSE',
        value: 'MRTP06',
      },
      {
        seq: 6,
        label: 'MIRROR',
        value: 'MRTP07',
      },
      {
        seq: 7,
        label: 'BUMPER',
        value: 'MRTP08',
      },
      {
        seq: 8,
        label: 'GLADHAND',
        value: 'MRTP09',
      },
      {
        seq: 9,
        label: 'SENSOR',
        value: 'MRTP10',
      },
      {
        seq: 10,
        label: 'COOLANT',
        value: 'MRTP11',
      },
      {
        seq: 11,
        label: 'OTHER',
        value: 'MRTP12',
      },
    ],
    MRT003: [
      {
        seq: 0,
        label: 'STEER TIRE',
        value: 'MRTT01',
      },
      {
        seq: 1,
        label: 'DRIVE TIRE',
        value: 'MRTT02',
      },
      {
        seq: 2,
        label: 'WHEEL',
        value: 'MRTT03',
      },
    ],
    MRT004: [
      {
        seq: 0,
        label: 'OTHER',
        value: 'MRTO01',
      },
    ],
  },
  MR0002: {
    MRC001: [
      {
        seq: 0,
        label: 'NEW TIRE',
        value: 'MRCT01',
      },
      {
        seq: 1,
        label: 'FLAT TIRE',
        value: 'MRCT02',
      },
    ],
    MRC002: [
      {
        seq: 0,
        label: 'BRAKE',
        value: 'MRCM01',
      },
      {
        seq: 1,
        label: 'SUSPENSION',
        value: 'MRCM02',
      },
    ],
    MRC003: [
      {
        seq: 0,
        label: 'LANDING GEAR',
        value: 'MRCP01',
      },
      {
        seq: 1,
        label: 'ELECTRIC',
        value: 'MRCP02',
      },
      {
        seq: 2,
        label: 'AIR HOSE',
        value: 'MRCP03',
      },
      {
        seq: 3,
        label: 'GLADHAND',
        value: 'MRCP04',
      },
      {
        seq: 4,
        label: 'AIR VALVE',
        value: 'MRCP05',
      },
    ],
    MRC004: [
      {
        seq: 0,
        label: 'OTHER',
        value: 'MRCO01',
      },
    ],
  },
  MR0003: {
    MRL001: [
      {
        seq: 0,
        label: 'NEW TIRE',
        value: 'MRLT01',
      },
      {
        seq: 1,
        label: 'FLAT TIRE',
        value: 'MRLT02',
      },
    ],
    MRL002: [
      {
        seq: 0,
        label: 'BRAKE',
        value: 'MRLM01',
      },
      {
        seq: 1,
        label: 'SUSPENSION',
        value: 'MRLM02',
      },
    ],
    MRL003: [
      {
        seq: 0,
        label: 'LANDING GEAR',
        value: 'MRLP01',
      },
      {
        seq: 1,
        label: 'ELECTRIC',
        value: 'MRLP02',
      },
      {
        seq: 2,
        label: 'AIR HOSE',
        value: 'MRLP03',
      },
      {
        seq: 3,
        label: 'GLADHAND',
        value: 'MRLP04',
      },
      {
        seq: 4,
        label: 'AIR VALVE',
        value: 'MRLP05',
      },
    ],
    MRL004: [
      {
        seq: 0,
        label: 'OTHER',
        value: 'MRLO01',
      },
    ],
  },
  MR0004: {
    MRO001: [
      {
        seq: 0,
        label: 'OTHER',
        value: 'MROS01',
      },
    ],
    MRO002: [
      {
        seq: 0,
        label: 'OTHER',
        value: 'MROO01',
      },
    ],
  },
};
