import { PATH_EDITABLE_HEADER_MARK } from '../../../../constant/Common/Path/Asset/Icon/Button';

const createHeaderComponent = ({ ...props }) => {
  return {
    template: `<div class="ag-cell-label-container" role="presentation">
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                    <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                        <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                        ${
                          props?.column?.colDef?.editable ||
                          props?.column?.colDef?.clickable
                            ? `
                            <div class="flex justify-center items-center p-[5px]">
                                <img src=${PATH_EDITABLE_HEADER_MARK} alt="Editable Column Mark" />
                            </div>`
                            : ''
                        } <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                    </div>
                </div>`,
  };
};

export default createHeaderComponent;
