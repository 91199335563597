import React from 'react';
import StyledCommonInputAdornment from '../../../../style/Common/Controller/Common/Adornment/StyledCommonInputAdornment';

/**
 * @description
 * Function that generate start adornment content in Mui component
 * @param {string} cellType
 * Cell type
 * @returns
 * Start adornment component
 */
const generateAdornmentContent = ({ adornment, ...props }) => {
  if (adornment && adornment?.position && adornment?.content) {
    const { position, content, styles } = adornment;
    return (
      <StyledCommonInputAdornment position={position} $styles={styles}>
        {content}
      </StyledCommonInputAdornment>
    );
  }
  return null;
};

export default generateAdornmentContent;
