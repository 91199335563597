import handleCheckboxChange from '../Handler/handleCheckboxChange';

export const createObjOption = (list, property) => {
  const result = [
    {
      key: 0,
      label: 'Select',
      value: '',
    },
  ];

  list?.forEach((element, idx) => {
    result?.push({
      key: idx,
      label: element?.[property],
      value: element?.[property],
    });
  });

  return result;
};

export const createArrOption = list => {
  const result = [];

  list?.forEach((element, idx) => {
    result?.push({
      key: idx,
      label: element,
      value: element,
    });
  });

  return result;
};

export const createFilterOption = (list, property, { setQuery }) => {
  const result = [];

  list?.forEach((element, idx) => {
    result?.push({
      key: idx,
      label: element?.[property],
      value: element?.[property],
      onChange: ({ ...props }) =>
        handleCheckboxChange({
          ...props,
          setQuery,
        }),
    });
  });

  return result;
};
