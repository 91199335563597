import React from 'react';
import StyledErrorPageWrapper from '../../../../style/Error/StyledErrorPageWrapper';
import StyledErrorPageContentsWrapper from '../../../../style/Error/StyledErrorPageContentsWrapper';

/**
 * @description
 * Component of the not found page.
 * @returns {React.ReactElement}
 */
const ErrorNotFound = () => {
  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageContentsWrapper>
        <div>
          <p className="text-[#00235D] text-[75px] font-semibold">OOPS!</p>
        </div>
        <div className="pt-[36px]">
          <p className="text-[#00235D] text-[35px] font-semibold">
            PAGE NOT FOUND
          </p>
        </div>
        <div className="text-center pt-[30px] flex gap-[10px] flex-col">
          <p className="text-[#00235D] text-[22px] font-normal">
            Sorry, the page you’re looking for does not exist.
          </p>
          <p className="text-[#00235D] text-[22px] font-normal">
            If you think something is broken, report a problem.
          </p>
        </div>
        <div className="pt-[20px]">
          <p className="text-[#5D0000] text-[20px] font-normal">
            ERROR CODE: 404
          </p>
        </div>
      </StyledErrorPageContentsWrapper>
    </StyledErrorPageWrapper>
  );
};

export default ErrorNotFound;
