import React from 'react';

const LabsHeader = () => {
  return (
    <div className="w-full h-fit text-[50px] font-bold text-[#001E5F] p-[30px]">
      Labs
    </div>
  );
};

export default LabsHeader;
