import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import StyledNavWrapper from '../../../style/Inventory/FilterNavigator/StyledNavWrapper';
import StyledNavBoxContainer from '../../../style/Inventory/FilterNavigator/StyledNavBoxContainer';
import { INVENTORY_FILTER_NAVIGATOR_VALUE } from '../../../constant/Inventory/Value';

const FilterNavigator = ({ data, equip, setEquip, isLoadingOpen }) => {
  const queryClient = useQueryClient();

  const handleNavClick = (e, selectedEquip) => {
    e.stopPropagation();
    setEquip(selectedEquip);
    queryClient.invalidateQueries({ queryKey: ['INVENTORY', 'COUNTS'] });
  };

  return (
    <StyledNavWrapper>
      {INVENTORY_FILTER_NAVIGATOR_VALUE?.map(unit => {
        return (
          <StyledNavBoxContainer
            key={`${unit.header}`}
            equip={equip}
            name={unit.value}
            disabled={isLoadingOpen}
          >
            <div className="nav-header">{unit.header}</div>
            <Link
              to={`${unit.to}`}
              className="nav-button"
              onClick={e => handleNavClick(e, unit.value)}
            >
              <div>Total</div>
              <div>
                {!data?.disable
                  ? data?.[unit.count]?.toString() || 'Loading...'
                  : 0}
              </div>
            </Link>
          </StyledNavBoxContainer>
        );
      })}
    </StyledNavWrapper>
  );
};

export default FilterNavigator;
