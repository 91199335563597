import React from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import StyledInputWrapper from '../../../style/Labs/Input/StyledInputWrapper';
import InputContents from '../../../component/Labs/Input/InputContents';
import CommonButton from '../../../component/Common/Controller/Button';
import { PRESET_GREEN } from '../../../constant/Common/Style/preset';

const Custom = () => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const styles = {
    width: '160px',
  };

  const inputProperty = {
    disabled: false,
    maxLength: 5,
    label: 'James is handsome guy',
    suppressLabelVisible: false,
    suppressLabelContentVisible: false,
    suppressErrorVisible: false,
    suppressErrorContentVisible: true,
    suppressCountVisible: true,
    suppressUtilityButton: true,
  };

  return (
    <StyledInputWrapper>
      <InputContents
        type="customText"
        styles={styles}
        {...inputProperty}
        defaultValue="Hello World Text"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange(props?.e?.target?.value);
        }}
      />
      <InputContents
        type="customAutoComplete"
        styles={styles}
        {...inputProperty}
        defaultValue="Hello World Auto Complete"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange(props?.controllerProps?.value || '');
        }}
      />
      <InputContents
        type="customSelect"
        styles={styles}
        {...inputProperty}
        defaultValue="Hello World Select"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange(props?.e?.target?.value || '');
        }}
      />
      <InputContents
        type="customDate"
        styles={styles}
        {...inputProperty}
        defaultValue="2024-01-30"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange(
            props?.e && dayjs(props?.e)?.isValid() ? dayjs(props?.e) : null,
          );
        }}
      />
      <InputContents
        type="customTime"
        styles={styles}
        {...inputProperty}
        defaultValue="2024-01-30T13:30:00"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange(
            props?.e && dayjs(props?.e)?.isValid() ? dayjs(props?.e) : null,
          );
        }}
      />
      <InputContents
        type="customTextarea"
        styles={styles}
        {...inputProperty}
        defaultValue="Hello World Text Area"
        onChange={({ ...props }) => {
          console.log('Props : ', props);
          props?.onChange?.(props?.e?.target?.value);
          props?.setCompValue?.(props?.e?.target?.value);
        }}
      />
    </StyledInputWrapper>
  );
};

export default Custom;
