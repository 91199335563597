import styled from 'styled-components';
import { ALERT_MODAL_CONTENT_BACKGROUND_COLOR } from '../../../../../../constant/Common/Modal/Alert/color';

const StyledCommonAlertContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: fit-content;
  min-height: 61px;

  padding: 10px;

  background-color: ${ALERT_MODAL_CONTENT_BACKGROUND_COLOR};
`;

export default StyledCommonAlertContentWrapper;
