import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCustomControllerWrapper from '../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import ControllerLabel from '../Common/Label';
import ControllerError from '../Common/Error';
import CustomSelect from '../Select/Custom';
import CustomTextInput from '../Text/Custom';
import CountryCodeOption from './CountryCodeOption';
import { COMMON_COUNTRY_CODE_SELECT_OPTION } from '../../../../constant/Common/Option/Country';
import { changeSelect } from '../../../../util/Safety/Common/Handler/controllerChangeHandler';
import CountryCodeOptionLabel from './CountryCodeOption/CountryCodeOptionLabel';
import CountryCodeLabel from './CountryCodeLabel';
import { REGEX_ONLY_NUMBER } from '../../../../constant/Common/Regex';

const CommonPhoneNumber = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
    ...formController
  } = useFormContext();

  const [label, setLabel] = useState(props?.label || '');
  useEffect(() => {
    setLabel(prev => props?.label);
  }, [props?.label]);
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    setRequired(prev => props?.required);
  }, [props?.required]);
  const [inputName, setInputName] = useState(props?.inputName);
  const [defaultValue, setDefaultValue] = useState(
    props?.defaultValue || {
      country: 'US',
      phone: '',
    },
  );

  return (
    <StyledCustomControllerWrapper>
      <ControllerLabel
        label={label}
        required={required}
        suppressLabelVisible={props?.suppressLabelVisible}
        suppressLabelContentVisible={props?.suppressLabelContentVisible}
      />
      <div className="flex flex-row">
        {/* Country Code */}
        <div className="w-[105px]">
          <CustomSelect
            inputName={inputName?.[0]}
            suppressErrorVisible
            suppressLabelVisible
            defaultValue={defaultValue?.country}
            required={required}
            styles={{
              borderRadius: '5px 0px 0px 5px',
            }}
            renderValue={selected => <CountryCodeLabel selected={selected} />}
            option={COMMON_COUNTRY_CODE_SELECT_OPTION?.map(option => ({
              ...option,
              value: option?.code,
              label: <CountryCodeOptionLabel option={option} />,
            }))}
            onChange={({ ...changeProps }) => {
              changeSelect({ ...changeProps });
            }}
          />
        </div>
        {/* Phone Number */}
        <div className="flex-1">
          <CustomTextInput
            inputName={inputName?.[1]}
            suppressErrorVisible
            suppressLabelVisible
            defaultValue={defaultValue?.phone}
            required={required}
            styles={{
              borderRadius: '0px 5px 5px 0px',
            }}
            onChange={({ e, onChange, ...changeProps }) => {
              const numericValue = e.target.value.replace(
                REGEX_ONLY_NUMBER,
                '',
              );
              onChange?.(numericValue);
            }}
            maxLength={10}
          />
        </div>
      </div>
      <ControllerError
        errors={errors}
        inputName={inputName?.[1]}
        suppressErrorVisible={props?.suppressErrorVisible}
        suppressErrorContentVisible={props?.suppressErrorContentVisible}
      />
    </StyledCustomControllerWrapper>
  );
};

export default CommonPhoneNumber;
