import React from 'react';
import { Outlet } from 'react-router-dom';
import StyledLabsWrapper from '../../style/Labs/StyledLabsWrapper';
import StyledLabsContentsWrapper from '../../style/Labs/StyledLabsContentsWrapper';
import LabsHeader from '../../component/Labs/LabsHeader';
import SubMenuNavigator from '../../component/Common/Router/App/Common/SubMenuNavigator';
import { PAGE_LABS } from '../../constant/Common/Router/App/Common/mapper';

const Labs = () => {
  return (
    <StyledLabsWrapper>
      <StyledLabsContentsWrapper>
        <LabsHeader />
        <SubMenuNavigator page={PAGE_LABS} />
        <div className="w-full h-full flex-1">
          <Outlet />
        </div>
      </StyledLabsContentsWrapper>
    </StyledLabsWrapper>
  );
};

export default Labs;
