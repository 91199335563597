import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getChassisSizeList from '../../../service/Common/Get/getChassisSizeList';

const useGetChassisSizeList = ({ ...props }) => {
  const { keyword } = props;

  const [chassisSizeList, setChassisSizeList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys?.chassisSizeList({ keyword }),
    queryFn: () => getChassisSizeList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setChassisSizeList(prev => query?.data?.data);
    } else {
      setChassisSizeList(prev => []);
    }
  }, [query?.data]);

  return { chassisSizeList, ...query };
};

export default useGetChassisSizeList;
