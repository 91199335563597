import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getPoolList from '../../../service/Common/Get/getPoolList';

const useGetPoolList = ({ ...props }) => {
  const { keyword } = props;

  const [poolList, setPoolList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.poolList({ keyword }),
    queryFn: () => getPoolList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setPoolList(prev => query?.data?.data);
    } else {
      setPoolList(prev => []);
    }
  }, [query?.data]);

  return { poolList, ...query };
};

export default useGetPoolList;
