import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDefaultAPI from '../../api';

const getGroup = async () => {
  try {
    const response = await adminDefaultAPI.get('group');
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getGroup;
