import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const clickGridRow = ({
  setSpecificSeq,
  setModalOpen,
  setEditMode,
  ...props
}) => {
  const { gridParam, preventTargetColumnID } = props;
  const getColId = gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (!preventTargetColumnID?.includes(getColId)) {
    setEditMode?.(prev => EDIT_MODE?.edit);
    setSpecificSeq?.(props?.gridParam?.data?.seq);
    setModalOpen?.(prev => true);
  }
};

export default clickGridRow;
