import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonDataGrid from '../../../component/Common/Datagrid/index';
import CommonLoading from '../../../component/Common/Loading/index';
import CommonAlert from '../../../component/Common/Modal/Alert';
import AddButton from '../../../component/Safety/ITTablet/AddButton';
import AddEditModal from '../../../component/Safety/ITTablet/AddEditModal';
import ExcelButton from '../../../component/Safety/ITTablet/ExcelButton';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import { PERM_SAFETY_IT_TABLET_EDIT } from '../../../constant/Common/Permission/index';
import useGetITTabletList from '../../../hook/Safety/ITTablet/Get/useGetITTabletList';
import usePatchITTabletActiveMutation from '../../../hook/Safety/ITTablet/Patch/usePatchITTabletActiveMutation';
import usePatchITTabletMutation from '../../../hook/Safety/ITTablet/Patch/usePatchITTabletMutation';
import usePostITTabletMutation from '../../../hook/Safety/ITTablet/Post/usePostITTabletMutation';
import checkPermission from '../../../util/Common/Router/Handler/checkPermission';
import createSafetyITTabletColumnDefs from '../../../util/Safety/ITTablet/Generator/createSafetyITTabletColumnDefs';
import handleCellClick from '../../../util/Safety/ITTablet/Handler/handleCellClick';

const ITTablet = () => {
  /** useRef for handling disabled */
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  const gridRef = useRef(null);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState('');
  const [list, setList] = useState([]);
  const driverList = useSelector(state => state.common.driverList);
  const [driverOptions, setDriverOptions] = useState([]);
  const divList = localStorage.getItem('allDiv')?.split(',');
  const [divOptions, setDivOptions] = useState([]);
  const [selectedITTablet, setSelectedITTablet] = useState({});
  const isEditable = checkPermission({
    permission: PERM_SAFETY_IT_TABLET_EDIT,
  });

  useEffect(() => {
    if (driverList) {
      setDriverOptions(
        driverList.map(driver => ({ value: driver.id, label: driver.id })),
      );
    }
  }, []);

  useEffect(() => {
    if (divList) {
      setDivOptions(divList.map(div => ({ value: div, label: div })));
    }
  }, []);

  const {
    isLoadingITTabletList,
    data,
    isRefetchingITTabletList,
    isErrorITTabletList,
  } = useGetITTabletList();

  const { mutate: postITTabletMutation } = usePostITTabletMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    setIsAddEditModalOpen,
  });

  const { mutate: patchITTabletMutation } = usePatchITTabletMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    setIsAddEditModalOpen,
  });

  const { mutate: patchITTabletActiveMutation } =
    usePatchITTabletActiveMutation({
      gridRef,
      disabledRef,
      setIsMessageOpen,
      setMessage,
      setMessageStatus,
    });

  const [columnDefs, setColumnDefs] = useState(
    createSafetyITTabletColumnDefs({
      patchITTabletActiveMutation,
      isEditable,
      disabledRef,
    }),
  );

  useEffect(() => {
    if (isErrorITTabletList) {
      setMessage('Failed to fetch IT Tablet list');
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setIsMessageOpen(true);
    }
  }, [isErrorITTabletList]);

  useEffect(() => {
    if (data) {
      setList(data.data);
    }
  }, [data]);

  return (
    <>
      <CommonLoading isOpen={isLoadingITTabletList} />
      <AddEditModal
        isAddEditModalOpen={isAddEditModalOpen}
        setIsAddEditModalOpen={setIsAddEditModalOpen}
        isAdd={isAdd}
        postITTabletMutation={postITTabletMutation}
        driverOptions={driverOptions}
        divOptions={divOptions}
        selectedITTablet={selectedITTablet}
        setSelectedITTablet={setSelectedITTablet}
        patchITTabletMutation={patchITTabletMutation}
        isEditable={isEditable}
      />
      {messageStatus !== ALERT_MODAL_STATUS.SUCCESS && (
        <CommonAlert
          isOpen={isMessageOpen}
          setOpen={setIsMessageOpen}
          status={messageStatus}
          content={message}
          onOKButtonClick={() => {
            setIsMessageOpen(false);
          }}
        />
      )}
      <div className="pl-[40px] pr-[40px] flex flex-col flex-1 pb-8">
        <div className="mt-[18px] flex justify-end gap-[17px]">
          <ExcelButton gridRef={gridRef} />
          {isEditable && (
            <AddButton
              setIsAddEditModalOpen={setIsAddEditModalOpen}
              setIsAdd={setIsAdd}
            />
          )}
        </div>
        <div className="mt-[15px] h-full">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={list}
            onCellClicked={params =>
              handleCellClick(
                { setSelectedITTablet, setIsAdd, setIsAddEditModalOpen },
                params,
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default ITTablet;
