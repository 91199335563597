import React from 'react';
import CommonButton from '../../../Common/Controller/Button';

const ButtonPresetWrapper = ({ name, styles, icon }) => {
  return (
    <div className="w-fit min-w-[200px] h-fit min-h-[80px] rounded-[5px] border-solid border-[1px] border-black flex flex-col block">
      <div className="flex items-center p-[10px] text-[16px] h-fit justify-center font-bold">
        {name || 'Preset'}
      </div>
      <div className="flex justify-center items-center">
        <CommonButton
          icon={icon}
          styles={{
            ...styles,
            width: '100px',
            height: '30px',
            fontSize: '14px',
          }}
        >
          {icon ? null : 'Preset'}
        </CommonButton>
      </div>
    </div>
  );
};

export default ButtonPresetWrapper;
