import { STATUS_VALUE } from '../../../../constant/Admin/User/standardCodes';

const getDefaultValue = ({ divList }) => {
  const divisionDefaultValue = divList?.map(item => {
    return { [item]: false };
  });

  return {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    group_name: '',
    default_div: '',
    ...divisionDefaultValue,
  };
};

const generateDefaultValue = ({ userInfo, divList }) => {
  if (!userInfo || Object?.keys?.(userInfo)?.length < 1) {
    return { ...getDefaultValue };
  }

  /** When User Information Is Correct */
  const userObject = { ...userInfo };

  userObject.group_name =
    userInfo?.yms_status === STATUS_VALUE?.WORKING ? userInfo?.group_name : '';

  let selectDivisionLength = 0;
  Object?.keys?.(userInfo?.userdivision_set)?.forEach(key => {
    userObject[key] = userInfo?.userdivision_set?.[key];
    if (key !== 'default_div' && userInfo?.userdivision_set?.[key]) {
      selectDivisionLength++;
    }
  });

  userObject.ALL = divList?.length === selectDivisionLength;

  return userObject;
};

export default generateDefaultValue;
