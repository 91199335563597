const checkSession = () => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const userID = localStorage.getItem('userId');

  if (accessToken && refreshToken && userID) {
    return true;
  }
  return false;
};

export default checkSession;
