import React from 'react';
import StyledTriggerWrapper from '../../../style/Labs/Trigger/StyledTriggerWrapper';
import ButtonTrigger from '../../../component/Labs/Trigger/ButtonTrigger';
import AlertTrigger from '../../../component/Labs/Trigger/AlertTrigger';

const Trigger = () => {
  return (
    <StyledTriggerWrapper>
      <ButtonTrigger />
      <AlertTrigger />
    </StyledTriggerWrapper>
  );
};

export default Trigger;
