import React from 'react';

const ModalHeader = ({ ...props }) => {
  const { editMode, title } = props;

  return (
    <div className="text-[#000] text-[14px] font-bold">
      {editMode ? 'Edit' : 'Add'} {title}
    </div>
  );
};

export default ModalHeader;
