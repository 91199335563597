import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useControlOption = () => {
  const driverList = useSelector(state => state.common.driverList);
  const yardList = useSelector(state => state.common.ymsYard);
  const truckList = useSelector(state => state.common.safetyTruck);
  const chassisList = useSelector(state => state.common.safetyChassis);
  const divList = useSelector(state => state.common.allDiv);

  const [driverOptions, setDriverOptions] = useState([]);
  const [truckOptions, setTruckOptions] = useState([]);
  const [chassisOptions, setChassisOptions] = useState([]);
  const [yardOptions, setYardOptions] = useState([]);
  const [divOptions, setDivOptions] = useState([]);

  /** Option Setting */
  useEffect(() => {
    if (driverList) {
      setDriverOptions(
        driverList.map(driver => ({ value: driver.id, label: driver.id })),
      );
    }
  }, [driverList]);

  useEffect(() => {
    if (truckList) {
      setTruckOptions(
        truckList?.map(truck => ({
          label: truck?.truck_no,
          value: truck?.truck_no,
          plate: truck?.plate_no,
        })),
      );
    } else {
      setTruckOptions([]);
    }
  }, [truckList]);

  useEffect(() => {
    if (chassisList) {
      setChassisOptions(
        chassisList?.map(chassis => ({
          label: chassis?.ch_no,
          value: chassis?.ch_no,
          plate: chassis?.plate_no,
        })),
      );
    } else {
      setChassisOptions([]);
    }
  }, [chassisList]);

  useEffect(() => {
    if (yardList) {
      setYardOptions(
        yardList?.map(yard => ({
          label: yard?.name,
          value: yard?.name,
          div: yard?.div,
        })),
      );
    } else {
      setYardOptions([]);
    }
  }, [yardList]);

  useEffect(() => {
    if (divList) {
      setDivOptions(divList?.map(div => ({ label: div, value: div })));
    } else {
      setDivOptions([]);
    }
  }, [divList]);

  return {
    driverOptions,
    setDriverOptions,
    yardOptions,
    setYardOptions,
    truckOptions,
    setTruckOptions,
    chassisOptions,
    setChassisOptions,
    divOptions,
    setDivOptions,
  };
};

export default useControlOption;
