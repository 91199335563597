import { useMutation } from '@tanstack/react-query';
import patchActive from '../../../../service/Safety/Truck/Patch/patchActive';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchActive = ({ gridRef, ...props }) => {
  const { addToast } = useControlToast();

  const { disabledRef, setAlertModalStatus, setAlertOpen } = props;

  const mutator = useMutation({
    mutationFn: patchActive,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { seq: variables?.seq, active: !data?.data?.data },
        isUpdate: true,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: (error, variables) => {
      variables?.setChecked(checked => !checked);
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
    onSettled: () => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
    },
  });

  return { ...mutator };
};

export default usePatchActive;
