import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import damageReportKeys from '../keys';
import getDamageReportList from '../../../../service/Safety/DamageReport/Get/getDamageReportList';
import generateQueryString from '../../../../util/Safety/Common/Generator/generateQueryString';

const useGetDamageReportList = ({ queryObject }) => {
  const queryParam = { ...queryObject };
  queryParam.status = queryParam?.status === 'ALL' ? '' : queryParam?.status;
  queryParam['from-date'] =
    queryParam['from-date'] && dayjs(queryParam['from-date'])?.isValid()
      ? dayjs(queryParam['from-date'])?.format('YYYY-MM-DD')
      : null;

  queryParam['to-date'] =
    queryParam['to-date'] && dayjs(queryParam['to-date'])?.isValid()
      ? dayjs(queryParam['to-date'])?.format('YYYY-MM-DD')
      : null;

  const queryString = generateQueryString({ queryObject: queryParam });

  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: damageReportKeys.damageReportList({
      status: queryObject?.status,
      fromDate: queryObject['from-date'],
      toDate: queryObject['to-date'],
    }),
    queryFn: () => getDamageReportList({ queryString }),
    refetchOnWindowFocus: false,
  });

  const [damageReportList, setDamageReportList] = useState([]);

  useEffect(() => {
    setDamageReportList(prev => data?.data || []);
  }, [data?.data]);

  return { damageReportList, isLoading, isFetching, isError };
};

export default useGetDamageReportList;
