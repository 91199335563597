import React from 'react';

const AccidentFormModalHeader = ({ modalType }) => {
  return (
    <div className="ml-[36px] mt-[9px] font-[700] text-[14px] text-[#001E5F]">
      {modalType ? 'Edit' : 'Add'} Accident
    </div>
  );
};

export default AccidentFormModalHeader;
