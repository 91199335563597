import { useEffect, useState } from 'react';
import { STATUS_VALUE } from '../../../../constant/Admin/User/standardCodes';
import {
  createMandatorySelectOption,
  createOptionalSelectOption,
} from '../../../../util/Common/Option/Generator/selectOptionCreator';

const useGetAuthValue = ({ userInfo, authList }) => {
  const [isRequiredAuth, setIsRequiredAuth] = useState(null);
  useEffect(() => {
    setIsRequiredAuth(userInfo?.yms_status === STATUS_VALUE?.WORKING);
  }, [userInfo?.yms_status]);

  const [authOptionList, setAuthOptionList] = useState([]);
  useEffect(() => {
    const optionList = isRequiredAuth
      ? createMandatorySelectOption({
          items: authList?.map(auth => auth?.name),
          labelKey: 'name',
        })
      : createOptionalSelectOption({
          items: authList?.map(auth => auth?.name),
          labelKey: 'name',
        });

    setAuthOptionList(optionList);
  }, [isRequiredAuth, authList]);

  return { isRequiredAuth, authOptionList };
};

export default useGetAuthValue;
