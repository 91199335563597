import React from 'react';
import CommonTextarea from '../../../Common/Controller/Textarea';
import FileTable from '../../Common/Modal/FileTable';
import { changeText } from '../../../../util/Safety/Common/Handler/controllerChangeHandler';

const OtherInfoContent = ({ accident, isEditable }) => {
  return (
    <div className="flex flex-col h-fit pt-[26px] pb-[14px]">
      <div className="w-full">
        <FileTable page="accident" isEditable={isEditable} />
      </div>
      <div className="mt-[18px]">
        <CommonTextarea
          inputName="remarks"
          label="REMARKS"
          defaultValue={accident?.remarks || ''}
          maxLength={120}
          onChangeHandler={changeText}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default OtherInfoContent;
