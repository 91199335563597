import styled, { css } from 'styled-components';
import { TOAST_TYPE } from '../../../constant/Common/Toast/toastTypes';

const StyledCommonToastColorBox = styled.div`
  width: 14px;
  height: 100%;
  border-radius: 9px 0 0 9px;

  ${props =>
    props?.$toastType === TOAST_TYPE?.SUCCESS &&
    css`
      background-color: #72cc9e;
    `}

  ${props =>
    props?.$toastType === TOAST_TYPE?.ERROR &&
    css`
      background-color: #f55757;
    `}

    ${props =>
    props?.$toastType === TOAST_TYPE?.WARNING &&
    css`
      background-color: #eec452;
    `}
`;

export default StyledCommonToastColorBox;
