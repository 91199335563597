import styled from 'styled-components';

const StyledSessionInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: fit-content;
  padding-left: 1rem;
  padding-right: 3rem;

  /** Align right */
  margin-left: auto;
  margin-right: 0;
`;

export default StyledSessionInfoWrapper;
