import generateNormalCellRenderer from './generateNormalCellRenderer';
import generateRowPinnedCellRenderer from './generateRowPinnedCellRenderer';

/**
 * @description
 * Function that generate cell renderer. Used in cell render selector in column definition.
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 * @returns
 * Cell renderer.
 */
const generateCellRenderer = ({
  gridParam,
  cellType,
  adornment,
  styles,
  ...props
}) => {
  /** Generate pinned row generator */
  if (gridParam?.node?.rowPinned) {
    return generateRowPinnedCellRenderer({
      gridParam,
      cellType,
      adornment,
      ...props,
    });
  }
  /** Normal cell renderer */
  return generateNormalCellRenderer({
    gridParam,
    cellType,
    adornment,
    styles,
    ...props,
  });
};

export default generateCellRenderer;
