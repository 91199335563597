import React from 'react';
import CommonBlur from '../../../Controller/Blur';
import StyledCommonMessageModalFrameWrapper from '../../../../../style/Common/Modal/Message/Frame/StyledCommonMessageModalFrameWrapper';
import CommonMessageModalFrameHeader from './Header';
import CommonMessageModalCloseButton from './Header/CloseButton';
import CommonMessageModalFrameContent from './Content';
import CommonMessageModalFrameButton from './Button';

const CommonMessageModalFrame = ({
  isOpen,
  setOpen,
  styles,
  header,
  content,
  button,
  onCloseButtonClick,
  ...props
}) => {
  return (
    <CommonBlur isOpen={isOpen}>
      <StyledCommonMessageModalFrameWrapper styles={{ ...styles }}>
        <CommonMessageModalCloseButton
          setOpen={setOpen}
          onCloseButtonClick={onCloseButtonClick}
        />
        <CommonMessageModalFrameHeader>
          {header || 'Header'}
        </CommonMessageModalFrameHeader>
        <CommonMessageModalFrameContent>
          {content || 'Content'}
        </CommonMessageModalFrameContent>
        <CommonMessageModalFrameButton>
          {button || 'Button'}
        </CommonMessageModalFrameButton>
      </StyledCommonMessageModalFrameWrapper>
    </CommonBlur>
  );
};

export default CommonMessageModalFrame;
