import React from 'react';
import CommonButton from '../../../Common/Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const AddButton = ({ setIsAddEditModalOpen, setIsAdd }) => {
  return (
    <CommonButton
      icon={PATH_COMMON_BUTTON_ICON.plusBlue}
      styles={{
        border: '1px solid #556DA1',
        backgroundColor: '#fff',
        hoverBackgroundColor: '#556DA1',
        hoverBorder: '1px solid #556DA1',
        hoverColor: '#fff',
        color: '#556DA1',
      }}
      onClick={() => {
        setIsAdd(true);
        setIsAddEditModalOpen(true);
      }}
    >
      Add
    </CommonButton>
  );
};

export default AddButton;
