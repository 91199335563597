import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import serviceDefaultAPI from '../../api';

const login = async data => {
  try {
    const response = await serviceDefaultAPI.post(`sign/login`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default login;
