import React from 'react';
import { COMMON_COUNTRY_CODE_SELECT_OPTION } from '../../../../../constant/Common/Option/Country';

const CountryCodeLabel = ({ ...props }) => {
  const { selected } = props;

  return (
    <div className="flex flex-row gap-[4px]">
      <img
        width="24"
        height="18"
        srcSet={`https://flagcdn.com/48x36/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
          item => item?.code === selected,
        )?.code.toLowerCase()}.png 2x,
  https://flagcdn.com/72x54/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
    item => item?.code === selected,
  )?.code.toLowerCase()}.png 3x
  `}
        src={`https://flagcdn.com/24x18/${COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
          item => item?.code === selected,
        )?.code.toLowerCase()}.png`}
        alt="Country Flag"
      />
      <p>
        +
        {
          COMMON_COUNTRY_CODE_SELECT_OPTION?.find(
            item => item?.code === selected,
          )?.phone
        }
      </p>
    </div>
  );
};

export default CountryCodeLabel;
