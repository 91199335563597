import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getInventoryChassisList from '../../../service/DailyInOut/Get/getInventoryChassisList';

const useGetInventoryChassisList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryChassisList,
    data,
    isRefetching: isRefetchingInventoryChassisList,
    isError: isErrorInventoryChassisList,
  } = useQuery({
    queryKey: dailyInOutKeys?.chassisList({ div, yard }),
    queryFn: () => getInventoryChassisList({ div, yard }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingInventoryChassisList,
    inventoryChassisList: data?.data,
    isRefetchingInventoryChassisList,
    isErrorInventoryChassisList,
  };
};

export default useGetInventoryChassisList;
