import refresh from '../../../service/SignIn/Post/refresh';

const responseInterceptors = ({ axiosInstance }) => {
  axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const result = await refresh({ originalRequest, axiosInstance });
        return result;
      }
      throw error;
    },
  );
};

export default responseInterceptors;
