import styled from 'styled-components';

const StyledInfoWrapper = styled.div`
  width: 100%;
  background-color: #f9f9f9;

  padding-top: 20px;
  padding-left: 37px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default StyledInfoWrapper;
