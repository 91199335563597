import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setCurrDiv } from '../../../redux/Feature/Common';

const useSetDefaultState = () => {
  const currDiv = useSelector(state => state?.common?.currDiv);
  const dispatch = useDispatch();

  useEffect(() => {
    /** Set current division */
    if (!currDiv) {
      /** Set default division to current division */
      const defaultDiv = localStorage?.getItem('defaultDiv');
      dispatch(setCurrDiv(defaultDiv));
    }
  }, []);
};

export default useSetDefaultState;
