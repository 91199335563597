const SAFETY_MR_MODAL_DEFAULT_VALUE = {
  seq: null,
  div: '',
  equip_id: '',
  make_year: '',
  type: '',
  category: '',
  sub_category: '',
  odometer: '',
  repaired_by: '',
  repaired_date: null,
  inv_no: '',
  mttr: '',
  labor: '',
  part: '',
  tax: '',
  other: '',
  total: '',
  remarks: '',
  created_by: '',
  created_date: null,
  updated_by: '',
  updated_date: null,
  doc: [
    {
      updated_date: null,
      document: '',
      exp_date: null,
      file_url: null,
      updated_by: '',
    },
  ],
};

export default SAFETY_MR_MODAL_DEFAULT_VALUE;
