import { ERROR_SPECIFIC_CODES } from '../../../../constant/Common/Error/specificErrorCodeField';

/**
 * @description
 * Function that check if error code exist in specific codes.
 * @param {list} code
 * List of error code
 * @returns {boolean}
 * True if error code exist in specific codes, false otherwise
 */
export const specificCodeExistHandler = ({ code }) => {
  /** Check code */
  if (ERROR_SPECIFIC_CODES.includes(code)) return true;
  return false;
};
