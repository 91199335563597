import styled, { css } from 'styled-components';

const StyledCommonButton = styled.button`
  display: ${props => props?.styles?.display || 'flex'};
  justify-content: ${props => props?.styles?.justifyContent || 'center'};
  align-items: ${props => props?.styles?.alignItems || 'center'};
  gap: ${props => props?.styles?.gap || '10px'};

  width: ${props => props?.styles?.width || 'fit-content'};
  height: ${props => props?.styles?.height || '24px'};

  font-size: ${props => props?.styles?.fontSize || '12px'};
  font-weight: ${props => props?.styles?.fontWeight || '500'};

  /** Padding */
  padding-top: ${props => props?.styles?.paddingTop || 'unset'};
  padding-right: ${props => props?.styles?.paddingRight || 'unset'};
  padding-bottom: ${props => props?.styles?.paddingBottom || 'unset'};
  padding-left: ${props => props?.styles?.paddingLeft || 'unset'};
  padding: ${props => props?.styles?.padding || '5px 10px'};

  /** Margin */
  margin-top: ${props => props?.styles?.marginTop || 'unset'};
  margin-right: ${props => props?.styles?.marginRight || 'unset'};
  margin-bottom: ${props => props?.styles?.marginBottom || 'unset'};
  margin-left: ${props => props?.styles?.marginLeft || 'unset'};
  margin: ${props => props?.styles?.margin || 'unset'};

  /** Border */
  border-top: ${props => props?.styles?.borderTop || 'unset'};
  border-right: ${props => props?.styles?.borderRight || 'unset'};
  border-bottom: ${props => props?.styles?.borderBottom || 'unset'};
  border-left: ${props => props?.styles?.borderLeft || 'unset'};

  border-radius: ${props => props?.styles?.borderRadius || '5px'};
  border-color: ${props => props?.styles?.borderColor || 'unset'};
  border-width: ${props => props?.styles?.borderWidth || 'unset'};
  border-style: ${props => props?.styles?.borderStyle || 'unset'};
  border: ${props => props?.styles?.border || 'unset'};
  outline: ${props => props?.styles?.outline || 'unset'};

  /** Color */
  background-color: ${props => props?.styles?.backgroundColor || 'transparent'};
  color: ${props => props?.styles?.color || 'black'};

  /** Hover */
  transition-duration: ${props => props?.styles?.transitionDuration || '300ms'};
  &:not(:disabled):hover {
    border-color: ${props => props?.styles?.hoverBorderColor || 'unset'};
    border: ${props => props?.styles?.hoverBorder || 'unset'};
    background-color: ${props => {
      if (props?.styles?.hoverBackgroundColor)
        return props?.styles?.hoverBackgroundColor;
      if (props?.styles?.backgroundColor)
        return `${props?.styles?.backgroundColor}b3`;
      return 'transparent';
    }};
    color: ${props => {
      if (props?.styles?.hoverColor) return props?.styles?.hoverColor;
      if (props?.styles?.color) return props?.styles?.color;
      return 'black';
    }};
  }

  cursor: ${props => props?.styles?.cursor || 'pointer'};

  /** Disabled case */
  ${props =>
    props?.disabled &&
    css`
      background-color: #a6a6a6;
      border: 1px solid #a6a6a6;
      color: white;
      cursor: not-allowed;
    `}

  /** Small size */
    ${props =>
    props?.styles?.size === 'small' &&
    css`
      height: 20px;
      padding: 3px 5px;
      border-radius: 3px;
      gap: 7px;
    `}
`;

export default StyledCommonButton;
