export const BASIC_INFO_STATUS = [
  {
    seq: 0,
    label: 'Local',
    value: 'SST001',
  },
  {
    seq: 1,
    label: 'Line',
    value: 'SST002',
  },
  {
    seq: 2,
    label: 'Leased',
    value: 'SST003',
  },
  {
    seq: 3,
    label: 'Rental',
    value: 'SST004',
  },
];

export const CHASSIS_BASIC_INFO_STATUS = [
  {
    seq: 0,
    label: 'Lease',
    value: 'SSC001',
  },
  {
    seq: 1,
    label: 'Own',
    value: 'SSC002',
  },
];
