import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const getSelectedDailyInOut = async ({ seq }) => {
  try {
    if (seq !== 0) {
      const response = await dailyInOutDefaultAPI.get(`${seq}`);
      return response;
    }
    return null;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSelectedDailyInOut;
