const damageReportKeys = {
  all: ['DAMAGE_REPORT'],
  damageReportList: ({ status, fromDate, toDate }) => [
    ...damageReportKeys.all,
    'DAMAGE_REPORT_LIST',
    status,
    fromDate,
    toDate,
  ],
  damageReport: ({ seq }) => [...damageReportKeys.all, 'DAMAGE_REPORT', seq],
};

export default damageReportKeys;
