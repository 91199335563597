import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyITTabletDefaultAPI from '../api';

const getITTabletList = async () => {
  try {
    const response = await safetyITTabletDefaultAPI.get();
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getITTabletList;
