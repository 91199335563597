import { EDIT_MODE } from '../../../../../constant/Safety/Common/mode';
import updateButtonDisabled from '../../../../Common/Controller/Handler/updateButtonDisabled';
import convertDataFormat from '../../../Common/Handler/convertDataFormat';
import deleteProperty from '../../../Common/Handler/deleteProperty';
import separateFileTableData from '../../../Common/Handler/separateFileTableData';

const saveMR = ({ ...props }) => {
  const { data, editMutate, addMutate, seq, editMode, saveButtonRef } = props;

  // Disable save button
  if (saveButtonRef?.current)
    updateButtonDisabled({ ref: saveButtonRef, disabled: true });

  // User input data
  const inputData = { ...data };

  // Convert data format
  const convertedData = convertDataFormat({
    inputData,
    date: ['repaired_date'],
    number: ['make_year', 'mttr', 'labor', 'part', 'tax', 'other'],
  });

  // Separate file-table data
  const separatedData = separateFileTableData({ inputData: convertedData });

  // Remove unnecessary data
  const maniuplatedData = deleteProperty({ inputData: separatedData });

  if (editMode === EDIT_MODE?.edit)
    editMutate({ seq, data: maniuplatedData, buttonRef: saveButtonRef });
  if (editMode === EDIT_MODE?.add)
    addMutate({ data: maniuplatedData, buttonRef: saveButtonRef });
};

export default saveMR;
