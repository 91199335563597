import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import accidentKeys from '../keys';
import getAccident from '../../../../service/Safety/Accident/Get/getAccident';
import { ACCIDENT_DEFAULT_VALUE } from '../../../../constant/Safety/Accident/formDefaultValue';

const useGetAccident = ({ setAlertOptions }) => {
  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  const [seq, setSeq] = useState(null);
  const [accidentInfo, setAccidentInfo] = useState({});

  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: accidentKeys?.accident({ seq }),
    queryFn: () => getAccident({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq,
  });

  useEffect(() => {
    if (data) {
      setAccidentInfo(data?.data);
    } else {
      setAccidentInfo(ACCIDENT_DEFAULT_VALUE);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS.ERROR,
        message: error?.message,
      }));
      setIsOpenAlert(prev => true);
    }
  }, [isError]);

  return {
    seq,
    setSeq,
    accidentInfo,
    setAccidentInfo,
    isLoading,
    isFetching,
  };
};

export default useGetAccident;
