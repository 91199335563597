import React from 'react';
import { useDispatch } from 'react-redux';
import StyledDivisionSelectorItemWrapper from '../../../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorOption/StyledDivisionSelectorItem/StyledDivisionSelectorItemWrapper';
import { setCurrDiv } from '../../../../../../../redux/Feature/Common';

/**
 * @description
 * Division selector item component.
 * @param {object} children
 * Render the children inside the item.
 * @param {boolean} isSelected
 * Check if the item is selected.
 * @returns {React.ReactComponentElement}
 */
const DivisionSelectorItem = ({ children, isSelected, setIsOpen }) => {
  const dispatch = useDispatch();

  return (
    <StyledDivisionSelectorItemWrapper
      onClick={() => dispatch(setCurrDiv(children)) && setIsOpen(false)}
    >
      <div
        className={`w-1 h-full ${isSelected ? `bg-[#001E5F]` : `bg-transparent`}`}
      />
      <div
        className={`w-[65px] h-full flex justify-center items-center ${isSelected ? `text-[#001E5F]` : `text-[#B2BDC9]`}`}
      >
        {children}
      </div>
    </StyledDivisionSelectorItemWrapper>
  );
};

export default DivisionSelectorItem;
