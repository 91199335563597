import cancelRowEdit from './cancelRowEdit';
import clickRowAdd from './clickRowAdd';

const handleCellKeyDown = ({ gridParam, onAddClicked }) => {
  const keyEvent = gridParam?.event;

  if (keyEvent?.code === 'Escape') cancelRowEdit({ gridParam });
  if (keyEvent?.code === 'Enter' && keyEvent?.ctrlKey) {
    if (gridParam?.rowPinned === 'top') {
      clickRowAdd({ gridParam, onAddClicked });
    } else {
      gridParam?.api?.stopEditing(false);
    }
    /** Focus out */
    gridParam?.event?.target?.blur();
  }
};

export default handleCellKeyDown;
