import dayjs from 'dayjs';

export const generateFormattedDay = (value, format) => {
  if (value && dayjs(value)?.isValid()) {
    return dayjs(value)?.format(format);
  }
  return null;
};

export const generateFormattedTime = time => {
  if (time && dayjs(time, 'HH:mm:ss')?.isValid()) {
    return time.slice(0, 5);
  }
  return null;
};
