import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import commonDefaultAPI from '../api';

const getDriver = async () => {
  try {
    const response = await commonDefaultAPI.get(`driver-list`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDriver;
