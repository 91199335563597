import React from 'react';
import CommonButton from '../../../../Common/Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../../../constant/Common/Style/preset';

const ModalButton = ({
  onClearClick,
  onSaveClick,
  isEditable,
  saveButtonRef,
}) => {
  return (
    isEditable && (
      <div className="w-full h-fit flex justify-center items-center gap-[20px]">
        <CommonButton
          icon={PATH_COMMON_BUTTON_ICON.clearRed}
          styles={PRESET_RED}
          onClick={() => onClearClick()}
        >
          Clear
        </CommonButton>
        <CommonButton
          ref={saveButtonRef}
          icon={PATH_COMMON_BUTTON_ICON.saveBlue}
          styles={PRESET_PRIMARY}
          onClick={() => onSaveClick()}
        >
          Save
        </CommonButton>
      </div>
    )
  );
};

export default ModalButton;
