import React from 'react';
import CommonButton from '../../../Controller/Button';
import cancelRowEdit from '../../../../../util/Common/Datagrid/Handler/cancelRowEdit';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Button';

/**
 * @description
 * Component that appears when the Edit button is pressed
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @param {string} modType
 * Patameter that indicate the type of modification of the grid
 * GRID_MOD_BOTH: Enable edit and delete
 * GRID_MOD_EDIT: Enable edit only
 * GRID_MOD_DELETE: Enable delete only
 * @returns
 * Component that appears when the Edit button is pressed
 */
const CurrentRowEditingCell = ({ gridParam, modType, ...props }) => {
  return (
    <>
      <CommonButton
        category="save"
        styles={{
          width: '85px',
          color: '#556DA1',
          border: '1px solid #556DA1',
          hoverBackgroundColor: '#556DA1',
          hoverColor: '#ffffff',
          size: 'small',
        }}
        icon={PATH_COMMON_BUTTON_ICON.saveBlue}
        onClick={() => {
          /** Stop editing when save button clicked */
          gridParam?.api?.stopEditing(false);
        }}
      >
        Save
      </CommonButton>
      <CommonButton
        category="cancel"
        styles={{
          width: '85px',
          color: '#B74747',
          border: '1px solid #B74747',
          hoverBackgroundColor: '#B74747',
          hoverColor: '#ffffff',
          size: 'small',
        }}
        icon={PATH_COMMON_BUTTON_ICON.cancelRed}
        onClick={() => {
          /** Cancel and stop editing when cancel button clicked */
          cancelRowEdit({ gridParam });
        }}
      >
        Cancel
      </CommonButton>
    </>
  );
};

export default CurrentRowEditingCell;
