import { CELL_TYPE_TEXT } from '../../../../constant/Common/DataGrid/cellType';
import { PATH_DATAGRID_GROUP_FOLD_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import generateCellEditor from '../../../Common/Datagrid/Generator/generateCellEditor';

const createModAdornmentGroup = () => {
  return [
    {
      headerName: 'Group07 - Mod Adornment',
      backgroundColor: '#2510a3',
      color: '#ffffff',
      icon: PATH_DATAGRID_GROUP_FOLD_ICON,
      isColumnGroup: true,
      children: [
        {
          headerName: '₩ - Start Adornment',
          field: 'won',
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '₩',
                position: 'start',
              },
            }),
        },
        {
          headerName: '₩ - End Adornment',
          field: 'won',
          columnGroupShow: 'open',

          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '₩',
                position: 'end',
              },
            }),
        },
        {
          headerName: '$ - Start Adornment',
          field: 'won',
          columnGroupShow: 'open',

          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '$',
                position: 'start',
              },
            }),
        },
        {
          headerName: '$ - End Adornment',
          field: 'won',
          columnGroupShow: 'open',

          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_TEXT,
              adornment: {
                content: '$',
                position: 'end',
              },
            }),
        },
      ],
    },
  ];
};

export default createModAdornmentGroup;
