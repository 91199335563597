import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  SAFETY_COMMON_CHS_TYPE_CATEGORY,
  SAFETY_COMMON_OTHER_TYPE_CATEGORY,
  SAFETY_COMMON_SUB_CATEGORY,
  SAFETY_COMMON_TRK_TYPE_CATEGORY,
  SAFETY_COMMON_TRL_TYPE_CATEGORY,
} from '../../../../constant/Safety/Common/Option/Category';

const useGetTypeCategory = ({ type, category, subCategory }) => {
  const { setValue } = useFormContext();

  const typeWatcher = useWatch({ name: 'type', defaultValue: type });
  const categoryWatcher = useWatch({
    name: 'category',
    defaultValue: category,
  });

  const [categoryOption, setCategoryOption] = useState([]);
  const [subCategoryOption, setSubCategoryOption] = useState([]);

  /** Set category option */
  useEffect(() => {
    /** Reset fields */
    setValue('category', '');
    setValue('sub_category', '');

    /** Set option */
    if (typeWatcher && typeWatcher !== '') {
      if (typeWatcher === 'MR0004') {
        setCategoryOption(prev => SAFETY_COMMON_OTHER_TYPE_CATEGORY);
        setSubCategoryOption(prev => []);
      } else if (typeWatcher === 'MR0001') {
        setCategoryOption(prev => SAFETY_COMMON_TRK_TYPE_CATEGORY);
        setSubCategoryOption(prev => []);
      } else if (typeWatcher === 'MR0002') {
        setCategoryOption(prev => SAFETY_COMMON_CHS_TYPE_CATEGORY);
        setSubCategoryOption(prev => []);
      } else if (typeWatcher === 'MR0003') {
        setCategoryOption(prev => SAFETY_COMMON_TRL_TYPE_CATEGORY);
        setSubCategoryOption(prev => []);
      }
    } else {
      setCategoryOption(prev => []);
      setSubCategoryOption(prev => []);
    }
  }, [typeWatcher]);

  /** Set subcategory option */
  useEffect(() => {
    /** Reset fields */
    setValue('sub_category', '');
    if (categoryWatcher && categoryWatcher !== '') {
      setSubCategoryOption(
        prev => SAFETY_COMMON_SUB_CATEGORY?.[typeWatcher]?.[categoryWatcher],
      );
    } else {
      setSubCategoryOption(prev => []);
    }
  }, [categoryWatcher]);

  useEffect(() => {
    setValue('category', category);
  }, [categoryOption]);

  useEffect(() => {
    setValue('sub_category', subCategory);
  }, [subCategoryOption]);

  return { categoryOption, subCategoryOption };
};

export default useGetTypeCategory;
