import { REGEX_EMAIL } from '../../../../constant/Common/Regex';

const validateEmailListFormat = ({ emailList: paramEmailList }) => {
  const emailList = [...paramEmailList];
  /** Handle exception case */
  if (emailList?.length === 1 && emailList?.[0] === '') return true;
  for (let i = 0; i < emailList.length; i++) {
    if (!REGEX_EMAIL.test(emailList[i]) || emailList[i] === '') return false;
  }
  return true;
};

/**
 * @description
 * Function that validate email format
 * @param {list | string} email
 * Email data
 * @returns {boolean}
 * Is email format valid
 */
const checkEmailFormat = ({ email }) => {
  /** Check string email */
  if (typeof email === 'string') {
    const convertedEmailList = email?.split(',')?.map(item => item?.trim());
    if (!validateEmailListFormat({ emailList: convertedEmailList }))
      return false;
  }
  /** Check email list */
  if (Array.isArray(email)) {
    if (!validateEmailListFormat({ emailList: email })) return false;
  }
  /** Valid email format include null */
  return true;
};

export default checkEmailFormat;
