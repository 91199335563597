export const SAFETY_COMMON_TYPE = [
  {
    seq: 0,
    label: 'TRK',
    value: 'MR0001',
  },
  {
    seq: 1,
    label: 'CHS',
    value: 'MR0002',
  },
  {
    seq: 2,
    label: 'TRL',
    value: 'MR0003',
  },
  {
    seq: 3,
    label: 'OTHER',
    value: 'MR0004',
  },
];
