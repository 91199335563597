import { useQuery } from '@tanstack/react-query';
import adminDivConfKeys from '../keys';
import getYMSYard from '../../../../service/Admin/DivConf/Get/getYMSYard';

const useGetYMSYard = ({ div }) => {
  const {
    isLoading: isLoadingYMSYard,
    data,
    isRefetching: isRefetchingYMSYard,
    isError: isErrorYMSYard,
  } = useQuery({
    queryKey: adminDivConfKeys?.ymsYard({ div }),
    queryFn: () => getYMSYard({ div }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingYMSYard,
    data: data?.data,
    isRefetchingYMSYard,
    isErrorYMSYard,
  };
};

export default useGetYMSYard;
