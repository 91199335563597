import { ERROR_SPECIFIC_STATUS_CODE } from '../../../../constant/Common/Error/status';
import handleCustomQueryError from './handleCustomQueryError';
import handleErrorStatus from './handleErrorStatus';

/**
 * @description
 * Handle query error from API
 * @param {object} error
 * Error object from API query
 * @returns {string}
 * Error message
 */
const handleQueryError = ({ error }) => {
  /** Check error response status code */
  if (ERROR_SPECIFIC_STATUS_CODE.includes(error?.response?.status))
    return handleErrorStatus({ error });

  /** Check error response data that have code and fields */
  const errors = error?.response?.data?.errors;
  if (errors && errors.length > 0) return handleCustomQueryError({ error });

  /** Uncaught error */
  return 'Unknown error occurred. Please try again or contact the administrator.';
};

export default handleQueryError;
