import React, { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../../../constant/Common/Path/Asset/Icon/Button';
import getFileType from '../../../../../../../util/Safety/Common/Handler/getFileType';
import { ALERT_MODAL_STATUS } from '../../../../../../../constant/Common/Modal/Alert/status';

const FileIcon = ({ rowIdx, alertDataSetter }) => {
  const [fileType, setFileType] = useState(null);
  const fileURL = useWatch({ name: `doc.${rowIdx}.file_url` });

  const [fileSrc, setFileSrc] = useState(
    PATH_COMMON_BUTTON_ICON.downloadBlue.default,
  );
  useEffect(() => {
    if (fileType?.startsWith('video')) {
      setFileSrc(prev => PATH_COMMON_BUTTON_ICON.downloadBlue.default);
    } else if (fileType?.startsWith('image')) {
      setFileSrc(prev => PATH_COMMON_BUTTON_ICON.imageFileBlue.default);
    } else if (fileType?.startsWith('pdf')) {
      setFileSrc(prev => PATH_COMMON_BUTTON_ICON.pdfFileBlue.default);
    } else if (fileType?.startsWith('etc')) {
      setFileSrc(prev => PATH_COMMON_BUTTON_ICON.downloadBlue.default);
    }
  }, [fileType]);

  useEffect(() => {
    if (typeof fileURL === 'object') {
      if (fileURL?.name) {
        const fileURLExtension = fileURL?.name.split('.').pop();
        const tempFileType = getFileType({ extension: fileURLExtension });
        setFileType(prev => `${tempFileType}_temp`);
      }
    }
    if (typeof fileURL === 'string') {
      const fileURLExtension = fileURL.split('.').pop();
      const tempFileType = getFileType({ extension: fileURLExtension });
      setFileType(prev => tempFileType);
    }
  }, [fileURL]);

  if (fileType && !fileType?.endsWith('temp'))
    return (
      <a
        className="flex justify-center items-center cursor-pointer"
        href={fileURL}
        target="_blank"
        rel="noreferrer"
      >
        <img src={fileSrc} alt="File icon" />
      </a>
    );

  if (fileType && fileType?.endsWith('temp'))
    return (
      <div
        className="cursor-not-allowed"
        onClick={() => {
          alertDataSetter?.setAlertModalStatus(prev => {
            return {
              message:
                'You can see the preview or download this file after saving it.',
              status: ALERT_MODAL_STATUS?.ERROR,
            };
          });
          alertDataSetter?.setAlertOpen(prev => true);
        }}
      >
        <img src={fileSrc} alt="File icon" />
      </div>
    );

  return <div />;
};

export default FileIcon;
