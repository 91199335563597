import {
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOTFOUND,
  ERROR_UNAUTHORIZED,
} from '../../../../constant/Common/Error/status';
import {
  handleErrorForbidden,
  handleErrorInternalServerError,
  handleErrorUnauthorized,
  handleNotFound,
} from './handleSpecificErrorStatus';

/**
 * @description
 * Function that handle specific error status code
 * @param {object} error
 * API error reponse
 * @returns {string}
 * Error message
 */
const handleErrorStatus = ({ error }) => {
  /** Get error message from error response */
  const getErrorStatusCode = error?.response?.status;

  /** Handle Unauthorized status (401) */
  if (getErrorStatusCode === ERROR_UNAUTHORIZED)
    return handleErrorUnauthorized();
  /** Handle Forbidden status (403) */
  if (getErrorStatusCode === ERROR_FORBIDDEN) return handleErrorForbidden();
  /** Handle Internal Server Error status (500) */
  if (getErrorStatusCode === ERROR_INTERNAL_SERVER_ERROR)
    return handleErrorInternalServerError();
  /** Handle Not Found Error status (404) */
  if (getErrorStatusCode === ERROR_NOTFOUND) return handleNotFound();
  /** Not handling status code */
  return null;
};

export default handleErrorStatus;
