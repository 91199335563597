const createInputContentsHeader = ({ type }) => {
  if (type === 'text') return 'Text';
  if (type === 'textAdornmentStart') return 'Text Adornment (Start)';
  if (type === 'textAdornmentEnd') return 'Text Adornment (End)';
  if (type === 'autoComplete') return 'Auto Complete';
  if (type === 'select') return 'Select';
  if (type === 'date') return 'Date';
  if (type === 'time') return 'Time';
  if (type === 'textarea') return 'Text Area';
  if (type === 'file') return 'File';
  if (type === 'checkbox') return 'Checkbox';
  if (type === 'toggle') return 'Toggle';
  if (type === 'radio') return 'Radio';
  if (type === 'multipleInput') return 'Multiple Input';
  if (type === 'textSelect') return 'Text Select';
  if (type === 'phoneNumber') return 'Phone Number';
  // Custom
  if (type === 'customText') return 'Custom Text';
  if (type === 'customAutoComplete') return 'Custom Auto Complete';
  if (type === 'customSelect') return 'Custom Select';
  if (type === 'customDate') return 'Custom Date';
  if (type === 'customTime') return 'Custom Time';
  if (type === 'customTextarea') return 'Custom Textarea';
  return '';
};

export default createInputContentsHeader;
