import styled from 'styled-components';

const StyledLogoWrapper = styled.div`
  height: 100%;
  width: fit-content;
  min-width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledLogoWrapper;
