import React, { memo } from 'react';
import StyledSidebarMenuIcon from '../../../../../style/Common/Sidebar/StyledSidebarMenu/StyledSidebarMenuItem/StyledSidebarMenuIcon';
import StyledSidebarMenuIconContent from '../../../../../style/Common/Sidebar/StyledSidebarMenu/StyledSidebarMenuItem/StyledSidebarMenuIcon/StyledSidebarMenuIconContent';

/**
 * @description
 * Sidebar menu icon component.
 * @param {boolean} isCurrentMenu
 * Flag to indicate the current menu.
 * @returns {React.ReactElement}
 */
const SidebarMenuIcon = memo(({ ...props }) => {
  const { isCurrentMenu, iconSet, label } = props;
  return (
    <StyledSidebarMenuIcon className="group/sidebarMenuIcon">
      <div
        className={`w-[4px] h-full ${isCurrentMenu ? `bg-[#001E5F]` : `bg-white`} duration-500`}
      />
      <div className="flex-1 flex justify-center items-center">
        {isCurrentMenu ? (
          <StyledSidebarMenuIconContent
            src={iconSet?.active}
            alt="Sidebar active icon"
          />
        ) : (
          <StyledSidebarMenuIconContent
            src={iconSet?.default}
            alt="Sidebar default icon"
          />
        )}
      </div>
      <div
        className="absolute bottom-[-3px] left-[50%] bg-[#656A6F] text-[#fff] translate-x-[-50%]
                    text-[9px] rounded-[3px] px-[4px] py-[2px] z-[100] whitespace-nowrap hidden group-hover/sidebarMenuIcon:block"
      >
        {label}
      </div>
    </StyledSidebarMenuIcon>
  );
}, null);

export default SidebarMenuIcon;
