import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import { addImageFileList } from './photoFileHandler';
import { validateFileListLength, validateFileType } from './fileValidater';

const handleFileChange = ({ ...props }) => {
  const {
    e,
    imageFileList,
    setImageFileList,
    setAlertOptions,
    fileKey,
    setFileKey,
  } = props;

  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  const newFileList = [...e?.target?.files];

  const isValidFileLength = validateFileListLength({
    newFileList,
    prevFileList: imageFileList,
  });

  if (!isValidFileLength) {
    setIsOpenAlert(true);
    setAlertInfo({
      message: 'Only up to 8 files can be uploaded',
      status: ALERT_MODAL_STATUS?.ERROR,
    });
    return;
  }

  const isValidFileType = validateFileType({ newFileList });

  if (!isValidFileType) {
    setIsOpenAlert(true);
    setAlertInfo({
      message: 'Only image files can be uploaded',
      status: ALERT_MODAL_STATUS?.ERROR,
    });
    return;
  }

  addImageFileList({ setImageFileList, newFileList, fileKey, setFileKey });
};

export default handleFileChange;
