import { PATH_SIDEBAR_MENU_ICON } from '../../Path/Asset/Icon/Sidebar';
import {
  PERM_ADMIN,
  PERM_ADMIN_AUTH,
  PERM_ADMIN_DIV_CONF,
  PERM_ADMIN_USER,
  PERM_DAILY_IN_OUT_DAILY,
  PERM_DAILY_IN_OUT_INVENTORY,
  PERM_SAFETY,
  PERM_SAFETY_ACCIDENT,
  PERM_SAFETY_CHASSIS,
  PERM_SAFETY_DAMAGE_REPORT,
  PERM_SAFETY_IT_TABLET,
  PERM_SAFETY_MR,
  PERM_SAFETY_TRUCK,
  PERM_SAFETY_VAN,
} from '../../Permission';

/** Constant of sidebar menu */
export const SIDEBAR_MENU = [
  {
    seq: 0,
    label: 'DAILY IN / OUT',
    to: '/daily-in-out',
    iconSet: PATH_SIDEBAR_MENU_ICON?.dailyInOut,
    permission: PERM_DAILY_IN_OUT_DAILY,
  },
  {
    seq: 1,
    label: 'INVENTORY',
    to: '/inventory',
    iconSet: PATH_SIDEBAR_MENU_ICON?.inventory,
    permission: PERM_DAILY_IN_OUT_INVENTORY,
  },
  {
    seq: 2,
    label: 'SAFETY',
    to: '/safety/maintenance-repair',
    iconSet: PATH_SIDEBAR_MENU_ICON?.safety,
    permission: PERM_SAFETY,
  },
  {
    seq: 3,
    label: 'ADMIN',
    to: '/admin/user',
    iconSet: PATH_SIDEBAR_MENU_ICON?.admin,
    permission: PERM_ADMIN,
  },
];

/** Constant of sub-menu in each sidebar menu */
export const SIDEBAR_SUB_MENU = {
  /** Daily In / Out sub-menu */
  0: [
    {
      seq: 0,
      label: 'DAILY IN / OUT',
      to: '/daily-in-out',
      permission: PERM_DAILY_IN_OUT_DAILY,
    },
  ],
  /** Inventory sub-menu */
  1: [
    {
      seq: 0,
      label: 'TRUCK',
      to: '/inventory/truck',
      permission: PERM_DAILY_IN_OUT_INVENTORY,
    },
    {
      seq: 1,
      label: 'TRAILER',
      to: '/inventory/trailer',
      permission: PERM_DAILY_IN_OUT_INVENTORY,
    },
    {
      seq: 2,
      label: 'CONTAINER',
      to: '/inventory/container',
      permission: PERM_DAILY_IN_OUT_INVENTORY,
    },
    {
      seq: 3,
      label: 'CHASSIS',
      to: '/inventory/chassis',
      permission: PERM_DAILY_IN_OUT_INVENTORY,
    },
  ],
  /** Safety sub-menu */
  2: [
    {
      seq: 0,
      label: 'M&R',
      to: '/safety/maintenance-repair',
      permission: PERM_SAFETY_MR,
    },
    {
      seq: 1,
      label: 'TRUCK',
      to: '/safety/truck',
      permission: PERM_SAFETY_TRUCK,
    },
    {
      seq: 2,
      label: 'CHASSIS',
      to: '/safety/chassis',
      permission: PERM_SAFETY_CHASSIS,
    },
    {
      seq: 3,
      label: 'VAN',
      to: '/safety/van',
      permission: PERM_SAFETY_VAN,
    },
    {
      seq: 4,
      label: 'IT(TABLET)',
      to: '/safety/it-tablet',
      permission: PERM_SAFETY_IT_TABLET,
    },
    {
      seq: 5,
      label: 'ACCIDENT',
      to: '/safety/accident',
      permission: PERM_SAFETY_ACCIDENT,
    },
    {
      seq: 6,
      label: 'DAMAGE REPORT',
      to: '/safety/damage-report',
      permission: PERM_SAFETY_DAMAGE_REPORT,
    },
  ],
  /** Admin sub-menu */
  3: [
    {
      seq: 0,
      label: 'USER',
      to: '/admin/user',
      permission: PERM_ADMIN_USER,
    },
    {
      seq: 1,
      label: 'AUTH',
      to: '/admin/auth',
      permission: PERM_ADMIN_AUTH,
    },
    {
      seq: 2,
      label: 'SETTINGS',
      to: '/admin/div-conf',
      permission: PERM_ADMIN_DIV_CONF,
    },
  ],
  /** Labs sub-menu */
  4: [
    {
      seq: 0,
      label: 'TRIGGER',
      to: '/labs/trigger',
    },
    {
      seq: 1,
      label: 'GRID',
      to: '/labs/grid',
    },
    {
      seq: 2,
      label: 'INPUT',
      to: '/labs/input',
    },
    {
      seq: 3,
      label: 'CUSTOM',
      to: '/labs/custom',
    },
    {
      seq: 4,
      label: 'BUTTON PRESET',
      to: '/labs/button-preset',
    },
    {
      seq: 5,
      label: 'BUTTON ICON',
      to: '/labs/button-icon',
    },
  ],
};
