import { ALERT_MODAL_STATUS } from '../../../../../constant/Common/Modal/Alert/status';

const clickOKButtonInSuccess = ({ setAlertOpen, setModalOpen }) => {
  setAlertOpen?.(prev => false);
  setModalOpen?.(prev => false);
};

const clickOKButtonInError = ({ setAlertOpen }) => {
  setAlertOpen?.(prev => false);
};

const clickOKButton = ({ setAlertOpen, setModalOpen, status }) => {
  if (status === ALERT_MODAL_STATUS?.SUCCESS) {
    clickOKButtonInSuccess({ setAlertOpen, setModalOpen });
  }
  if (status === ALERT_MODAL_STATUS?.ERROR) {
    clickOKButtonInError({ setAlertOpen });
  }
};

export default clickOKButton;
