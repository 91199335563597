import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import createModalDefaultValue from '../../../../util/Safety/VAN/Generator/createModalDefaultValue';

const useFormController = ({ ...props }) => {
  const { isModalOpen, editMode, specificData } = props;

  const [formDefaultValue, setFormDefaultValue] = useState(
    createModalDefaultValue({ editMode, specificData }),
  );

  const formController = useForm({
    defaultValues: formDefaultValue,
    shouldUnregister: true,
  });

  /** Reset form default value */
  useEffect(() => {
    const newFormDefaultValue = createModalDefaultValue({
      specificData,
      editMode,
    });
    setFormDefaultValue(prev => newFormDefaultValue);
    formController?.reset(newFormDefaultValue);
  }, [isModalOpen, editMode, specificData]);

  return { formController, formDefaultValue };
};

export default useFormController;
