import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getSafetyTruck from '../../../service/Common/Get/getSafetyTruck';

const useGetSafetyTruck = ({ ...props }) => {
  const { keyword } = props;

  const [safetyTruck, setSafetyTruck] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.safetyTruck(),
    queryFn: () => getSafetyTruck({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setSafetyTruck(prev => query?.data?.data);
    } else {
      setSafetyTruck(prev => []);
    }
  }, [query?.data]);

  return { safetyTruck, ...query };
};

export default useGetSafetyTruck;
