import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import inventoryDefaultAPI from '../../api';

const getEquipList = async ({ equip, params }) => {
  try {
    const response = await inventoryDefaultAPI.get(`${equip}`, {
      params,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getEquipList;
