import React, { useEffect } from 'react';
import { LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormContext } from 'react-hook-form';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomTime from '../../../../../style/Common/Controller/Common/Time/Custom/StyledCustomTime';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import ControllerLabel from '../../Common/Label';
import ControllerError from '../../Common/Error';
import ComponentController from '../../Common/ComponentController';

const CustomTime = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
  } = useFormContext();

  const { styles, disabled, required, ...compState } = useControlComponentState(
    { ...props },
  );

  const {
    inputName,
    defaultValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    suppressUtilityButton,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'time',
    setValue,
  });

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ComponentController
            Component={StyledCustomTime}
            inputName={inputName}
            defaultValue={defaultValue}
            componentProps={{
              type: 'time',
              styles,
              disabled,
              required,
              ampm: false,
              format: 'HH:mm',
              slotProps: {
                textField: { error: Boolean(errors?.[inputName]) },
              },
              viewRenderers: {
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
              },
              $suppressUtilityButton: suppressUtilityButton,
              onChange: props?.onChange,
              onClick: props?.onClick,
              onKeyDown: props?.onKeyDown,
              onBlur: props?.onBlur,
            }}
          />
        </LocalizationProvider>
      </StyledCustomControllerWrapper>
      <ControllerError
        errors={errors}
        inputName={inputName}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </>
  );
};

export default CustomTime;
