import styled from 'styled-components';

const StyledModalInfoContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 10px;

  font-size: 12px;

  .info-title {
    font-weight: 600;
    color: #264b9f;
  }

  .info-data {
    display: flex;
    gap: 5px;
    font-weight: 500;
    color: #878787;
  }

  .info-separator {
    height: 6px;
    display: flex;
    border-left: 1px solid #878787;
    align-self: center;
  }
`;

export default StyledModalInfoContainer;
