import { TOAST_TYPE } from '../../../../constant/Common/Toast/toastTypes';

export const generateToastHeader = ({ type, header }) => {
  if (type === TOAST_TYPE?.SUCCESS) {
    return 'Success!';
  }
  if (type === TOAST_TYPE?.ERROR) {
    return 'Error';
  }
  if (type === TOAST_TYPE?.WARNING) {
    return 'Warning!';
  }
  return header || 'Toast Message';
};

export const generateToastMessage = ({ type, message }) => {
  if (type === TOAST_TYPE?.SUCCESS) {
    return message || 'You have successfully completed this work.';
  }
  return message;
};
