import styled from 'styled-components';

const StyledModalInputContainer = styled.div`
  width: 100%;

  display: grid;
  gap: 0 18px;
  grid-template-columns: ${props => props?.$columnSize || 'repeat(4, 116px)'};

  padding: 0px 12px 0px 12px;
`;

export default StyledModalInputContainer;
