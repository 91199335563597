import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../../../../../../constant/Common/Style/color';

const StyledSubMenuNavigatorItemActivator = styled.div`
  width: 100%;
  height: 2px;

  background-color: ${props =>
    props?.$isCurrentMenu ? PRIMARY_COLOR : 'transparent'};

  transition-duration: 300ms;
`;

export default StyledSubMenuNavigatorItemActivator;
