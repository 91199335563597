import adminDefaultAPI from '../../api';
import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';

const getAuthList = async () => {
  try {
    const response = await adminDefaultAPI.get(`auth`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getAuthList;
