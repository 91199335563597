import styled from 'styled-components';

const StyledModalContentWrapper = styled.div`
  width: 670px;

  display: flex;
  align-items: center;
  flex-direction: column;

  overflow-y: auto;

  gap: 11px;
`;

export default StyledModalContentWrapper;
