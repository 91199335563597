import styled from 'styled-components';

const StyledUtilityBarContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px 40px 15px 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;

  .utility-button-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
`;

export default StyledUtilityBarContainer;
