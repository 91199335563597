import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getSafetyChassis from '../../../service/Common/Get/getSafetyChassis';

const useGetSafetyChassis = ({ ...props }) => {
  const { keyword } = props;

  const [safetyChassis, setSafetyChassis] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.safetyChassis(),
    queryFn: () => getSafetyChassis({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setSafetyChassis(prev => query?.data?.data);
    } else {
      setSafetyChassis(prev => []);
    }
  }, [query?.data]);

  return { safetyChassis, ...query };
};

export default useGetSafetyChassis;
