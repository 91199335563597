const handleChangedDivision = ({
  divList,
  setValue,
  selectedDivList,
  setSelectedDivList,
  ...props
}) => {
  const { e, onChange, ...handlerProps } = props;

  const changeValue = e?.target?.checked;
  onChange(changeValue);

  if (handlerProps?.name === 'ALL') {
    divList?.forEach(div => {
      setValue(div?.div, changeValue);
    });

    const newSelectedDivList = [...selectedDivList].map(div => {
      return { ...div, value: changeValue };
    });

    setSelectedDivList(newSelectedDivList);
  }
};

export default handleChangedDivision;
