import {
  ERROR_ASSIGNED,
  ERROR_DEPENDENT,
  ERROR_DOES_NOT_EXIST,
  ERROR_DUPLICATED,
  ERROR_INACTIVE_USER,
  ERROR_INVALID,
  ERROR_MUTEX,
  ERROR_NOT_FOUND,
  ERROR_PAIR_UNIQUE,
  ERROR_PERMISSION_DENIED,
  ERROR_QB_ALREADY_EXIST,
  ERROR_REQUIRED,
  ERROR_UNIQUE,
} from './message';

const ERROR_MESSAGE_PAIR = {
  required: ERROR_REQUIRED,
  invalid: ERROR_INVALID,
  unique: ERROR_UNIQUE,
  pair_unique: ERROR_PAIR_UNIQUE,
  not_found: ERROR_NOT_FOUND,
  permission_denied: ERROR_PERMISSION_DENIED,
  inactive_user: ERROR_INACTIVE_USER,
  qb_already_exist: ERROR_QB_ALREADY_EXIST,
  dependent: ERROR_DEPENDENT,
  mutex: ERROR_MUTEX,
  assigned: ERROR_ASSIGNED,
  duplicated: ERROR_DUPLICATED,
  does_not_exist: ERROR_DOES_NOT_EXIST,
};

export default ERROR_MESSAGE_PAIR;
