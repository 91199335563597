import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StyledSubMenuNavigatorItemWrapper from '../../../../../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorContentsWrapper/StyledSubMenuNavigatorItemWrapper';
import checkCurrentMenu from '../../../../../../../util/Common/Sidebar/SidebarMenu/Handler/checkCurrentMenu';
import StyledSubMenuNavigatorItemActivator from '../../../../../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorContentsWrapper/StyledSubMenuNavigatorItemWrapper/StyledSubMenuNavigatorItemActivator';

const SubMenuItem = ({ ...props }) => {
  const { label, to } = props;

  const { pathname } = useLocation();

  const [isCurrentMenu, setIsCurrentMenu] = useState(false);

  useEffect(() => {
    if (checkCurrentMenu({ pathname, to, isSubMenu: true })) {
      setIsCurrentMenu(true);
    } else {
      setIsCurrentMenu(false);
    }
  }, [pathname]);

  return (
    <Link to={to}>
      <StyledSubMenuNavigatorItemWrapper $isCurrentMenu={isCurrentMenu}>
        {label}
      </StyledSubMenuNavigatorItemWrapper>
      <StyledSubMenuNavigatorItemActivator $isCurrentMenu={isCurrentMenu} />
    </Link>
  );
};

export default SubMenuItem;
