import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import { PATH_DATAGRID_GROUP_FOLD_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';

const createDefaultInfoGroup = () => {
  return [
    {
      headerName: 'Group00 - Default Info',
      backgroundColor: '#e8d8ff',
      // icon: PATH_DATAGRID_GROUP_FOLD_ICON,
      icon: {
        fold: PATH_COMMON_BUTTON_ICON.activeGreen?.hover,
        unfold: PATH_COMMON_BUTTON_ICON.activeGreen?.default,
      },
      isColumnGroup: true,
      children: [
        {
          headerName: 'First Name',
          field: 'first_name',
          floatingFilterComponent: FloatingFilterSelect,
          floatingFilterComponentParams: {
            option: [
              { value: '', label: 'Select' },
              { value: 'James', label: 'James' },
              { value: 'Jayden', label: 'Jayden' },
              { value: 'Hwee', label: 'Hwee' },
              { value: 'Ted', label: 'Ted' },
              { value: 'Others', label: 'Others' },
            ],
          },
        },
        {
          headerName: 'Last Name',
          field: 'last_name',
          floatingFilterComponent: FloatingFilterSelect,
          floatingFilterComponentParams: {
            option: [
              { value: '', label: 'Select' },
              { value: 'Cha', label: 'Cha' },
              { value: 'Lim', label: 'Lim' },
              { value: 'Song', label: 'Song' },
              { value: 'Jeon', label: 'Jeon' },
              { value: 'Others', label: 'Others' },
            ],
          },
          columnGroupShow: 'open',
        },
        {
          headerName: 'Phone',
          field: 'phone_number',
          columnGroupShow: 'open',
        },
      ],
    },
  ];
};

export default createDefaultInfoGroup;
