export const CARRIER_OPTION = [
  { value: 'IC0001', label: 'AT&T' },
  { value: 'IC0002', label: 'SPRINT' },
  { value: 'IC0003', label: 'TELCEL' },
  { value: 'IC0004', label: 'T-MOBILE' },
  { value: 'IC0005', label: 'VERIZON' },
];

export const STATUS_OPTION = [
  { value: 'SSI001', label: 'WORKING' },
  { value: 'SSI002', label: 'REPAIRING' },
  { value: 'SSI003', label: 'BROKEN' },
];
