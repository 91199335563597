import styled from 'styled-components';

const StyledCommonMessageModalCloseButtonWrapper = styled.div`
  width: fit-content;
  height: fit-content;

  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;

  cursor: pointer;

  border-radius: 5px;

  transition-duration: 0.3s;
  &:hover {
    background-color: #e5e7eb6b;
  }
`;

export default StyledCommonMessageModalCloseButtonWrapper;
