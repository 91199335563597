import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import StyledFileTableRowWrapper from '../../../../../../style/Safety/Common/Modal/FileTable/FileTableRow/StyledFileTableRowWrapper';
import StyledRowItemWrapper from '../../../../../../style/Safety/Common/Modal/FileTable/FileTableRow/StyledRowItemWrapper';
import CustomDate from '../../../../../Common/Controller/Date/Custom';
import CustomTime from '../../../../../Common/Controller/Time/Custom';
import CustomSelect from '../../../../../Common/Controller/Select/Custom';
import CustomTextInput from '../../../../../Common/Controller/Text/Custom';
import CommonButton from '../../../../../Common/Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../../constant/Common/Path/Asset/Icon/Button';
import CustomHidden from '../../../../../Common/Controller/File/CustomHidden';
import {
  changeDate,
  changeFile,
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import {
  appendRow,
  removeRow,
} from '../../../../../../util/Safety/Common/Handler/tableRowAppendRemoveHandler';
import FileIcon from './FileIcon';
import createFileTableDocumentOption from '../../../../../../util/Safety/Common/Generator/createFileTableDocumentOption';

const FileTableRow = ({ ...props }) => {
  const { getValues, setValue, register, control } = useFormContext();
  const {
    type,
    rowIdx,
    append,
    remove,
    data,
    page,
    isEditable,
    alertDataSetter,
  } = props;

  return (
    <>
      <StyledFileTableRowWrapper key={rowIdx}>
        <StyledRowItemWrapper className="w-[98px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          <CustomDate
            inputName={`doc.${rowIdx}.updated_date`}
            styles={{
              border: 'none',
              borderRadius: '0px',
              hoverBorder: 'none',
              disabledBorder: 'none',
              fontWeight: '700',
              fontSize: '12px',
            }}
            disabled
            suppressLabelVisible
            suppressErrorVisible
            suppressUtilityButton
            onChange={({ ...changeProps }) => {
              changeDate({ ...changeProps });
            }}
            defaultValue={data?.updated_date}
          />
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[70px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          <CustomTime
            inputName={`doc.${rowIdx}.updated_time`}
            styles={{
              border: 'none',
              borderRadius: '0px',
              hoverBorder: 'none',
              disabledBorder: 'none',
            }}
            disabled
            suppressLabelVisible
            suppressErrorVisible
            suppressUtilityButton
            onChange={({ ...changeProps }) => {
              changeDate({ ...changeProps });
            }}
            defaultValue={
              data?.updated_date && dayjs(data?.updated_date)?.isValid()
                ? dayjs(data?.updated_date)
                : null
            }
          />
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[116px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          <CustomSelect
            inputName={`doc.${rowIdx}.document`}
            required={rowIdx !== 0}
            styles={{
              border: 'none',
              borderRadius: '0px',
              hoverBorder: 'none',
              disabledBorder: 'none',
            }}
            option={createFileTableDocumentOption({ page })}
            suppressLabelVisible
            suppressErrorVisible
            suppressUtilityButton
            onChange={({ ...changeProps }) => {
              changeSelect({ ...changeProps });
            }}
            defaultValue={data?.document || ''}
            disabled={!isEditable}
          />
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[100px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          <CustomDate
            inputName={`doc.${rowIdx}.exp_date`}
            styles={{
              border: 'none',
              borderRadius: '0px',
              hoverBorder: 'none',
              disabledBorder: 'none',
            }}
            suppressLabelVisible
            suppressErrorVisible
            suppressUtilityButton
            onChange={({ ...changeProps }) => {
              changeDate({ ...changeProps });
            }}
            defaultValue={
              data?.exp_date && dayjs(data?.exp_date)?.isValid()
                ? dayjs(data?.exp_date)
                : null
            }
            disabled={!isEditable}
          />
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[99px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          <CustomTextInput
            inputName={`doc.${rowIdx}.updated_by`}
            styles={{
              border: 'none',
              borderRadius: '0px',
              hoverBorder: 'none',
              disabledBorder: 'none',
            }}
            suppressLabelVisible
            suppressErrorVisible
            suppressUtilityButton
            disabled
            onChange={({ ...changeProps }) => {
              changeText({ ...changeProps });
            }}
            defaultValue={data?.updated_by || ''}
          />
        </StyledRowItemWrapper>
        <StyledRowItemWrapper
          className={`w-[65px] h-full border-solid border-r-[1px] border-r-[#EEE] ${type === 'add' ? `bg-[#F9F9F9]` : ''}`}
        >
          {isEditable && rowIdx !== 0 && (
            <CommonButton
              icon={PATH_COMMON_BUTTON_ICON.browseBlue}
              styles={{
                border: 'none',
                hoverBackgroundColor: '#556DA1',
                iconSize: '14px !important',
                padding: '5px !important',
              }}
              onClick={() =>
                document.getElementById(`doc.${rowIdx}.file_url`).click()
              }
            />
          )}
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[35px] h-full border-solid border-r-[1px] border-r-[#EEE]">
          {type === 'add' ? null : (
            <FileIcon rowIdx={rowIdx} alertDataSetter={alertDataSetter} />
          )}
        </StyledRowItemWrapper>
        <StyledRowItemWrapper className="w-[28px] h-full">
          {isEditable && (
            <CommonButton
              icon={
                type === 'add'
                  ? PATH_COMMON_BUTTON_ICON.circlePlus
                  : PATH_COMMON_BUTTON_ICON.circleMinus
              }
              styles={{
                border: 'none',
                hoverBackgroundColor: type === 'add' ? '#556DA1' : '#B74747',
                iconSize: '14px !important',
                padding: '5px !important',
              }}
              onClick={() =>
                type === 'add'
                  ? appendRow({ append, getValues, setValue })
                  : removeRow({ remove, getValues, rowIdx })
              }
            />
          )}
        </StyledRowItemWrapper>
      </StyledFileTableRowWrapper>
      <CustomHidden
        inputName={`doc.${rowIdx}.file_url`}
        onChange={changeFile}
        defaultValue={data?.file_url || null}
      />
    </>
  );
};

export default FileTableRow;
