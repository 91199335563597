import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import safetyVANKeys from '../keys';
import getListData from '../../../../service/Safety/VAN/Get/getListData';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const useGetListData = ({ ...props }) => {
  const { setAlertOpen, setAlertModalStatus } = props;

  const [listData, setListData] = useState([]);

  const query = useQuery({
    queryKey: safetyVANKeys.listData(),
    queryFn: () => getListData(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setListData(prev => query?.data?.data);
    } else {
      setListData(prev => []);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { ...query, listData };
};

export default useGetListData;
