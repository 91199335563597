import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import StyledLogoWrapper from '../../../../style/Common/Header/StyledLogo/StyledLogoWrapper';
import { PATH_HEADER_LOGO } from '../../../../constant/Common/Path/Asset/Logo';

/**
 * @description
 * Component of Logo in the application header.
 * @returns {React.ReactElement}
 */
const Logo = memo(() => {
  return (
    <StyledLogoWrapper>
      <Link to="/daily-in-out">
        <img src={PATH_HEADER_LOGO} alt="Header logo" />
      </Link>
      <div className="text-[#fff] font-bold text-[12px]">YMS</div>
    </StyledLogoWrapper>
  );
}, null);

export default Logo;
