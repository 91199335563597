import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import commonDefaultAPI from '../api';

const getSizeList = async ({ keyword }) => {
  try {
    const response = await commonDefaultAPI.get(
      `size-list${keyword ? `/${keyword}` : ''}`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSizeList;
