import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchYMSYardActivate from '../../../../service/Admin/DivConf/Patch/patchYMSYardActivate';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminDivConfKeys from '../keys';

const usePatchYMSYardActivateMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  div,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchYMSYardActivate,
    onSettled: (data, error, variables) => {
      if (variables?.setDisabled) variables?.setDisabled(false);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchYMSYardActivateMutation;
