import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../constant/Common/Style/color';

const StyledHeaderWrapper = styled.div`
  /** Default property */
  height: 56px;
  width: 100%;
  min-width: 650px;
  background-color: ${PRIMARY_COLOR};

  /** Position */
  position: fixed;
  top: 0;
  left: 0;

  /** Display */
  display: flex;
  align-items: center;

  /** Do not fix this value */
  z-index: 9998;
`;

export default StyledHeaderWrapper;
