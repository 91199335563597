import React from 'react';
import { Navigate } from 'react-router-dom';
import getAuthorizedMenu from '../../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import AuthRouter from '../../../../../component/Common/Router/Common/AuthRouter';
import { PERM_DAILY_IN_OUT_DAILY } from '../../../Permission';
import DailyInOut from '../../../../../page/DailyInOut/DailyInOut';
/**
 * @description
 * Children routes of the daily in-out page.
 */
const DAILY_IN_OUT_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_DAILY}>
        <DailyInOut />
      </AuthRouter>
    ),
  },
];

export default DAILY_IN_OUT_CHILDREN_ROUTE;
