import React from 'react';
import StyledSubMenuNavigatorWrapper from '../../../../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorWrapper';
import StyledSubMenuNavigatorHeader from '../../../../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorHeader';
import {
  PAGE_NAME_MAPPER,
  SUB_MENU_MAPPER,
} from '../../../../../../constant/Common/Router/App/Common/mapper';
import { SIDEBAR_SUB_MENU } from '../../../../../../constant/Common/Option/Sidebar';
import StyledSubMenuNavigatorContentsWrapper from '../../../../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorContentsWrapper';
import SubMenuItem from './SubMenuItem';
import getAuthorizedMenu from '../../../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';

const SubMenuNavigator = ({ page }) => {
  const router = getAuthorizedMenu({
    allMenu: SIDEBAR_SUB_MENU?.[SUB_MENU_MAPPER[page]],
  });

  return (
    <StyledSubMenuNavigatorWrapper>
      <StyledSubMenuNavigatorHeader>
        {PAGE_NAME_MAPPER?.[page]}
      </StyledSubMenuNavigatorHeader>
      <StyledSubMenuNavigatorContentsWrapper>
        {router?.map(element => (
          <SubMenuItem key={element?.seq} {...element} />
        ))}
      </StyledSubMenuNavigatorContentsWrapper>
    </StyledSubMenuNavigatorWrapper>
  );
};

export default SubMenuNavigator;
