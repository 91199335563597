import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyITTabletDefaultAPI from '../api';

const getSelectedITTablet = async ({ seq }) => {
  try {
    const response = await safetyITTabletDefaultAPI.get(`/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSelectedITTablet;
