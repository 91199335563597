import styled from 'styled-components';
import { APP_BACKGROUND_COLOR } from '../../../constant/Common/Style/color';

const StyledLabsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* align-items: center; */
  background-color: ${APP_BACKGROUND_COLOR};
  padding: 20px;
  overflow: auto;
`;

export default StyledLabsWrapper;
