import dayjs from 'dayjs';

export const handleInputChange = ({ e, onChange, ...props }) => {
  onChange(e?.target?.value);
};

export const handleDateInputChange = ({ e, onChange, ...props }) => {
  const returnValue = e && dayjs?.(e)?.isValid() ? e : null;

  onChange(returnValue);
};

export const handleAutoCompleteChange = ({ e, onChange, selectProps }) => {
  onChange?.(selectProps?.value);
};

export const handleTimeChange = ({ e, onChange, ...props }) => {
  const returnValue = e && dayjs?.(e)?.isValid() ? e : null;

  onChange(returnValue);
};

export const handleUpperInputBlur = ({ e, onChange }) => {
  onChange(e?.target?.value?.toUpperCase());
};

export const handleCheckBoxChange = ({ e, onChange, ...props }) => {
  onChange(e?.target?.checked);
};

export const handlePairPlateAutoCompleteChange = ({
  e,
  onChange,
  selectProps,
  ...props
}) => {
  /**  Props for Change Plate */
  const { setValue, plateInputName } = props;

  handleAutoCompleteChange({ e, onChange, selectProps, ...props });
  setValue(plateInputName, selectProps?.plate);
};
