import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import UtilityBar from '../../Common/UtilityBar';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import ModalHeader from '../../Common/Modal/ModalHeader';
import ModalButton from '../../Common/Modal/ModalButton';
import SafetyDatagrid from '../../Common/SafetyDatagrid';
import createTruckColumnDefs from '../../../../util/Safety/Truck/Generator/createTruckColumnDefs';
import ModalContent from '../Modal/ModalContent';
import useGetListData from '../../../../hook/Safety/Truck/Get/useGetListData';
import usePatchActive from '../../../../hook/Safety/Truck/Patch/usePatchActive';
import CommonAlert from '../../../Common/Modal/Alert';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import CommonLoading from '../../../Common/Loading';
import useControlLoadingStatus from '../../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import useFormController from '../../../../hook/Safety/Truck/Form/useFormController';
import useGetSpecificTruckData from '../../../../hook/Safety/Truck/Get/useGetSpecificTruckData';
import createModalDefaultValue from '../../../../util/Safety/Truck/Generator/createModalDefaultValue';
import saveTruck from '../../../../util/Safety/Truck/Handler/Save/saveTruck';
import usePatchSpecificTruckData from '../../../../hook/Safety/Truck/Patch/usePatchSpecificTruckData';
import usePostTruckData from '../../../../hook/Safety/Truck/Post/usePostTruckData';
import clickOKButton from '../../../../util/Safety/Common/Handler/Save/okButtonHandler';
import useGetGridFilterOption from '../../../../hook/Safety/Common/Option/useGetGridFilterOption';
import checkPermission from '../../../../util/Common/Router/Handler/checkPermission';
import { PERM_SAFETY_TRUCK_EDIT } from '../../../../constant/Common/Permission';

const SafetyTruckContents = () => {
  /** useRef for handling disabled */
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  /** Essential */
  const gridRef = useRef(null);
  const saveButtonRef = useRef(null);
  const [seq, setSeq] = useState(null);
  const isEditable = () =>
    checkPermission({ permission: [PERM_SAFETY_TRUCK_EDIT] });

  /** Data modal */
  const [isModalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(EDIT_MODE?.add);

  /** Message modal opener */
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertModalStatus, setAlertModalStatus] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });
  const alertDataSetter = { setAlertOpen, setAlertModalStatus };

  /** Grid, Modal data */
  const {
    listData,
    isLoading: loadingGetList,
    isFetching: fetchingGetList,
  } = useGetListData({ ...alertDataSetter });
  const {
    specificData,
    isLoading: loadingSpecificData,
    isFetching: fetchingSpecificData,
    isRefetching: refetchingSpecificData,
    isError: errorSpecificData,
  } = useGetSpecificTruckData({ seq, isModalOpen, ...alertDataSetter });

  /** Form */
  const { formController, formDefaultValue } = useFormController({
    isModalOpen,
    editMode,
    specificData,
  });

  /** Data patcher */
  const { mutate: mutatePatchActive, isPending: pendingPatchActive } =
    usePatchActive({
      gridRef,
      disabledRef,
      setAlertModalStatus,
      setAlertOpen,
    });
  const { mutate: editMutate, isPending: editMutatePending } =
    usePatchSpecificTruckData({
      gridRef,
      setAlertModalStatus,
      setAlertOpen,
      setModalOpen,
    });
  const { mutate: addMutate, isPending: addMutatePending } = usePostTruckData({
    setAlertModalStatus,
    setAlertOpen,
    setModalOpen,
  });

  /** Loading component opener */
  const { isLoadingOpen, setLoadingOpen } = useControlLoadingStatus({
    isFetching: [fetchingSpecificData],
    isLoading: [loadingGetList, loadingSpecificData],
    isPending: [],
  });

  /** Filter option */
  const gridFilterOption = useGetGridFilterOption();
  const { filterDivisionList } = gridFilterOption;

  /** Grid column definition */
  const [columnDefs, setColumnDefs] = useState(
    createTruckColumnDefs({
      mutatePatchActive,
      isEditable: isEditable(),
      disabledRef,
      ...gridFilterOption,
    }),
  );

  return (
    <>
      <div className="pl-[41px] flex flex-col flex-1 pb-8">
        <UtilityBar
          setModalOpen={setModalOpen}
          setEditMode={setEditMode}
          gridRef={gridRef}
          isEditable={isEditable()}
          subPage="TRUCK"
        />

        {filterDivisionList?.length > 1 && (
          <SafetyDatagrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={listData}
            setSpecificSeq={setSeq}
            setModalOpen={setModalOpen}
            setEditMode={setEditMode}
            preventTargetColumnID={['active']}
          />
        )}
      </div>
      {/* Input modal */}
      {isModalOpen && !loadingSpecificData && (
        <CommonMessageModalFrame
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          header={<ModalHeader editMode={editMode} title="Truck" />}
          content={
            <ModalContent
              formController={formController}
              data={formDefaultValue}
              isEditable={isEditable()}
            />
          }
          button={
            <ModalButton
              onClearClick={() => {
                formController?.reset({
                  ...createModalDefaultValue({
                    specificData,
                    editMode,
                  }),
                });
              }}
              onSaveClick={() => {
                formController?.handleSubmit(data =>
                  saveTruck?.({
                    data,
                    editMutate,
                    addMutate,
                    seq,
                    editMode,
                    saveButtonRef,
                  }),
                )();
              }}
              isEditable={isEditable()}
              saveButtonRef={saveButtonRef}
            />
          }
        />
      )}
      {/* Loading */}
      {isLoadingOpen && <CommonLoading isOpen={isLoadingOpen} />}
      {/* Alert */}
      {isAlertOpen &&
        alertModalStatus?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
          <CommonAlert
            isOpen={isAlertOpen}
            setOpen={setAlertOpen}
            status={alertModalStatus?.status}
            content={alertModalStatus?.message}
            onOKButtonClick={() => {
              clickOKButton?.({
                setAlertOpen,
                setModalOpen,
                status: alertModalStatus?.status,
              });
            }}
          />
        )}
    </>
  );
};

export default SafetyTruckContents;
