export const clickAdd = ({ gridParam, ...props }) => {
  console.log('Add Clicked : ', gridParam, props);
};

export const clickDelete = ({ gridParam, ...props }) => {
  console.log('Delete Clicked : ', gridParam, props);
};

export const clickSave = ({ gridParam, ...props }) => {
  console.log('Save Clicked : ', gridParam, props);
};

export const clickRow = ({ gridParam, ...props }) => {
  const getColId = gridParam?.api?.getFocusedCell().column.getColId();
  if (!getColId.startsWith('button_')) {
    console.log('Row Clicked : ', gridParam, props, getColId);
  }
};

export const clickCell = ({ gridParam, ...props }) => {
  const getColId = gridParam?.column?.colId;
  if (!getColId.startsWith('button_')) {
    console.log('Cell Clicked : ', gridParam, props, getColId);
  }
};

export const startRowEdit = ({ gridParam, ...props }) => {
  console.log('Row Editing Started : ', gridParam, props);
};

export const stopRowEdit = ({ gridParam, ...props }) => {
  console.log('Row Editing Stopped : ', gridParam, props);
};
