import getSelectedITTablet from '../../../../service/Safety/ITTablet/Get/getSelectedITTablet';

const handleCellClick = async (
  { setSelectedITTablet, setIsAdd, setIsAddEditModalOpen },
  params,
) => {
  if (params?.gridParam?.column?.colId === 'active') return;
  const response = await getSelectedITTablet({
    seq: params?.gridParam?.data?.seq,
  });
  setSelectedITTablet(response?.data);
  setIsAdd(false);
  setIsAddEditModalOpen(true);
};

export default handleCellClick;
