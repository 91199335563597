import React from 'react';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonDate from '../../../Common/Controller/Date';
import {
  changeDate,
  changeText,
} from '../../../../util/Safety/Common/Handler/controllerChangeHandler';

const InsuranceInfoContent = ({ accident, isEditable }) => {
  return (
    <div className="flex pt-[21px] gap-x-[16px]">
      <div>
        <CommonTextInput
          label="CLAIM #"
          inputName="claim_no"
          width="w-[200px]"
          defaultValue={accident?.claim_no || ''}
          maxLength={64}
          onChangeHandler={changeText}
          disabled={!isEditable}
        />
      </div>
      <div>
        <CommonDate
          label="CLAIM DATE"
          inputName="claim_date"
          width="w-[169px]"
          defaultValue={accident?.claim_date || ''}
          onChangeHandler={changeDate}
          disabled={!isEditable}
        />
      </div>
      <div>
        <CommonTextInput
          label="ADJUSTOR"
          inputName="adjustor"
          width="w-[200px]"
          defaultValue={accident?.adjustor || ''}
          maxLength={20}
          onChangeHandler={changeText}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default InsuranceInfoContent;
