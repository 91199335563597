import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDefaultAPI from '../../api';

const deleteGroup = async ({ id }) => {
  try {
    const response = await adminDefaultAPI.delete(`group/${id}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteGroup;
