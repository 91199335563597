import styled from 'styled-components';

const StyledHamburgerButton = styled.button`
  width: fit-content;
  height: fit-content;

  padding: 10px;
  border-radius: 5px;

  &:hover {
    background-color: #556da1;
    transition-duration: 300ms;
  }
`;

export default StyledHamburgerButton;
