import React from 'react';
import StyledFileTableHeaderWrapper from '../../../../../../style/Safety/Common/Modal/FileTable/FileTableHeader/StyledFileTableHeaderWrapper';
import StyledHeaderItemWrapper from '../../../../../../style/Safety/Common/Modal/FileTable/FileTableHeader/HeaderItem/StyledHeaderItemWrapper';

const FileTableHeader = () => {
  return (
    <StyledFileTableHeaderWrapper>
      <StyledHeaderItemWrapper className="w-[98px] h-full border-solid border-[1px] border-[#EEE]">
        Date
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[70px] h-full border-solid border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]">
        Time
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[116px] h-full border-solid border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]">
        Document
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[100px] h-full border-solid border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]">
        EXP Date
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[99px] h-full border-solid border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]">
        Updated by
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[65px] h-full border-solid border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]">
        Browse
      </StyledHeaderItemWrapper>
      <StyledHeaderItemWrapper className="w-[35px] h-full border-b-[1px] border-y-[1px] border-y-[#EEE]" />
      <StyledHeaderItemWrapper className="w-[28px] h-full border-b-[1px] border-r-[1px] border-r-[#EEE] border-y-[1px] border-y-[#EEE]" />
    </StyledFileTableHeaderWrapper>
  );
};

export default FileTableHeader;
