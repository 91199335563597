import React from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCheckBoxArea from '../../../../style/Safety/DamageReport/StyledCheckBoxArea';
import CommonDamageCheckBox from '../CommonDamageCheckBox';
import {
  TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST,
  TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST2,
} from '../../../../constant/Safety/DamageReport/options';
import handlePairCheckBoxChange from '../../../../util/Safety/DamageReport/Handler/handlePairCheckBoxChange';
import {
  STYLE_CHECK_BOX_COLUMN_WRAPPER,
  STYLE_CHECK_BOX_HEADER_WRAPPER,
  STYLE_TRAILER_CHASSIS_TIRES_CHECKBOX_AREA,
} from '../../../../style/Safety/DamageReport/styles';

const TrailerChassisTiresInfoFields = ({
  isCompleted,
  damageReport,
  isEditable,
}) => {
  const { setValue } = useFormContext();
  return (
    <div className={STYLE_CHECK_BOX_COLUMN_WRAPPER}>
      <div className={STYLE_CHECK_BOX_HEADER_WRAPPER}>
        TRAILER OR CHASSIS TIRES
      </div>
      <div className="w-full">
        <StyledCheckBoxArea
          styles={{ ...STYLE_TRAILER_CHASSIS_TIRES_CHECKBOX_AREA }}
        >
          <div>
            <CommonDamageCheckBox
              options={TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST}
              disabled={isCompleted || !isEditable}
              onChangeHandler={props =>
                handlePairCheckBoxChange({ setValue, ...props })
              }
            />
          </div>
          <div className="mt-[8px]">
            <CommonDamageCheckBox
              options={TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST2}
              disabled={isCompleted || !isEditable}
              onChangeHandler={props =>
                handlePairCheckBoxChange({ setValue, ...props })
              }
            />
          </div>
        </StyledCheckBoxArea>
      </div>
    </div>
  );
};

export default TrailerChassisTiresInfoFields;
