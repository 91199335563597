/** Constant of sub-menu mapper */
export const SUB_MENU_MAPPER = {
  dailyInOut: 0,
  inventory: 1,
  safety: 2,
  admin: 3,
  labs: 4,
};

export const PAGE_NAME_MAPPER = {
  dailyInOut: 'DAILY IN / OUT',
  inventory: 'INVENTORY',
  safety: 'SAFETY',
  admin: 'ADMIN',
  labs: 'Hello World!',
};

export const PAGE_DAILY_IN_OUT = 'dailyInOut';
export const PAGE_INVENTORY = 'inventory';
export const PAGE_SAFETY = 'safety';
export const PAGE_ADMIN = 'admin';
export const PAGE_LABS = 'labs';
