import React from 'react';
import { useSelector } from 'react-redux';
import DivisionSelectorItem from './DivisionSelectorItem';
import StyledDivisionSelectorOptionWrapper from '../../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorOption/StyledDivisionSelectorOptionWrapper';

/**
 * @description
 * Division selector option component.
 * @returns {React.ReactElement}
 */
const DivisionSelectorOption = ({ setIsOpen, ...props }) => {
  const currDiv = useSelector(state => state?.common?.currDiv);
  const divisionList = useSelector(state => state?.common?.divisionList);

  return (
    <StyledDivisionSelectorOptionWrapper>
      {divisionList?.map((division, index) => (
        <DivisionSelectorItem
          key={String(index + 1)}
          isSelected={currDiv === division?.div}
          setIsOpen={setIsOpen}
        >
          {division?.div}
        </DivisionSelectorItem>
      ))}
    </StyledDivisionSelectorOptionWrapper>
  );
};

export default DivisionSelectorOption;
