import React from 'react';
import { Outlet } from 'react-router-dom';
import SubMenuNavigator from '../../component/Common/Router/App/Common/SubMenuNavigator';
import { PAGE_ADMIN } from '../../constant/Common/Router/App/Common/mapper';

const Admin = () => {
  return (
    <div className="w-full flex flex-col">
      <SubMenuNavigator page={PAGE_ADMIN} />
      <Outlet />
    </div>
  );
};

export default Admin;
