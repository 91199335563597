import React from 'react';
import StyledCommonAlertContentWrapper from '../../../../../style/Common/Modal/Alert/Content/StyledCommonAlertContentWrapper';

const CommonAlertContent = ({ children, ...props }) => {
  return (
    <StyledCommonAlertContentWrapper>
      {children}
    </StyledCommonAlertContentWrapper>
  );
};

export default CommonAlertContent;
