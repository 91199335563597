import styled from 'styled-components';

const StyledErrorPageContentsWrapper = styled.div`
  width: 895px;
  height: 570px;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default StyledErrorPageContentsWrapper;
