import styled from 'styled-components';

const StyledCommonMessageModalContentWrapper = styled.div`
  width: 100%;
  height: fit-content;
  overflow-y: scroll;

  margin-top: 8px;
  padding-top: 12px;

  display: flex;
  justify-content: center;
`;

export default StyledCommonMessageModalContentWrapper;
