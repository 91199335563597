export const COMMON_DEPRECIATION_STATUS = [
  {
    seq: 0,
    label: 'Finish',
    value: 'DS0001',
  },
  {
    seq: 1,
    label: 'Continue',
    value: 'DS0002',
  },
];
