const DAILY_IN_OUT_TYPE = {
  DTI001: 'IN - EMPTY',
  DTI002: 'IN - LOAD',
  DTO001: 'OUT - EMPTY',
  DTO002: 'OUT - LOAD',
};
const DAILY_IN_OUT_CATEGORY_TYPE = {
  DC0001: 'IMPORT',
  DC0002: 'EXPORT',
  DC0003: 'LOCAL',
};

const DAILY_IN_OUT_OPTION = [
  { label: 'Empty In', value: 'DTI001' },
  { label: 'Empty Out', value: 'DTO001' },
  { label: 'Load In', value: 'DTI002' },
  { label: 'Load Out', value: 'DTO002' },
];

const DAILY_IN_OUT_CATEGORY_OPTION = [
  { label: 'IMPORT', value: 'DC0001' },
  { label: 'EXPORT', value: 'DC0002' },
  { label: 'LOCAL', value: 'DC0003' },
];

export {
  DAILY_IN_OUT_CATEGORY_OPTION,
  DAILY_IN_OUT_OPTION,
  DAILY_IN_OUT_TYPE,
  DAILY_IN_OUT_CATEGORY_TYPE,
};
