import styled from 'styled-components';

const StyledCommonMessageModalCloseButtonContent = styled.img`
  width: 12px;
  height: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledCommonMessageModalCloseButtonContent;
