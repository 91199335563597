import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import convertInputData from './convertInputData';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import checkContainerNumberConstraint from '../../../Common/Validator/Handler/checkContainerNumberConstraint';
import { ERROR_INVALID_CONTAINER_NUMBER } from '../../../../constant/Common/Error/message';
import updateButtonDisabled from '../../../Common/Controller/Handler/updateButtonDisabled';

const saveDamageReport = ({
  seq,
  modalType,
  handleSubmit,
  postDamageReportMutator,
  setAlertOptions,
  imageFileList,
  damagePatchMutator,
  saveButtonRef,
}) => {
  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  handleSubmit(submitData => {
    if (saveButtonRef)
      updateButtonDisabled({ ref: saveButtonRef, disabled: true });

    const inputData = { ...submitData };
    const fileList = [];

    /** Handling Truck # / Chassis # */
    inputData.trk_no = inputData.isNGLTruck
      ? inputData.trk_no_auto_complete
      : inputData.trk_no_text;

    inputData.ch_no = inputData.isNGLChassis
      ? inputData.ch_no_auto_complete
      : inputData.ch_no_text;

    /** Chassis No / Truck No null validation */
    if (!inputData?.ch_no && !inputData?.trk_no) {
      if (saveButtonRef)
        updateButtonDisabled({ ref: saveButtonRef, disabled: false });
      setIsOpenAlert(prev => true);
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS?.ERROR,
        message: 'One of the Truck # / Classis # must be entered.',
      }));
      return;
    }

    if (!checkContainerNumberConstraint({ containerNo: inputData?.cont_no })) {
      if (saveButtonRef)
        updateButtonDisabled({ ref: saveButtonRef, disabled: false });
      setIsOpenAlert(prev => true);
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS?.ERROR,
        message: ERROR_INVALID_CONTAINER_NUMBER,
      }));
      return;
    }

    const convertedData = convertInputData({
      dateList: ['ch_annual', 'ch_bit', 'report_date'],
      timeList: ['report_time'],
      possibleEmptyStringList: [
        'trk_no',
        'driver',
        'trk_plate_no',
        'trk_ordometer',
        'cont_no',
        'cont_seal',
        'ch_no',
        'ch_plate_no',
        'ch_seal',
        'yard_location',
        'body_condition_remarks',
        'trk_others',
        'ch_others',
      ],
      autoCompleteList: ['driver', 'trk_no', 'ch_no'],
      inputData,
    });

    /** Photo File Handling */
    imageFileList?.forEach(imageFile => {
      fileList?.push(
        imageFile?.fileInfo?.lastModified
          ? imageFile?.fileInfo
          : imageFile?.fileInfo?.file_url,
      );
    });

    convertedData.photo = fileList;

    /** Post */
    if (modalType === EDIT_MODE?.add) {
      postDamageReportMutator({
        postData: convertedData,
        buttonRef: saveButtonRef,
      });
      return;
    }

    /** Patch */
    if (modalType === EDIT_MODE?.edit && seq) {
      damagePatchMutator({
        seq,
        patchData: convertedData,
        buttonRef: saveButtonRef,
      });
    }
  })();
};

export default saveDamageReport;
