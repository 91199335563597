/** Specific code error message */
export const ERROR_PERMISSION_DENIED = 'Permission denied.';
export const ERROR_NOT_FOUND = `Please try again. Cannot found from your request.`;
export const ERROR_INACTIVE_USER =
  'Your account has been deactivated. Please contact to System Admin.';
export const ERROR_DEPENDENT = 'Please check dependent fields follow.';

/** Field(s) related error message */
export const ERROR_REQUIRED = 'field(s) is required.';
export const ERROR_INVALID = 'field(s) is invalid.';
export const ERROR_UNIQUE = 'field(s) should be given a unique value.';
export const ERROR_PAIR_UNIQUE = 'field(s) pairs must have unique values.';
export const ERROR_QB_ALREADY_EXIST = 'is already exist in QuickBook.';
export const ERROR_MUTEX = `field(s) can't be used together. Choice one of them.`;
export const ERROR_ASSIGNED = `field(s) already assigned. Please check again.`;
export const ERROR_DUPLICATED = `field(s) is duplicated.`;
export const ERROR_DOES_NOT_EXIST = `field(s) does not exist.`;

/** Pre-check error message */
export const ERROR_INVALID_CONTAINER_NUMBER = `Please input validate container number in CONT #`;
export const ERROR_INVALID_CHASSIS_NUMBER = `Please input 4 Capital letters and 6 digits in CHASSIS #`;

export const ERROR_UNKNOWN =
  'Unknown Error occurred :<. Please contact to admin.';
