import generateFieldRelatedErrorMessage from '../Generator/generateFieldRelatedErrorMessage';
import { generateSpecificCodeErrorMessage } from '../Generator/generateSpecificErrorMessage';
import { specificCodeExistHandler } from './checkSpecificFieldCodeExist';

/**
 * @description
 * Function that handle custom error.
 * @param {object} error: Error object from query
 * @returns {string}
 * Error message
 */
const handleCustomQueryError = ({ error }) => {
  const errors = error?.response?.data?.errors;
  const targetError = errors?.[0];

  /** Return a message for a specific error or field */
  if (specificCodeExistHandler({ code: targetError?.code }))
    return generateSpecificCodeErrorMessage({ error });

  /** Return a message for a specific field related error message */
  return generateFieldRelatedErrorMessage({ error });
};

export default handleCustomQueryError;
