import { EDIT_MODE } from '../../../../../constant/Safety/Common/mode';
import updateButtonDisabled from '../../../../Common/Controller/Handler/updateButtonDisabled';
import convertDataFormat from '../../../Common/Handler/convertDataFormat';
import deleteProperty from '../../../Common/Handler/deleteProperty';
import separateFileTableData from '../../../Common/Handler/separateFileTableData';

const saveVAN = ({ ...props }) => {
  const { data, editMutate, addMutate, seq, editMode, saveButtonRef } = props;

  // Disable save button
  if (saveButtonRef?.current)
    updateButtonDisabled({ ref: saveButtonRef, disabled: true });

  // User input data
  const inputData = { ...data };

  // Convert data format
  const convertedData = convertDataFormat({
    inputData,
    date: [
      'loan_start_date',
      'loan_end_date',
      'depreciation_start_date',
      'depreciation_end_date',
    ],
    number: [
      'make_year',
      'purchase_amt',
      'total_loan_amt',
      'sche_payment',
      'extra_payment',
      'depreciation_amt',
    ],
  });

  // Separate file-table data
  const separatedData = separateFileTableData({ inputData: convertedData });

  // Remove unnecessary data
  const manipulatedData = deleteProperty({ inputData: separatedData });

  if (editMode === EDIT_MODE?.edit)
    editMutate?.({ seq, data: manipulatedData, buttonRef: saveButtonRef });
  if (editMode === EDIT_MODE?.add)
    addMutate?.({ data: manipulatedData, buttonRef: saveButtonRef });
};

export default saveVAN;
