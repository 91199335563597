import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomDate from '../../../../../style/Common/Controller/Common/Date/Custom/StyledCustomDate';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import ControllerLabel from '../../Common/Label';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import ControllerError from '../../Common/Error';
import ComponentController from '../../Common/ComponentController';

const CustomDate = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
  } = useFormContext();

  const { styles, disabled, required, ...compState } = useControlComponentState(
    { ...props },
  );

  const {
    inputName,
    defaultValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    suppressUtilityButton,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'date',
    setValue,
  });

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ComponentController
            Component={StyledCustomDate}
            inputName={inputName}
            defaultValue={
              defaultValue && dayjs(defaultValue)?.isValid()
                ? dayjs(defaultValue)
                : null
            }
            componentProps={{
              type: 'date',
              styles,
              disabled,
              required,
              format: 'MM/DD/YY',
              slotProps: {
                textField: { error: Boolean(errors?.[inputName]) },
              },
              $suppressUtilityButton: suppressUtilityButton,
              onChange: props?.onChange,
              onClick: props?.onClick,
              onKeyDown: props?.onKeyDown,
              onBlur: props?.onBlur,
            }}
          />
        </LocalizationProvider>
      </StyledCustomControllerWrapper>
      <ControllerError
        errors={errors}
        inputName={inputName}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </>
  );
};

export default CustomDate;
