import styled from 'styled-components';

const StyledInputContentsWrapper = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: white;

  width: 500px;
  height: fit-content;
`;

export default StyledInputContentsWrapper;
