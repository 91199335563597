import React from 'react';
import StyledCustomLabelWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomLabelWrapper';
import StyledCustomLabelRequiredWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomLabelRequiredWrapper';

const ControllerLabel = ({
  label,
  required,
  suppressLabelVisible,
  suppressLabelContentVisible,
}) => {
  return (
    !suppressLabelVisible && (
      <StyledCustomLabelWrapper>
        {!suppressLabelContentVisible && (
          <>
            {required && label && (
              <StyledCustomLabelRequiredWrapper>
                *
              </StyledCustomLabelRequiredWrapper>
            )}
            {label}
          </>
        )}
      </StyledCustomLabelWrapper>
    )
  );
};

export default ControllerLabel;
