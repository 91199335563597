import styled from 'styled-components';

const StyledCustomLabelWrapper = styled.div`
  width: ${props => props?.styles?.labelWidth || '100%'};
  height: ${props => props?.styles?.labelHeight || '14px'};
  padding-bottom: ${props => props?.styles?.labelPaddingBottom || '3px'};

  display: flex;
  align-items: center;

  color: ${props => props?.styles?.labelColor || '#595656'};
  font-size: ${props => props?.styles?.labelFontSize || '12px'};
  font-weight: ${props => props?.styles?.labelFontWeight || '500'};
`;

export default StyledCustomLabelWrapper;
