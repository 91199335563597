import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import inventoryDefaultAPI from '../../api';

const patchSpecificEquipData = async ({ equip, seq, data }) => {
  try {
    const response = await inventoryDefaultAPI.patch(`${equip}/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchSpecificEquipData;
