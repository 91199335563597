import axios from 'axios';
import requestInterceptors from '../../util/Common/API/requestInterceptors';
import responseInterceptors from '../../util/Common/API/responseInterceptors';

const SERVICE_BASE_URL = `${process.env.REACT_APP_BASE_URL}/common/`;

/**
 * @description
 * Function that configure axios property
 * @param {string | any} withCredentials
 * Parameter to include credentials in the request
 * @param {string | any} xsrfHeaderName
 * Parameter to include xsrf header name
 * @param {string | any} xsrfCookieName
 * Parameter to include xsrf cookie name
 * @param {object} headers
 * Parameter to include headers
 * @returns {object}
 * Axios property
 */
const configureAxiosProperty = ({
  withCredentials,
  xsrfHeaderName,
  xsrfCookieName,
  headers,
}) => {
  return {
    withCredentials: withCredentials || true,
    xsrfHeaderName: xsrfHeaderName || 'X-CSRFTOKEN',
    xsrfCookieName: xsrfCookieName || 'XCSRF-TOKEN',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      ...headers,
    },
  };
};

/**
 * Axios object with base url
 */
const createServiceDefaultAPI = (props = {}) => {
  const { withCredentials, xsrfHeaderName, xsrfCookieName, headers } = props;

  const axiosInstance = axios.create({
    baseURL: SERVICE_BASE_URL,
    ...configureAxiosProperty({
      withCredentials,
      xsrfHeaderName,
      xsrfCookieName,
      headers,
    }),
  });

  requestInterceptors({ axiosInstance });
  responseInterceptors({ axiosInstance });

  return axiosInstance;
};

const commonDefaultAPI = createServiceDefaultAPI();

export default commonDefaultAPI;
