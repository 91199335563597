import styled from 'styled-components';

const StyledSidebarBlurWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: rgba(0, 0, 0, 0.5);

  transition-duration: 300ms;

  z-index: 100;
`;

export default StyledSidebarBlurWrapper;
