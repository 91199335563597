import { useMutation } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchSpecificEquipData from '../../../../service/Inventory/Common/Patch/patchSpecificEquipData';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';
import useControlToast from '../../../Common/Toast/useControlToast';

const usePatchSpecificEquipData = ({ equip, gridRef, ...props }) => {
  const { addToast } = useControlToast();

  const { setAlertModalStatus, setAlertOpen, setSeq } = props;

  const mutator = useMutation({
    mutationFn: patchSpecificEquipData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (res, variables) => {
      variables.data.seq = variables.seq;
      updateGridData({ gridRef, newData: variables.data, isUpdate: true });
      addToast({ message: SUCCESS_SAVE });
      setSeq(prev => null);
    },
    onError: error => {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
  });

  return { ...mutator };
};

export default usePatchSpecificEquipData;
