import React from 'react';
import StyledModalInfoContainer from '../../../../../../style/Inventory/Modal/EditModal/StyledModalInfoContainer';
import { generateFormattedDay } from '../../../../../../util/Inventory/Common/Generator/formattedDayGenerator';

const UpdateInfoArea = ({ data }) => {
  return (
    <StyledModalInfoContainer>
      <div className="info-title">UPDATED BY</div>
      <div className="info-data">
        <div>{data?.updated_by}</div>
        <div className="info-separator" />
        <div>{generateFormattedDay(data?.updated_date, 'MM/DD/YY')}</div>
      </div>
    </StyledModalInfoContainer>
  );
};
export default UpdateInfoArea;
