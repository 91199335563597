import React from 'react';
import Trigger from '../../../../page/Labs/Trigger';
import Grid from '../../../../page/Labs/Grid';
import Input from '../../../../page/Labs/Input';
import ButtonPreset from '../../../../page/Labs/ButtonPreset';
import ButtonIcon from '../../../../page/Labs/ButtonIcon';
import Custom from '../../../../page/Labs/Custom';

/**
 * @description
 * Children routes of the Labs page.
 */
const LABS_CHILDREN_ROUTE = [
  {
    path: '',
    element: <Trigger />,
  },
  {
    path: 'trigger/*',
    element: <Trigger />,
  },
  {
    path: 'grid/*',
    element: <Grid />,
  },
  {
    path: 'input/*',
    element: <Input />,
  },
  {
    path: 'custom/*',
    element: <Custom />,
  },
  {
    path: 'button-preset/*',
    element: <ButtonPreset />,
  },
  {
    path: 'button-icon/*',
    element: <ButtonIcon />,
  },
];

export default LABS_CHILDREN_ROUTE;
