import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getInventoryTrailerList from '../../../service/DailyInOut/Get/getInventoryTrailerList';

const useGetInventoryTrailerList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryTrailerList,
    data,
    isRefetching: isRefetchingInventoryTrailerList,
    isError: isErrorInventoryTrailerList,
  } = useQuery({
    queryKey: dailyInOutKeys?.trailerList({ div, yard }),
    queryFn: () => getInventoryTrailerList({ div, yard }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingInventoryTrailerList,
    inventoryTrailerList: data?.data,
    isRefetchingInventoryTrailerList,
    isErrorInventoryTrailerList,
  };
};

export default useGetInventoryTrailerList;
