import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDivConfDefaultAPI from '../../divConfAPI';

const patchYMSYardActivate = async ({ seq }) => {
  try {
    const response = await adminDivConfDefaultAPI.patch(
      `yms-yard/${seq}/activate`,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchYMSYardActivate;
