import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import serviceDefaultAPI from '../../api';

const getUserInfo = async () => {
  try {
    const response = await serviceDefaultAPI.get(`sign/user-info`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getUserInfo;
