const handleTriggerActive = ({ setTrigger, target, cancelTime }) => {
  setTrigger?.(prev => {
    return { ...prev, [target]: true };
  });
  if (cancelTime && cancelTime !== 0) {
    setTimeout(() => {
      setTrigger?.(prev => {
        return { ...prev, [target]: false };
      });
    }, cancelTime);
  }
};

export default handleTriggerActive;
