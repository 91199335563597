import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import patchDailyInOut from '../../../service/DailyInOut/Patch/patchDailyInOut';
import updateButtonDisabled from '../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../util/Common/Datagrid/Handler/updateGridData';
import useControlToast from '../../Common/Toast/useControlToast';
import dailyInOutKeys from '../keys';

const usePatchDailyInOutMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  setIsAddEditModalOpen,
  addEditModalController,
  selectedSeq,
  gridRef,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchDailyInOut,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (res, variables) => {
      variables.data.seq = variables.seq;
      setIsAddEditModalOpen(false);
      addEditModalController.reset();
      queryClient.refetchQueries({
        queryKey: dailyInOutKeys?.selected({ seq: selectedSeq }),
      });
      updateGridData({ gridRef, newData: variables.data, isUpdate: true });
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchDailyInOutMutation;
