import safetyDefaultAPI from '../../api';

const getDamageReportList = async ({ queryString }) => {
  try {
    const response = await safetyDefaultAPI.get(`damage-report?${queryString}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDamageReportList;
