import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import postYMSYard from '../../../../service/Admin/DivConf/Post/postYMSYard';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminDivConfKeys from '../keys';

const usePostYMSYardMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  div,
  divConfController,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postYMSYard,
    onSettled: (data, error, variables) => {
      if (variables?.setDisabled) variables?.setDisabled(false);
    },
    onSuccess: () => {
      divConfController.reset();
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      addToast?.({ message: SUCCESS_SAVE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostYMSYardMutation;
