import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDivConfDefaultAPI from '../../divConfAPI';

const getYMSYard = async ({ div }) => {
  try {
    const response = await adminDivConfDefaultAPI.get(`${div}/yms-yard`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getYMSYard;
