import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createOptionalSelectOption } from '../../../../util/Common/Option/Generator/selectOptionCreator';

const useGetGridFilterOption = () => {
  /** Get item from redux */
  const divisionList = useSelector(state => state?.common?.allDiv);
  const chassisSizeList = useSelector(state => state?.common?.chassisSizeList);
  const chassisTypeList = useSelector(state => state?.common?.chassisTypeList);
  const poolList = useSelector(state => state?.common?.poolList);
  const sizeList = useSelector(state => state?.common?.sizeList);
  const sslList = useSelector(state => state?.common?.sslList);
  const typeList = useSelector(state => state?.common?.typeList);

  /** Division */
  const [filterDivisionList, setFilterDivisionList] = useState(
    createOptionalSelectOption({
      items: divisionList,
      isGridFilter: true,
    }) || [],
  );
  useEffect(() => {
    setFilterDivisionList(
      createOptionalSelectOption({
        items: divisionList,
        isGridFilter: true,
      }) || [],
    );
  }, [divisionList]);

  /** Chassis size */
  const [filterChassisSizeList, setFilterChassisSizeList] = useState(
    createOptionalSelectOption({
      items: chassisSizeList,
      isGridFilter: true,
    }) || [],
  );
  useEffect(() => {
    setFilterChassisSizeList(
      createOptionalSelectOption({
        items: chassisSizeList,
        isGridFilter: true,
      }) || [],
    );
  }, [chassisSizeList]);

  /** Chassis type */
  const [filterChassisTypeList, setFilterChassisTypeList] = useState(
    createOptionalSelectOption({
      items: chassisTypeList,
      isGridFilter: true,
    }) || [],
  );
  useEffect(() => {
    setFilterChassisTypeList(
      createOptionalSelectOption({
        items: chassisTypeList,
        isGridFilter: true,
      }) || [],
    );
  }, [chassisTypeList]);

  /** Pool */
  const [filterPoolList, setFilterPoolList] = useState(
    createOptionalSelectOption({ items: poolList, isGridFilter: true }) || [],
  );
  useEffect(() => {
    setFilterPoolList(
      createOptionalSelectOption({ items: poolList, isGridFilter: true }) || [],
    );
  }, [poolList]);

  /** Size */
  const [filterSizeList, setFilterSizeList] = useState(
    createOptionalSelectOption({ items: sizeList, isGridFilter: true }) || [],
  );
  useEffect(() => {
    setFilterSizeList(
      createOptionalSelectOption({ items: sizeList, isGridFilter: true }) || [],
    );
  }, [sizeList]);

  /** SSL */
  const [filterSSLList, setFilterSSLList] = useState(
    createOptionalSelectOption({ items: sslList, isGridFilter: true }) || [],
  );
  useEffect(() => {
    setFilterSSLList(
      createOptionalSelectOption({ items: sslList, isGridFilter: true }) || [],
    );
  }, [sslList]);

  /** Type */
  const [filterTypeList, setFilterTypeList] = useState(
    createOptionalSelectOption({ items: typeList, isGridFilter: true }) || [],
  );
  useEffect(() => {
    setFilterTypeList(
      createOptionalSelectOption({ items: typeList, isGridFilter: true }) || [],
    );
  }, [typeList]);

  return {
    filterDivisionList,
    filterChassisSizeList,
    filterChassisTypeList,
    filterPoolList,
    filterSizeList,
    filterSSLList,
    filterTypeList,
    setFilterDivisionList,
    setFilterChassisSizeList,
    setFilterChassisTypeList,
    setFilterPoolList,
    setFilterSizeList,
    setFilterSSLList,
    setFilterTypeList,
  };
};

export default useGetGridFilterOption;
