import React from 'react';
import { PATH_DATAGRID_FOLD_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Datagrid';

const FoldCellRenderer = ({ ...props }) => {
  return (
    <div
      onClick={() => {
        props?.node?.setExpanded(!props?.node?.expanded);
      }}
      className="w-full h-full flex justify-center items-center cursor-pointer"
    >
      {props?.node?.expanded ? (
        <img src={PATH_DATAGRID_FOLD_ICON?.fold} alt="Datagrid fold icon" />
      ) : (
        <img src={PATH_DATAGRID_FOLD_ICON?.unfold} alt="Datagrid unfold icon" />
      )}
    </div>
  );
};

export default FoldCellRenderer;
