import React, { useEffect } from 'react';
import StyledNormalCellRenderer from '../../../../../style/Common/Datagrid/NormalCellRenderer/StyledNormalCellRenderer';
import StyledNormalCellAdornmentRenderer from '../../../../../style/Common/Datagrid/NormalCellRenderer/StyledNormalCellAdornmentRenderer';
import StyledNormalCellValueRenderer from '../../../../../style/Common/Datagrid/NormalCellRenderer/StyledNormalCellValueRenderer';

/**
 * @description
 * Normal cell renderer
 * @param {*} param0
 * @returns
 * Normal cell renderer
 */
const NormalCellRenderer = ({ styles, adornment, ...props }) => {
  /** Get cell props */
  const { value: cellValue, gridParam } = props;

  return (
    <StyledNormalCellRenderer {...styles} {...gridParam}>
      {adornment?.position === 'start' && (
        <StyledNormalCellAdornmentRenderer
          $adornmentPosition={adornment?.position}
          {...styles}
        >
          {adornment?.content}
        </StyledNormalCellAdornmentRenderer>
      )}
      <StyledNormalCellValueRenderer {...styles}>
        {cellValue}
      </StyledNormalCellValueRenderer>
      {adornment?.position === 'end' && (
        <StyledNormalCellAdornmentRenderer
          $adornmentPosition={adornment?.position}
          {...styles}
        >
          {adornment?.content}
        </StyledNormalCellAdornmentRenderer>
      )}
    </StyledNormalCellRenderer>
  );
};

export default NormalCellRenderer;
