const getFileType = ({ extension }) => {
  if (
    ['mp4', 'mov', 'wmv', 'avi', 'avchd', 'flv', 'f4v', 'swf', 'mkv'].includes(
      extension,
    )
  )
    return 'video';
  if (['bmp', 'gif', 'jpg', 'jpeg', 'png', 'tif'].includes(extension))
    return 'image';
  if (['pdf'].includes(extension)) return 'pdf';
  if (extension) return 'etc';
  return null;
};

export default getFileType;
