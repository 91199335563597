import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomTextarea from '../../../../../style/Common/Controller/Common/Textarea/Custom/StyledCustomTextarea';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import ControllerLabel from '../../Common/Label';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import ControllerError from '../../Common/Error';
import ComponentController from '../../Common/ComponentController';

const CustomTextarea = ({ ...props }) => {
  const {
    formState: { errors },
    unregister,
    setValue,
  } = useFormContext();

  const { styles, disabled, required, maxLength, ...compState } =
    useControlComponentState({ ...props });

  const {
    inputName,
    defaultValue,
    compValue,
    setCompValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    suppressCountVisible,
    suppressUtilityButton,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'textarea',
    setValue,
  });

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />
        <ComponentController
          Component={StyledCustomTextarea}
          inputName={inputName}
          defaultValue={defaultValue}
          setCompValue={setCompValue}
          componentProps={{
            styles,
            disabled,
            required,
            maxLength,
            onChange: props?.onChange,
            onClick: props?.onClick,
            onKeyDown: props?.onKeyDown,
            onBlur: props?.onBlur,
          }}
        />
      </StyledCustomControllerWrapper>
      <div className="flex justify-between mt-0">
        <ControllerError
          errors={errors}
          inputName={inputName}
          suppressErrorVisible={suppressErrorVisible}
          suppressErrorContentVisible={suppressErrorContentVisible}
        />
        {!suppressCountVisible && (
          <div
            className={`text-[12px] my-[5px] ${
              errors?.[inputName] ? 'text-[#FF2424]' : ''
            }`}
          >
            {` ${compValue?.length || 0} / ${maxLength}`}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomTextarea;
