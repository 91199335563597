import styled from 'styled-components';

const StyledUtilityBarWrapper = styled.div`
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;

  width: 100%;
  /* min-width: 1000px; */
  height: fit-content;
`;

export default StyledUtilityBarWrapper;
