import createAddAdornmentGroup from './createAddAdornmentGroup';
import createAddGroup from './createAddGroup';
import createAdornmentGroup from './createAdornmentGroup';
import createButtonGroup from './createButtonGroup';
import createDefaultInfoGroup from './createDefaultInfoGroup';
import createModAdornmentGroup from './createModAdornmentGroup';
import createModGroup from './createModGroup';
import createNormalGroup from './createNormalGroup';

const createGridColumn = () => {
  return [
    { headerName: 'TEST1', field: 'test1' },
    { headerName: 'TEST100', field: 'test2' },
    ...createDefaultInfoGroup(),
    { headerName: 'TEST2', field: 'test3' },
    { headerName: 'TEST3', field: 'test4' },
    ...createAdornmentGroup(),
    ...createButtonGroup(),
    { headerName: 'TEST4', field: 'test5' },
    { headerName: 'TEST5', field: 'test6' },
    ...createAddGroup(),
    ...createAddAdornmentGroup(),
    ...createModGroup(),
    ...createModAdornmentGroup(),
    ...createNormalGroup(),
  ];
};

export default createGridColumn;
