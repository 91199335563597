import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchITTablet from '../../../../service/Safety/ITTablet/Patch/patchITTablet';
import useControlToast from '../../../Common/Toast/useControlToast';
import safetyITTabletKeys from '../keys';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchITTabletMutation = ({
  gridRef,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  setIsAddEditModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchITTablet,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      const { div, ...successData } = data?.data;
      updateGridData({
        gridRef,
        isUpdate: true,
        newData: { ...variables?.data, ...successData },
      });
      setIsAddEditModalOpen(false);
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: safetyITTabletKeys?.itTabletList(),
      });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error?.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchITTabletMutation;
