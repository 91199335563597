export const validateFileListLength = ({ newFileList, prevFileList }) => {
  const MAX_IMAGE_LIST_LENGTH = 8;
  let isValidFileLength;
  if (newFileList && prevFileList) {
    isValidFileLength =
      newFileList?.length + prevFileList?.length <= MAX_IMAGE_LIST_LENGTH;
  } else {
    isValidFileLength = false;
  }

  return isValidFileLength;
};

export const validateFileType = ({ newFileList }) => {
  const isValidFileType = !newFileList?.some(file => {
    if (!file?.type?.includes('image')) {
      return true;
    }
    return false;
  });

  return isValidFileType;
};
