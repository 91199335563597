import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonTextInput from '../../../Common/Controller/Text';
import {
  handleAutoCompleteChange,
  handleCheckBoxChange,
  handleInputChange,
  handlePairPlateAutoCompleteChange,
  handleUpperInputBlur,
} from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CustomTextInput from '../../../Common/Controller/Text/Custom';
import CommonCheckbox from '../../../Common/Controller/Checkbox';
import { IS_NGL_TRUCK_OPTION } from '../../../../constant/Safety/DamageReport/options';

const TruckBasicInfoFields = ({
  driverOptions,
  truckOptions,
  isCompleted,
  damageReport,
  isEditable,
}) => {
  const { watch, setValue } = useFormContext();

  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-x-[10px]">
        <div className="w-[54px]">
          <CommonCheckbox
            label="TRUCK #"
            borderVisible
            options={IS_NGL_TRUCK_OPTION}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleCheckBoxChange}
          />
        </div>
        <div className="pt-[14px] w-[155px]">
          <div className={`${watch('isNGLTruck') ? 'block' : 'hidden'}`}>
            <CommonAutoComplete
              labelVisible={false}
              inputName="trk_no_auto_complete"
              option={truckOptions}
              disabled={isCompleted || !isEditable}
              onChangeHandler={props =>
                handlePairPlateAutoCompleteChange({
                  plateInputName: 'trk_plate_no',
                  setValue,
                  ...props,
                })
              }
              defaultValue={damageReport?.trk_no_auto_complete || ''}
            />
          </div>
          <div className={`${!watch('isNGLTruck') ? 'block' : 'hidden'}`}>
            <CustomTextInput
              inputName="trk_no_text"
              maxLength={10}
              suppressLabelVisible
              suppressLabelContentVisible
              suppressErrorVisible
              suppressErrorContentVisible
              onChange={handleInputChange}
              onBlur={handleUpperInputBlur}
              disabled={isCompleted || !isEditable}
              defaultValue={damageReport?.trk_no_text || ''}
            />
          </div>
        </div>
        <div className="w-[221px]">
          <CommonTextInput
            label="PLATE #"
            inputName="trk_plate_no"
            maxLength={10}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.trk_plate_no || ''}
          />
        </div>
      </div>
      <div className="flex gap-x-[10px] mt-[-1px]">
        <div className="w-[221px]">
          <CommonAutoComplete
            label="DRIVER ID"
            inputName="driver"
            option={driverOptions}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleAutoCompleteChange}
            defaultValue={damageReport?.driver || ''}
          />
        </div>
        <div className="w-[221px]">
          <CommonTextInput
            label="ODOMETER"
            inputName="trk_ordometer"
            disabled={isCompleted || !isEditable}
            maxLength={7}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.trk_ordometer || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default TruckBasicInfoFields;
