import styled from 'styled-components';

const StyledFileTableRowWrapper = styled.div`
  width: 100%;
  height: 31px;
  background-color: white;
  border: 1px solid #eee;
  border-top: none;

  display: flex;
  flex-direction: row;
`;

export default StyledFileTableRowWrapper;
