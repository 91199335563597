import { createGlobalStyle } from 'styled-components';
import { APP_BACKGROUND_COLOR } from '../../constant/Common/Style/color';

const StyledGlobal = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif !important;
}

html{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${APP_BACKGROUND_COLOR};
}

#root{
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.16px;
    height: 100%;
    width: 100%;
    position: relative;
}

::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    margin: 20px;
}
    
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    background-clip: padding-box;
    border: 3px solid transparent;
    height: 10%;
}

.ag-theme-alpine {
    --ag-grid-size: 2px;
}
`;

export default StyledGlobal;
