import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const patchActive = async ({ seq }) => {
  try {
    const response = await safetyDefaultAPI.patch(`chassis/${seq}/active`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchActive;
