/**
 * @description
 * Path of icon used in the button component.
 */
const PATH_BUTTON_ICON = '/asset/Icon/Button';
const PATH_COMMON_ICON = '/asset/Icon/Common';

export const PATH_HAMBURGER_ICON = `${PATH_BUTTON_ICON}/hamburger.svg`;
export const PATH_DIVISION_SELECTOR_COLLAPSE_ICON = `${PATH_BUTTON_ICON}/divisionSelectorCollapse.svg`;
export const PATH_SIDEBAR_MENU_ARROW_ICON = {
  greyScale: `${PATH_BUTTON_ICON}/sidebarMenuArrowGreyScale.svg`,
};
export const PATH_MODAL_CLOSE_ICON = `${PATH_BUTTON_ICON}/modalClose.svg`;
export const PATH_INPUT_CLEAR_ICON = `${PATH_BUTTON_ICON}/inputClear.svg`;
export const PATH_DELETE_RED_ONLY_ICON = `${PATH_BUTTON_ICON}/deleteRedOnlyIcon.svg`;
export const PATH_DELETE_GREY_ONLY_ICON = `${PATH_BUTTON_ICON}/deleteGreyOnlyIcon.svg`;
export const PATH_HEADER_NAVIGATOR_ARROW_ICON = `${PATH_BUTTON_ICON}/headerNavigatorArrow.svg`;
export const PATH_HEADER_NAVIGATOR_ICON = `${PATH_BUTTON_ICON}/headerNavigator.svg`;
export const PATH_ARROW_DOWN_BLUE_ICON = `${PATH_COMMON_ICON}/arrowDownBlue.svg`;
export const PATH_HYPHEN_ICON = `${PATH_COMMON_ICON}/hyphen.svg`;
export const PATH_EDITABLE_HEADER_MARK = `${PATH_COMMON_ICON}/editableHeaderMark.svg`;

export const PATH_COMMON_BUTTON_ICON = {
  checkBlue: {
    default: `${PATH_BUTTON_ICON}/checkBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/checkWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/checkWhite.svg`,
  },
  editGrey: {
    default: `${PATH_BUTTON_ICON}/editGrey.svg`,
    hover: `${PATH_BUTTON_ICON}/editWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/editWhite.svg`,
  },
  saveBlue: {
    default: `${PATH_BUTTON_ICON}/saveBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/saveWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/saveWhite.svg`,
  },
  cancelRed: {
    default: `${PATH_BUTTON_ICON}/cancelRed.svg`,
    hover: `${PATH_BUTTON_ICON}/cancelWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/cancelWhite.svg`,
  },
  deleteGrey: {
    default: `${PATH_BUTTON_ICON}/deleteGrey.svg`,
    hover: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
  },
  deleteBlue: {
    default: `${PATH_BUTTON_ICON}/deleteBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
  },
  deleteRed: {
    default: `${PATH_BUTTON_ICON}/deleteRed.svg`,
    hover: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/deleteWhite.svg`,
  },
  plusBlue: {
    default: `${PATH_BUTTON_ICON}/plusBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/plusWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/plusWhite.svg`,
  },
  clearRed: {
    default: `${PATH_BUTTON_ICON}/clearRed.svg`,
    hover: `${PATH_BUTTON_ICON}/clearWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/clearWhite.svg`,
  },
  clearBlue: {
    default: `${PATH_BUTTON_ICON}/clearBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/clearWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/clearWhite.svg`,
  },
  pdfRed: {
    default: `${PATH_BUTTON_ICON}/pdfRed.svg`,
    hover: `${PATH_BUTTON_ICON}/pdfWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/pdfWhite.svg`,
  },
  excelGreen: {
    default: `${PATH_BUTTON_ICON}/excelGreen.svg`,
    hover: `${PATH_BUTTON_ICON}/excelWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/excelWhite.svg`,
  },
  syncBlue: {
    default: `${PATH_BUTTON_ICON}/syncBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/syncWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/syncWhite.svg`,
  },
  circleCheckBlue: {
    default: `${PATH_BUTTON_ICON}/circleCheckBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/circleCheckWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/circleCheckWhite.svg`,
  },
  banRed: {
    default: `${PATH_BUTTON_ICON}/banRed.svg`,
    hover: `${PATH_BUTTON_ICON}/banWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/banWhite.svg`,
  },
  editBlue: {
    default: `${PATH_BUTTON_ICON}/editBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/editWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/editWhite.svg`,
  },
  exportBlue: {
    default: `${PATH_BUTTON_ICON}/exportBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/exportWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/exportWhite.svg`,
  },
  importBlue: {
    default: `${PATH_BUTTON_ICON}/importBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/importWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/importWhite.svg`,
  },
  exchangeExportBlue: {
    default: `${PATH_BUTTON_ICON}/exchangeExportBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/exchangeExportWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/exchangeExportWhite.svg`,
  },
  exchangeImportBlue: {
    default: `${PATH_BUTTON_ICON}/exchangeImportBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/exchangeImportWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/exchangeImportWhite.svg`,
  },
  uploadBlue: {
    default: `${PATH_BUTTON_ICON}/uploadBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/uploadWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/uploadWhite.svg`,
  },
  leaveBlue: {
    default: `${PATH_BUTTON_ICON}/leaveBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/leaveWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/leaveWhite.svg`,
  },
  inactiveRed: {
    default: `${PATH_BUTTON_ICON}/inactiveRed.svg`,
    hover: `${PATH_BUTTON_ICON}/inactiveWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/inactiveWhite.svg`,
  },
  activeGreen: {
    default: `${PATH_BUTTON_ICON}/activeGreen.svg`,
    hover: `${PATH_BUTTON_ICON}/activeWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/activeWhite.svg`,
  },
  trackingGrey: {
    default: `${PATH_BUTTON_ICON}/trackingGrey.svg`,
    hover: `${PATH_BUTTON_ICON}/trackingWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/trackingWhite.svg`,
  },
  trackingBlue: {
    default: `${PATH_BUTTON_ICON}/trackingBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/trackingWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/trackingWhite.svg`,
  },
  searchBlue: {
    default: `${PATH_BUTTON_ICON}/searchBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/searchWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/searchWhite.svg`,
  },
  searchGrey: {
    default: `${PATH_BUTTON_ICON}/searchGrey.svg`,
    hover: `${PATH_BUTTON_ICON}/searchWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/searchWhite.svg`,
  },
  bcDetailRed: {
    default: `${PATH_BUTTON_ICON}/bcDetailRed.svg`,
    hover: `${PATH_BUTTON_ICON}/bcDetailWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/bcDetailWhite.svg`,
  },
  qbGreen: {
    default: `${PATH_BUTTON_ICON}/qbGreen.svg`,
    hover: `${PATH_BUTTON_ICON}/qbWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/qbWhite.svg`,
  },
  scanBlue: {
    default: `${PATH_BUTTON_ICON}/scanBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/scanWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/scanWhite.svg`,
  },
  backRed: {
    default: `${PATH_BUTTON_ICON}/backRed.svg`,
    hover: `${PATH_BUTTON_ICON}/backWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/backWhite.svg`,
  },
  assignBlue: {
    default: `${PATH_BUTTON_ICON}/assignBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/assignWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/assignWhite.svg`,
  },
  createBillBlue: {
    default: `${PATH_BUTTON_ICON}/createBillBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/createBillWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/createBillWhite.svg`,
  },
  clipFileRed: {
    default: `${PATH_BUTTON_ICON}/clipFileRed.svg`,
    hover: `${PATH_BUTTON_ICON}/clipFileWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/clipFileWhite.svg`,
  },
  paperPlaneBlue: {
    default: `${PATH_BUTTON_ICON}/paperPlaneBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/paperPlaneWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/paperPlaneWhite.svg`,
  },
  invoiceBlue: {
    default: `${PATH_BUTTON_ICON}/invoiceBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/invoiceWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/invoiceWhite.svg`,
  },
  overWriteBlue: {
    default: `${PATH_BUTTON_ICON}/overWriteBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/overWriteWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/overWriteWhite.svg`,
  },
  skipRed: {
    default: `${PATH_BUTTON_ICON}/skipRed.svg`,
    hover: `${PATH_BUTTON_ICON}/skipWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/skipWhite.svg`,
  },
  exclamationRed: {
    default: `${PATH_BUTTON_ICON}/exclamationRed.svg`,
    hover: `${PATH_BUTTON_ICON}/exclamationWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/exclamationWhite.svg`,
  },
  browseBlue: {
    default: `${PATH_BUTTON_ICON}/browseBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/browseBlue.svg`,
    disabled: `${PATH_BUTTON_ICON}/browseBlue.svg`,
  },
  videoBlue: {
    default: `${PATH_BUTTON_ICON}/videoBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/videoWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/videoWhite.svg`,
  },
  customizeGrey: {
    default: `${PATH_BUTTON_ICON}/customizeGrey.svg`,
    hover: `${PATH_BUTTON_ICON}/customizeWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/customizeWhite.svg`,
  },
  uploadFile: {
    default: `${PATH_BUTTON_ICON}/uploadFileBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/uploadFileBlue.svg`,
    disabled: `${PATH_BUTTON_ICON}/uploadFileBlue.svg`,
  },
  crossRed: {
    default: `${PATH_BUTTON_ICON}/crossRed.svg`,
    hover: `${PATH_BUTTON_ICON}/crossRed.svg`,
    disabled: `${PATH_BUTTON_ICON}/crossRed.svg`,
  },
  circlePlus: {
    default: `${PATH_BUTTON_ICON}/circlePlusBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/circlePlusWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/circlePlusWhite.svg`,
  },
  circleMinus: {
    default: `${PATH_BUTTON_ICON}/circleMinusRed.svg`,
    hover: `${PATH_BUTTON_ICON}/circleMinusWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/circleMinusWhite.svg`,
  },
  downloadBlue: {
    default: `${PATH_BUTTON_ICON}/downloadBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/downloadWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/downloadWhite.svg`,
  },
  imageFileBlue: {
    default: `${PATH_BUTTON_ICON}/imageFileBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/imageFileWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/imageFileWhite.svg`,
  },
  pdfFileBlue: {
    default: `${PATH_BUTTON_ICON}/pdfFileBlue.svg`,
    hover: `${PATH_BUTTON_ICON}/pdfFileWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/pdfFileWhite.svg`,
  },
  inOrange: {
    default: `${PATH_BUTTON_ICON}/inOrange.svg`,
    hover: `${PATH_BUTTON_ICON}/inWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/inWhite.svg`,
  },
  outGreen: {
    default: `${PATH_BUTTON_ICON}/outGreen.svg`,
    hover: `${PATH_BUTTON_ICON}/outWhite.svg`,
    disabled: `${PATH_BUTTON_ICON}/outWhite.svg`,
  },
};
