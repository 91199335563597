import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import { PERIOD_OPTION } from '../../../constant/Common/Option/Date';
import {
  PATH_COMMON_BUTTON_ICON,
  PATH_HYPHEN_ICON,
} from '../../../constant/Common/Path/Asset/Icon/Button';
import changePeriod from '../../../util/Common/Option/Handler/changePeriod';
import checkDateDiff from '../../../util/Common/Validator/Handler/checkDateDiff';
import CommonButton from '../../Common/Controller/Button';
import CommonDate from '../../Common/Controller/Date/index';
import CommonSelect from '../../Common/Controller/Select/index';
import CommonMessageModalFrame from '../../Common/Modal/Message/Frame';

const AdvancedSearchModal = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
  yardOption,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  yard,
  setYard,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
}) => {
  const searchController = useForm();
  const [period, setPeriod] = useState('');
  const onSearch = () => {
    if (
      !checkDateDiff({
        fromDate: searchController.getValues('fromDate'),
        toDate: searchController.getValues('toDate'),
      })
    ) {
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setMessage('The date range cannot exceed one year. Please try again.');
      setIsMessageOpen(true);

      return;
    }
    setPeriod(searchController?.getValues()?.period);
    setFromDate(searchController?.getValues()?.fromDate);
    setToDate(searchController?.getValues()?.toDate);
    if (searchController?.getValues()?.yard) {
      setYard(searchController?.getValues()?.yard);
    } else {
      setYard('');
    }
    setIsSearchModalOpen?.(false);
  };

  return (
    <CommonMessageModalFrame
      isOpen={isSearchModalOpen}
      onCloseButtonClick={() => {
        setIsSearchModalOpen(false);
      }}
      header="Advanced Search"
      content={
        <FormProvider {...searchController}>
          <form>
            <div className="bg-[#F9F9F9] pt-[19px] pl-[30px] pr-[30px]">
              <div className="flex flex-col">
                <CommonSelect
                  inputName="yard"
                  label="YARD"
                  width="w-[153px]"
                  option={yardOption}
                  defaultValue={yard}
                  onChangeHandler={({ e, onChange }) => {
                    onChange(e?.target?.value);
                  }}
                />
                <div className="flex">
                  <div className="w-[153px] mr-[20px]">
                    <CommonSelect
                      width="w-[153px]"
                      label="PERIOD"
                      defaultValue={period}
                      option={PERIOD_OPTION}
                      inputName="period"
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                        const dateRange = changePeriod(e?.target?.value);
                        searchController.setValue(
                          'fromDate',
                          dateRange?.fromDate,
                        );
                        searchController.setValue('toDate', dateRange?.toDate);
                      }}
                    />
                  </div>
                  <div className="w-[116px]">
                    <CommonDate
                      label=""
                      inputName="fromDate"
                      width="w-[116px]"
                      defaultValue={fromDate}
                      required
                      onChangeHandler={({ e, onChange }) => {
                        const value = dayjs(e)?.isValid()
                          ? dayjs(e)?.format('YYYY-MM-DD')
                          : null;
                        onChange?.(value);
                        searchController.setValue('period', '');
                      }}
                    />
                  </div>
                  <div className="ml-[4px] mr-[4px] flex items-center">
                    <img src={PATH_HYPHEN_ICON} alt="hyphenIcon" width="13px" />
                  </div>
                  <div className="w-[116px]">
                    <CommonDate
                      label=""
                      inputName="toDate"
                      width="w-[116px]"
                      defaultValue={toDate}
                      required
                      onChangeHandler={({ e, onChange }) => {
                        const value = dayjs(e)?.isValid()
                          ? dayjs(e)?.format('YYYY-MM-DD')
                          : null;
                        onChange?.(value);
                        searchController.setValue('period', '');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      }
      button={
        <div className="flex justify-center space-x-[18px]">
          <div>
            <CommonButton
              icon={PATH_COMMON_BUTTON_ICON.cancelRed}
              styles={{
                hoverBackgroundColor: '#B74747',
                border: '1px solid #B74747',
                hoverBorder: '1px solid #B74747',
                hoverColor: '#fff',
                color: '#B74747',
              }}
              onClick={() => {
                setIsSearchModalOpen(false);
              }}
            >
              Cancel
            </CommonButton>
          </div>
          <div>
            <CommonButton
              type="submit"
              icon={PATH_COMMON_BUTTON_ICON.searchBlue}
              styles={{
                hoverBackgroundColor: '#556DA1',
                border: '1px solid #556DA1',
                hoverBorder: '1px solid #556DA1',
                hoverColor: '#fff',
                color: '#556DA1',
              }}
              onClick={searchController.handleSubmit(onSearch)}
            >
              Search
            </CommonButton>
          </div>
        </div>
      }
    />
  );
};

export default AdvancedSearchModal;
