import React, { useEffect, useState } from 'react';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import {
  REGEX_NUMBER_ONLY_POSITIVE_DECIMAL,
  REGEX_NINE_NUMBER_DECIMAL_POINT_TWO,
} from '../../../../../../constant/Common/Regex';
import {
  changeDate,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';

const RepairInfo = ({ ...props }) => {
  const { data, isEditable } = props;
  const [repairCost, setRepairCost] = useState({
    labor: !isNaN(Number(data?.labor)) ? Number(data?.labor) : 0,
    part: !isNaN(Number(data?.part)) ? Number(data?.part) : 0,
    other: !isNaN(Number(data?.other)) ? Number(data?.other) : 0,
    tax: !isNaN(Number(data?.tax)) ? Number(data?.tax) : 0,
  });

  useEffect(() => {
    setRepairCost(prev => {
      return {
        labor: !isNaN(Number(data?.labor)) ? Number(data?.labor) : 0,
        part: !isNaN(Number(data?.part)) ? Number(data?.part) : 0,
        other: !isNaN(Number(data?.other)) ? Number(data?.other) : 0,
        tax: !isNaN(Number(data?.tax)) ? Number(data?.tax) : 0,
      };
    });
  }, [data]);

  return (
    <StyledInfoWrapper>
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold">
        Repair Info
      </div>
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REPAIRED BY"
              inputName="repaired_by"
              required
              maxLength={20}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              defaultValue={data?.repaired_by || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="INV #"
              inputName="inv_no"
              maxLength={20}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              defaultValue={data?.inv_no || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label="DATE"
              inputName="repaired_date"
              required
              onChangeHandler={({ ...changeProps }) => {
                changeDate({ ...changeProps });
              }}
              defaultValue={data?.repaired_date || null}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="MTTR"
              inputName="mttr"
              required
              maxLength={3}
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              defaultValue={data?.mttr || ''}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>

      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="LABOR"
              inputName="labor"
              regex={REGEX_NINE_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                if (changeText({ ...changeProps })) {
                  setRepairCost(prev => {
                    return {
                      ...prev,
                      labor: Number(changeProps.e.target.value),
                    };
                  });
                }
              }}
              defaultValue={data?.labor || ''}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="PART"
              inputName="part"
              regex={REGEX_NINE_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                if (changeText({ ...changeProps })) {
                  setRepairCost(prev => {
                    return {
                      ...prev,
                      part: Number(changeProps.e.target.value),
                    };
                  });
                }
              }}
              defaultValue={data?.part || ''}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="OTHER"
              inputName="other"
              regex={REGEX_NINE_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                if (changeText({ ...changeProps })) {
                  setRepairCost(prev => {
                    return {
                      ...prev,
                      other: Number(changeProps.e.target.value),
                    };
                  });
                }
              }}
              defaultValue={data?.other || ''}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="TAX"
              inputName="tax"
              regex={REGEX_NINE_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                if (changeText({ ...changeProps })) {
                  setRepairCost(prev => {
                    return { ...prev, tax: Number(changeProps.e.target.value) };
                  });
                }
              }}
              defaultValue={data?.tax || ''}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="TOTAL"
              inputName="total"
              disabled
              defaultValue={
                Math.round(
                  ((isNaN(Number(repairCost.labor)) ? 0 : repairCost.labor) +
                    (isNaN(Number(repairCost.part)) ? 0 : repairCost.part) +
                    (isNaN(Number(repairCost.other)) ? 0 : repairCost.other) +
                    (isNaN(Number(repairCost.tax)) ? 0 : repairCost.tax)) *
                    100,
                ) / 100
              }
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default RepairInfo;
