const SAFETY_VAN_MODAL_DEFAULT_VALUE = {
  seq: null,
  div: '',
  size: '',
  type: '',
  trailer_no: '',
  trailer_make: '',
  make_year: '',
  vin_no: '',
  plate_no: '',
  asset_tracker_no: '',
  state: '',
  bank: '',
  purchase_amt: '',
  total_loan_amt: '',
  sche_payment: '',
  interest_rate: '',
  loan_terms: '',
  loan_status: '',
  loan_start_date: null,
  loan_end_date: null,
  extra_payment: '',
  depreciation_amt: '',
  depreciation_start_date: null,
  depreciation_end_date: null,
  depreciable_life: '',
  depreciation_status: '',
  remarks: '',
  active: true,
  created_by: '',
  created_date: null,
  updated_by: '',
  updated_date: null,
  doc: [
    {
      updated_date: null,
      document: '',
      exp_date: null,
      file_url: null,
      updated_by: '',
    },
  ],
};

export default SAFETY_VAN_MODAL_DEFAULT_VALUE;
