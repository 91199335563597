import React from 'react';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import { COMMON_DEPRECIATION_STATUS } from '../../../../../../constant/Common/Option/Status/depreciation';
import {
  changeDate,
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import {
  REGEX_NINE_NUMBER_DECIMAL_POINT_TWO,
  REGEX_NUMBER_DECIMAL_POINT_TWO,
} from '../../../../../../constant/Common/Regex';
import { createOptionalSelectOption } from '../../../../../../util/Common/Option/Generator/selectOptionCreator';

const Depreciation = ({ ...props }) => {
  const { data, isEditable } = props;
  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Depreciation
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="DEPRECIATION AMT"
              inputName="depreciation_amt"
              defaultValue={data?.depreciation_amt || ''}
              regex={REGEX_NINE_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className="flex flex-row items-center gap-[2px]">
            <div className={STYLE_ITEM_WRAPPER}>
              <CommonDate
                label="START DATE"
                inputName="depreciation_start_date"
                defaultValue={data?.depreciation_start_date || null}
                onChangeHandler={({ ...changeProps }) => {
                  changeDate({ ...changeProps });
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[12px] h-[1px] border-[1px] border-solid border-[#D9D9D9]" />
            <div className={STYLE_ITEM_WRAPPER}>
              <CommonDate
                label="END DATE"
                inputName="depreciation_end_date"
                defaultValue={data?.depreciation_end_date || null}
                onChangeHandler={({ ...changeProps }) => {
                  changeDate({ ...changeProps });
                }}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="DEPRECIABLE LIFE"
              inputName="depreciable_life"
              maxLength={2}
              defaultValue={data?.depreciable_life || ''}
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="DEP STATUS"
              inputName="depreciation_status"
              option={createOptionalSelectOption({
                items: COMMON_DEPRECIATION_STATUS,
              })}
              defaultValue={data?.depreciation_status || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default Depreciation;
