import React from 'react';
import ButtonPresetWrapper from '../../../component/Labs/ButtonPreset/ButtonPresetWrapper';
import { PRESET_MAPPER } from '../../../constant/Labs/ButtonPreset/mapping';

const ButtonPreset = () => {
  return (
    <div className="flex gap-[20px] p-[20px] flex-wrap">
      {PRESET_MAPPER?.map(element => (
        <ButtonPresetWrapper name={element?.name} styles={element?.styles} />
      ))}
    </div>
  );
};

export default ButtonPreset;
