import React from 'react';

const CountryCodeOptionLabel = ({ ...props }) => {
  const { option } = props;

  return (
    <>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt="Country Flag"
      />{' '}
      {option?.label} ({option?.code}) +{option?.phone}
    </>
  );
};

export default CountryCodeOptionLabel;
