import { INVENTORY_KEY_VALUE } from '../../constant/Inventory/Value';

const inventoryKeys = {
  all: ['INVENTORY'],
  equipList: ({ equip, div, yardQuery }) => [
    ...inventoryKeys.all,
    INVENTORY_KEY_VALUE?.[equip],
    div,
    yardQuery,
  ],
  specificEquipData: ({ equip, seq }) => [
    ...inventoryKeys.equipList({ equip }),
    seq,
  ],
  equipCounts: ({ div, yardQuery }) => [
    ...inventoryKeys.all,
    'COUNTS',
    div,
    yardQuery,
  ],
};

export default inventoryKeys;
