const handlePairCheckBoxChange = ({ ...props }) => {
  const { e, setValue, option } = props;
  if (!option?.pairs) {
    return;
  }

  if (e?.target?.checked) {
    const pairList = option?.pairs?.filter(pair => pair !== e?.target?.name);

    pairList?.forEach(pair => {
      setValue(pair, false);
    });
  }
};

export default handlePairCheckBoxChange;
