const checkValidation = ({ ...props }) => {
  const { data } = props;

  // User input data
  const inputData = { ...data };

  return false;
};

export default checkValidation;
