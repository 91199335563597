import {
  SELECT_ITEM_AE,
  SELECT_ITEM_ALL,
} from '../../../../constant/Admin/Auth/adminAuthElements';
import theme from '../../../../constant/Admin/Auth/theme';
import {
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
} from '../../../../constant/Common/DataGrid/cellType';
import generateCellEditor from '../../../Common/Datagrid/Generator/generateCellEditor';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const adminAuthColumnDefinitions = () => {
  const authColumnDefs = [
    {
      headerName: 'NAME',
      pinned: 'left',
      field: 'name',
      width: 170,
      editable: true,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 150,
        }),
      cellEditorSelector: gridParam =>
        generateCellEditor({
          gridParam,
          cellType: CELL_TYPE_TEXT,
          maxLength: 150,
        }),
    },
    {
      headerName: 'DAILY IN/OUT',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'DAILY IN/OUT',
          field: 'in_out_daily',
          width: 108,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'INVENTORY',
          field: 'in_out_inventory',
          width: 100,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
      ],
    },
    {
      headerName: 'SAFETY',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'M&R',
          field: 'safety_mr',
          width: 58,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'TRUCK',
          field: 'safety_truck',
          width: 70,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'CHASSIS',
          field: 'safety_ch',
          width: 83,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'VAN',
          field: 'safety_van',
          width: 55,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'IT(TABLET)',
          field: 'safety_it',
          width: 95,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'ACCIDENT',
          field: 'safety_accident',
          width: 90,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'DAMAGE REPORT',
          field: 'safety_damage',
          width: 133,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_ALL,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'COMP, INCOM BTN',
          field: 'safety_complete',
          width: 140,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
    {
      headerName: 'ADMIN',
      isColumnGroup: true,
      backgroundColor: theme?.bgColor,
      color: theme?.textColor,
      openByDefault: true,
      children: [
        {
          headerName: 'USER',
          field: 'admin_user',
          width: 60,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'AUTH',
          field: 'admin_auth',
          width: 65,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
        {
          columnGroupShow: 'open',
          headerName: 'SETTINGS',
          field: 'admin_div_conf',
          width: 90,
          editable: true,
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: SELECT_ITEM_AE,
            }),
        },
      ],
    },
  ];

  const valueGetterColumnDefs = [...authColumnDefs]?.map(authColumnDef => {
    const newAuthColumnDefs = { ...authColumnDef };
    const childColumnDefs = newAuthColumnDefs?.children;

    if (childColumnDefs) {
      const tempChildrenColumnDefs = [...childColumnDefs]?.map(
        childColumnDef => {
          return {
            ...childColumnDef,
            valueGetter: gridParam => {
              const isPinnedRow = gridParam?.node?.rowPinned;
              if (isPinnedRow) return '';

              const lastWord = gridParam?.data?.permissions?.[
                childColumnDef?.field
              ]
                ?.split('_')
                ?.pop();

              if (lastWord === 'rw') return 'Edit';
              if (lastWord === 'r') return 'View';
              return 'None';
            },
          };
        },
      );
      newAuthColumnDefs.children = tempChildrenColumnDefs;
    }

    return newAuthColumnDefs;
  });

  return valueGetterColumnDefs;
};

export default adminAuthColumnDefinitions;
