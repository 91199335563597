import { useMutation } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchSpecificChassisData from '../../../../service/Safety/Chassis/Patch/patchSpecificChassisData';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import generateLastExpDate from '../../../../util/Safety/Common/Generator/generateLastExpData';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchSpecificChassisData = ({ gridRef, ...props }) => {
  const { addToast } = useControlToast();

  const { setAlertModalStatus, setAlertOpen, setModalOpen } = props;

  const mutator = useMutation({
    mutationFn: patchSpecificChassisData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      const { div, doc, type, size, ...successData } = data?.data;

      /** Exp Date Column */
      const regExpDate = generateLastExpDate({
        documentList: doc,
        field: 'Registration',
      });
      const days90Date = generateLastExpDate({
        documentList: doc,
        field: '90 Days',
      });
      const annualExpDate = generateLastExpDate({
        documentList: doc,
        field: 'Annual',
      });

      updateGridData({
        gridRef,
        newData: {
          seq: variables?.seq,
          ...variables?.data,
          ...successData,
          reg_exp_date: regExpDate,
          '90_days_exp': days90Date,
          annual_exp: annualExpDate,
        },
        isUpdate: true,
      });

      setModalOpen?.(prev => false);
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
  });

  return { ...mutator };
};

export default usePatchSpecificChassisData;
