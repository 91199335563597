import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminUserKeys from '../keys';
import getUserList from '../../../../service/Admin/User/Get/getUserList';

const useGetUserList = ({ currDiv }) => {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: adminUserKeys.userList({ currDiv }),
    queryFn: () => getUserList(),
    staleTime: 0,
  });

  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setUserList(data?.data || []);
  }, [data?.data]);

  return { userList, isLoading, isFetching, isError };
};

export default useGetUserList;
