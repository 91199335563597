import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import StyledAppContentsWrapper from './style/App/StyledAppContentsWrapper';
import Header from './component/Common/Header';
import Sidebar from './component/Common/Sidebar';
import StyledAppWrapper from './style/App/StyledAppWrapper';
import useSetDefaultState from './hook/Common/App/useSetDefaultState';
import useSetCommonData from './hook/Common/App/useSetCommonData';
import useGetUserInfo from './hook/SignIn/useGetUserInfo';
import CommonLoading from './component/Common/Loading';
import { ToastProvider } from './context/Toast/ToastContext';
import CommonToast from './component/Common/ContextToast';

const App = () => {
  /** Set default state */
  useSetDefaultState();
  useSetCommonData();

  const [isEssentialLoading, setEssentialLoading] = useState(true);
  const {
    isLoading: userInfoLoading,
    isFetching: userInfoFetching,
    isRefetching: userInfoRefetching,
  } = useGetUserInfo({ setEssentialLoading });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (
    userInfoLoading ||
    userInfoFetching ||
    userInfoRefetching ||
    isEssentialLoading
  ) {
    return (
      <CommonLoading
        isOpen={
          userInfoLoading ||
          userInfoFetching ||
          userInfoRefetching ||
          isEssentialLoading
        }
      />
    );
  }

  return (
    <StyledAppWrapper>
      <Header setIsSidebarOpen={setIsSidebarOpen} />
      <Sidebar isOpen={isSidebarOpen} setOpen={setIsSidebarOpen} />
      <StyledAppContentsWrapper>
        <ToastProvider>
          <Outlet />
          <CommonToast />
        </ToastProvider>
      </StyledAppContentsWrapper>
    </StyledAppWrapper>
  );
};

export default App;
