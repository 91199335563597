import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import generateCellEditor from '../../../Common/Datagrid/Generator/generateCellEditor';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createNormalGroup = () => {
  return [
    {
      headerName: 'Group08 - Normal Group',
      backgroundColor: '#2510a3',
      color: '#ffffff',
      isColumnGroup: true,
      children: [
        {
          headerName: 'Switch',
          field: 'won',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_SWITCH,
              onChangeHandler: ({ ...props }) => {
                console.log('Props : ', props);
              },
            }),
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SWITCH,
              onChangeHandler: ({ ...props }) => {
                props?.onValueChange(!props?.value);
                props?.setDataValue(!props?.value);
              },
            }),
        },
      ],
    },
  ];
};

export default createNormalGroup;
