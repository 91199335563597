export const DAMAGE_FORM_MODAL = {
  width: '928px',
  height: '920px',
};

export const OTHER_INPUT_STYLE = { height: '20px', width: '395px' };

export const STYLE_CHECK_BOX_COLUMN_WRAPPER =
  'w-full flex flex-col gap-y-[8px]';

export const STYLE_CHECK_BOX_HEADER_WRAPPER =
  'text-[#595656] font-[500] text-[11px] mb-[-4px]';

export const STYLE_TRUCK_STATUS_CHECKBOX_AREA = {
  height: '286px',
};

export const STYLE_CHASSIS_STATUS_CHECKBOX_AREA = {
  height: '102px',
};

export const STYLE_DRIVER_TIRES_CHECKBOX_AREA = {
  height: '82px',
  paddingBottom: '10px',
  paddingTop: '8px',
};

export const STYLE_DRIVER_STEER_TIRES_CHECKBOX_AREA = {
  height: '26px',
};

export const STYLE_TRAILER_CHASSIS_TIRES_CHECKBOX_AREA = {
  height: '121px',
};
