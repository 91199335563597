import React from 'react';
import { Navigate } from 'react-router-dom';
import ITTablet from '../../../../../page/Safety/ITTablet';
import AuthRouter from '../../../../../component/Common/Router/Common/AuthRouter';
import {
  PERM_SAFETY_ACCIDENT,
  PERM_SAFETY_CHASSIS,
  PERM_SAFETY_DAMAGE_REPORT,
  PERM_SAFETY_IT_TABLET,
  PERM_SAFETY_MR,
  PERM_SAFETY_TRUCK,
  PERM_SAFETY_VAN,
} from '../../../Permission';
import MR from '../../../../../page/Safety/MR';
import Truck from '../../../../../page/Safety/Truck';
import Chassis from '../../../../../page/Safety/Chassis';
import VAN from '../../../../../page/Safety/VAN';
import getAuthorizedMenu from '../../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import Accident from '../../../../../page/Safety/Accident';
import DamageReport from '../../../../../page/Safety/DamageReport';

/**
 * @description
 * Children route of the safety page.
 */
const SAFETY_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[2],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'maintenance-repair/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_MR}>
        <MR />
      </AuthRouter>
    ),
  },
  {
    path: 'truck/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_TRUCK}>
        <Truck />
      </AuthRouter>
    ),
  },
  {
    path: 'chassis/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_CHASSIS}>
        <Chassis />
      </AuthRouter>
    ),
  },
  {
    path: 'van/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_VAN}>
        <VAN />
      </AuthRouter>
    ),
  },
  {
    path: 'it-tablet/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_IT_TABLET}>
        <ITTablet />
      </AuthRouter>
    ),
  },
  {
    path: 'accident/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_ACCIDENT}>
        <Accident />
      </AuthRouter>
    ),
  },
  {
    path: 'damage-report/*',
    element: (
      <AuthRouter permission={PERM_SAFETY_DAMAGE_REPORT}>
        <DamageReport />
      </AuthRouter>
    ),
  },
];

export default SAFETY_CHILDREN_ROUTE;
