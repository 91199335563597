import styled from 'styled-components';

const StyledCheckBoxWrapper = styled.div`
  width: ${props => props?.width || '95px'};
  min-width: ${props => props?.width || '95px'};
  max-width: ${props => props?.width || '95px'};

  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #494949;
`;

export default StyledCheckBoxWrapper;
