import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDivConfDefaultAPI from '../../divConfAPI';

const deleteYMSYard = async ({ seq }) => {
  try {
    const response = await adminDivConfDefaultAPI.delete(`yms-yard/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default deleteYMSYard;
