import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import checkDateDiff from '../../../Common/Validator/Handler/checkDateDiff';

const searchContent = ({
  handleSubmit,
  setQueryObject,
  setIsSearchModal,
  setAlertOptions,
}) => {
  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  handleSubmit(submitData => {
    if (
      !checkDateDiff({
        fromDate: submitData?.['from-date'],
        toDate: submitData?.['to-date'],
      })
    ) {
      setIsOpenAlert(true);
      setAlertInfo({
        message: 'The date range cannot exceed one year. Please try again.',
        status: ALERT_MODAL_STATUS?.ERROR,
      });

      return;
    }

    setQueryObject(submitData);
    setIsSearchModal(false);
  })();
};

export default searchContent;
