export const ACCIDENT_STATUS_CODE = {
  Completed: 'SSA001',
  'On Track': 'SSA002',
};

export const OP_CODE = {
  COM: 'AO0001',
  OP: 'AO0002',
};

export const RESPONSIBILITY_CODE = {
  'AT-FAULT': 'AR0001',
  'NO-FAULT': 'AR0002',
};
