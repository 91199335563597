import React from 'react';
import { FormProvider } from 'react-hook-form';
import CommonBlur from '../../../Common/Controller/Blur';
import SideModalHeader from '../SideModalHeader';
import SideModalContent from '../SideModalContent';
import SideModalButtonArea from '../SideModalButtonArea';

const SideUserModal = ({
  userID,
  authList,
  divList,
  isOpenSideModal,
  setIsOpenSideModal,
  userInfoPatchMutator,
  userInfo,
  userController,
  divisionCheckBoxOption,
}) => {
  return (
    <FormProvider {...userController}>
      <form>
        <CommonBlur isOpen={isOpenSideModal}>
          <div className="w-full h-full flex justify-end ">
            <div className="bg-white min-w-[471px] w-[471px] h-full animate-fade-in-right pl-[84px]">
              <SideModalHeader setIsOpenSideModal={setIsOpenSideModal} />
              <SideModalContent
                userInfo={userInfo}
                authList={authList}
                divList={divList}
                divisionCheckBoxOption={divisionCheckBoxOption}
              />
              <SideModalButtonArea
                userID={userID}
                divList={divList}
                userInfoPatchMutator={userInfoPatchMutator}
              />
            </div>
          </div>
        </CommonBlur>
      </form>
    </FormProvider>
  );
};

export default SideUserModal;
