import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import Common from '../Feature/Common';

const reducer = combineReducers({
  common: Common,
});

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(logger),
});

export default store;
