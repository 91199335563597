import React from 'react';
import CommonTextInput from '../../../Common/Controller/Text';
import {
  handleInputChange,
  handleUpperInputBlur,
} from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';

const EquipmentInfoFields = ({ isCompleted, damageReport, isEditable }) => {
  return (
    <div className="flex space-x-[10px] mt-[18px]">
      <div className="w-[221px]">
        <CommonTextInput
          label="EQUIPMENT"
          inputName="cont_no"
          placeholder="CONT #"
          disabled={isCompleted || !isEditable}
          onChangeHandler={handleInputChange}
          onBlurHandler={handleUpperInputBlur}
          defaultValue={damageReport?.cont_no || ''}
        />
      </div>
      <div className="w-[221px]">
        <CommonTextInput
          inputName="cont_seal"
          disabled={isCompleted || !isEditable}
          placeholder="SEAL #"
          maxLength={20}
          onChangeHandler={handleInputChange}
          defaultValue={damageReport?.cont_seal || ''}
        />
      </div>
    </div>
  );
};

export default EquipmentInfoFields;
