import styled from 'styled-components';

const StyledUserWrapper = styled.div`
  height: 100%;
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bolder;
  color: #fff;
`;

export default StyledUserWrapper;
