import { useEffect, useState } from 'react';
import { PATH_DATAGRID_GROUP_FOLD_ICON } from '../../../constant/Common/Path/Asset/Icon/Datagrid';

const useGetGroupExpandIcon = ({ columnDefs }) => {
  /** Icon state */
  const [icon, setIcon] = useState([]);

  /** Extract icon from user defined column definition object */
  useEffect(() => {
    const iconArr = [];
    columnDefs?.forEach(res => {
      if (res?.isColumnGroup) {
        if (res?.icon) {
          iconArr.push(res?.icon);
        } else {
          iconArr.push(PATH_DATAGRID_GROUP_FOLD_ICON);
        }
      } else {
        iconArr.push(null);
      }
    });
    setIcon(iconArr);
  }, [columnDefs]);

  return { icon };
};

export default useGetGroupExpandIcon;
