import styled from 'styled-components';

const StyledRowItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export default StyledRowItemWrapper;
