import dayjs from 'dayjs';
import {
  CELL_TYPE_AUTO_COMPLETE,
  CELL_TYPE_DATE,
  CELL_TYPE_SELECT,
  CELL_TYPE_TEXT,
  CELL_TYPE_TIME,
} from '../../../../constant/Common/DataGrid/cellType';
import generateCellEditor from '../../../Common/Datagrid/Generator/generateCellEditor';
import { PATH_DATAGRID_GROUP_FOLD_WHITE_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';

const createModGroup = () => {
  return [
    {
      headerName: 'Group06 - Mod',
      backgroundColor: '#0000ff',
      color: '#ffffff',
      icon: PATH_DATAGRID_GROUP_FOLD_WHITE_ICON,
      isColumnGroup: true,
      children: [
        {
          headerName: 'Text',
          field: 'add_text',
          cellEditorSelector: gridParam =>
            generateCellEditor({ gridParam, cellType: CELL_TYPE_TEXT }),
        },
        {
          headerName: 'Auto Complete',
          field: 'add_auto_complete',
          columnGroupShow: 'open',
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_AUTO_COMPLETE,
              option: [
                {
                  seq: 0,
                  label: 'YGB',
                  value: 'YGB',
                },
                {
                  seq: 1,
                  label: 'BCSD',
                  value: 'BCSD',
                },
                {
                  seq: 2,
                  label: 'JSG DDRT',
                  value: 'JSG DDRT',
                },
              ],
            }),
        },
        {
          headerName: 'Select',
          field: 'add_select',
          columnGroupShow: 'open',
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_SELECT,
              option: [
                {
                  seq: 0,
                  label: 'YGB',
                  value: 'YGB',
                },
                {
                  seq: 1,
                  label: 'BCSD',
                  value: 'BCSD',
                },
                {
                  seq: 2,
                  label: 'JSG DDRT',
                  value: 'JSG DDRT',
                },
              ],
            }),
        },
        {
          headerName: 'Date',
          field: 'add_date',
          columnGroupShow: 'open',
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_DATE,
            }),
          valueFormatter: params => {
            return params.data?.add_date &&
              dayjs(params.data?.add_date)?.isValid()
              ? dayjs(params.data?.add_date)?.format('MM/DD/YY')
              : null;
          },
        },
        {
          headerName: 'Time',
          field: 'add_time',
          columnGroupShow: 'open',
          cellEditorSelector: gridParam =>
            generateCellEditor({
              gridParam,
              cellType: CELL_TYPE_TIME,
            }),
          valueFormatter: params => {
            return params.data?.add_time &&
              dayjs(params.data?.add_time)?.isValid()
              ? dayjs(params.data?.add_time)?.format('HH:mm')
              : null;
          },
        },
      ],
    },
  ];
};

export default createModGroup;
