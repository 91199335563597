import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminUserKeys from '../keys';
import getAuthList from '../../../../service/Admin/User/Get/getAuthList';

const useGetAuthList = () => {
  const { data, isError } = useQuery({
    queryKey: adminUserKeys.groupAuthList(),
    queryFn: () => getAuthList(),
    staleTime: 0,
  });

  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    setAuthList(data?.data || []);
  }, [data?.data]);

  useEffect(() => {
    if (isError) {
      setAuthList([]);
    }
  }, [isError]);

  return { authList };
};

export default useGetAuthList;
