import styled from 'styled-components';

const StyledDivisionSelectorWrapper = styled.div`
  width: fit-content;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`;

export default StyledDivisionSelectorWrapper;
