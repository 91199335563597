import React from 'react';
import StyledCommonMessageModalButtonWrapper from '../../../../../../style/Common/Modal/Message/Frame/Button/StyledCommonMessageModalButtonWrapper';

const CommonMessageModalFrameButton = ({ children, ...props }) => {
  return (
    <StyledCommonMessageModalButtonWrapper>
      {children}
    </StyledCommonMessageModalButtonWrapper>
  );
};

export default CommonMessageModalFrameButton;
