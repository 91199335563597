import { useEffect, useState } from 'react';

const useControlComponentState = ({ ...props }) => {
  /** Component style */
  const [styles, setStyles] = useState(props?.styles || {});
  useEffect(() => {
    setStyles(prev => {
      return { ...props?.styles };
    });
  }, [props?.styles]);

  /** Disabled */
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    setDisabled(prev => props?.disabled);
  }, [props?.disabled]);

  /** Required */
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    setRequired(prev => props?.required);
  }, [props?.required]);

  /** Option */
  const [option, setOption] = useState(props?.option || []);
  useEffect(() => {
    setOption(prev => props?.option);
  }, [props?.option]);

  /** Max length */
  const [maxLength, setMaxLength] = useState(props?.maxLength || 0);
  useEffect(() => {
    setMaxLength(prev => props?.maxLength);
  }, [props?.maxLength]);

  return {
    styles,
    setStyles,
    disabled,
    setDisabled,
    required,
    setRequired,
    option,
    setOption,
    maxLength,
    setMaxLength,
  };
};

export default useControlComponentState;
