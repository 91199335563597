/**
 * @description
 * Function that handle the row clear button click event.
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 */
const clearAddRow = ({ gridParam, ...props }) => {
  /** Clear node data. Check gridParam.data */
  gridParam?.node?.setData({});
  /** Refresh add row */
  gridParam?.api?.redrawRows({ rowNodes: [gridParam?.node] });
};

export default clearAddRow;
