import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormContext } from 'react-hook-form';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomAutoComplete from '../../../../../style/Common/Controller/Common/AutoComplete/Custom/StyledCustomAutoComplete';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import ControllerLabel from '../../Common/Label';
import ControllerError from '../../Common/Error';
import ComponentController from '../../Common/ComponentController';
import handleAutoCompleteFilterOptions from '../../../../../util/Common/Controller/Handler/handleAutoCompleteFilterOptions';

const CustomAutoComplete = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
  } = useFormContext();

  const { styles, disabled, required, option, ...compState } =
    useControlComponentState({ ...props });

  const {
    inputName,
    defaultValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'autoComplete',
    setValue,
  });

  // Set default loading for input. Default is false.
  const [isLoading, setIsLoading] = useState(props?.isLoading || false);
  useEffect(() => {
    /**
     * If default loading value changed, component loading value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.isLoading !== undefined && props?.isLoading !== null) {
      setIsLoading(props?.isLoading);
    }
  }, [props?.isLoading]);

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />
        <ComponentController
          Component={StyledCustomAutoComplete}
          inputName={inputName}
          defaultValue={defaultValue}
          componentProps={{
            styles,
            disabled,
            required,
            options: [...option],
            noOptionsText: isLoading ? 'Loading...' : 'No options',
            popupIcon: <ExpandMoreIcon />,
            renderInput: params => (
              <TextField {...params} error={Boolean(errors?.[inputName])} />
            ),
            onChange: props?.onChange,
            onClick: props?.onClick,
            onKeyDown: props?.onKeyDown,
            onBlur: props?.onBlur,
            forcePopupIcon: false,
            filterOptions: (options, params) =>
              handleAutoCompleteFilterOptions({ options, params }),
            isOptionEqualToValue: (paramOption, paramValue) =>
              paramOption.value === paramValue.value ||
              paramOption.value === paramValue,
            componentsProps: {
              popper: {
                style: { width: 'fit-content' },
                placement: 'bottom-start',
                sx: {
                  zIndex: 9999,
                  '.MuiAutocomplete-option': {
                    fontSize: '12px !important',
                  },
                  '.MuiAutocomplete-noOptions': {
                    fontSize: '12px !important',
                  },
                },
              },
            },
          }}
        />
      </StyledCustomControllerWrapper>
      <ControllerError
        errors={errors}
        inputName={inputName}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </>
  );
};

export default CustomAutoComplete;
