export const SWITCH_OPTION_ACTIVE = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];
