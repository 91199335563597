import styled from 'styled-components';

const StyledAppContentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding-left: 67px;
  padding-top: 56px;

  display: flex;

  overflow: scroll;
`;

export default StyledAppContentsWrapper;
