import {
  COMMON_ACCIDENT_DOCUMENT,
  COMMON_CHASSIS_DOCUMENT,
  COMMON_TRUCK_DOCUMENT,
  COMMON_VAN_DOCUMENT,
} from '../../../../constant/Common/Option/Document';
import { createOptionalSelectOption } from '../../../Common/Option/Generator/selectOptionCreator';

const createFileTableDocumentOption = ({ page }) => {
  if (page === 'truck' || page === 'mr')
    return createOptionalSelectOption({ items: COMMON_TRUCK_DOCUMENT });
  if (page === 'chassis')
    return createOptionalSelectOption({ items: COMMON_CHASSIS_DOCUMENT });
  if (page === 'van')
    return createOptionalSelectOption({ items: COMMON_VAN_DOCUMENT });
  if (page === 'accident')
    return createOptionalSelectOption({ items: COMMON_ACCIDENT_DOCUMENT });
  return [];
};

export default createFileTableDocumentOption;
