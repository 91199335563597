import React, { useEffect, useState } from 'react';
import CommonMessageModalFrame from '../Message/Frame';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import createAlertHeader from '../../../../util/Common/Modal/Alert/Generator/createAlertHeader';
import CommonAlertContent from './Content';
import CommonButton from '../../Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';

const CommonAlert = ({
  isOpen,
  header,
  content,
  status,
  onOKButtonClick,
  onCloseButtonClick,
  setOpen,
}) => {
  const { STABLE } = ALERT_MODAL_STATUS;
  const [alertStatus, setStatus] = useState(STABLE);
  useEffect(() => {
    if (status !== null && status !== undefined) setStatus(prev => status);
  }, [status]);

  return (
    <CommonMessageModalFrame
      isOpen={isOpen}
      setOpen={setOpen}
      onCloseButtonClick={onCloseButtonClick}
      header={createAlertHeader({
        status: alertStatus,
        header: header || 'Alert',
      })}
      content={<CommonAlertContent>{content}</CommonAlertContent>}
      button={
        <CommonButton
          icon={PATH_COMMON_BUTTON_ICON.checkBlue}
          styles={{
            width: '80px',
            height: '24px',
            border: '1px solid #556DA1',
            hoverBackgroundColor: '#556DA1',
            hoverColor: '#fff',
            color: '#556DA1',
          }}
          onClick={onOKButtonClick}
        >
          Got it
        </CommonButton>
      }
    />
  );
};

export default CommonAlert;
