import React from 'react';
import CommonButton from '../../../Controller/Button';
import clickRowAdd from '../../../../../util/Common/Datagrid/Handler/clickRowAdd';
import clearAddRow from '../../../../../util/Common/Datagrid/Handler/clearAddRow';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Button';

/**
 * @description
 * Component that appears when addable is true.
 * Row used when adding data
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @param {function} onAddClicked
 * Function that will be called when add button clicked
 * @returns
 * Row used when adding data
 */
const CurrentRowAddCell = ({ gridParam, onAddClicked, ...props }) => {
  return (
    <div className="flex justify-center items-center gap-[16px] w-full h-full">
      <CommonButton
        category="add"
        icon={PATH_COMMON_BUTTON_ICON.plusBlue}
        styles={{
          width: '85px',
          color: '#556DA1',
          border: '1px solid #556DA1',
          backgroundColor: '#ffffff',
          hoverBackgroundColor: '#556DA1',
          hoverColor: '#ffffff',
        }}
        onClick={() => {
          clickRowAdd({ gridParam, onAddClicked });
        }}
      >
        Add
      </CommonButton>
      <CommonButton
        category="clear"
        icon={PATH_COMMON_BUTTON_ICON.clearRed}
        styles={{
          width: '85px',
          color: '#B74747',
          border: '1px solid #B74747',
          backgroundColor: '#ffffff',
          hoverBackgroundColor: '#B74747',
          hoverColor: '#ffffff',
        }}
        onClick={() => clearAddRow({ gridParam })}
      >
        Clear
      </CommonButton>
    </div>
  );
};

export default CurrentRowAddCell;
