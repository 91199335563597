import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import { MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StyledEditCellRenderer from '../../../../../../style/Common/Datagrid/EditCellRenderer/StyledEditCellRenderer';

/**
 * @description
 * Select modification cell renderer
 * @param {object} props
 * Grid props
 * @param {object} ref
 * Grid reference
 */
const FloatingFilterSelect = forwardRef((props, ref) => {
  /** Get grid parameter */
  const { adornment, option: selectOption, isCancelBeforeStartHandler } = props;

  /** Input inner component reference */
  const compRef = useRef(null);

  /** Input component property */
  const [value, setDataValue] = useState(props?.model || '');
  useEffect(() => {
    setDataValue(prev => props?.model?.filter);
  }, [props?.model]);
  const [option, setOption] = useState(selectOption || []);
  useEffect(() => {
    setOption(prev => selectOption);
  }, [selectOption]);
  const [disabled, setDisabled] = useState(false);

  /** Component data setter */
  const compDataSetter = {
    setDataValue,
    setOption,
    setDisabled,
  };

  /**
   * Component Editor Lifecycle methods
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  useImperativeHandle(ref, () => {
    return {
      /** The final value to send to the grid, on completion of editing */
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        isCancelBeforeStartHandler?.({ ...props, ...compDataSetter });
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
      ref,
      ...compDataSetter,
      ...props,
    };
  });

  /** Render component */
  return (
    <StyledEditCellRenderer>
      <Select
        ref={compRef}
        value={value || ''}
        displayEmpty
        onChange={e => {
          props?.onModelChange(
            e.target?.value === ''
              ? null
              : {
                  ...(props?.model || {
                    type: 'equals',
                  }),
                  filter: e?.target?.value,
                },
          );
        }}
        MenuProps={{
          disableScrollLock: true,
          style: { zIndex: 9999 },
        }}
        IconComponent={ExpandMoreIcon}
        disabled={disabled}
        /** Component Style */
        className={`border-solid border-[#D9D9D9] rounded-[5px] text-xs text-black w-[164px] h-[24px]  ${
          disabled ? `bg-[#F9F9F9]` : `bg-white`
        }`}
        sx={{
          // Select Arrow Icon
          fontWeight: '500',
          '.MuiSvgIcon-root': {
            fontSize: '16px',
            color: '#264B9F !important',
          },
          '.MuiSelect-select': {
            paddingLeft: '2px !important',
            paddingRight: '20px !important',
          },
          '&.MuiInputBase-root': {
            '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset': {
              borderColor: '#8E9396 !important',
            },
            '&.Mui-focused fieldset': {
              border: '1.2px solid #264B9F !important',
              boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
            },
            '&.Mui-error fieldset': {
              border: '1.2px solid #C24D4D !important',
              backgroundColor: `#fff !important`,
            },
            '&.Mui-disabled fieldset': {
              backgroundColor: 'unset !important',
            },
          },
          fieldset: {
            borderColor: '#D9D9D9 !important',
          },
        }}
      >
        {option?.map((item, index) => {
          return (
            <MenuItem
              value={item?.value}
              key={String(index + 1)}
              className="text-xs"
              {...item}
            >
              {item?.label}
            </MenuItem>
          );
        })}
      </Select>
    </StyledEditCellRenderer>
  );
});

export default FloatingFilterSelect;
