import React from 'react';
import StyledModalContentArea from '../../../../style/Safety/Accident/StyledModalContentArea';
import StyledModalContentAreaHeader from '../../../../style/Safety/Accident/StyledModalContentAreaHeader';

const AccidentFormContentArea = ({ header, content, ...props }) => {
  return (
    <StyledModalContentArea {...props}>
      <StyledModalContentAreaHeader {...props}>
        {header}
      </StyledModalContentAreaHeader>
      {content}
    </StyledModalContentArea>
  );
};

export default AccidentFormContentArea;
