export const COMMON_CTP = [
  {
    seq: 0,
    label: 'Register',
    value: 'CS0001',
  },
  {
    seq: 1,
    label: 'Paid',
    value: 'CS0002',
  },
];
