import React from 'react';
import { Navigate } from 'react-router-dom';
import DailyInOut from '../../../../page/DailyInOut';
import DAILY_IN_OUT_CHILDREN_ROUTE from './DailyInOut';
import Inventory from '../../../../page/Inventory';
import INVENTORY_CHILDREN_ROUTE from './Inventory';
import Safety from '../../../../page/Safety';
import SAFETY_CHILDREN_ROUTE from './Safety';
import Admin from '../../../../page/Admin';
import ADMIN_CHILDREN_ROUTE from './Admin';
import AuthRouter from '../../../../component/Common/Router/Common/AuthRouter';
import {
  PERM_ADMIN,
  PERM_DAILY_IN_OUT_DAILY,
  PERM_DAILY_IN_OUT_INVENTORY,
  PERM_SAFETY,
} from '../../Permission';
import getAuthorizedMenu from '../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../Option/Sidebar';

/**
 * @description
 * Children routes of the app page.
 */
const APP_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[0],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'daily-in-out',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_DAILY}>
        <DailyInOut />
      </AuthRouter>
    ),
    children: [...DAILY_IN_OUT_CHILDREN_ROUTE],
  },
  {
    path: 'inventory',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_INVENTORY}>
        <Inventory />
      </AuthRouter>
    ),
    children: [...INVENTORY_CHILDREN_ROUTE],
  },
  {
    path: 'safety',
    element: (
      <AuthRouter permission={PERM_SAFETY}>
        <Safety />
      </AuthRouter>
    ),
    children: [...SAFETY_CHILDREN_ROUTE],
  },
  {
    path: 'admin',
    element: (
      <AuthRouter permission={PERM_ADMIN}>
        <Admin />
      </AuthRouter>
    ),
    children: [...ADMIN_CHILDREN_ROUTE],
  },
];

export default APP_CHILDREN_ROUTE;
