import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import {
  INVENTORY_COLUMN_DEFENITION,
  INVENTORY_KEY_VALUE,
} from '../../../constant/Inventory/Value';
import useGetEquipCounts from '../../../hook/Inventory/Common/Get/useGetEquipCounts';
import useGetEquipList from '../../../hook/Inventory/Common/Get/useGetEquipList';
import useGetSpecificEquipData from '../../../hook/Inventory/Common/Get/useGetSpecificEquipData';
import usePatchSpecificEquipData from '../../../hook/Inventory/Common/Patch/usePatchSpecificEquipData';
import useControlLoadingStatus from '../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import generateSelectOptions from '../../../util/Inventory/Common/Generator/generateSelectOptions';
import CommonLoading from '../../Common/Loading';
import CommonAlert from '../../Common/Modal/Alert';
import InventoryDatagrid from '../InventoryDatagrid';
import EditModal from '../Modal/EditModal';
import UtilityBar from '../UtilityBar';

const InventoryContents = ({ isEditable }) => {
  /** Essential */
  const gridRef = useRef(null);
  const { pathname } = useLocation();

  const [equip, setEquip] = useState(pathname.split('/').pop());
  const [seq, setSeq] = useState(null);

  useEffect(() => {
    setSeq(null);
    setEquip(pathname.split('/').pop());
  }, [pathname]);

  /** API parameters */
  const currDiv = useSelector(state => state?.common?.currDiv);
  const [yardQuery, setYardQuery] = useState({});
  const [yardFilterOption, setYardFilterOption] = useState([]);

  /** Common select options */
  const { selectOptions } = generateSelectOptions({
    setYardQuery,
    setYardFilterOption,
  });

  /** Message modal opener */
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertModalStatus, setAlertModalStatus] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });
  const alertDataSetter = { setAlertOpen, setAlertModalStatus };

  /** Data modal */
  const [isModalOpen, setModalOpen] = useState(false);
  const [isYardFilterOpen, setYardFilterOpen] = useState(false);

  const yardFilterProps = {
    isYardFilterOpen,
    setYardFilterOpen,
    yardFilterOption,
    setYardQuery,
  };

  const [columnDefs, setColumnDefs] = useState(
    INVENTORY_COLUMN_DEFENITION?.[equip]?.() || [],
  );

  useEffect(() => {
    setColumnDefs(INVENTORY_COLUMN_DEFENITION?.[equip]?.() || []);
  }, [equip]);

  /** Grid, Modal data */
  const { countData } = useGetEquipCounts({
    currDiv,
    yardQuery,
    ...alertDataSetter,
  });

  const {
    listData,
    isLoading: loadingGetList,
    isFetching: fetchingGetList,
  } = useGetEquipList({ equip, currDiv, yardQuery, ...alertDataSetter });

  const {
    specificData,
    isLoading: loadingSpecificData,
    isFetching: fetchingSpecificData,
  } = useGetSpecificEquipData({ equip, seq, ...alertDataSetter });

  /** Data patcher */
  const { mutate: mutatePatchEquipData, isPending: pendingPatchEquipData } =
    usePatchSpecificEquipData({
      setAlertModalStatus,
      setAlertOpen,
      setSeq,
      equip,
      gridRef,
    });

  /** Loading component opener */
  const { isLoadingOpen, setLoadingOpen } = useControlLoadingStatus({
    isFetching: [fetchingSpecificData],
    isLoading: [loadingGetList, loadingSpecificData],
    isPending: [],
  });

  return (
    <>
      <div className="pl-[41px] flex flex-col flex-1 pb-8">
        <UtilityBar
          gridRef={gridRef}
          data={countData}
          equip={equip}
          setEquip={setEquip}
          isLoadingOpen={isLoadingOpen}
          fileName={`INVENTORY_${INVENTORY_KEY_VALUE[equip]}`}
          {...yardFilterProps}
        />
        <InventoryDatagrid
          gridRef={gridRef}
          columnDefs={columnDefs}
          data={listData}
          setSpecificSeq={setSeq}
          setModalOpen={setModalOpen}
        />
      </div>
      {isModalOpen && !!Object.keys(specificData).length && (
        <EditModal
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          equip={equip}
          specificData={specificData}
          setSpecificSeq={setSeq}
          selectOptions={selectOptions}
          mutate={mutatePatchEquipData}
          isEditable={isEditable}
          setAlertModalStatus={setAlertModalStatus}
          setAlertOpen={setAlertOpen}
        />
      )}
      {/* Loading */}
      {isLoadingOpen && <CommonLoading isOpen={isLoadingOpen} />}
      {/* Alert */}
      {isAlertOpen &&
        alertModalStatus?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
          <CommonAlert
            isOpen={isAlertOpen}
            setOpen={setAlertOpen}
            status={alertModalStatus?.status}
            content={alertModalStatus?.message}
            onOKButtonClick={() => {
              setAlertOpen(false);
            }}
          />
        )}
    </>
  );
};

export default InventoryContents;
