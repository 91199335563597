import styled from 'styled-components';

const StyledLoadingLogoWrapper = styled.img`
  width: 150px;
  min-width: 150px;
  height: 150px;
  min-height: 150px;
`;

export default StyledLoadingLogoWrapper;
