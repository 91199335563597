import styled from 'styled-components';

const StyledCommonMessageModalButtonWrapper = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 20px;
`;

export default StyledCommonMessageModalButtonWrapper;
