import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const getInventoryTrailerList = async ({ div, yard }) => {
  if (!div || !yard) {
    return null;
  }
  try {
    const response = await dailyInOutDefaultAPI.get('invt-trailer', {
      params: { div, yard },
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getInventoryTrailerList;
