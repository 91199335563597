import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import damageReportKeys from '../keys';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import getDamageReport from '../../../../service/Safety/DamageReport/Get/getDamageReport';
import { DEFAULT_DAMAGE_DATA } from '../../../../constant/Safety/DamageReport/defaultDamageData';

const useGetDamageReport = ({ setAlertOptions }) => {
  const { setAlertInfo, setIsOpenAlert } = setAlertOptions;
  const [seq, setSeq] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [damageReportInfo, setDamageReportInfo] = useState(DEFAULT_DAMAGE_DATA);

  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: damageReportKeys?.damageReport({ seq }),
    queryFn: () => getDamageReport({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq,
  });

  useEffect(() => {
    if (data?.data) {
      setDamageReportInfo(prev => data?.data);
      setIsCompleted(prev => data?.data?.completed);
    } else {
      setDamageReportInfo({});
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS.ERROR,
        message: error?.message,
      }));
      setIsOpenAlert(prev => true);
    }
  }, [isError]);

  return {
    seq,
    setSeq,
    isCompleted,
    setIsCompleted,
    damageReportInfo,
    setDamageReportInfo,
    isLoading,
    isFetching,
  };
};

export default useGetDamageReport;
