import React, { useContext } from 'react';
import { ToastContext } from '../../../context/Toast/ToastContext';
import ToastItem from './ToastItem';
import StyledCommonToastBackGround from '../../../style/Common/Toast/StyledCommonToastBackGround';

const CommonToast = () => {
  const { toastList } = useContext(ToastContext);

  return (
    toastList?.length > 0 && (
      <StyledCommonToastBackGround>
        {toastList?.map(toast => (
          <ToastItem key={toast.key} toastKey={toast.key} {...toast} />
        ))}
      </StyledCommonToastBackGround>
    )
  );
};

export default CommonToast;
