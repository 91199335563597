export const addImageFileList = ({
  setImageFileList,
  newFileList,
  fileKey,
  setFileKey,
}) => {
  if (newFileList && newFileList?.length > 0) {
    const tempNewFileList = newFileList?.map((file, index) => ({
      fileKey: fileKey + index,
      fileInfo: file,
    }));
    setImageFileList(prev => {
      return [...prev, ...tempNewFileList];
    });
    setFileKey(prev => prev + tempNewFileList?.length);
  }
};

export const removeImageFileList = ({ setImageFileList, removeIdx }) => {
  setImageFileList(prev => {
    const newImageFileList = prev?.filter((_, index) => {
      return index !== removeIdx;
    });

    return newImageFileList;
  });
};
