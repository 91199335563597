import dayjs from 'dayjs';

const separateFileTableData = ({ inputData }) => {
  const tempInputData = { ...inputData };

  /** Create empty list to save file-table data */
  const docFile = [];
  const docType = [];
  const docExpDate = [];

  /** Separate file-table data */
  tempInputData?.doc?.shift();
  const docData = tempInputData?.doc;
  docData?.forEach((doc, idx) => {
    docFile.push(doc?.file_url || '');
    docType.push(doc?.document);
    docExpDate.push(
      doc?.exp_date && dayjs(doc?.exp_date)?.isValid()
        ? dayjs(doc?.exp_date)?.format('YYYY-MM-DD')
        : '',
    );
  });

  /** Apply file-table data */
  const separatedData = {
    ...tempInputData,
    doc_file: docFile,
    doc_type: docType,
    doc_expired: docExpDate,
  };

  return separatedData;
};

export default separateFileTableData;
