import { ACCIDENT_DEFAULT_VALUE } from '../../../../constant/Safety/Accident/formDefaultValue';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const capitalizeWords = str => {
  const capitalized = str
    ?.split(' ')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');

  return capitalized;
};

const generateAccidentDefaultValue = ({ modalType, accidentInfo }) => {
  if (modalType === EDIT_MODE?.add) {
    return { ...ACCIDENT_DEFAULT_VALUE };
  }

  if (accidentInfo) {
    const accidentData = Object?.entries?.(accidentInfo)?.map(
      ([key, value]) => {
        if (key !== 'doc') {
          return value !== undefined && value !== null
            ? [key, value]
            : [key, ''];
        }
        return [
          key,
          [
            {
              updated_date: null,
              document: '',
              exp_date: null,
              file_url: null,
              updated_by: '',
            },
            ...value?.map(({ document, ...doc }) => ({
              document: capitalizeWords(document),
              ...doc,
            })),
          ],
        ];
      },
    );

    return Object?.fromEntries?.(accidentData);
  }
  return { ...ACCIDENT_DEFAULT_VALUE };
};
export default generateAccidentDefaultValue;
