import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../Common/Controller/Button';
import {
  PRESET_RED,
  PRESET_PRIMARY,
} from '../../../../constant/Common/Style/preset';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import searchContent from '../../../../util/Safety/DamageReport/Handler/searchContent';

const AdvancedSearchModalButton = ({
  setIsSearchModal,
  setQueryObject,
  setAlertOptions,
}) => {
  const { handleSubmit } = useFormContext();
  return (
    <div className="flex gap-x-[18px]">
      <CommonButton
        styles={PRESET_RED}
        icon={PATH_COMMON_BUTTON_ICON?.cancelRed}
        onClick={() => {
          setIsSearchModal(false);
        }}
      >
        Cancel
      </CommonButton>
      <CommonButton
        styles={PRESET_PRIMARY}
        icon={PATH_COMMON_BUTTON_ICON?.searchBlue}
        onClick={() => {
          searchContent({
            handleSubmit,
            setQueryObject,
            setIsSearchModal,
            setAlertOptions,
          });
        }}
      >
        Search
      </CommonButton>
    </div>
  );
};

export default AdvancedSearchModalButton;
