import styled from 'styled-components';

const StyledCustomLabelRequiredWrapper = styled.div`
  width: fit-content;
  height: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  color: red;
  padding-right: 3px;
`;

export default StyledCustomLabelRequiredWrapper;
