import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import StyledInputContentsWrapper from '../../../../style/Labs/Input/StyledInputContentsWrapper';
import StyledInputHeaderWrapper from '../../../../style/Labs/Input/StyledInputContentsWrapper/StyledInputHeaderWrapper';
import createInputContentsHeader from '../../../../util/Labs/Input/Generator/createInputContentsHeader';
import StyledInputItemsWrapper from '../../../../style/Labs/Input/StyledInputContentsWrapper/StyledInputItemsWrapper';
import createController from '../../../../util/Labs/Input/Generator/createController';
import CommonButton from '../../../Common/Controller/Button';
import { PRESET_GREEN } from '../../../../constant/Common/Style/preset';

const InputContents = ({ ...props }) => {
  const { type } = props;
  const InputComponent = createController({ type });
  const inputController = useForm();

  return (
    <StyledInputContentsWrapper>
      <StyledInputHeaderWrapper>
        {createInputContentsHeader({ type })}
      </StyledInputHeaderWrapper>
      <StyledInputItemsWrapper>
        <FormProvider {...inputController}>
          <form className="w-full">
            <div className="w-full">
              <InputComponent
                {...props}
                styles={{ ...props?.styles }}
                disabled={props?.disabled}
                maxLength={props?.maxLength}
                inputName={type}
                label={props?.label}
                required={[true, true, true]}
                option={[
                  { label: 'Option 1', value: 'Option 1' },
                  { label: 'Option 2', value: 'Option 2' },
                  { label: 'Option 3', value: 'Option 3' },
                ]}
                options={[
                  {
                    inputName: 'Option 1',
                    optionLabel: 'Option 1',
                    label: 'Option 1',
                    value: 'Option 1',
                  },
                  {
                    inputName: 'Option 2',
                    optionLabel: 'Option 2',
                    label: 'Option 2',
                    value: 'Option 2',
                  },
                  {
                    inputName: 'Option 3',
                    optionLabel: 'Option 3',
                    label: 'Option 3',
                    value: 'Option 3',
                  },
                ]}
                adornment={{
                  position: (() => {
                    if (type === 'textAdornmentStart') return 'start';
                    if (type === 'textAdornmentEnd') return 'end';
                    return null;
                  })(),
                  content: (
                    <div
                      className="text-[#264B9F] text-[12px] font-bold cursor-pointer"
                      onClick={() => console.log('Adornment Click')}
                    >
                      $
                    </div>
                  ),
                }}
              />
              <CommonButton
                styles={PRESET_GREEN}
                onClick={() => {
                  inputController?.handleSubmit(data =>
                    console.log('Data : ', data),
                  )();
                }}
              >
                Submit
              </CommonButton>
            </div>
          </form>
        </FormProvider>
      </StyledInputItemsWrapper>
    </StyledInputContentsWrapper>
  );
};

export default InputContents;
