import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getTypeList from '../../../service/Common/Get/getTypeList';

const useGetTypeList = ({ ...props }) => {
  const { keyword } = props;

  const [typeList, setTypeList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.typeList({ keyword }),
    queryFn: () => getTypeList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setTypeList(prev => query?.data?.data);
    } else {
      setTypeList(prev => []);
    }
  }, [query?.data]);

  return { typeList, ...query };
};

export default useGetTypeList;
