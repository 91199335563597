const generateQueryString = ({ queryObject }) => {
  const queryString = Object.entries(queryObject)
    ?.map(query => {
      return query[1] !== undefined && query[1] !== null && query[1] !== ''
        ? query?.join('=')
        : null;
    })
    .filter(query => query !== null)
    .join('&');

  return queryString;
};

export default generateQueryString;
