import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../Common/Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../../constant/Common/Style/preset';
import saveUserInfo from '../../../../util/Admin/User/Handler/saveUserInfo';

const SideModalButtonArea = ({ userID, divList, userInfoPatchMutator }) => {
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = useFormContext();

  return (
    <div className="flex ml-[64px] gap-x-[30px] mt-[28px]">
      <CommonButton
        icon={PATH_COMMON_BUTTON_ICON?.clearRed}
        styles={PRESET_RED}
        onClick={() => reset()}
      >
        Clear
      </CommonButton>
      <CommonButton
        icon={PATH_COMMON_BUTTON_ICON?.saveBlue}
        styles={PRESET_PRIMARY}
        preventDupClick
        onClick={({ buttonRef }) =>
          saveUserInfo({
            handleSubmit,
            userID,
            divList,
            userInfoPatchMutator,
            isValid,
            buttonRef,
          })
        }
      >
        Save
      </CommonButton>
    </div>
  );
};

export default SideModalButtonArea;
