import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../../constant/Common/Style/color';

const StyledInputHeaderWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: ${PRIMARY_COLOR};
  font-weight: bold;
  font-size: 20px;
`;

export default StyledInputHeaderWrapper;
