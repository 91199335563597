import React from 'react';
import { useSelector } from 'react-redux';
import { PATH_DIVISION_SELECTOR_COLLAPSE_ICON } from '../../../../../../constant/Common/Path/Asset/Icon/Button';
import StyledDivisionSelectorLabelWrapper from '../../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorLabel/StyledDivisionSelectorLabelWrapper';
import StyledDivisionSelectorLabelContent from '../../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorLabel/StyledDivisionSelectorLabelContent';
import StyledDivisionSelectorLabelButton from '../../../../../../style/Common/Header/StyledSessionInfo/StyledDivisionSelector/StyledDivisionSelectorLabel/StyledDivisionSelectorLabelButton';

/**
 * @description
 * Division selector label component.
 * @param {function} setIsOpen
 * Function to handle the division selector open/close event.
 * @returns {React.ReactElement}
 */
const DivisionSelectorLabel = ({ ...props }) => {
  /** Get props data */
  const { setIsOpen } = props;

  /** Get current division */
  const currDiv = useSelector(state => state?.common?.currDiv);

  return (
    <StyledDivisionSelectorLabelWrapper
      onClick={() => setIsOpen(prev => !prev)}
    >
      <StyledDivisionSelectorLabelContent>
        {currDiv}
      </StyledDivisionSelectorLabelContent>
      <StyledDivisionSelectorLabelButton>
        <img
          src={PATH_DIVISION_SELECTOR_COLLAPSE_ICON}
          alt="Division selector collapse icon"
        />
      </StyledDivisionSelectorLabelButton>
    </StyledDivisionSelectorLabelWrapper>
  );
};

export default DivisionSelectorLabel;
