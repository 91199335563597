const handleRowClicked = ({ props, setUserID, setIsOpenSideModal }) => {
  const getColID = props?.gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (getColID !== 'yms_status') {
    const userID = props?.gridParam?.data?.id;

    setUserID(userID);
    setIsOpenSideModal(true);
  }
};

export default handleRowClicked;
