import generateCellRendererWithCellType from './generateCellRendererWithCellType';

const generateNormalCellRenderer = ({
  gridParam,
  cellType,
  adornment,
  styles,
  ...props
}) => {
  const normalCellSettings = {
    component: generateCellRendererWithCellType({ cellType }),
    params: {
      gridParam,
      cellType,
      adornment,
      styles,
      ...props,
    },
  };

  return normalCellSettings;
};

export default generateNormalCellRenderer;
