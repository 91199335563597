export const PRESET_PRIMARY = {
  color: '#556DA1',
  backgroundColor: '#ffffff',
  border: '1px solid #556DA1',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#556DA1',
  hoverBorder: '1px solid #556DA1',
};

export const PRESET_RED = {
  color: '#C24D4D',
  backgroundColor: '#ffffff',
  border: '1px solid #C24D4D',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#C24D4D',
  hoverBorder: '1px solid #C24D4D',
};

export const PRESET_GREEN = {
  color: '#3A825D',
  backgroundColor: '#ffffff',
  border: '1px solid #3A825D',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#3A825D',
  hoverBorder: '1px solid #3A825D',
};

export const PRESET_GREY = {
  color: '#666666',
  backgroundColor: '#ffffff',
  border: '1px solid #666666',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#666666',
  hoverBorder: '1px solid #666666',
};

export const PRESET_GREY_TRANSPARENT = {
  color: '#666666',
  backgroundColor: 'transparent',
  border: 'none',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#666666',
};

export const PRESET_ORANGE = {
  color: '#DB5C00',
  backgroundColor: '#ffffff',
  border: '1px solid #DB5C00',
  hoverColor: '#ffffff',
  hoverBackgroundColor: '#DB5C00',
  hoverBorder: '1px solid #DB5C00',
};
