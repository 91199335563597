const deleteProperty = ({ inputData }) => {
  const tempInputData = { ...inputData };

  /** Delete unnecessary data */
  delete tempInputData?.seq;
  delete tempInputData?.created_by;
  delete tempInputData?.created_date;
  delete tempInputData?.updated_by;
  delete tempInputData?.updated_date;
  delete tempInputData?.total;

  /** Delete doc first row (input row) */
  delete tempInputData?.doc;

  return tempInputData;
};

export default deleteProperty;
