import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonDate from '../../../Common/Controller/Date';
import CommonCheckbox from '../../../Common/Controller/Checkbox';
import {
  handleCheckBoxChange,
  handleDateInputChange,
  handleInputChange,
  handlePairPlateAutoCompleteChange,
  handleUpperInputBlur,
} from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';
import {
  IS_NGL_CHASSIS_OPTION,
  REG_CHECKBOX_OPTION,
} from '../../../../constant/Safety/DamageReport/options';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CustomTextInput from '../../../Common/Controller/Text/Custom';

const ChassisBasicInfoFields = ({
  isCompleted,
  damageReport,
  chassisOptions,
  isEditable,
}) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const chassisValue = damageReport?.ch_no;
    const isNGLChassis = !!chassisOptions?.find(
      chassis => chassis.value === chassisValue,
    );
    setValue('isNGLChassis', isNGLChassis);
  }, [damageReport?.ch_no]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex space-x-[10px]">
        <div className="w-[54px]">
          <CommonCheckbox
            label="CHASSIS #"
            borderVisible
            options={IS_NGL_CHASSIS_OPTION}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleCheckBoxChange}
          />
        </div>
        <div className="pt-[14px] w-[155px]">
          <div className={`${watch('isNGLChassis') ? 'block' : 'hidden'}`}>
            <CommonAutoComplete
              labelVisible={false}
              inputName="ch_no_auto_complete"
              option={chassisOptions}
              disabled={isCompleted || !isEditable}
              onChangeHandler={props =>
                handlePairPlateAutoCompleteChange({
                  plateInputName: 'ch_plate_no',
                  setValue,
                  ...props,
                })
              }
              defaultValue={damageReport?.ch_no || ''}
            />
          </div>
          <div className={`${!watch('isNGLChassis') ? 'block' : 'hidden'}`}>
            <CustomTextInput
              inputName="ch_no_text"
              maxLength={12}
              suppressLabelVisible
              suppressLabelContentVisible
              suppressErrorVisible
              suppressErrorContentVisible
              onChange={handleInputChange}
              onBlur={handleUpperInputBlur}
              disabled={isCompleted || !isEditable}
              defaultValue={damageReport?.ch_no || ''}
            />
          </div>
        </div>
        <div className="w-[221px]">
          <CommonTextInput
            label="PLATE #"
            inputName="ch_plate_no"
            disabled={isCompleted || !isEditable}
            maxLength={8}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.ch_plate_no || ''}
          />
        </div>
      </div>
      <div className="flex gap-x-[7.5px]">
        <div className="w-[107px]">
          <CommonDate
            label="BIT"
            inputName="ch_bit"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleDateInputChange}
            defaultValue={damageReport?.ch_bit || ''}
          />
        </div>
        <div className="w-[107px]">
          <CommonDate
            label="ANNUAL"
            inputName="ch_annual"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleDateInputChange}
            defaultValue={damageReport?.ch_annual || ''}
          />
        </div>
        <div className="w-[42px]">
          <CommonCheckbox
            label="REG"
            inputName="ch_reg"
            borderVisible
            options={REG_CHECKBOX_OPTION}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleCheckBoxChange}
            defaultValue={damageReport?.ch_reg || false}
          />
        </div>
        <div className="w-[171px]">
          <CommonTextInput
            label="SEAL"
            inputName="ch_seal"
            maxLength={20}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.ch_seal || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default ChassisBasicInfoFields;
