import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import postITTablet from '../../../../service/Safety/ITTablet/Post/postITTablet';
import useControlToast from '../../../Common/Toast/useControlToast';
import safetyITTabletKeys from '../keys';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const usePostITTabletMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  setIsAddEditModalOpen,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postITTablet,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setIsAddEditModalOpen(false);
      queryClient.refetchQueries({
        queryKey: safetyITTabletKeys?.itTabletList(),
      });
      addToast?.({ message: SUCCESS_SAVE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: safetyITTabletKeys?.itTabletList(),
      });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error?.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostITTabletMutation;
