export const SELECT_ITEM_AE = [
  { label: 'None', value: 'None' },
  { label: 'Edit', value: 'Edit' },
];

export const SELECT_ITEM_VIEW = [
  { label: 'None', value: 'None' },
  { label: 'View', value: 'View' },
];

export const SELECT_ITEM_ALL = [
  { label: 'None', value: 'None' },
  { label: 'View', value: 'View' },
  { label: 'Edit', value: 'Edit' },
];

export const PERM_FORMAT = {
  admin_auth: null,
  admin_div_conf: null,
  admin_user: null,
  in_out_daily: null,
  in_out_inventory: null,
  safety_accident: null,
  safety_ch: null,
  safety_complete: null,
  safety_damage: null,
  safety_it: null,
  safety_mr: null,
  safety_truck: null,
  safety_van: null,
};
