import dayjs from 'dayjs';

export const changeText = ({ e, ...props }) => {
  const { onChange, regex } = props;
  if (regex && !regex.test(e?.target?.value)) {
    return false;
  }
  onChange?.(e?.target?.value);
  return true;
};

export const changeAutoComplete = ({ e, ...props }) => {
  const { onChange, controllerProps } = props;
  onChange?.(controllerProps?.value);
  return true;
};

export const changeSelect = ({ e, ...props }) => {
  const { onChange } = props;
  onChange?.(e?.target?.value);
  return true;
};

export const changeDate = ({ e, ...props }) => {
  const { onChange } = props;
  const dateValue = e && dayjs(e)?.isValid() ? dayjs(e) : null;
  onChange?.(dateValue);
  return true;
};

export const changeFile = ({ e, ...props }) => {
  const { onChange, inputName, setValue } = props;

  const fileElement = document.getElementById(inputName)?.files;
  if (inputName) {
    onChange?.(fileElement?.[0]);
    setValue?.(inputName, fileElement?.[0]);
    return true;
  }
  return false;
};
