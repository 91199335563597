import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import adminUserKeys from '../keys';
import getUser from '../../../../service/Admin/User/Get/getUser';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const useGetUser = ({ setIsOpenErrorModal, setAlertInfo }) => {
  const [userID, setUserID] = useState(null);

  const { data, isError, error, isLoading, isFetching } = useQuery({
    queryKey: adminUserKeys.user({ id: userID }),
    queryFn: () => getUser({ id: userID }),
    staleTime: 0,
    enabled: !!userID,
  });

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(data?.data || {});
  }, [data]);

  useEffect(() => {
    if (isError) {
      setIsOpenErrorModal(true);
      setAlertInfo({
        stats: ALERT_MODAL_STATUS?.ERROR,
        message: 'Failed to fetch user information',
      });
    }
  }, [isError]);

  return { userID, setUserID, userInfo, isLoading, isFetching };
};

export default useGetUser;
