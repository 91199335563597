import React from 'react';
import NotFound from '../../../../page/Error/NotFound';
import AccessDenied from '../../../../page/Error/AccessDenied';
import InternalServer from '../../../../page/Error/InternalServer';

/**
 * @description
 * Children routes of the Error page.
 */
const ERROR_CHILDREN_ROUTE = [
  {
    path: '',
    element: <NotFound />,
  },
  {
    path: '404/*',
    element: <NotFound />,
  },
  {
    path: '403/*',
    element: <AccessDenied />,
  },
  {
    path: '500/*',
    element: <InternalServer />,
  },
];

export default ERROR_CHILDREN_ROUTE;
