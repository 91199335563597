import React from 'react';
import CommonButton from '../../../Controller/Button';

const ButtonCellRenderer = ({
  content,
  styles,
  icon,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <div className="flex justify-center items-center w-full">
      <CommonButton
        styles={{ ...styles }}
        icon={icon || null}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </CommonButton>
    </div>
  );
};

export default ButtonCellRenderer;
