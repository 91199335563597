import React from 'react';
import {
  PATH_ARROW_DOWN_BLUE_ICON,
  PATH_DELETE_GREY_ONLY_ICON,
  PATH_DELETE_RED_ONLY_ICON,
} from '../../../../constant/Common/Path/Asset/Icon/Button';
import CommonTextInput from '../../../Common/Controller/Text';
import ToggleWithText from '../ToggleWithText';

const YMSYardContainer = ({
  ymsYard,
  div,
  postYMSYardMutation,
  patchYMSYardActivateMutation,
  deleteYMSYardMutation,
}) => {
  return (
    <div className="flex mt-[40px] flex-col align-center">
      <div className="flex">
        <img src={PATH_ARROW_DOWN_BLUE_ICON} alt="arrowDownBlue" />
        <div className="ml-[12px] text-blue-800 text-sm font-bold">
          YMS YARD
        </div>
      </div>
      <div className="mt-[6px] mb-[-10px] ml-[27px]">
        <CommonTextInput
          inputName="YMS Yard"
          placeholder="Add Yard"
          width="w-[183px]"
          maxLength={10}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value?.toUpperCase());
          }}
          onKeyDownHandler={({ e, setDisabled }) => {
            if (e.key === 'Enter') {
              setDisabled(true);
              e.preventDefault();
              postYMSYardMutation({
                div,
                name: e?.target?.value,
                setDisabled,
              });
            }
          }}
        />
      </div>
      <div className="flex flex-col ml-[27px] w-[183px] h-[222px] bg-white rounded-[5px] border border-zinc-300 gap-[7px] overflow-x-hidden overflow-y-scroll">
        {ymsYard?.map(item => (
          <div className="flex">
            <div>
              <ToggleWithText
                key={item?.id}
                name={item?.name}
                inputName={item?.name}
                isActive={item?.active}
                mutation={patchYMSYardActivateMutation}
                seq={item?.seq}
              />
            </div>
            <div className="mt-[7px] ml-[-3px]">
              {!item?.active ? (
                <button
                  type="button"
                  className="cursor-pointer"
                  onClick={() => {
                    deleteYMSYardMutation({ seq: item?.seq });
                  }}
                >
                  <img src={PATH_DELETE_RED_ONLY_ICON} alt="deleteRed" />
                </button>
              ) : (
                <img src={PATH_DELETE_GREY_ONLY_ICON} alt="deleteGrey" />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YMSYardContainer;
