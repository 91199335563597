import { useQuery } from '@tanstack/react-query';
import dailyInOutKeys from '../keys';
import getInventoryTruckList from '../../../service/DailyInOut/Get/getInventoryTruckList';

const useGetInventoryTruckList = ({ div, yard }) => {
  const {
    isLoading: isLoadingInventoryTruckList,
    data,
    isRefetching: isRefetchingInventoryTruckList,
    isError: isErrorInventoryTruckList,
  } = useQuery({
    queryKey: dailyInOutKeys?.truckList({ div, yard }),
    queryFn: () => getInventoryTruckList({ div, yard }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingInventoryTruckList,
    inventoryTruckList: data?.data,
    isRefetchingInventoryTruckList,
    isErrorInventoryTruckList,
  };
};

export default useGetInventoryTruckList;
