import React from 'react';
import AccidentFormContentArea from '../AccidentFormContentArea';
import BasicInfoContent from '../BasicInfoContent';
import LoanInfoContent from '../LoanInfoContent';
import InsuranceInfoContent from '../InsuranceInfoContent';
import OtherInfoContent from '../OtherInfoContent';

const AccidentFormContent = ({
  modalType,
  accident,
  nameOptionList,
  divisionList,
  isEditable,
}) => {
  return (
    <div className="flex flex-col w-full mt-[7px] pl-[33px] gap-y-[10px] h-[755px]">
      <AccidentFormContentArea
        header="Basic Info"
        content={
          <BasicInfoContent
            modalType={modalType}
            accident={accident}
            nameOptionList={nameOptionList}
            divisionList={divisionList}
            isEditable={isEditable}
          />
        }
        width="669px"
        height="278px"
      />
      <AccidentFormContentArea
        header="Loan Info"
        content={
          <LoanInfoContent accident={accident} isEditable={isEditable} />
        }
        width="669px"
        height="130px"
      />
      <AccidentFormContentArea
        header="Insurance Info"
        content={
          <InsuranceInfoContent accident={accident} isEditable={isEditable} />
        }
        width="669px"
        height="138px"
      />
      <AccidentFormContentArea
        header="Other Info"
        content={
          <OtherInfoContent accident={accident} isEditable={isEditable} />
        }
        width="669px"
        height="auto"
      />
    </div>
  );
};

export default AccidentFormContent;
//
