import React, { useEffect, useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import StyledCommonInputWrapper from '../../../../style/Common/Controller/Common/StyledCommonInputWrapper';
import StyledCommonLabel from '../../../../style/Common/Controller/Common/StyledCommonLabel';
import StyledCommonInputRequiredLabel from '../../../../style/Common/Controller/Common/StyledCommonInputRequiredLabel';
import createDefaultInputRules from '../../../../util/Common/Controller/Generator/createDefaultInputRules';
import generateAdornmentContent from '../../../../util/Common/Datagrid/Generator/generateAdornmentContent';

const CommonTextInput = ({ ...props }) => {
  /**
   * @description
   * Get react-hook-form object from useFormContext.
   * @control Must-have elements for getting values from a form.
   * @unregister Allows to unregister a single input or an array of inputs.
   * @setValue Setter value of input.
   * @formState Object containing information about the form.
   */
  const {
    control,
    unregister,
    setValue,
    resetField,
    formState: { errors: formErrors },
  } = useFormContext();

  /**
   * @backgroundColor {string} - Background color of input.
   * @maxLength {number} - Max length of input.
   * @label {string} - Label of input.
   * @defaultValue {any} - Default value of input.
   * @disabled {boolean} - Disable input.
   * @required {boolean} - Required input.
   */

  const [backgroundColor, setBackgroundColor] = useState(
    props?.backgroundColor || '#ffffff',
  );
  useEffect(() => {
    /**
     * If default background color changed, component background color would be changed too.
     * Undefined, Null exception handling.
     */
    if (
      props?.backgroundColor !== undefined &&
      props?.backgroundColor !== null
    ) {
      setBackgroundColor(props?.backgroundColor);
    }
  }, [props?.backgroundColor]);

  // Set default max length of input as 20.
  const [maxLength, setMaxLength] = useState(props?.maxLength || 20);

  const [label, setLabel] = useState(props?.label);

  // Set default input value as empty string ('').
  const [defaultValue, setDefaultValue] = useState(props?.defaultValue || '');
  useEffect(() => {
    /**
     * If default value changed, component default value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.defaultValue !== undefined && props?.defaultValue !== null) {
      setDefaultValue(props?.defaultValue);
      setValue(props?.inputName, props?.defaultValue);
    }
  }, [props?.defaultValue]);

  // Set default disabled as false
  const [disabled, setDisabled] = useState(props?.disabled || false);
  useEffect(() => {
    /**
     * If default disabled value changed, component disabled value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.disabled !== undefined && props?.disabled !== null) {
      setDisabled(props?.disabled);
    }
  }, [props?.disabled]);

  // Set default required as false
  const [required, setRequired] = useState(props?.required || false);
  useEffect(() => {
    /**
     * If default required value changed, component required value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.required !== undefined && props?.required !== null) {
      setRequired(props?.required);
    }
  }, [props?.required]);

  useEffect(() => {
    /**
     * Unregister input when component unmount.
     * Unregister single input only.
     */
    return () => unregister(props?.inputName);
  }, []);

  /**
   * @description
   * Render input component.
   * @control - Must-have elements for getting values from a form.
   * @name - Name of input.
   * @defaultValue - Default value of input.
   * @rules - Rules of input - Check constraints.
   * @render - Render target input component.
   * - field : Object containing field props, like name, value, onChange, onBlur.
   * - fieldState : Object containing field state, like error, invalid, etc.
   */
  return (
    <Controller
      control={control}
      name={props?.inputName}
      defaultValue={defaultValue}
      rules={createDefaultInputRules({ ...props })}
      render={({
        field: { ref, onChange, value, ...field },
        fieldState: { invalid, error },
      }) => {
        // Package of handler props
        const handlerProps = {
          onChange,
          value,
          maxLength,
          ...field,
          ...props,
          setDisabled,
        };

        // Render Control Input Component
        return (
          <>
            <StyledCommonInputWrapper labelStyle={props?.labelStyle}>
              {(!!label || props.labelVisible !== false) && (
                <StyledCommonLabel
                  labelStyle={props?.labelStyle}
                  lbl-pd-right={props?.[`lbl-pd-right`]}
                >
                  {!!label && (
                    <>
                      {!!required && (
                        <StyledCommonInputRequiredLabel>
                          *
                        </StyledCommonInputRequiredLabel>
                      )}
                      {label}
                    </>
                  )}
                </StyledCommonLabel>
              )}
              <div className={`${props?.width ? `${props?.width}` : 'w-full'}`}>
                <TextField
                  {...field}
                  variant="outlined"
                  inputRef={ref}
                  error={invalid}
                  value={value}
                  onChange={e =>
                    props?.onChangeHandler?.({
                      e,
                      ...handlerProps,
                    })
                  }
                  /**
                   * onClick Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onClick={e => props?.onClickHandler?.({ e, ...handlerProps })}
                  /**
                   * onKeyDown Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onKeyDown={e =>
                    props?.onKeyDownHandler?.({ e, ...handlerProps })
                  }
                  /**
                   * onBlur Handler. Only handlers of parameters work.
                   * To change value, should use Controller field's onChange changer.
                   * ex)
                   * (tempValue) => {
                   *    onChange(tempValue);
                   * }
                   */
                  onBlur={e => props?.onBlurHandler?.({ e, ...handlerProps })}
                  className={`border-solid border-[#D9D9D9] h-[30px] rounded-[5px] text-xs text-black w-full ${
                    disabled ? 'bg-[#F9F9F9]' : 'bg-white'
                  }
                  hover:border-[#8E9396]`}
                  sx={{
                    input: {
                      border: 'unset !important',
                      zIndex: '999',
                      WebkitTextFillColor: disabled && '#222 !important',
                    },
                    '.MuiInputBase-root': {
                      height: '30px',
                      fontSize: '12px',
                      fontWeight: '500',
                      '&.Mui-focused fieldset': {
                        border: '1.2px solid #264B9F !important',
                        boxShadow: '0px 0px 4px 0px #8BBCE9 !important',
                      },
                      '&.Mui-error fieldset': {
                        border: '1.2px solid #C24D4D !important',
                      },
                      '&:not(.Mui-focused):not(.Mui-disabled):hover fieldset':
                        !formErrors?.[props?.inputName]?.message && {
                          borderColor: '#8E9396 !important',
                        },
                      '&.Mui-disabled fieldset': {
                        backgroundColor: 'unset !important',
                      },
                    },
                    fieldset: {
                      borderColor: '#D9D9D9 !important',
                      backgroundColor: `${backgroundColor} !important`,
                    },
                  }}
                  disabled={disabled}
                  placeholder={props?.placeholder || ''}
                  InputProps={{
                    startAdornment:
                      props?.adornment?.position === 'start' &&
                      generateAdornmentContent({
                        adornment: props?.adornment,
                      }),
                    endAdornment:
                      props?.adornment?.position === 'end' &&
                      generateAdornmentContent({
                        adornment: props?.adornment,
                      }),
                    inputProps: {
                      maxLength,
                      autoComplete: 'off',
                    },
                  }}
                />
              </div>
            </StyledCommonInputWrapper>
            <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
              {!props?.suppressErrorVisible && error?.message}
            </FormHelperText>
          </>
        );
      }}
    />
  );
};

export default CommonTextInput;
