const createDefaultInputRules = ({
  type,
  required,
  maxLength,
  minLength,
  pattern,
  ...props
}) => {
  const validate = { ...props?.validate };

  const rules = {
    required: {
      value: required,
      message: 'This field is mandatory.',
    },
    maxLength: {
      value: maxLength,
      message: `This field must be less than ${maxLength} characters.`,
    },
    minLength: {
      value: minLength,
      message: `This field must be more than ${minLength} characters.`,
    },
    ...validate?.default,
    validate: {
      ...validate?.custom,
    },
  };

  return rules;
};

export default createDefaultInputRules;
