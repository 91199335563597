import styled from 'styled-components';

const StyledHeaderItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;

  font-size: 12px;
  color: #222;
  font-weight: 700;
`;

export default StyledHeaderItemWrapper;
