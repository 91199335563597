import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CommonButton from '../../../component/Common/Controller/Button/index';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import { PRESET_GREEN } from '../../../constant/Common/Style/preset';
import CommonAlert from '../../../component/Common/Modal/Alert';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import CommonDataGrid from '../../../component/Common/Datagrid/index';
import createUserColumnDefs from '../../../util/Admin/User/Generator/createUserColumnDefs';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import SideUserModal from '../../../component/Admin/User/SideModal';
import handleRowClicked from '../../../util/Admin/User/Handler/handleRowClick';
import useGetUserList from '../../../hook/Admin/User/Get/useGetUserList';
import useGetAuthList from '../../../hook/Admin/User/Get/useGetAuthList';
import usePatchUserInfo from '../../../hook/Admin/User/Patch/usePatchUserInfo';
import usePatchUserStatus from '../../../hook/Admin/User/Patch/usePatchUserStatus';
import CommonLoading from '../../../component/Common/Loading/index';
import useGetUser from '../../../hook/Admin/User/Get/useGetUser';
import { generateDivisionCheckboxOption } from '../../../util/Admin/User/Generator/generateOption';
import generateDefaultValue from '../../../util/Admin/User/Generator/generateDefaultValue';
import useControlLoadingStatus from '../../../hook/Safety/Common/Loading/useControlLoadingStatus';

const User = () => {
  /** useRef for handling disabled */
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  /** Get System Division List */
  const divList = useSelector(state => state.common.divisionList);
  const currDiv = useSelector(state => state?.common?.currDiv);

  /** Alert Modal */
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    status: ALERT_MODAL_STATUS.STABLE,
    message: '',
  });

  /** DataGrid */
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState([]);

  /** Side Modal */
  const [isOpenSideModal, setIsOpenSideModal] = useState(false);

  /** Get Auth List  */
  const { authList } = useGetAuthList();

  /** Get User List */
  const {
    userList,
    isLoading: isLoadingUserList,
    isFetching: isFetchingUserList,
    isError: isErrorUserList,
  } = useGetUserList({ currDiv });

  useEffect(() => {
    if (isErrorUserList) {
      setIsOpenErrorModal(true);
      setAlertInfo({
        status: ALERT_MODAL_STATUS.ERROR,
        message: 'Failed to fetch user list',
      });
    }
  }, [isErrorUserList]);

  /** Get User Information */
  const {
    userID,
    setUserID,
    userInfo,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useGetUser({
    setIsOpenErrorModal,
    setAlertInfo,
  });

  /** Patch User Information */
  const { mutate: userInfoPatchMutator } = usePatchUserInfo({
    gridRef,
    currDiv,
    setIsOpenErrorModal,
    setAlertInfo,
    setIsOpenSideModal,
    setUserID,
  });

  /** Related to Patch completed */
  const { mutate: userStatusPatchMutator } = usePatchUserStatus({
    gridRef,
    disabledRef,
    setIsOpenErrorModal,
    setAlertInfo,
  });

  const [formDefaultValue, setFormDefaultValue] = useState(
    generateDefaultValue({ userInfo, divList }),
  );

  /** Form Controller */
  const userController = useForm({ defaultValues: formDefaultValue });

  /** Form Default Value Setting */
  useEffect(() => {
    const newDefaultValue = generateDefaultValue({ userInfo, divList });
    userController.reset(newDefaultValue);
    setFormDefaultValue(newDefaultValue);
  }, [userInfo, divList]);

  useEffect(() => {
    if (!isOpenSideModal) {
      setUserID(null);
    }
  }, [isOpenSideModal]);

  /** Loading */
  const { isLoadingOpen } = useControlLoadingStatus({
    isFetching: [isFetchingUser],
    isLoading: [isLoadingUserList, isLoadingUser],
    isPending: [],
  });

  /** Division Checkbox Option */
  const [divisionCheckBoxOption, setDivisionCheckBoxOption] = useState([]);
  useEffect(() => {
    const option = generateDivisionCheckboxOption({ divList });
    setDivisionCheckBoxOption(option);

    setColumnDefs(
      createUserColumnDefs({
        userStatusPatchMutator,
        divList,
        disabledRef,
      }),
    );
  }, [divList]);

  useEffect(() => {
    setColumnDefs(
      createUserColumnDefs({
        userStatusPatchMutator,
        divList,
        disabledRef,
      }),
    );
  }, []);

  return (
    <>
      <CommonLoading isOpen={isLoadingOpen} />
      <div className="pt-[18px] pl-[41px] flex flex-col h-full pb-8">
        <div className="flex flex-col w-full h-full">
          <div className="flex justify-end gap-x-[15px] pr-[40px]">
            <CommonButton
              styles={PRESET_GREEN}
              icon={PATH_COMMON_BUTTON_ICON.excelGreen}
              onClick={() => {
                exportToExcel({ gridRef, fileName: 'USER' });
              }}
            >
              EXCEL
            </CommonButton>
          </div>
          <div className="w-full pt-[15px] pr-[40px] flex-1">
            <CommonDataGrid
              gridRef={gridRef}
              columnDefs={columnDefs}
              data={userList}
              onRowClicked={props =>
                handleRowClicked({
                  props,
                  setUserID,
                  setIsOpenSideModal,
                })
              }
            />
          </div>
        </div>
      </div>
      <SideUserModal
        userID={userID}
        authList={authList}
        divList={divList}
        isOpenSideModal={isOpenSideModal}
        setIsOpenSideModal={setIsOpenSideModal}
        userInfoPatchMutator={userInfoPatchMutator}
        userInfo={formDefaultValue}
        userController={userController}
        divisionCheckBoxOption={divisionCheckBoxOption}
      />
      {isOpenErrorModal &&
        alertInfo?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
          <CommonAlert
            isOpen={isOpenErrorModal}
            content={alertInfo?.message}
            status={alertInfo?.status}
            onCloseButtonClick={() => setIsOpenErrorModal(false)}
            onOKButtonClick={() => setIsOpenErrorModal(false)}
          />
        )}
    </>
  );
};

export default User;
