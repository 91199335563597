import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import createDefaultValue from '../../../util/Common/Controller/Generator/createDefaultValue';

const useControlComponentData = ({ ...props }) => {
  /** Input name */
  const [inputName, setInputName] = useState(props?.inputName || null);
  useEffect(() => {
    setInputName(prev => props?.inputName);
  }, [props?.inputName]);

  /** Value */
  const [defaultValue, setDefaultValue] = useState(
    createDefaultValue({
      type: props?.type,
      defaultValue: props?.defaultValue,
      option: props?.option,
    }),
  );

  const [compValue, setCompValue] = useState(
    createDefaultValue({
      type: props?.type,
      defaultValue: props?.defaultValue,
      option: props?.option,
    }),
  );
  useEffect(() => {
    if (
      (props?.type === 'date' || props?.type === 'time') &&
      dayjs(defaultValue)?.isSame(props?.defaultValue)
    ) {
      return;
    }
    setDefaultValue(prev =>
      createDefaultValue({
        type: props?.type,
        defaultValue: props?.defaultValue,
        option: props?.option,
      }),
    );
    setCompValue(prev =>
      createDefaultValue({
        type: props?.type,
        defaultValue: props?.defaultValue,
        option: props?.option,
      }),
    );
    props?.setValue?.(
      inputName,
      createDefaultValue({
        type: props?.type,
        defaultValue: props?.defaultValue,
        option: props?.option,
      }),
    );
  }, [props?.defaultValue]);

  /** Label */
  const [label, setLabel] = useState(props?.label || '');
  useEffect(() => {
    setLabel(prev => props?.label);
  }, [props?.label]);

  const [suppressLabelVisible, setSuppressLabelVisible] = useState(false);
  useEffect(() => {
    setSuppressLabelVisible(prev => props?.suppressLabelVisible);
  }, [props?.suppressLabelVisible]);

  const [suppressLabelContentVisible, setSuppressLabelContentVisible] =
    useState(false);
  useEffect(() => {
    setSuppressLabelContentVisible(prev => props?.suppressLabelContentVisible);
  }, [props?.suppressLabelContentVisible]);

  /** Error */
  const [suppressErrorVisible, setSuppressErrorVisible] = useState(false);
  useEffect(() => {
    setSuppressErrorVisible(prev => props?.suppressErrorVisible);
  }, [props?.suppressErrorVisible]);

  const [suppressErrorContentVisible, setSuppressErrorContentVisible] =
    useState(false);
  useEffect(() => {
    setSuppressErrorContentVisible(prev => props?.suppressErrorContentVisible);
  }, [props?.suppressErrorContentVisible]);

  /** Count */
  const [suppressCountVisible, setSuppressCountVisible] = useState(false);
  useEffect(() => {
    setSuppressCountVisible(prev => props?.suppressCountVisible);
  }, [props?.suppressCountVisible]);

  const [suppressCountContentVisible, setSuppressCountContentVisible] =
    useState(false);
  useEffect(() => {
    setSuppressCountContentVisible(prev => props?.suppressCountContentVisible);
  }, [props?.suppressCountContentVisible]);

  /** Utility Button */
  const [suppressUtilityButton, setSuppressUtilityButton] = useState(false);
  useEffect(() => {
    setSuppressUtilityButton(prev => props?.suppressUtilityButton);
  }, [props?.suppressUtilityButton]);

  return {
    inputName,
    setInputName,
    defaultValue,
    compValue,
    setCompValue,
    setDefaultValue,
    label,
    setLabel,
    suppressLabelVisible,
    setSuppressLabelVisible,
    suppressLabelContentVisible,
    setSuppressLabelContentVisible,
    suppressErrorVisible,
    setSuppressErrorVisible,
    suppressErrorContentVisible,
    setSuppressErrorContentVisible,
    suppressCountVisible,
    setSuppressCountVisible,
    suppressCountContentVisible,
    setSuppressCountContentVisible,
    suppressUtilityButton,
    setSuppressUtilityButton,
  };
};

export default useControlComponentData;
