import React, { memo } from 'react';
import { PATH_HAMBURGER_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import StyledHamburgerWrapper from '../../../../style/Common/Header/StyledHamburger/StyledHamburgerWrapper';
import StyledHamburgerButton from '../../../../style/Common/Header/StyledHamburger/StyledHamburgerButton';
import handleSidebarOpen from '../../../../util/Common/Header/Hamburger/Handler/handleSidebarOpen';

/**
 * @description
 * Component of Hamburger in the application header.
 * @returns {React.ReactElement}
 */
const Hamburger = memo(({ ...props }) => {
  return (
    <StyledHamburgerWrapper>
      <StyledHamburgerButton onClick={() => handleSidebarOpen({ ...props })}>
        <img
          src={PATH_HAMBURGER_ICON}
          alt="Hamburger menu on to expand the navigation menu"
        />
      </StyledHamburgerButton>
    </StyledHamburgerWrapper>
  );
}, null);

export default Hamburger;
