import React from 'react';

const FoldedContents = () => {
  return (
    <div className="flex justify-center items-center w-full h-fit bg-yellow-200">
      <img
        src="https://database.hanyang.ac.kr/wp-content/uploads/2023/03/%EC%9D%B4%EC%A0%95%EC%9D%B8-%EC%82%AC%EC%A7%84.jpg"
        alt="hyua"
        width={700}
        height={500}
      />
    </div>
  );
};

export default FoldedContents;
