import React from 'react';
import { Outlet } from 'react-router-dom';
import SubMenuNavigator from '../../component/Common/Router/App/Common/SubMenuNavigator';
import { PAGE_DAILY_IN_OUT } from '../../constant/Common/Router/App/Common/mapper';

const DailyInOut = () => {
  return (
    <div className="w-full flex flex-col">
      <SubMenuNavigator page={PAGE_DAILY_IN_OUT} />
      <Outlet />
    </div>
  );
};

export default DailyInOut;
