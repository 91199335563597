import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonButton from '../../../Common/Controller/Button';
import {
  PRESET_RED,
  PRESET_PRIMARY,
} from '../../../../constant/Common/Style/preset';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import saveAccident from '../../../../util/Safety/Accident/Handler/saveAccident';

const AccidentFormModalButton = ({
  seq,
  modalType,
  postAccidentMutator,
  patchAccidentMutator,
  nameObject,
  isEditable,
}) => {
  const { reset, handleSubmit } = useFormContext();

  const saveButtonRef = useRef(null);

  return (
    <div className="flex gap-x-[18px] mt-[15px]">
      {isEditable && (
        <>
          <CommonButton
            styles={PRESET_RED}
            icon={PATH_COMMON_BUTTON_ICON?.clearRed}
            onClick={() => {
              reset();
            }}
          >
            Clear
          </CommonButton>

          <CommonButton
            ref={saveButtonRef}
            styles={PRESET_PRIMARY}
            icon={PATH_COMMON_BUTTON_ICON?.saveBlue}
            onClick={() => {
              saveAccident({
                seq,
                handleSubmit,
                postAccidentMutator,
                modalType,
                patchAccidentMutator,
                nameObject,
                saveButtonRef,
              });
            }}
          >
            Save
          </CommonButton>
        </>
      )}
    </div>
  );
};

export default AccidentFormModalButton;
