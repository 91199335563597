import React from 'react';
import CurrentRowNotEditingCell from './CurrentRowNotEditingCell';
import CurrentRowEditingCell from './CurrentRowEditingCell';

const ModificationCell = ({
  gridParam,
  modType,
  onDeleteClicked,
  ...props
}) => {
  const editingCells = gridParam?.api?.getEditingCells();

  const isCurrentRowEditing = editingCells?.some(cell => {
    return cell.rowIndex === gridParam?.node?.rowIndex;
  });

  return (
    <div className="flex justify-center items-center gap-[16px] w-full h-full">
      {isCurrentRowEditing ? (
        <CurrentRowEditingCell gridParam={gridParam} />
      ) : (
        <CurrentRowNotEditingCell
          gridParam={gridParam}
          modType={modType}
          onDeleteClicked={onDeleteClicked}
        />
      )}
    </div>
  );
};

export default ModificationCell;
