import React from 'react';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import {
  changeDate,
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { REGEX_NUMBER_DECIMAL_POINT_TWO } from '../../../../../../constant/Common/Regex';
import { COMMON_LOAN_STATUS } from '../../../../../../constant/Common/Option/Status/loan';
import { createOptionalSelectOption } from '../../../../../../util/Common/Option/Generator/selectOptionCreator';

const LoanInfo = ({ ...props }) => {
  const { data, isEditable } = props;

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Loan Info
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="PURCHASE AMT"
              inputName="purchase_amt"
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              defaultValue={data?.purchase_amt || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={`${STYLE_ITEM_WRAPPER} w-[300px]`}>
            <CommonTextInput
              label="BANK"
              inputName="bank"
              maxLength={10}
              defaultValue={data?.bank || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="TOTAL LOAN AMT"
              inputName="total_loan_amt"
              defaultValue={data?.total_loan_amt || ''}
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="INTEREST RATE"
              inputName="interest_rate"
              maxLength={2}
              defaultValue={data?.interest_rate || ''}
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'end',
                content: '%',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="LOAN TERMS"
              inputName="loan_terms"
              defaultValue={data?.loan_terms || ''}
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonSelect
              label="LOAN STATUS"
              inputName="loan_status"
              defaultValue={data?.loan_status || ''}
              option={createOptionalSelectOption({ items: COMMON_LOAN_STATUS })}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
      {/* Third row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="SCHEDULED PAYMENT"
              inputName="sche_payment"
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              defaultValue={data?.sche_payment || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className="flex flex-row items-center gap-[2px]">
            <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
              <CommonDate
                label="START DATE"
                inputName="loan_start_date"
                defaultValue={data?.loan_start_date || null}
                onChangeHandler={({ ...changeProps }) => {
                  changeDate({ ...changeProps });
                }}
                disabled={!isEditable}
              />
            </div>
            <div className="w-[12px] h-[1px] border-[1px] border-solid border-[#D9D9D9]" />
            <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
              <CommonDate
                label="END DATE"
                inputName="loan_end_date"
                defaultValue={data?.loan_end_date || null}
                onChangeHandler={({ ...changeProps }) => {
                  changeDate({ ...changeProps });
                }}
                disabled={!isEditable}
              />
            </div>
          </div>
          <div className={`${STYLE_ITEM_WRAPPER} w-[143px]`}>
            <CommonTextInput
              label="EXTRA PAYMENT"
              inputName="extra_payment"
              defaultValue={data?.extra_payment || ''}
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'start',
                content: '$',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '700',
                },
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default LoanInfo;
