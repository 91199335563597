import {
  SAFETY_COMMON_CHS_TYPE_CATEGORY,
  SAFETY_COMMON_OTHER_TYPE_CATEGORY,
  SAFETY_COMMON_SUB_CATEGORY,
  SAFETY_COMMON_TRK_TYPE_CATEGORY,
  SAFETY_COMMON_TRL_TYPE_CATEGORY,
} from '../../../../constant/Safety/Common/Option/Category';
import { SAFETY_COMMON_TYPE } from '../../../../constant/Safety/Common/Option/Type';

export const convertMRType = type => {
  return SAFETY_COMMON_TYPE?.find(item => item?.value === type)?.label;
};

export const convertMRCategory = category => {
  /** 1. Check TRK related category */
  const trkCategory = SAFETY_COMMON_TRK_TYPE_CATEGORY.find(
    item => item?.value === category,
  );
  if (trkCategory) return trkCategory?.label;

  /** 2. Check CHS related category */
  const chsCategory = SAFETY_COMMON_CHS_TYPE_CATEGORY.find(
    item => item?.value === category,
  );
  if (chsCategory) return chsCategory?.label;

  /** 3. Check TRL related category */
  const trlCategory = SAFETY_COMMON_TRL_TYPE_CATEGORY.find(
    item => item?.value === category,
  );
  if (trlCategory) return trlCategory?.label;

  /** 4. Check OTHER related category */
  const otherCategory = SAFETY_COMMON_OTHER_TYPE_CATEGORY?.find(
    item => item?.value === category,
  );
  if (otherCategory) return otherCategory?.label;

  return null;
};

export const convertMRSubCategory = ({ type, category, subCategory }) => {
  const mrSubCategory = SAFETY_COMMON_SUB_CATEGORY?.[type]?.[category]?.find(
    item => item?.value === subCategory,
  );
  if (mrSubCategory) return mrSubCategory?.label;

  return null;
};
