import { createSlice } from '@reduxjs/toolkit';
import commonSliceKey from '../../Constant/Common/key';

const initialState = {
  allDiv: localStorage?.getItem('allDiv')?.split(',') || null,
  currDiv: localStorage?.getItem('currDiv') || null,
  divisionList: [],
  driverList: [],
  chassisSizeList: [],
  chassisTypeList: [],
  poolList: [],
  safetyTruck: [],
  sizeList: [],
  sslList: [],
  typeList: [],
  ymsYard: [],
};

const commonSlice = createSlice({
  name: commonSliceKey.all,
  initialState,
  reducers: {
    setCurrDiv: (state, action) => {
      const newCurrDiv = action?.payload;
      localStorage?.setItem('currDiv', newCurrDiv);
      return { ...state, currDiv: newCurrDiv };
    },
    setAllDiv: (state, action) => {
      const newAllDiv = action?.payload;
      return { ...state, allDiv: newAllDiv };
    },
    setDivisionList: (state, action) => {
      const newDivisionList = action?.payload;
      return { ...state, divisionList: newDivisionList };
    },
    setDriverList: (state, action) => {
      const newDriverList = action?.payload;
      return { ...state, driverList: newDriverList };
    },
    setChassisSizeList: (state, action) => {
      const newChassisSizeList = action?.payload;
      return { ...state, chassisSizeList: newChassisSizeList };
    },
    setChassisTypeList: (state, action) => {
      const newChassisTypeList = action?.payload;
      return { ...state, chassisTypeList: newChassisTypeList };
    },
    setPoolList: (state, action) => {
      const newPoolList = action?.payload;
      return { ...state, poolList: newPoolList };
    },
    setSafetyTruck: (state, action) => {
      const newSafetyTruck = action?.payload;
      return { ...state, safetyTruck: newSafetyTruck };
    },
    setSafetyChassis: (state, action) => {
      const newSafetyChassis = action?.payload;
      return { ...state, safetyChassis: newSafetyChassis };
    },
    setSizeList: (state, action) => {
      const newSizeList = action?.payload;
      return { ...state, sizeList: newSizeList };
    },
    setSSLList: (state, action) => {
      const newSSLList = action?.payload;
      return { ...state, sslList: newSSLList };
    },
    setTypeList: (state, action) => {
      const newTypeList = action?.payload;
      return { ...state, typeList: newTypeList };
    },
    setYmsYard: (state, action) => {
      const newYmsYard = action?.payload;
      return { ...state, ymsYard: newYmsYard };
    },
  },
});

export const {
  setCurrDiv,
  setAllDiv,
  setDivisionList,
  setDriverList,
  setChassisSizeList,
  setChassisTypeList,
  setPoolList,
  setSafetyTruck,
  setSafetyChassis,
  setSizeList,
  setSSLList,
  setTypeList,
  setYmsYard,
} = commonSlice?.actions;
export default commonSlice?.reducer;
