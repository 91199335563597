import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import inventoryDefaultAPI from '../../api';

const getSpecificEquipData = async ({ equip, seq }) => {
  try {
    const response = await inventoryDefaultAPI.get(`${equip}/${seq}`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getSpecificEquipData;
