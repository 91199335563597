import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';

const CustomHidden = ({ ...props }) => {
  const { control, unregister, setValue, ...formController } = useFormContext();

  const { styles, disabled, required, ...compState } = useControlComponentState(
    { ...props },
  );
  const { inputName, defaultValue } = useControlComponentData({
    ...props,
    type: 'file',
    setValue,
  });

  const [multiple, setMultiple] = useState(props?.multiple || false);
  useEffect(() => {
    /**
     * If default multiple value changed, component multiple value would be changed too.
     * Undefined, Null exception handling.
     */
    if (props?.multiple !== undefined && props?.multiple !== null) {
      setMultiple(props?.multiple);
    }
  }, [props?.multiple]);

  useEffect(() => {
    return () => unregister(inputName);
  }, []);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, value, ...field } }) => {
        const handlerProps = {
          onChange,
          value,
          inputName,
          ...field,
          ...props,
        };

        return (
          <input
            {...field}
            ref={ref}
            className="hidden"
            type="file"
            id={inputName}
            onChange={e => props?.onChange({ e, ...handlerProps, setValue })}
            multiple={multiple}
          />
        );
      }}
    />
  );
};

export default CustomHidden;
