import { useQuery } from '@tanstack/react-query';
import getGroup from '../../../../service/Admin/Auth/Get/getGroup';
import adminAuthKeys from '../keys';

const useGetGroup = () => {
  const {
    isLoading: isLoadingGroup,
    data,
    isRefetching: isRefetchingGroup,
    isError: isErrorGroup,
  } = useQuery({
    queryKey: adminAuthKeys?.group(),
    queryFn: getGroup,
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingGroup,
    data,
    isRefetchingGroup,
    isErrorGroup,
  };
};

export default useGetGroup;
