import {
  PATH_TOAST_ERROR_ICON,
  PATH_TOAST_SUCCESS_ICON,
  PATH_TOAST_WARNING_ICON,
} from '../Path/Asset/Toast';
import { TOAST_TYPE } from './toastTypes';

export const TOAST_ICON = {
  [TOAST_TYPE.SUCCESS]: {
    iconPath: PATH_TOAST_SUCCESS_ICON,
    width: 38,
    height: 29,
  },
  [TOAST_TYPE.ERROR]: {
    iconPath: PATH_TOAST_ERROR_ICON,
    width: 31,
    height: 21,
  },
  [TOAST_TYPE.WARNING]: {
    iconPath: PATH_TOAST_WARNING_ICON,
    width: 49,
    height: 65,
  },
};
