import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../../../App';
import APP_CHILDREN_ROUTE from './App';
import SignIn from '../../../page/SignIn';
import NotFound from '../../../page/Error/NotFound';
import Labs from '../../../page/Labs';
import LABS_CHILDREN_ROUTE from './Labs';
import Error from '../../../page/Error';
import ERROR_CHILDREN_ROUTE from './Error';
import ErrorBoundary from '../../../page/Error/ErrorBoundary';
import AuthRouter from '../../../component/Common/Router/Common/AuthRouter';
import { PERM_ALL } from '../Permission';

/**
 * @description
 * This is the main router of the application.
 */
const ROUTER = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthRouter>
        <App />
      </AuthRouter>
    ),
    children: [...APP_CHILDREN_ROUTE],
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'sign-in',
    element: <SignIn />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'labs',
    element: <Labs />,
    children: [...LABS_CHILDREN_ROUTE],
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'error',
    element: <Error />,
    children: [...ERROR_CHILDREN_ROUTE],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default ROUTER;
