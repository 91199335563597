/**
 * @description
 * Path of logo used in the application.
 */

/** Toast Icon */
export const PATH_TOAST_SUCCESS_ICON =
  '/asset/Icon/Toast/toastSuccessIcon.svg ';
export const PATH_TOAST_ERROR_ICON = '/asset/Icon/Toast/toastErrorIcon.svg';
export const PATH_TOAST_WARNING_ICON = '/asset/Icon/Toast/toastWarningIcon.svg';
