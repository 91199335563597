import CommonTextInput from '../../../../component/Common/Controller/Text';
import CommonAutoComplete from '../../../../component/Common/Controller/AutoComplete';
import CommonSelect from '../../../../component/Common/Controller/Select';
import CommonDate from '../../../../component/Common/Controller/Date';
import CommonTime from '../../../../component/Common/Controller/Time';
import CommonTextarea from '../../../../component/Common/Controller/Textarea';
import CommonFile from '../../../../component/Common/Controller/File';
import CommonCheckbox from '../../../../component/Common/Controller/Checkbox';
import CommonToggle from '../../../../component/Common/Controller/Toggle';
import CommonRadioInput from '../../../../component/Common/Controller/Radio';
import CommonMultipleInput from '../../../../component/Common/Controller/MultipleInput';
import CommonTextSelect from '../../../../component/Common/Controller/TextSelect';
import CustomTextInput from '../../../../component/Common/Controller/Text/Custom';
import CustomAutoComplete from '../../../../component/Common/Controller/AutoComplete/Custom';
import CustomSelect from '../../../../component/Common/Controller/Select/Custom';
import CustomDate from '../../../../component/Common/Controller/Date/Custom';
import CustomTime from '../../../../component/Common/Controller/Time/Custom';
import CustomTextarea from '../../../../component/Common/Controller/Textarea/Custom';
import CommonPhoneNumber from '../../../../component/Common/Controller/PhoneNumber';

const createController = ({ type }) => {
  if (type === 'text') return CommonTextInput;
  if (type === 'textAdornmentStart') return CommonTextInput;
  if (type === 'textAdornmentEnd') return CommonTextInput;
  if (type === 'autoComplete') return CommonAutoComplete;
  if (type === 'select') return CommonSelect;
  if (type === 'date') return CommonDate;
  if (type === 'time') return CommonTime;
  if (type === 'textarea') return CommonTextarea;
  if (type === 'file') return CommonFile;
  if (type === 'checkbox') return CommonCheckbox;
  if (type === 'toggle') return CommonToggle;
  if (type === 'radio') return CommonRadioInput;
  if (type === 'multipleInput') return CommonMultipleInput;
  if (type === 'textSelect') return CommonTextSelect;
  if (type === 'phoneNumber') return CommonPhoneNumber;
  // Custom
  if (type === 'customText') return CustomTextInput;
  if (type === 'customAutoComplete') return CustomAutoComplete;
  if (type === 'customSelect') return CustomSelect;
  if (type === 'customDate') return CustomDate;
  if (type === 'customTime') return CustomTime;
  if (type === 'customTextarea') return CustomTextarea;
  return null;
};

export default createController;
