import React from 'react';
import StyledCheckBoxArea from '../../../../style/Safety/DamageReport/StyledCheckBoxArea';
import { CHASSIS_DAMAGE_CHECKBOX_OPTION_LIST } from '../../../../constant/Safety/DamageReport/options';
import CommonDamageCheckBox from '../CommonDamageCheckBox';
import CustomTextInput from '../../../Common/Controller/Text/Custom';
import { handleInputChange } from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';
import {
  OTHER_INPUT_STYLE,
  STYLE_CHASSIS_STATUS_CHECKBOX_AREA,
} from '../../../../style/Safety/DamageReport/styles';

const ChassisDamagedInfoFields = ({
  isCompleted,
  damageReport,
  isEditable,
}) => {
  return (
    <div className="w-full mt-[-6px]">
      <StyledCheckBoxArea styles={{ ...STYLE_CHASSIS_STATUS_CHECKBOX_AREA }}>
        <CommonDamageCheckBox
          options={CHASSIS_DAMAGE_CHECKBOX_OPTION_LIST}
          disabled={isCompleted || !isEditable}
        />
        <div className="flex mt-[11px] items-center gap-x-[7px]">
          <div className="font-[400] text-[12px] text-[#494949]">Other</div>
          <div>
            <CustomTextInput
              inputName="ch_others"
              maxLength={120}
              suppressLabelVisible
              suppressLabelContentVisible
              suppressErrorVisible
              suppressErrorContentVisible
              disabled={isCompleted || !isEditable}
              styles={OTHER_INPUT_STYLE}
              onChange={handleInputChange}
              defaultValue={damageReport?.ch_others || ''}
            />
          </div>
        </div>
      </StyledCheckBoxArea>
    </div>
  );
};

export default ChassisDamagedInfoFields;
