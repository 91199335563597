import React from 'react';
import StyledSubMenuNavigatorWrapper from '../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorWrapper';
import StyledSubMenuNavigatorHeader from '../../../style/Common/Router/App/Common/SubMenuNavigator/StyledSubMenuNavigatorHeader';
import { PAGE_NAME_MAPPER } from '../../../constant/Common/Router/App/Common/mapper';

const PageHeader = ({ page }) => {
  return (
    <StyledSubMenuNavigatorWrapper className="h-[80px]">
      <StyledSubMenuNavigatorHeader>
        {PAGE_NAME_MAPPER?.[page]}
      </StyledSubMenuNavigatorHeader>
    </StyledSubMenuNavigatorWrapper>
  );
};

export default PageHeader;
