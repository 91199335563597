import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import { SWITCH_OPTION_ACTIVE } from '../../../../constant/Common/Option/Switch';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createVANColumnDefs = ({ mutatePatchActive, ...props }) => {
  const sizeList = useSelector(state => state?.common?.sizeList);
  const typeList = useSelector(state => state?.common?.typeList);

  /** Filter item */
  const { filterDivisionList, isEditable, disabledRef } = props;

  const safetyVANColumn = [
    {
      headerName: 'DIV',
      field: 'div',
      editable: false,
      width: 38,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'SZ',
      field: 'size',
      editable: false,
      width: 42,
      valueFormatter: params => {
        return sizeList.find(element => element?.size === params?.value)?.size;
      },
      valueGetter: params => {
        return sizeList.find(element => element?.size === params?.data?.size)
          ?.size;
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TYPE',
      field: 'type',
      editable: false,
      width: 77,
      valueFormatter: params => {
        return typeList.find(element => element?.type === params?.value)?.type;
      },
      valueGetter: params => {
        return typeList.find(element => element?.type === params?.data?.type)
          ?.type;
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TRL #',
      field: 'trailer_no',
      editable: false,
      width: 87,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'MAKE',
      field: 'trailer_make',
      editable: false,
      width: 105,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'YEAR',
      field: 'make_year',
      editable: false,
      width: 50,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'VIN #',
      field: 'vin_no',
      editable: false,
      width: 160,
    },
    {
      headerName: 'PLATE #',
      field: 'plate_no',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'ST',
      field: 'state',
      editable: false,
      width: 35,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'ASSET TRACKER #',
      field: 'asset_tracker_no',
      editable: false,
      width: 127,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'REG EXP DATE',
      field: 'reg_exp_date',
      editable: false,
      width: 106,
      valueFormatter: params => {
        const data = params?.data?.reg_exp_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      valueGetter: params => {
        const data = params?.data?.reg_exp_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
  ];

  if (isEditable)
    safetyVANColumn?.push({
      headerName: 'ACTIVE',
      field: 'active',
      editable: false,
      clickable: true,
      width: 75,
      valueFormatter: params => params.value,
      valueGetter: params => {
        return params?.data?.active ? 'Active' : 'Inactive';
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.active,
          onChangeHandler: ({ ...params }) => {
            if (
              disabledRef?.current?.disabled &&
              disabledRef?.current?.disabledRow === gridParam?.rowIndex
            ) {
              return;
            }
            disabledRef.current.disabled = true;
            disabledRef.current.disabledRow = gridParam?.rowIndex;

            const { data } = gridParam;
            const { e, setChecked } = params;

            setChecked(!!e?.target?.checked);
            mutatePatchActive?.({ seq: data?.seq, setChecked });
          },
        }),
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: SWITCH_OPTION_ACTIVE,
      },
    });

  return safetyVANColumn;
};

export default createVANColumnDefs;
