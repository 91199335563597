import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonCheckboxList from '../../Common/Controller/CheckboxList';
import CommonButton from '../../Common/Controller/Button';
import FilterNavigator from '../FilterNavigator';
import StyledUtilityBarContainer from '../../../style/Inventory/StyledUtilityBarContainer';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import handleAllCheckboxChange from '../../../util/Inventory/Common/Handler/handleAllCheckboxChange';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_GREEN,
  PRESET_GREY_TRANSPARENT,
} from '../../../constant/Common/Style/preset';

const UtilityBar = ({
  isYardFilterOpen,
  setYardFilterOpen,
  yardFilterOption,
  setYardQuery,
  gridRef,
  fileName,
  ...props
}) => {
  const yardController = useForm();

  return (
    <StyledUtilityBarContainer>
      <FilterNavigator {...props} />
      <div className="utility-button-field">
        <FormProvider {...yardController}>
          <CommonCheckboxList
            isOpen={isYardFilterOpen}
            options={yardFilterOption}
            onChangeAll={({ ...handleProps }) => {
              handleAllCheckboxChange({
                ...handleProps,
                setQuery: setYardQuery,
              });
            }}
            suppressIndeterminate
            button={
              <CommonButton
                styles={PRESET_GREY_TRANSPARENT}
                icon={PATH_COMMON_BUTTON_ICON.customizeGrey}
                onClick={() => setYardFilterOpen(prev => !prev)}
              >
                Yard
              </CommonButton>
            }
          />
        </FormProvider>
        <CommonButton
          styles={PRESET_GREEN}
          icon={PATH_COMMON_BUTTON_ICON.excelGreen}
          onClick={() => exportToExcel({ gridRef, fileName })}
        >
          EXCEL
        </CommonButton>
      </div>
    </StyledUtilityBarContainer>
  );
};

export default UtilityBar;
