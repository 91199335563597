export const SAFETY_COMMON_DIVISION = [
  {
    seq: 0,
    label: 'PHX',
    value: 'PHX',
  },
  {
    seq: 1,
    label: 'LA',
    value: 'LA',
  },
  {
    seq: 2,
    label: 'HOU',
    value: 'HOU',
  },
  {
    seq: 3,
    label: 'SAV',
    value: 'SAV',
  },
  {
    seq: 4,
    label: 'SD',
    value: 'SD',
  },
];
