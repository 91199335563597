import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../../../../constant/Common/Style/color';

const StyledDivisionSelectorLabelContent = styled.div`
  font-size: 14px;
  font-weight: bolder;
  color: ${PRIMARY_COLOR};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 23px;
`;

export default StyledDivisionSelectorLabelContent;
