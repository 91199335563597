import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDefaultAPI from '../../api';

const patchGroup = async ({ id, data }) => {
  try {
    const response = await adminDefaultAPI.patch(`group/${id}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchGroup;
