import React from 'react';
import { Outlet } from 'react-router-dom';
import PageHeader from '../../component/Inventory/PageHeader';
import { PAGE_INVENTORY } from '../../constant/Common/Router/App/Common/mapper';

const Inventory = () => {
  return (
    <div className="w-full flex flex-col">
      <PageHeader page={PAGE_INVENTORY} />
      <Outlet />
    </div>
  );
};

export default Inventory;
