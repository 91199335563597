import React, { useEffect } from 'react';
import StyledCommonBlurWrapper from '../../../../style/Common/Controller/Blur/StyledCommonBlurWrapper';

/**
 * @description
 * Common blur component.
 * @param {children} children
 * Children component.
 * @param {boolean} isOpen
 * Blur open state.
 * @returns {React.ReactElement}
 */
const CommonBlur = ({ children, isOpen }) => {
  useEffect(() => {
    if (document.activeElement) {
      document.activeElement.blur();
    }
  }, []);

  return (
    isOpen && (
      <StyledCommonBlurWrapper
        // className="backdrop-blur-sm"
        id="commonBlur"
      >
        {children}
      </StyledCommonBlurWrapper>
    )
  );
};

export default CommonBlur;
