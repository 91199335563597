import styled from 'styled-components';

const StyledCommonMessageModalFrameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  background-color: white;

  width: ${props => props?.styles?.width || 'fit-content'};
  min-width: ${props => props?.styles?.width || '400px'};
  height: ${props => props?.styles?.height || 'fit-content'};
  max-height: ${props => props?.styles?.maxHeight || '90%'};
  min-height: ${props => props?.styles?.minHeight || '180px'};

  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;

  font-size: 14px;
`;

export default StyledCommonMessageModalFrameWrapper;
