import TruckInfo from '../../../../component/Inventory/Modal/EditModal/ModalContent/TruckInfo';
import ContainerInfo from '../../../../component/Inventory/Modal/EditModal/ModalContent/ContainerInfo';
import ChassisInfo from '../../../../component/Inventory/Modal/EditModal/ModalContent/ChassisInfo';
import TrailerInfo from '../../../../component/Inventory/Modal/EditModal/ModalContent/TrailerInfo';

const createEquipField = ({ type }) => {
  if (type === 'truck') return TruckInfo;
  if (type === 'container') return ContainerInfo;
  if (type === 'chassis') return ChassisInfo;
  if (type === 'trailer') return TrailerInfo;
  return null;
};

export default createEquipField;
