import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import {
  CARRIER_OPTION,
  STATUS_OPTION,
} from '../../../../constant/Safety/ITTablet';
import {
  STYLE_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/Safety/ITTablet/StyledAddEditModalWrapper/style';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete/index';
import CommonButton from '../../../Common/Controller/Button';
import CommonDate from '../../../Common/Controller/Date';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonTextarea from '../../../Common/Controller/Textarea';
import CommonToggle from '../../../Common/Controller/Toggle/index';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame/index';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const AddEditModal = ({
  isAddEditModalOpen,
  setIsAddEditModalOpen,
  isAdd,
  postITTabletMutation,
  driverOptions,
  divOptions,
  selectedITTablet,
  setSelectedITTablet,
  patchITTabletMutation,
  isEditable,
}) => {
  const addEditModalController = useForm();

  const saveButtonRef = useRef(null);

  // reset form when modal is closed
  useEffect(() => {
    if (!isAddEditModalOpen) {
      addEditModalController.reset();
      setSelectedITTablet();
    }
  }, [isAddEditModalOpen, addEditModalController.reset]);

  const handleSubmit = data => {
    updateButtonDisabled({ ref: saveButtonRef, disabled: true });
    if (isAdd) {
      postITTabletMutation({ data, buttonRef: saveButtonRef });
      return;
    }
    patchITTabletMutation({
      seq: selectedITTablet?.seq,
      data,
      buttonRef: saveButtonRef,
    });
  };

  return (
    <CommonMessageModalFrame
      isOpen={isAddEditModalOpen}
      onCloseButtonClick={() => {
        addEditModalController.reset();
        setIsAddEditModalOpen(false);
      }}
      header={isAdd ? 'Add Tablet' : 'Edit Tablet'}
      content={
        <FormProvider {...addEditModalController}>
          <form>
            <div className="w-full h-[342px] bg-[#f9f9f9] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[10px]">
              {/* Header */}
              <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold">
                Basic Info
              </div>
              {/* First row */}
              <div className={STYLE_EACH_ROW_WRAPPER}>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="TABLET ID"
                      inputName="tablet_id"
                      defaultValue={selectedITTablet?.tablet_id}
                      disabled
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonSelect
                      label="DIV"
                      inputName="div"
                      required
                      option={divOptions}
                      defaultValue={selectedITTablet?.div}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="IMEI"
                      inputName="imei"
                      required
                      maxLength={20}
                      defaultValue={selectedITTablet?.imei}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="CREATED BY"
                      inputName="created_by"
                      defaultValue={selectedITTablet?.created_by}
                      disabled
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonDate
                      label=""
                      inputName="createdDate"
                      defaultValue={selectedITTablet?.created_date}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* Second row */}
              <div className={STYLE_EACH_ROW_WRAPPER}>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="MAKE"
                      inputName="make"
                      required
                      maxLength={10}
                      defaultValue={selectedITTablet?.make}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonAutoComplete
                      label="DRIVER"
                      inputName="driver"
                      option={driverOptions}
                      defaultValue={selectedITTablet?.driver}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange, selectProps }) => {
                        onChange?.(selectProps?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonSelect
                      label="CARRIER"
                      inputName="carrier"
                      required
                      option={CARRIER_OPTION}
                      defaultValue={selectedITTablet?.carrier}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="UPDATED BY"
                      inputName="updatedBy"
                      defaultValue={selectedITTablet?.updated_by}
                      disabled
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonDate
                      label=""
                      inputName="updatedDate"
                      defaultValue={selectedITTablet?.updated_date}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* Third row */}
              <div className={STYLE_EACH_ROW_WRAPPER}>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="MODEL"
                      inputName="model"
                      required
                      maxLength={20}
                      defaultValue={selectedITTablet?.model}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonSelect
                      label="STATUS"
                      inputName="status"
                      required
                      option={STATUS_OPTION}
                      defaultValue={selectedITTablet?.status}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="SIM #"
                      inputName="sim_no"
                      maxLength={20}
                      defaultValue={selectedITTablet?.sim_no}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonTextInput
                      label="CELL #"
                      inputName="cell_no"
                      maxLength={20}
                      defaultValue={selectedITTablet?.cell_no}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className={STYLE_ITEM_WRAPPER}>
                    <CommonToggle
                      label="ACTIVE"
                      options={[
                        {
                          label: 'Active',
                          defaultValue: selectedITTablet
                            ? selectedITTablet.active
                            : true,
                          inputName: 'active',
                        },
                      ]}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* Fourth row */}
              <div className={STYLE_EACH_ROW_WRAPPER}>
                <div className={STYLE_CONTENT_WRAPPER}>
                  <div className={`${STYLE_ITEM_WRAPPER} w-[623px]`}>
                    <CommonTextarea
                      label="REMARKS"
                      inputName="remarks"
                      maxLength={120}
                      defaultValue={selectedITTablet?.remarks}
                      disabled={!isEditable}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      }
      button={
        <div className="flex justify-center space-x-[18px]">
          {isEditable && (
            <>
              <div>
                <CommonButton
                  icon={PATH_COMMON_BUTTON_ICON.clearRed}
                  styles={{
                    hoverBackgroundColor: '#B74747',
                    border: '1px solid #B74747',
                    hoverBorder: '1px solid #B74747',
                    hoverColor: '#fff',
                    color: '#B74747',
                  }}
                  onClick={() => {
                    addEditModalController.reset();
                  }}
                >
                  Clear
                </CommonButton>
              </div>
              <div>
                <CommonButton
                  ref={saveButtonRef}
                  type="submit"
                  icon={PATH_COMMON_BUTTON_ICON.saveBlue}
                  styles={{
                    hoverBackgroundColor: '#556DA1',
                    border: '1px solid #556DA1',
                    hoverBorder: '1px solid #556DA1',
                    hoverColor: '#fff',
                    color: '#556DA1',
                  }}
                  onClick={addEditModalController.handleSubmit(handleSubmit)}
                >
                  Save
                </CommonButton>
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default AddEditModal;
