const clickGridRow = ({ setSpecificSeq, setModalOpen, ...props }) => {
  const { gridParam, preventTargetColumnID } = props;
  const getColID = gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (!preventTargetColumnID?.includes(getColID)) {
    setSpecificSeq?.(props?.gridParam?.data?.seq);
    setModalOpen?.(prev => true);
  }
};

export default clickGridRow;
