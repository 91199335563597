import React from 'react';
import CommonButton from '../../Common/Controller/Button';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';

const SearchButton = ({ setIsSearchModalOpen }) => {
  return (
    <CommonButton
      icon={PATH_COMMON_BUTTON_ICON.searchGrey}
      styles={{
        hoverBackgroundColor: '#666666',
        hoverColor: '#fff',
        color: '#666666',
      }}
      onClick={() => {
        setIsSearchModalOpen(true);
      }}
    >
      Search
    </CommonButton>
  );
};

export default SearchButton;
