import styled from 'styled-components';

const StyledSidebarWrapper = styled.div`
  height: 100%;
  width: 67px;
  background-color: rgba(242, 244, 248);

  position: fixed;
  left: 0;

  transition-duration: 300ms;
  overflow-x: hidden;

  border-right: 1px solid rgba(0, 0, 0, 0.1);

  padding-top: 56px;

  /* Do not fix this value */
  z-index: 9997;
`;

export default StyledSidebarWrapper;
