import React, { useEffect, useState } from 'react';
import CommonLoading from '../../component/Common/Loading';
import CommonAlert from '../../component/Common/Modal/Alert';
import GoogleLoginButton from '../../component/SignIn/GoogleLoginButton';
import {
  PATH_SIGN_IN_BACKGROUND,
  PATH_SIGN_IN_LOGO,
} from '../../constant/Common/Path/Asset/Image/SignIn';
import { REDIRECT_URI } from '../../constant/SignIn/elements';
import useLoginMutation from '../../hook/SignIn/useLoginMutation';
import StyledSignInBackgroundImageWrapper from '../../style/SignIn/StyledSignInBackgroundImageWrapper';
import StyledSignInLoginButtonPartialWrapper from '../../style/SignIn/StyledSignInLoginButtonParticialWrapper';
import StyledSignInWrapper from '../../style/SignIn/StyledSignInWrapper';

const SignIn = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState();
  const [code, setCode] = useState('');

  const { mutate: loginMutation, isPending } = useLoginMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
  });

  useEffect(() => {
    const image = new Image();
    image.src = PATH_SIGN_IN_BACKGROUND;
    image.onload = () => {
      setIsImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    if (code) {
      loginMutation({
        authorization_code: code,
        redirect_uri: REDIRECT_URI,
      });
    }
  }, [code]);

  return (
    <>
      <CommonLoading isOpen={isPending} />
      <CommonAlert
        isOpen={isMessageOpen}
        setOpen={setIsMessageOpen}
        content={message}
        status={messageStatus}
        onOKButtonClick={() => {
          setIsMessageOpen(false);
        }}
      />
      <StyledSignInWrapper>
        {isImageLoaded && (
          <StyledSignInBackgroundImageWrapper>
            <StyledSignInLoginButtonPartialWrapper>
              <img
                width="112px"
                height="33px"
                src={PATH_SIGN_IN_LOGO}
                alt="logo"
              />
              <div data-testid="signInButton">
                <GoogleLoginButton setCode={setCode} />
              </div>
            </StyledSignInLoginButtonPartialWrapper>
          </StyledSignInBackgroundImageWrapper>
        )}
      </StyledSignInWrapper>
    </>
  );
};

export default SignIn;
