import ButtonCellRenderer from '../../../../component/Common/Datagrid/Component/ButtonCellRenderer';
import NormalCellRenderer from '../../../../component/Common/Datagrid/Component/NormalCellRenderer';
import SwitchCellRenderer from '../../../../component/Common/Datagrid/Component/SwitchCellRenderer';
import {
  CELL_TYPE_BUTTON,
  CELL_TYPE_GROUP,
  CELL_TYPE_SWITCH,
} from '../../../../constant/Common/DataGrid/cellType';

const generateCellRendererWithCellType = ({ cellType, ...props }) => {
  if (cellType === CELL_TYPE_BUTTON) return ButtonCellRenderer;
  if (cellType === CELL_TYPE_SWITCH) return SwitchCellRenderer;
  if (cellType === CELL_TYPE_GROUP) return 'agGroupCellRenderer';
  return NormalCellRenderer;
};

export default generateCellRendererWithCellType;
