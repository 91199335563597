export const STYLE_EACH_ROW_WRAPPER =
  'w-full h-fit flex items-center flex-row gap-[25px]';
export const STYLE_ROW_FIRST_CONTENT =
  'w-fit h-full flex justify-center items-center flex-row gap-[16px]';
export const STYLE_ITEM_WRAPPER =
  'w-[106px] flex justify-center items-center flex-col';

export const STYLE_COLUMN_ALIGNMENT = {
  number: {
    textAlign: 'right',
  },
  date: {
    textAlign: 'left',
  },
  button: {
    textAlign: 'center',
  },
};
