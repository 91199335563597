import React, { useState } from 'react';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button/index';
import PhotoBox from '../PhotoBox';
import CustomHidden from '../../../Common/Controller/File/CustomHidden';
import handleFileChange from '../../../../util/Safety/DamageReport/Handler/handleFileChange';
import {
  STYLE_CHECK_BOX_COLUMN_WRAPPER,
  STYLE_CHECK_BOX_HEADER_WRAPPER,
} from '../../../../style/Safety/DamageReport/styles';

const PhotoInfoFields = ({
  setAlertOptions,
  imageFileList,
  setImageFileList,
  isCompleted,
  fileKey,
  setFileKey,
  isEditable,
}) => {
  return (
    <>
      <div className={STYLE_CHECK_BOX_COLUMN_WRAPPER}>
        <div className={STYLE_CHECK_BOX_HEADER_WRAPPER}>PHOTO</div>
        <div className="w-full h-[160px] flex flex-col mt-[9px]">
          <div className="flex w-full justify-start gap-x-[13px] ">
            <div
              className={`flex flex-col pt-[2px] items-center justify-center w-[60px] h-[74px] border border-[#DDDDDD] border-dashed  ${isCompleted || !isEditable ? 'hover:cursor-auto' : 'hover:cursor-pointer'}`}
              onClick={() => {
                if (!isCompleted && isEditable) {
                  const inputElement =
                    document.getElementsByName('damageImageFile')[0];
                  inputElement.click();

                  inputElement.value = null;
                }
              }}
            >
              <img
                src={PATH_COMMON_BUTTON_ICON?.uploadFile?.default}
                alt="upload"
                width={18}
                height={18}
              />
              <span className="font-[500] text-[12px] text-[#556DA1] mt-[9px]">
                Photo
              </span>
            </div>
            <div className="w-[291px] flex flex-wrap gap-y-[8px] gap-x-[12px]">
              {imageFileList &&
                imageFileList?.length > 0 &&
                imageFileList?.map((file, index) => (
                  <PhotoBox
                    setImageFileList={setImageFileList}
                    key={file?.fileKey}
                    index={index}
                    file={file?.fileInfo}
                    isCompleted={isCompleted}
                    isEditable={isEditable}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <CustomHidden
        inputName="damageImageFile"
        multiple
        onChange={props =>
          handleFileChange({
            imageFileList,
            setImageFileList,
            setAlertOptions,
            fileKey,
            setFileKey,
            ...props,
          })
        }
      />
    </>
  );
};

export default PhotoInfoFields;
