export const generateInitialDivisionList = ({ divList, userInfo }) => {
  const divisionList = divList?.map(div => ({
    name: div?.div,
    value: userInfo?.userdivision_set?.[div?.div] || false,
  }));

  return divisionList;
};

export const generateSelectedDivisionList = ({
  selectedDivList,
  getValues,
}) => {
  const divisionList = [...selectedDivList].map(div => ({
    ...div,
    value: getValues(div?.name),
  }));

  return divisionList;
};
