import styled from 'styled-components';

const StyledNavBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 7px;

  .nav-header {
    color: ${props => (props.equip === props.name ? '#001e5f' : '#5c5c5c')};
  }

  .nav-button {
    width: 121px;
    height: 53px;
    padding: 10px 10px 11px 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: ${props => (props.equip === props.name ? 'white' : 'inherit')};

    box-shadow: 0px 0px 5px 0 #00000029;
    outline: 1px solid
      ${props => (props.equip === props.name ? '#001e5f' : 'white')};
    background-color: ${props =>
      props.equip === props.name ? '#001e5f' : '#f9f9f9'};

    &:not(:disabled):hover {
      outline: 1px solid #001e5f;
    }

    &:not(:disabled):focus {
      color: white;
      background-color: #001e5f;
    }
  }
`;

export default StyledNavBoxContainer;
