import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const postDailyInOut = async ({ data, div }) => {
  try {
    const response = await dailyInOutDefaultAPI.post(``, {
      div,
      driver: data?.driver,
      yard: data?.yard,
      wo_div: data?.wo_div,
      category: data?.category,
      remarks: data?.remarks,
      in_out_type: data?.in_out_type,
      cont_no: data?.cont_no,
      cont_size: data?.cont_size,
      cont_type: data?.cont_type,
      cont_ssl: data?.cont_ssl,
      cont_seal: data?.cont_seal,
      cont_color: data?.cont_color,
      cont_yard_location: data?.cont_yard_location,
      cont_remarks: data?.cont_remarks,
      trailer_size: data?.trailer_size,
      trailer_type: data?.trailer_type,
      truck_no: data?.truck_no,
      truck_dot_no: data?.truck_dot_no,
      truck_plate_no: data?.truck_plate_no,
      truck_state: data?.truck_state,
      truck_company: data?.truck_company,
      truck_color: data?.truck_color,
      truck_yard_location: data?.truck_yard_location,
      truck_remarks: data?.truck_remarks,
      trailer_no: data?.trailer_no,
      trailer_seal: data?.trailer_seal,
      trailer_color: data?.trailer_color,
      trailer_yard_location: data?.trailer_yard_location,
      trailer_remarks: data?.trailer_remarks,
      ch_no: data?.ch_no,
      ch_pool: data?.ch_pool,
      ch_type: data?.ch_type,
      ch_plate_no: data?.ch_plate_no,
      ch_state: data?.ch_state,
      ch_bare: data?.bare,
      ch_color: data?.ch_color,
      ch_yard_location: data?.ch_yard_location,
      ch_remarks: data?.ch_remarks,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postDailyInOut;
