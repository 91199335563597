import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_DELETE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import deleteYMSYard from '../../../../service/Admin/DivConf/Delete/deleteYMSYard';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminDivConfKeys from '../keys';

const useDeleteYMSYardMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  div,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: deleteYMSYard,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      addToast?.({ message: SUCCESS_DELETE });
    },
    onError: error => {
      queryClient.refetchQueries({
        queryKey: adminDivConfKeys?.ymsYard({ div }),
      });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default useDeleteYMSYardMutation;
