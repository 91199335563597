import React, { useEffect, useState } from 'react';
import TruckBasicInfoFields from '../TruckBasicInfoFields';
import TruckDamagedInfoFields from '../TruckDamagedInfoFields';
import EquipmentInfoFields from '../EquipmentInfoFields';
import ChassisBasicInfoFields from '../ChassisBasicInfoFields';
import ChassisDamagedInfoFields from '../ChassisDamagedInfoFields';
import LocationBasicInfoFields from '../LocationBasicInfoFields';
import SteerTiresInfoFields from '../SteerTiresInfoFields';
import DriveTiresInfoFields from '../DriveTiresInfoFields';
import TrailerChassisTiresInfoFields from '../TrailerChassisTiresInfoFields';
import PhotoInfoFields from '../PhotoInfoFields';
import DamageFormModalButton from '../DamageFormModalButton';

const DamageFormModalContent = ({
  modalType,
  setAlertOptions,
  isCompleted,
  damageReport,
  optionList,
  isEditable,
  imageFileList,
  setImageFileList,
  fileKey,
  setFileKey,
}) => {
  const {
    driverOptions,
    yardOptions,
    truckOptions,
    chassisOptions,
    divOptions,
  } = optionList;

  return (
    <div className="w-full h-full mt-[8px] px-[10px]">
      <div className="flex h-[783px] border-t-2 border-[#F5F5F5]">
        <div className="w-[479px] h-full border-r-2 border-[#F5F5F5]">
          <div className="flex flex-col pt-[23px] pb-[58px] w-full h-full pr-[24px]">
            <TruckBasicInfoFields
              driverOptions={driverOptions}
              truckOptions={truckOptions}
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <TruckDamagedInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <EquipmentInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <ChassisBasicInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              chassisOptions={chassisOptions}
              isEditable={isEditable}
            />
            <ChassisDamagedInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
          </div>
        </div>
        <div className="w-[383px] h-full">
          <div className="flex flex-col pt-[23px] pb-[24px] w-full h-full pl-[29px] gap-y-[16px]">
            <LocationBasicInfoFields
              yardOptions={yardOptions}
              modalType={modalType}
              isCompleted={isCompleted}
              damageReport={damageReport}
              divOptions={divOptions}
              isEditable={isEditable}
            />
            <SteerTiresInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <DriveTiresInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <TrailerChassisTiresInfoFields
              isCompleted={isCompleted}
              damageReport={damageReport}
              isEditable={isEditable}
            />
            <PhotoInfoFields
              setAlertOptions={setAlertOptions}
              imageFileList={imageFileList}
              setImageFileList={setImageFileList}
              isCompleted={isCompleted}
              damageReport={damageReport}
              fileKey={fileKey}
              setFileKey={setFileKey}
              isEditable={isEditable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DamageFormModalContent;
