import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import useControlComponentData from '../../../../../hook/Common/Controller/useControlComponentData';
import useControlComponentState from '../../../../../hook/Common/Controller/useControlComponentState';
import StyledCustomControllerWrapper from '../../../../../style/Common/Controller/Common/Custom/StyledCustomControllerWrapper';
import StyledCustomSelect from '../../../../../style/Common/Controller/Common/Select/Custom/StyledCustomSelect';
import ComponentController from '../../Common/ComponentController';
import ControllerError from '../../Common/Error';
import ControllerLabel from '../../Common/Label';

const CustomSelect = ({ ...props }) => {
  const {
    setValue,
    formState: { errors },
    unregister,
    clearErrors,
  } = useFormContext();

  const { styles, disabled, required, ...compState } = useControlComponentState(
    {
      ...props,
    },
  );

  const {
    inputName,
    defaultValue,
    label,
    suppressLabelVisible,
    suppressLabelContentVisible,
    suppressErrorVisible,
    suppressErrorContentVisible,
    ...compData
  } = useControlComponentData({
    ...props,
    type: 'select',
    option: compState?.option,
    setValue,
  });

  useEffect(() => {
    return () => {
      unregister(inputName);
    };
  }, []);

  // Clear error when required is false
  useEffect(() => {
    if (!required) {
      clearErrors(props?.inputName);
    }
  }, [required]);

  return (
    <>
      <StyledCustomControllerWrapper>
        <ControllerLabel
          label={label}
          required={required}
          suppressLabelVisible={suppressLabelVisible}
          suppressLabelContentVisible={suppressLabelContentVisible}
        />
        <ComponentController
          Component={StyledCustomSelect}
          inputName={inputName}
          defaultValue={defaultValue}
          componentProps={{
            styles,
            disabled,
            required,
            MenuProps: {
              disableScrollLock: true,
              style: { zIndex: 9999 },
            },
            IconComponent: ExpandMoreIcon,
            componentChildren: compState?.option?.map((item, index) => (
              <MenuItem
                key={String(index + 1)}
                value={item?.value}
                className="text-xs"
              >
                {item?.label}
              </MenuItem>
            )),
            renderValue: props?.renderValue,
            onChange: props?.onChange,
            onClick: props?.onClick,
            onKeyDown: props?.onKeyDown,
            onBlur: props?.onBlur,
          }}
        />
      </StyledCustomControllerWrapper>
      <ControllerError
        errors={errors}
        inputName={inputName}
        suppressErrorVisible={suppressErrorVisible}
        suppressErrorContentVisible={suppressErrorContentVisible}
      />
    </>
  );
};

export default CustomSelect;
