import React from 'react';
import StyledCheckBoxArea from '../../../../style/Safety/DamageReport/StyledCheckBoxArea';
import CommonDamageCheckBox from '../CommonDamageCheckBox';
import { DRIVER_TIRES_CHECKBOX_OPTION_LIST } from '../../../../constant/Safety/DamageReport/options';
import {
  STYLE_CHECK_BOX_COLUMN_WRAPPER,
  STYLE_CHECK_BOX_HEADER_WRAPPER,
  STYLE_DRIVER_TIRES_CHECKBOX_AREA,
} from '../../../../style/Safety/DamageReport/styles';

const DriveTiresInfoFields = ({ isCompleted, damageReport, isEditable }) => {
  return (
    <div className={STYLE_CHECK_BOX_COLUMN_WRAPPER}>
      <div className={STYLE_CHECK_BOX_HEADER_WRAPPER}>DRIVER TIRES</div>
      <div className="w-full">
        <StyledCheckBoxArea styles={{ ...STYLE_DRIVER_TIRES_CHECKBOX_AREA }}>
          <div className="w-full">
            <CommonDamageCheckBox
              options={DRIVER_TIRES_CHECKBOX_OPTION_LIST}
              disabled={isCompleted || !isEditable}
            />
          </div>
        </StyledCheckBoxArea>
      </div>
    </div>
  );
};

export default DriveTiresInfoFields;
