const requestInterceptors = ({ axiosInstance }) => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('accessToken');
      const currentDivision = localStorage.getItem('currDiv');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Selected-Div'] = currentDivision;
      return config;
    },
    error => {
      throw error;
    },
  );
};

export default requestInterceptors;
