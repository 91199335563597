import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import updateButtonDisabled from '../../../Common/Controller/Handler/updateButtonDisabled';
import convertDataFormat from '../../Common/Handler/convertDataFormat';
import deleteProperty from '../../Common/Handler/deleteProperty';
import separateFileTableData from '../../Common/Handler/separateFileTableData';

const saveAccident = ({
  seq,
  handleSubmit,
  modalType,
  postAccidentMutator,
  patchAccidentMutator,
  nameObject,
  saveButtonRef,
}) => {
  handleSubmit(submitData => {
    if (saveButtonRef)
      updateButtonDisabled({ ref: saveButtonRef, disabled: true });
    const formatData = convertDataFormat({
      inputData: submitData,
      date: ['accident_date', 'claim_date', 'paid_date'],
      number: ['balance_due', 'paid_amt', 'amount_to_pay'],
    });

    /** Add Type */
    formatData.type = nameObject[formatData?.name];

    /** Set Doc Data */
    const separatedData = separateFileTableData({ inputData: formatData });

    /** Remove unnecessary data */
    const manipulateData = deleteProperty({ inputData: separatedData });

    /** Mutate */
    if (modalType === EDIT_MODE?.add) {
      postAccidentMutator({
        postData: manipulateData,
        buttonRef: saveButtonRef,
      });
    }

    if (modalType === EDIT_MODE?.edit) {
      patchAccidentMutator({
        seq,
        patchData: manipulateData,
        buttonRef: saveButtonRef,
      });
    }
  })();
};

export default saveAccident;
