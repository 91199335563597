import React from 'react';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import CommonButton from '../../Common/Controller/Button';

const ExcelButton = ({ gridRef }) => {
  return (
    <CommonButton
      icon={PATH_COMMON_BUTTON_ICON.excelGreen}
      styles={{
        border: '1px solid #3A825D',
        backgroundColor: '#fff',
        hoverBackgroundColor: '#3A825D',
        hoverBorder: '1px solid #3A825D',
        hoverColor: '#fff',
        color: '#3A825D',
      }}
      onClick={() => {
        exportToExcel({ gridRef, fileName: 'DAILY IN OUT' });
      }}
    >
      EXCEL
    </CommonButton>
  );
};

export default ExcelButton;
