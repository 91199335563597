import adminDefaultAPI from '../../api';
import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';

const getUserList = async () => {
  try {
    const response = await adminDefaultAPI.get(`user`);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getUserList;
