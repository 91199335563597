import styled from 'styled-components';

const StyledCommonMessageModalHeaderWrapper = styled.div`
  width: 100%;
  height: 20px;
  min-height: 20px;

  display: flex;
  align-items: center;
`;

export default StyledCommonMessageModalHeaderWrapper;
