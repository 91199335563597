import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const handleRowClicked = ({
  setSeq,
  setIsOpenFormModal,
  setModalType,
  props,
}) => {
  const seq = props?.gridParam?.data?.seq;
  setSeq(seq);
  setIsOpenFormModal(true);
  setModalType(EDIT_MODE.edit);
};

export default handleRowClicked;
