import { useMutation, useQueryClient } from '@tanstack/react-query';
import postDamageReport from '../../../../service/Safety/DamageReport/Post/postDamageReport';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import damageReportKeys from '../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const usePostDamageReport = ({
  setAlertOptions,
  queryObject,
  setIsOpenFormModal,
  setSeq,
}) => {
  const { setAlertInfo, setIsOpenAlert } = setAlertOptions;
  const { addToast } = useControlToast();

  const queryClient = useQueryClient();
  const mutator = useMutation({
    mutationFn: postDamageReport,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setIsOpenFormModal(prev => false);
      setSeq(null);
      addToast({ message: SUCCESS_SAVE });
      queryClient?.invalidateQueries({
        queryKey: damageReportKeys?.damageReportList?.({
          status: queryObject?.status,
          fromDate: queryObject?.['from-date'],
          toDate: queryObject?.['to-date'],
        }),
      });
    },
    onError: error => {
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS?.ERROR,
        message: error?.message,
      }));
      setIsOpenAlert(prev => true);
    },
  });

  return { ...mutator };
};

export default usePostDamageReport;
