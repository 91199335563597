import { useMutation } from '@tanstack/react-query';
import logout from '../../service/SignIn/Post/logout';
import { ALERT_MODAL_STATUS } from '../../constant/Common/Modal/Alert/status';

const useLogoutMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
}) =>
  useMutation({
    mutationFn: logout,
    onSuccess: res => {
      localStorage.clear();
      window.location.href = `/sign-in`;
    },
    onError: error => {
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });

export default useLogoutMutation;
