/**
 * @description
 * Check if the current menu is would be active.
 * @param {string} pathname
 * Current pathname.
 * @param {string} to
 * Path for the menu item.
 * @param {boolean} isSubMenu
 * Flag to indicate the sub-menu state.
 * @returns {boolean}
 * Return the flag to indicate the current would be active or not.
 */
const checkCurrentMenu = ({ pathname, to, isSubMenu }) => {
  if (pathname === '/' && to?.startsWith?.('/daily-in-out')) return true;
  if (pathname?.startsWith(to)) return true;
  if (
    pathname?.split('/')?.slice(1)?.[0] === to?.split('/')?.slice(1)?.[0] &&
    !isSubMenu
  )
    return true;
  return false;
};

export default checkCurrentMenu;
