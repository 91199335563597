import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const patchSpecificVANData = async ({ seq, data }) => {
  try {
    const response = await safetyDefaultAPI.patch(`van/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchSpecificVANData;
