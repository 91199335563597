import styled from 'styled-components';

const StyledInputItemsWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export default StyledInputItemsWrapper;
