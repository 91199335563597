import dayjs from 'dayjs';

const convertDataFormat = ({ inputData, date, number }) => {
  const tempInputData = { ...inputData };

  /** Convert date format to YYYY-MM-DD */
  [...date].forEach(key => {
    if (tempInputData?.[key] && dayjs(tempInputData?.[key]).isValid()) {
      tempInputData[key] = dayjs(tempInputData?.[key]).format('YYYY-MM-DD');
    }
  });

  /** Convert string to number */
  [...number].forEach(key => {
    if (tempInputData?.[key] && !isNaN(Number(tempInputData?.[key])))
      tempInputData[key] = Number(tempInputData?.[key]);
  });

  /** Convert empty string to null */
  Object.keys(tempInputData).forEach(key => {
    if (
      !tempInputData?.[key] &&
      tempInputData?.[key] !== 0 &&
      tempInputData?.[key] !== false
    )
      tempInputData[key] = '';
  });

  return tempInputData;
};
export default convertDataFormat;
