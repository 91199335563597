import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame/index';
import AccidentFormModalHeader from '../AccidentFormModalHeader';
import { ACCIDENT_FORM_MODAL_STYLES } from '../../../../style/Safety/Accident/styles';
import AccidentFormModalButton from '../AccidentFormModalButton';
import AccidentFormContent from '../AccidentFormContent';

const AccidentFormModal = ({
  seq,
  isOpenFormModal,
  setIsOpenFormModal,
  modalType,
  accidentFormController,
  accident,
  postAccidentMutator,
  patchAccidentMutator,
  nameOptionList,
  nameInfoObject,
  isEditable,
}) => {
  const originDivisionList = useSelector(state => state?.common?.allDiv);

  const [divisionList, setDivisionList] = useState([]);
  useEffect(() => {
    const tempDivList = originDivisionList?.map(div => ({ div }));
    setDivisionList(tempDivList);
  }, [originDivisionList]);

  return (
    <FormProvider {...accidentFormController}>
      <form>
        <CommonMessageModalFrame
          isOpen={isOpenFormModal}
          setOpen={setIsOpenFormModal}
          styles={ACCIDENT_FORM_MODAL_STYLES}
          onCloseButtonClick={() => setIsOpenFormModal(false)}
          header={<AccidentFormModalHeader modalType={modalType} />}
          content={
            <AccidentFormContent
              modalType={modalType}
              accident={accident}
              nameOptionList={nameOptionList}
              divisionList={divisionList}
              isEditable={isEditable}
            />
          }
          button={
            <AccidentFormModalButton
              seq={seq}
              modalType={modalType}
              postAccidentMutator={postAccidentMutator}
              patchAccidentMutator={patchAccidentMutator}
              nameObject={nameInfoObject}
              isEditable={isEditable}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AccidentFormModal;
