import React from 'react';
import { Navigate } from 'react-router-dom';
import getAuthorizedMenu from '../../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';
import AuthRouter from '../../../../../component/Common/Router/Common/AuthRouter';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';
import {
  PERM_DAILY_IN_OUT_INVENTORY,
  PERM_DAILY_IN_OUT_INVENTORY_EDIT,
} from '../../../Permission';
import InventoryContents from '../../../../../component/Inventory/InventoryContents';
import checkPermission from '../../../../../util/Common/Router/Handler/checkPermission';

/**
 * @description
 * Children routes of the inventory page.
 */
const INVENTORY_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[1],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'truck/*',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_INVENTORY}>
        <InventoryContents
          isEditable={checkPermission({
            permission: PERM_DAILY_IN_OUT_INVENTORY_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'trailer/*',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_INVENTORY}>
        <InventoryContents
          isEditable={checkPermission({
            permission: PERM_DAILY_IN_OUT_INVENTORY_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'container/*',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_INVENTORY}>
        <InventoryContents
          isEditable={checkPermission({
            permission: PERM_DAILY_IN_OUT_INVENTORY_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
  {
    path: 'chassis/*',
    element: (
      <AuthRouter permission={PERM_DAILY_IN_OUT_INVENTORY}>
        <InventoryContents
          isEditable={checkPermission({
            permission: PERM_DAILY_IN_OUT_INVENTORY_EDIT,
          })}
        />
      </AuthRouter>
    ),
  },
];

export default INVENTORY_CHILDREN_ROUTE;
