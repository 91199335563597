import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonDate from '../../../Common/Controller/Date';
import {
  changeDate,
  changeText,
} from '../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO } from '../../../../constant/Common/Regex';

const LoanInfoContent = ({ accident, isEditable }) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const payAmount = !isNaN(Number(watch('amt_to_pay')))
      ? Number(watch('amt_to_pay'))
      : 0;
    const paidAmount = !isNaN(Number(watch('paid_amt')))
      ? Number(watch('paid_amt'))
      : 0;

    const balanceDue = (payAmount - paidAmount)?.toFixed(2);
    setValue('balance_due', balanceDue);
  }, [watch('amt_to_pay'), watch('paid_amt')]);

  return (
    <div className="h-full flex pt-[26px]">
      <div>
        <CommonTextInput
          inputName="amt_to_pay"
          label="AMOUNT TO PAY"
          defaultValue={accident?.amt_to_pay || ''}
          width="w-[106px]"
          onChangeHandler={changeText}
          regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO}
          adornment={{
            position: 'start',
            content: (
              <div className="text-[#264B9F] text-[12px] font-bold cursor-pointer">
                $
              </div>
            ),
          }}
          disabled={!isEditable}
        />
      </div>
      <div className="ml-[16px]">
        <CommonTextInput
          inputName="who_will_pay"
          width="w-[106px]"
          label="WHO WILL PAY"
          defaultValue={accident?.who_will_pay || ''}
          maxLength={20}
          onChangeHandler={changeText}
          disabled={!isEditable}
        />
      </div>
      <div className="ml-[16px]">
        <CommonTextInput
          inputName="paid_amt"
          width="w-[106px]"
          label="PAID AMOUNT"
          defaultValue={accident?.paid_amt || ''}
          onChangeHandler={changeText}
          regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO}
          adornment={{
            position: 'start',
            content: (
              <div className="text-[#264B9F] text-[12px] font-bold cursor-pointer">
                $
              </div>
            ),
          }}
          disabled={!isEditable}
        />
      </div>
      <div className="ml-[16px]">
        <CommonDate
          inputName="paid_date"
          label="PAID DATE"
          width="w-[106px]"
          defaultValue={accident?.paid_date || ''}
          onChangeHandler={changeDate}
          disabled={!isEditable}
        />
      </div>
      <div className="ml-[23px]">
        <CommonTextInput
          inputName="balance_due"
          width="w-[106px]"
          label="BALANCE DUE"
          defaultValue={accident?.balance_due || ''}
          onChangeHandler={changeText}
          disabled
          regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO}
          adornment={{
            position: 'start',
            content: (
              <div className="text-[#264B9F] text-[12px] font-bold cursor-pointer">
                $
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default LoanInfoContent;
