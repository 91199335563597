import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonSelect from '../../../Common/Controller/Select';
import CommonCheckbox from '../../../Common/Controller/Checkbox';
import handleDivisionChange from '../../../../util/Admin/User/Handler/handleDivisionChange';
import useGetAuthValue from '../../../../hook/Admin/User/Input/useGetAuthValue';
import useGetDivisionOption from '../../../../hook/Admin/User/Input/useGetDivisionOption';
import validateEmail from '../../../../util/Common/Controller/Validator/validateEmail';

const SideModalContent = ({
  userInfo,
  authList,
  divList,
  divisionCheckBoxOption,
}) => {
  const { setValue, control, getValues } = useFormContext();

  /** Information Related To The Auth Field */
  const { isRequiredAuth, authOptionList } = useGetAuthValue({
    userInfo,
    authList,
  });

  /** Information Related To Division Fields */
  const { defaultDivOptionList, selectedDivList, setSelectedDivList } =
    useGetDivisionOption({
      divList,
      userInfo,
      setValue,
      useWatch,
      control,
      getValues,
    });

  return (
    <div className="gap-y-[9px]">
      <div className="w-[302px]">
        <CommonTextInput
          inputName="username"
          label="USER ID"
          disabled
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
          defaultValue={userInfo?.username || ''}
        />
      </div>
      <div className="flex gap-x-[20px]">
        <div className="w-[141px]">
          <CommonTextInput
            inputName="first_name"
            label="FIRST NAME"
            disabled
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
            defaultValue={userInfo?.first_name || ''}
          />
        </div>
        <div className="w-[141px]">
          <CommonTextInput
            inputName="last_name"
            label="LAST NAME"
            disabled
            onChangeHandler={({ e, onChange }) => {
              onChange(e?.target?.value);
            }}
            defaultValue={userInfo?.last_name || ''}
          />
        </div>
      </div>
      <div className="w-[302px]">
        <CommonTextInput
          inputName="email"
          label="EMAIL"
          disabled
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
          defaultValue={userInfo?.email || ''}
          validate={{
            custom: {
              ...validateEmail(),
            },
          }}
        />
      </div>
      <div className="w-[141px]">
        <CommonSelect
          label="AUTH"
          required={isRequiredAuth}
          option={authOptionList}
          inputName="group_name"
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
          defaultValue={userInfo?.group_name || ''}
        />
      </div>
      <div className="w-[302px]">
        <CommonCheckbox
          inputName="checkbox"
          label="DIV"
          borderVisible
          options={divisionCheckBoxOption}
          onChangeHandler={props =>
            handleDivisionChange({
              divList,
              setValue,
              selectedDivList,
              setSelectedDivList,
              ...props,
            })
          }
        />
      </div>
      <div className="w-[141px]">
        <CommonSelect
          label="DEFAULT DIV"
          option={defaultDivOptionList}
          required
          inputName="default_div"
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
        />
      </div>
    </div>
  );
};

export default SideModalContent;
