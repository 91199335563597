import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BasicInfo from './BasicInfo';
import RepairInfo from './RepairInfo';
import OtherInfo from './OtherInfo';
import StyledModalContentWrapper from '../../../../../style/Safety/Common/Modal/StyledModalContentWrapper';

const ModalContent = ({ formController, isEditable, ...props }) => {
  return (
    <FormProvider {...formController}>
      <form>
        <StyledModalContentWrapper>
          <BasicInfo data={props?.data} isEditable={isEditable} />
          <RepairInfo data={props?.data} isEditable={isEditable} />
          <OtherInfo data={props?.data} isEditable={isEditable} />
        </StyledModalContentWrapper>
      </form>
    </FormProvider>
  );
};

export default ModalContent;
