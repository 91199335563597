import React from 'react';
import { useSelector } from 'react-redux';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import { BASIC_INFO_STATUS } from '../../../../../../constant/Common/Option/Status';
import { COMMON_STATE } from '../../../../../../constant/Common/Option/State';
import {
  changeAutoComplete,
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../../constant/Common/Regex';
import CustomAutoComplete from '../../../../../Common/Controller/AutoComplete/Custom';
import { createMandatorySelectOption } from '../../../../../../util/Common/Option/Generator/selectOptionCreator';

const BasicInfo = ({ ...props }) => {
  const { data, isEditable } = props;

  const divisionList = useSelector(state => state?.common?.allDiv);
  const driverList = useSelector(state => state?.common?.driverList);

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Basic Info
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="DIV"
              inputName="div"
              option={divisionList?.map((division, index) => ({
                key: index,
                label: division,
                value: division,
              }))}
              required
              defaultValue={data?.div || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="TRUCK #"
              inputName="truck_no"
              required
              defaultValue={data?.truck_no || ''}
              maxLength={10}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              onBlurHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value?.toUpperCase());
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="MAKE YEAR"
              inputName="make_year"
              required
              defaultValue={data?.make_year || ''}
              maxLength={4}
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="CREATED BY"
              inputName="created_by"
              disabled
              defaultValue={data?.created_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="created_date"
              disabled
              defaultValue={data?.created_date || ''}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATUS"
              inputName="status"
              option={BASIC_INFO_STATUS}
              required
              defaultValue={data?.status || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="MAKE/MODEL"
              inputName="truck_model"
              required
              maxLength={20}
              defaultValue={data?.truck_model || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="VIN #"
              inputName="vin_no"
              required
              maxLength={20}
              defaultValue={data?.vin_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="UPDATED BY"
              inputName="updated_by"
              disabled
              defaultValue={data?.updated_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="updated_date"
              disabled
              defaultValue={data?.updated_date || ''}
            />
          </div>
        </div>
      </div>
      {/* Third row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CustomAutoComplete
              label="OPERATED BY"
              inputName="driver"
              required
              maxLength={10}
              defaultValue={data?.driver || ''}
              option={createMandatorySelectOption({
                items: driverList,
                valueKey: 'id',
                customLabelGenerator: ({ item }) =>
                  `${item?.id} / ${item?.name}`,
              })}
              onChange={({ ...changeProps }) => {
                changeAutoComplete?.({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATE"
              inputName="state"
              option={COMMON_STATE}
              required
              defaultValue={data?.state || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="PLATE #"
              inputName="plate_no"
              required
              maxLength={10}
              defaultValue={data?.plate_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default BasicInfo;
