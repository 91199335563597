import {
  LFI_PAIR_LIST,
  LFO_PAIR_LIST,
  LRI_PAIR_LIST,
  LRO_PAIR_LIST,
  RFI_PAIR_LIST,
  RFO_PAIR_LIST,
  RRI_PAIR_LIST,
  RRO_PAIR_LIST,
} from './pairLists';

export const STATUS_OPTION_LIST = [
  { label: 'ALL', value: 'ALL' },
  { label: 'Damaged', value: 'SSD001' },
  { label: 'Repaired', value: 'SSD002' },
];

export const STATUS_FORM_OPTION_LIST = [
  { label: 'Damaged', value: 'SSD001' },
  { label: 'Repaired', value: 'SSD002' },
];

export const TRUCK_DAMAGE_CHECKBOX_FIRST_OPTION_LIST = [
  { key: 0, label: 'Lights', inputName: 'trk_lights' },
  { key: 1, label: 'Safety EQ', inputName: 'trk_safety_eq' },
  { key: 2, label: 'Air Compressor', inputName: 'trk_air_compressor' },
  { key: 3, label: 'Head - Stop', inputName: 'trk_head_stop', width: '80px' },
  { key: 4, label: 'Fire EXT', inputName: 'trk_fire_ext' },
  { key: 5, label: 'Battery', inputName: 'trk_battery' },
  { key: 6, label: 'Tail - Dash', inputName: 'trk_tail_dash' },
  { key: 7, label: 'Spare Tire', inputName: 'trk_spare_tire', width: '80px' },
  { key: 8, label: 'Brakes', inputName: 'trk_brakes' },
  { key: 9, label: 'Turn Indicators', inputName: 'trk_turn_indicators' },
  { key: 10, label: 'Defroster', inputName: 'trk_defroster' },
  { key: 11, label: 'Clutch', inputName: 'trk_clutch', width: '80px' },
  { key: 12, label: 'Mirrors', inputName: 'trk_mirrors' },
  { key: 13, label: 'Air Leak', inputName: 'trk_air_leak' },
  { key: 14, label: 'DriveShaft', inputName: 'trk_driveshaft' },
  { key: 15, label: 'Coolant', inputName: 'trk_coolant', width: '80px' },
  { key: 16, label: 'A/C', inputName: 'trk_ac' },
  { key: 17, label: 'Engine', inputName: 'trk_engine' },
  { key: 18, label: 'Windshield', inputName: 'trk_windshield' },
  { key: 19, label: 'Horn', inputName: 'trk_horn', width: '80px' },
  { key: 20, label: 'Fuild Level', inputName: 'trk_fuild_level' },
  { key: 21, label: 'Wipers', inputName: 'trk_wipers' },
  { key: 22, label: 'Fifth Wheel', inputName: 'trk_fifth_wheel' },
  {
    key: 23,
    label: 'Fuel Filter',
    inputName: 'trk_fuel_filter',
    width: '80px',
  },
  { key: 24, label: 'fuel Tanks', inputName: 'trk_fuel_tanks' },
  { key: 25, label: 'Release Lever', inputName: 'trk_release_lever' },
  { key: 26, label: 'Oil Pressure', inputName: 'trk_oil_pressure' },
  {
    key: 27,
    label: 'Power Steering',
    inputName: 'trk_power_steering',
    width: '80px',
  },
  { key: 28, label: 'Hubcap', inputName: 'trk_hubcap' },
  { key: 29, label: 'Radiator', inputName: 'trk_radiator' },
  { key: 30, label: 'Reflectors', inputName: 'trk_reflectors' },
  { key: 31, label: 'ELD/Tablet', inputName: 'trk_eld', width: '80px' },
  { key: 32, label: 'Springs', inputName: 'trk_springs' },
  { key: 33, label: 'Muffler', inputName: 'trk_muffler' },
  { key: 34, label: 'Dashcam', inputName: 'trk_dashcam' },
  {
    key: 35,
    label: 'Transmission',
    inputName: 'trk_transmission',
    width: '80px',
  },
];

export const TRUCK_DAMAGE_BODY_CHECKBOX_OPTION = [
  {
    key: 0,
    label: 'Body Condition',
    inputName: 'body_condition',
  },
];

export const TRUCK_DAMAGE_CHECKBOX_SECOND_OPTION_LIST = [
  { key: 0, label: 'Registration', inputName: 'registration' },
  { key: 1, label: 'IFTA', inputName: 'ifta' },
  { key: 2, label: 'COI (Insurance)', inputName: 'coi' },
];

export const CHASSIS_DAMAGE_CHECKBOX_OPTION_LIST = [
  { key: 0, label: 'Lights', inputName: 'ch_lights' },
  { key: 1, label: 'Door', inputName: 'ch_door' },
  { key: 3, label: 'Brakes', inputName: 'ch_brakes' },
  { key: 4, label: 'Reflector', inputName: 'ch_reflector', width: '48px' },
  { key: 5, label: 'Side', inputName: 'ch_side' },
  { key: 6, label: 'Coupling', inputName: 'ch_coupling' },
  { key: 7, label: 'Mudflap', inputName: 'ch_mudflap' },
  { key: 8, label: 'Roof', inputName: 'ch_roof', width: '48px' },
  { key: 9, label: 'Suspension', inputName: 'ch_suspension' },
  { key: 10, label: 'Landing Gear', inputName: 'ch_landing_gear' },
  { key: 11, label: 'Kingpin', inputName: 'ch_kingpin' },
  { key: 12, label: 'Hub Cap', inputName: 'ch_hubcap', width: '48px' },
];

export const STEER_TIRES_CHECKBOX_OPTION_LIST = [
  {
    key: 0,
    label: 'Damaged (LEFT)',
    inputName: 'st_left_dmg',
    width: '130px',
  },
  {
    key: 1,
    label: 'Damaged (RIGHT)',
    inputName: 'st_right_dmg',
    width: '130px',
  },
];

export const DRIVER_TIRES_CHECKBOX_OPTION_LIST = [
  {
    key: 0,
    label: 'Damaged',
    inputName: 'dt_lfo_dmg',
    subLabel: 'LFO',
    width: '71px',
  },
  {
    key: 1,
    label: 'Damaged',
    inputName: 'dt_lfi_dmg',
    subLabel: 'LFI',
    width: '71px',
  },
  {
    key: 2,
    label: 'Damaged',
    inputName: 'dt_rfo_dmg',
    subLabel: 'RFO',
    width: '71px',
  },
  {
    key: 3,
    label: 'Damaged',
    inputName: 'dt_rfi_dmg',
    subLabel: 'RFI',
    width: '52px',
  },
  {
    key: 4,
    label: 'Damaged',
    inputName: 'dt_lro_dmg',
    subLabel: 'LRO',
    width: '71px',
  },
  {
    key: 5,
    label: 'Damaged',
    inputName: 'dt_lri_dmg',
    subLabel: 'LRI',
    width: '71px',
  },
  {
    key: 6,
    label: 'Damaged',
    inputName: 'dt_rro_dmg',
    subLabel: 'FFO',
    width: '71px',
  },
  {
    key: 7,
    label: 'Damaged',
    inputName: 'dt_rri_dmg',
    subLabel: 'RRI',
    width: '52px',
  },
];

export const TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST = [
  {
    key: 0,
    label: 'Original',
    subLabel: 'LFO',
    inputName: 'tct_lfo_orig',
    width: '71px',
    pairs: LFO_PAIR_LIST,
  },
  {
    key: 1,
    label: 'Original',
    subLabel: 'LFI',
    inputName: 'tct_lfi_orig',
    width: '71px',
    pairs: LFI_PAIR_LIST,
  },
  {
    key: 2,
    label: 'Original',
    subLabel: 'RFO',
    inputName: 'tct_rfo_orig',
    width: '71px',
    pairs: RFO_PAIR_LIST,
  },
  {
    key: 3,
    label: 'Original',
    subLabel: 'RFI',
    inputName: 'tct_rfi_orig',
    width: '52px',
    pairs: RFI_PAIR_LIST,
  },
  {
    key: 4,
    label: 'Damaged',
    inputName: 'tct_lfo_dmg',
    width: '71px',
    pairs: LFO_PAIR_LIST,
  },
  {
    key: 5,
    label: 'Damaged',
    inputName: 'tct_lfi_dmg',
    width: '71px',
    pairs: LFI_PAIR_LIST,
  },
  {
    key: 6,
    label: 'Damaged',
    inputName: 'tct_rfo_dmg',
    width: '71px',
    pairs: RFO_PAIR_LIST,
  },
  {
    key: 7,
    label: 'Damaged',
    inputName: 'tct_rfi_dmg',
    width: '52px',
    pairs: RFI_PAIR_LIST,
  },
];

export const TRAILER_CHASSIS_TIRES_CHECKBOX_OPTION_LIST2 = [
  {
    key: 0,
    label: 'Original',
    subLabel: 'LRO',
    inputName: 'tct_lro_orig',
    width: '71px',
    pairs: LRO_PAIR_LIST,
  },
  {
    key: 1,
    label: 'Original',
    subLabel: 'LRI',
    inputName: 'tct_lri_orig',
    width: '71px',
    pairs: LRI_PAIR_LIST,
  },
  {
    key: 2,
    label: 'Original',
    subLabel: 'RRO',
    inputName: 'tct_rro_orig',
    width: '71px',
    pairs: RRO_PAIR_LIST,
  },
  {
    key: 3,
    label: 'Original',
    subLabel: 'RRI',
    inputName: 'tct_rri_orig',
    width: '52px',
    pairs: RRI_PAIR_LIST,
  },
  {
    key: 4,
    label: 'Damaged',
    inputName: 'tct_lro_dmg',
    width: '71px',
    pairs: LRO_PAIR_LIST,
  },
  {
    key: 5,
    label: 'Damaged',
    inputName: 'tct_lri_dmg',
    width: '71px',
    pairs: LRI_PAIR_LIST,
  },
  {
    key: 6,
    label: 'Damaged',
    inputName: 'tct_rro_dmg',
    width: '71px',
    pairs: RRO_PAIR_LIST,
  },
  {
    key: 7,
    label: 'Damaged',
    inputName: 'tct_rri_dmg',
    width: '52px',
    pairs: RRI_PAIR_LIST,
  },
];

export const REG_CHECKBOX_OPTION = [
  {
    inputName: 'ch_reg',
    label: 'Option 1',
    defaultValue: false,
  },
];

export const IS_NGL_TRUCK_OPTION = [
  {
    inputName: 'isNGLTruck',
    optionLabel: 'NGL',
  },
];

export const IS_NGL_CHASSIS_OPTION = [
  {
    inputName: 'isNGLChassis',
    optionLabel: 'NGL',
  },
];
