import React from 'react';
import ButtonPresetWrapper from '../../../component/Labs/ButtonPreset/ButtonPresetWrapper';
import { PRESET_ICON_MAPPER } from '../../../constant/Labs/ButtonPreset/mapping';

const ButtonIcon = () => {
  return (
    <div className="flex w-full h-full gap-[20px] p-[20px] flex-wrap justify-center">
      {PRESET_ICON_MAPPER?.map(element => (
        <ButtonPresetWrapper
          name={element?.name}
          styles={element?.styles}
          icon={element?.icon}
        />
      ))}
    </div>
  );
};

export default ButtonIcon;
