import {
  REGEX_CONTAINER_NUMBER,
  REGEX_CONTAINER_NUMBER_DRY,
} from '../../../../constant/Common/Regex';
import { CONTAINER_NO_PAIRS } from '../../../../constant/Common/Validation/container';

/**
 * @description
 * Function to check container number constraint
 * @param {string} containerNo
 * Target container number
 * @returns {boolean}
 * True if container number constraint is valid
 */
const checkContainerNumberConstraint = ({
  containerNo,
  containerSize,
  containerType,
}) => {
  if (containerNo && containerNo?.length > 0) {
    /**
     * Pass case
     * When container size is 53 or 45 HC or 48, validation logic will be passed.
     * 2024. 07. 23. - Confirmed by Meeting
     */
    if (
      containerSize === '53' ||
      containerSize === '45 HC' ||
      containerSize === '48'
    )
      return true;
    // 0. Check regular expression match of container number.
    if (containerType === 'DRY') {
      const matchWithRegex = containerNo.match(REGEX_CONTAINER_NUMBER_DRY);
      if (!matchWithRegex) {
        return false;
      }
    } else {
      const matchWithRegex = containerNo.match(REGEX_CONTAINER_NUMBER);
      if (!matchWithRegex) {
        return false;
      }
    }

    // 1. Check last digit of container number.
    let sumOfContainerNumber = 0;

    for (let i = 0; i < 10; i++) {
      if (i > 3) {
        sumOfContainerNumber += 2 ** i * parseInt(containerNo?.[i], 10);
      } else {
        const pairValue = CONTAINER_NO_PAIRS.find(
          element => element?.letter === containerNo?.[i],
        )?.value;
        sumOfContainerNumber += 2 ** i * pairValue;
      }
    }
    return sumOfContainerNumber % 11 === 10
      ? parseInt(containerNo?.[10], 10) === 0
      : sumOfContainerNumber % 11 === parseInt(containerNo?.[10], 10);
  }
  return true;
};

export default checkContainerNumberConstraint;
