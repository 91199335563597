import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import SAFETY_MR_MODAL_DEFAULT_VALUE from '../../../../constant/Safety/MR/Modal/defaultValue';

const createModalDefaultValue = ({ specificData, editMode }) => {
  if (editMode === EDIT_MODE?.add) return { ...SAFETY_MR_MODAL_DEFAULT_VALUE };

  if (specificData) {
    const specificDefaultData = {
      div: specificData?.div || '',
      equip_id: specificData?.equip_id || '',
      make_year: specificData?.make_year,
      type: specificData?.type || '',
      category: specificData?.category || '',
      sub_category: specificData?.sub_category || '',
      odometer: specificData?.odometer || '',
      repaired_by: specificData?.repaired_by || '',
      repaired_date: specificData?.repaired_date || null,
      inv_no: specificData?.inv_no || '',
      mttr: specificData?.mttr,
      labor: specificData?.labor || '',
      part: specificData?.part || '',
      tax: specificData?.tax || '',
      other: specificData?.other || '',
      total: specificData?.total || '',
      remarks: specificData?.remarks || '',
      created_by: specificData?.created_by || '',
      created_date: specificData?.created_date || null,
      updated_by: specificData?.updated_by || '',
      updated_date: specificData?.updated_date || null,
      doc: [
        {
          updated_date: null,
          document: '',
          exp_date: null,
          file_url: null,
          updated_by: '',
        },
        ...specificData?.doc,
      ],
    };
    return specificDefaultData;
  }

  return { ...SAFETY_MR_MODAL_DEFAULT_VALUE };
};

export default createModalDefaultValue;
