import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getSizeList from '../../../service/Common/Get/getSizeList';

const useGetSizeList = ({ ...props }) => {
  const { keyword } = props;

  const [sizeList, setSizeList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.sizeList({ keyword }),
    queryFn: () => getSizeList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setSizeList(prev => query?.data?.data);
    } else {
      setSizeList(prev => []);
    }
  }, [query?.data]);

  return { sizeList, ...query };
};

export default useGetSizeList;
