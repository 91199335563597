import { PERM_FORMAT } from '../../../../constant/Admin/Auth/adminAuthElements';
import formatPermission from './formatPermission';

const handleAddClicked = ({ e, postGroupMutation }) => {
  const convertedPermissions = PERM_FORMAT;
  const name = e?.gridParam?.data?.name;
  const permissions = e?.gridParam?.data;

  Object.keys(permissions).forEach(key => {
    convertedPermissions[key] = formatPermission(key, permissions[key]);
  });

  delete convertedPermissions.name;
  delete convertedPermissions.permissions;

  postGroupMutation({ data: { name, permissions: convertedPermissions } });
};

export default handleAddClicked;
