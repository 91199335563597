import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getChassisTypeList from '../../../service/Common/Get/getChassisTypeList';

const useGetChassisTypeList = ({ ...props }) => {
  const { keyword } = props;

  const [chassisTypeList, setChassisTypeList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys.chassisTypeList({ keyword }),
    queryFn: () => getChassisTypeList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setChassisTypeList(prev => query?.data?.data);
    } else {
      setChassisTypeList(prev => []);
    }
  }, [query?.data]);

  return { chassisTypeList, ...query };
};

export default useGetChassisTypeList;
