import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const postAccident = async ({ postData }) => {
  try {
    const response = await safetyDefaultAPI.post('accident', postData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postAccident;
