import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyDefaultAPI from '../../api';

const patchAccident = async ({ seq, patchData }) => {
  try {
    const response = await safetyDefaultAPI.patch(`accident/${seq}`, patchData);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchAccident;
