import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getSpecificMRData from '../../../../service/Safety/MR/Get/getSpecificMRData';
import safetyMRKeys from '../keys';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const useSpecificMRData = ({ ...props }) => {
  const { isModalOpen, setAlertOpen, setAlertModalStatus } = props;

  const [seq, setSeq] = useState(null);
  const [specificData, setSpecificData] = useState({
    seq: null,
    div: null,
    equip_id: '',
    make_year: '',
    type: '',
    category: '',
    sub_category: '',
    odometer: '',
    repaired_by: '',
    repaired_date: null,
    inv_no: '',
    mttr: '',
    labor: '',
    part: '',
    tax: '',
    other: '',
    total: '',
    remarks: '',
    created_by: '',
    created_date: null,
    updated_by: '',
    updated_date: null,
    doc: [],
  });

  const query = useQuery({
    queryKey: safetyMRKeys?.specificData({ seq }),
    queryFn: () => getSpecificMRData({ seq }),
    refetchOnWindowFocus: false,
    enabled: !!seq && !!isModalOpen,
  });

  useEffect(() => {
    if (query?.data) {
      setSpecificData(prev => query?.data?.data);
    } else {
      setSpecificData(prev => {
        return {
          seq: null,
          div: null,
          equip_id: '',
          make_year: '',
          type: '',
          category: '',
          sub_category: '',
          odometer: '',
          repaired_by: '',
          repaired_date: null,
          inv_no: '',
          mttr: '',
          labor: '',
          part: '',
          tax: '',
          other: '',
          total: '',
          remarks: '',
          created_by: '',
          created_date: null,
          updated_by: '',
          updated_date: null,
          doc: [],
        };
      });
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { specificData, seq, setSeq, ...query };
};

export default useSpecificMRData;
