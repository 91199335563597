import React, { useState } from 'react';
import StyledSessionInfoWrapper from '../../../../style/Common/Header/StyledSessionInfo/StyledSessionInfoWrapper';
import CommonButton from '../../Controller/Button';
import DivisionSelector from './DivisionSelector';
import User from './User';
import useLogoutMutation from '../../../../hook/SignIn/useLogoutMutation';
import CommonAlert from '../../Modal/Alert';
import Navigator from './Navigator';

/**
 * @description
 * Component of the session information. Show user information, division selector and log out button.
 * @returns {React.ReactElement}
 */
const SessionInfo = () => {
  const refresh = localStorage.getItem('refreshToken');
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState();

  const { mutate: logoutMutation } = useLogoutMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
  });

  return (
    <>
      <CommonAlert
        isOpen={isMessageOpen}
        setOpen={setIsMessageOpen}
        content={message}
        status={messageStatus}
        onOKButtonClick={() => {
          setIsMessageOpen(false);
        }}
      />
      <StyledSessionInfoWrapper className="space-x-4">
        <Navigator />
        <User />
        <DivisionSelector />
        <CommonButton
          styles={{
            width: '90px',
            color: '#fff',
            backgroundColor: '#556DA1',
            height: '1.75rem !important',
            fontSize: '0.875rem !important',
          }}
          onClick={() => {
            logoutMutation({ refresh });
          }}
        >
          Log Out
        </CommonButton>
      </StyledSessionInfoWrapper>
    </>
  );
};

export default SessionInfo;
