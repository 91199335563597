import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import { SAFETY_COMMON_DIVISION } from '../../../../../../constant/Safety/Common/Option/Division';
import {
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { SAFETY_COMMON_TYPE } from '../../../../../../constant/Safety/Common/Option/Type';
import useGetTypeCategory from '../../../../../../hook/Safety/Common/Option/useGetTypeCategory';
import { REGEX_NUMBER_ONLY_POSITIVE_DECIMAL } from '../../../../../../constant/Common/Regex';

const BasicInfo = ({ ...props }) => {
  const { data, isEditable } = props;

  const divisionList = useSelector(state => state?.common?.allDiv);

  const { categoryOption, subCategoryOption } = useGetTypeCategory({
    type: data?.type,
    category: data?.category,
    subCategory: data?.sub_category,
  });

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold">
        Basic Info
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="DIV"
              inputName="div"
              option={divisionList?.map((division, index) => ({
                key: index,
                label: division,
                value: division,
              }))}
              required
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              defaultValue={data?.div || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="EQ ID"
              inputName="equip_id"
              maxLength={6}
              required
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              defaultValue={data?.equip_id || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="MAKE YEAR"
              inputName="make_year"
              maxLength={4}
              required
              regex={REGEX_NUMBER_ONLY_POSITIVE_DECIMAL}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              defaultValue={data?.make_year || ''}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="CREATED BY"
              inputName="created_by"
              disabled
              defaultValue={data?.created_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="created_date"
              disabled
              defaultValue={data?.created_date || ''}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="type"
              required
              option={SAFETY_COMMON_TYPE}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              defaultValue={data?.type || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="CATEGORY"
              inputName="category"
              required
              option={categoryOption}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              defaultValue={data?.category || ''}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SUB CATEGORY"
              inputName="sub_category"
              required
              option={subCategoryOption}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              defaultValue={data?.sub_category || ''}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="UPDATED BY"
              inputName="updated_by"
              disabled
              defaultValue={data?.updated_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="updated_date"
              disabled
              defaultValue={data?.updated_date || null}
            />
          </div>
        </div>
      </div>
      {/* Third row */}
      <div>
        <div className={STYLE_ITEM_WRAPPER}>
          <CommonTextInput
            label="ODOMETER"
            inputName="odometer"
            maxLength={7}
            onChangeHandler={({ ...changeProps }) => {
              changeText({ ...changeProps });
            }}
            defaultValue={data?.odometer || ''}
            disabled={!isEditable}
          />
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default BasicInfo;
