import { useMutation } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchSpecificVANData from '../../../../service/Safety/VAN/Patch/patchSpecificVANData';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import generateLastExpDate from '../../../../util/Safety/Common/Generator/generateLastExpData';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchSpecificVANData = ({ gridRef, ...props }) => {
  const { addToast } = useControlToast();

  const { setAlertModalStatus, setAlertOpen, setModalOpen } = props;

  const mutator = useMutation({
    mutationFn: patchSpecificVANData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      const { div, size, type, doc, ...successData } = data?.data;
      /** Exp Date Column */
      const regExpDate = generateLastExpDate({
        documentList: doc,
        field: 'Registration',
      });

      updateGridData({
        gridRef,
        newData: {
          ...variables?.data,
          ...successData,
          reg_exp_date: regExpDate,
        },
        isUpdate: true,
      });

      setModalOpen?.(prev => false);
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
  });

  return { ...mutator };
};

export default usePatchSpecificVANData;
