import { useMutation } from '@tanstack/react-query';
import patchUserStatus from '../../../../service/Admin/User/Patch/patchUserStatus';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchUserStatus = ({
  gridRef,
  disabledRef,
  setIsOpenErrorModal,
  setAlertInfo,
}) => {
  const { addToast } = useControlToast();

  const mutate = useMutation({
    mutationFn: patchUserStatus,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { id: variables?.id, ...data?.data },
        key: 'id',
        isUpdate: true,
      });
      addToast({ message: SUCCESS_UPDATE });
    },
    onError: (error, variables) => {
      setIsOpenErrorModal(true);
      setAlertInfo({
        status: ALERT_MODAL_STATUS?.ERROR,
        message: error?.message,
      });
      variables?.setChecked(e => !e);
    },
    onSettled: () => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
    },
  });
  return { ...mutate };
};

export default usePatchUserStatus;
