import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import adminDivConfDefaultAPI from '../../divConfAPI';

const postYMSYard = async ({ div, name }) => {
  try {
    const response = await adminDivConfDefaultAPI.post(`${div}/yms-yard`, {
      name,
    });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default postYMSYard;
