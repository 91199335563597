import updateButtonDisabled from '../../../Common/Controller/Handler/updateButtonDisabled';

const saveUserInfo = ({
  handleSubmit,
  userID,
  divList,
  userInfoPatchMutator,
  isValid,
  buttonRef,
}) => {
  if (!isValid && buttonRef)
    updateButtonDisabled({ ref: buttonRef, disabled: false });
  handleSubmit(submitData => {
    const patchData = {
      userdivision_set: {
        default_div: submitData?.default_div,
      },
    };

    patchData.group_name = submitData?.group_name || null;
    divList?.forEach(div => {
      patchData.userdivision_set[div?.div] = submitData?.[div?.div];
    });

    userInfoPatchMutator({ id: userID, patchData, buttonRef });
  })();
};

export default saveUserInfo;
