import React from 'react';
import CommonTextarea from '../../../../../Common/Controller/Textarea';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import { STYLE_EACH_ROW_WRAPPER } from '../../../../../../style/Safety/Common/style';
import FileTable from '../../../../Common/Modal/FileTable';
import { changeText } from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';

const OtherInfo = ({ ...props }) => {
  const { data, isEditable } = props;

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold">
        Other Info
      </div>
      {/* Content */}
      <div className={`${STYLE_EACH_ROW_WRAPPER} pt-[22px] flex-col`}>
        <div className="w-full pr-[22px]">
          <FileTable page="mr" isEditable={isEditable} />
        </div>
        <div className="w-full pr-[22px]">
          <CommonTextarea
            label="REMARKS"
            inputName="remarks"
            maxLength={120}
            onChangeHandler={({ ...changeProps }) => {
              changeText({ ...changeProps });
            }}
            defaultValue={data?.remarks}
            disabled={!isEditable}
          />
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default OtherInfo;
