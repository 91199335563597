import dayjs from 'dayjs';
import { DEFAULT_DAMAGE_DATA } from '../../../../constant/Safety/DamageReport/defaultDamageData';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

export const createIsNGLTruckValue = ({ truckOptions, truckValue }) => {
  const isNGLTruck = !!truckOptions?.find(truck => truck?.value === truckValue);
  return isNGLTruck;
};

export const createIsNGLChassisValue = ({ chassisOptions, chassisValue }) => {
  const isNGLChassis = !!chassisOptions?.find(
    chassis => chassis?.value === chassisValue,
  );
  return isNGLChassis;
};

export const createDefaultModalValue = ({
  modalType,
  damageReportInfo,
  truckOptions,
  chassisOptions,
}) => {
  if (modalType === EDIT_MODE?.add) {
    return { ...DEFAULT_DAMAGE_DATA };
  }

  if (damageReportInfo) {
    const damageData = { ...damageReportInfo };

    damageData.report_time =
      damageData?.report_time &&
      dayjs(damageData?.report_time, 'HH:mm:ss')?.isValid()
        ? dayjs(damageData?.report_time, 'HH:mm:ss')
        : '';

    const isNGLTruck = createIsNGLTruckValue({
      truckOptions,
      truckValue: damageData.trk_no,
    });

    damageData.isNGLTruck = isNGLTruck;
    damageData.trk_no_auto_complete = isNGLTruck ? damageData?.trk_no : '';
    damageData.trk_no_text = !isNGLTruck ? damageData?.trk_no : '';

    const isNGLChassis = createIsNGLChassisValue({
      chassisOptions,
      chassisValue: damageData?.ch_no,
    });

    damageData.isNGLChassis = isNGLChassis;
    damageData.ch_no_auto_complete = isNGLChassis ? damageData?.ch_no : '';
    damageData.ch_no_text = !isNGLChassis ? damageData?.ch_no : '';

    Object?.keys?.(damageData)?.forEach(key => {
      damageData[key] =
        damageData[key] !== undefined && damageData[key] !== null
          ? damageData[key]
          : '';
    });

    return damageData;
  }
  return { ...DEFAULT_DAMAGE_DATA };
};
export default createDefaultModalValue;
