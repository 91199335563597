import axios from 'axios';

const refresh = async ({ originalRequest, axiosInstance }) => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/sign/refresh`,
      { refresh: refreshToken },
    );
    if (response.status === 200) {
      localStorage.setItem('accessToken', response.data.access);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;
      originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
      return axiosInstance(originalRequest);
    }
  } catch (error) {
    localStorage.clear();
    window.location.href = '/sign-in';
    throw new Error(error);
  }
  return null;
};

export default refresh;
