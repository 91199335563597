import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import ROUTER from './constant/Common/Router';
import store from './redux/Store';
import StyledScreenWrapper from './style/Common/StyledScreenWrapper';
import StyledGlobal from './style/Global';
import checkIsMobile from './util/Common/Router/Handler/checkIsMobile';
import Mobile from './page/Error/Mobile';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

if (checkIsMobile()) {
  root.render(<Mobile />);
} else {
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledGlobal />
        <StyledScreenWrapper>
          <RouterProvider router={ROUTER} />
        </StyledScreenWrapper>
      </QueryClientProvider>
    </Provider>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
