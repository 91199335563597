import React from 'react';
import ErrorInternalServer from '../../../component/Common/Error/ErrorInternalServer';

/**
 * @description
 * Component of the not found page.
 * @returns {React.ReactElement}
 */
const InternalServer = () => {
  return <ErrorInternalServer />;
};

export default InternalServer;
