import styled from 'styled-components';

const StyledSubMenuNavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100px;
  min-height: 100px;
  padding-top: 20px;
  padding-left: 41px;

  border-bottom: 1px solid rgb(229, 231, 235);
  gap: 10px;
`;

export default StyledSubMenuNavigatorWrapper;
