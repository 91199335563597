import styled from 'styled-components';

const StyledCheckBoxArea = styled.div`
  width: ${props => props?.styles?.width || '100%'};
  min-width: ${props => props?.styles?.width || '100%'};
  height: ${props => props?.styles?.height || '100%'};
  min-height: ${props => props?.styles?.height || '100%'};

  padding-top: ${props => props?.styles?.paddingTop || '7px'};
  padding-bottom: ${props => props?.styles?.paddingBottom || '12px'};
  padding-left: ${props => props?.styles?.paddingLeft || '10px'};
  padding-right: ${props => props?.styles?.paddingRight || '0px'};

  border-radius: 5px;
  border: 1px solid #eeeeee;
`;

export default StyledCheckBoxArea;
