import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const patchDailyInOut = async ({ seq, data }) => {
  try {
    const response = await dailyInOutDefaultAPI.patch(`/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDailyInOut;
