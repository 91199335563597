/**
 * @description
 * Function to create select option.
 * @param {list} items
 * List of items to be created as select option.
 * @param {string} labelKey
 * Key to be used as label.
 * @param {string} valueKey
 * Key to be used as value.
 * @returns {list}
 * List of select option.
 */
export const createMandatorySelectOption = ({
  items,
  labelKey,
  valueKey,
  customLabelGenerator,
}) => {
  const result = [];

  items?.forEach((item, idx) => {
    result?.push({
      key: idx,
      label:
        item?.[labelKey] ||
        customLabelGenerator?.({ item }) ||
        item?.label ||
        item,
      value: item?.[valueKey] || item?.value || item,
    });
  });

  return result;
};

/**
 * @description
 * Function to create select option.
 * Start with 'Select' option.
 * @param {list} items
 * List of items to be created as select option.
 * @param {string} labelKey
 * Key to be used as label.
 * @param {string} valueKey
 * Key to be used as value.
 * @returns {list}
 * List of select option.
 */
export const createOptionalSelectOption = ({
  items,
  labelKey,
  valueKey,
  optionalLabel,
  optionalValue,
  customLabelGenerator,
  isGridFilter,
}) => {
  if (!items) return [];

  const result = [
    {
      key: 0,
      label: optionalLabel || isGridFilter ? 'All' : 'Select',
      value: optionalValue || '',
    },
  ];

  items?.forEach((item, idx) => {
    result?.push({
      key: idx + 1,
      label:
        item?.[labelKey] ||
        customLabelGenerator?.({ item }) ||
        item?.label ||
        item,
      value: item?.[valueKey] || item?.value || item,
    });
  });

  return result;
};
