import React from 'react';
import StyledUserWrapper from '../../../../../style/Common/Header/StyledSessionInfo/StyledUser/StyledUserWrapper';

/**
 * @description
 * Component to display the user information.
 * @returns {React.ReactElement}
 */
const User = () => {
  /** Get user id from local storage */
  const userID = localStorage.getItem('userId');

  return (
    <StyledUserWrapper>
      <span className="text-[#B2BDC9] mr-2">Hi,</span>
      <span>{userID || 'NGL'}</span>
    </StyledUserWrapper>
  );
};

export default User;
