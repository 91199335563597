import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import CommonButton from '../../../Common/Controller/Button/index';
import {
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../../constant/Common/Style/preset';
import saveDamageReport from '../../../../util/Safety/DamageReport/Handler/saveDamageReport';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const DamageFormModalButton = ({
  seq,
  modalType,
  postDamageReportMutator,
  imageFileList,
  setImageFileList,
  damagePatchMutator,
  completedPatchMutator,
  isCompleted,
  setAlertOptions,
  isEditable,
  damageReport,
  setFileKey,
}) => {
  const { handleSubmit, reset } = useFormContext();
  const saveButtonRef = useRef(null);
  return (
    <div className="flex justify-end w-full gap-x-[17px] mt-[8px]">
      {!isCompleted && isEditable && (
        <>
          <div>
            <CommonButton
              icon={PATH_COMMON_BUTTON_ICON?.clearRed}
              styles={PRESET_RED}
              onClick={() => {
                const existImageFileList = damageReport?.photo?.map(
                  (file, index) => ({
                    fileKey: index,
                    fileInfo: file,
                  }),
                );

                setImageFileList(existImageFileList || []);
                setFileKey(existImageFileList?.length || 0);
                reset();
              }}
            >
              Clear
            </CommonButton>
          </div>
          <div>
            <CommonButton
              ref={saveButtonRef}
              icon={PATH_COMMON_BUTTON_ICON?.saveBlue}
              styles={PRESET_PRIMARY}
              onClick={() => {
                saveDamageReport({
                  seq,
                  modalType,
                  handleSubmit,
                  postDamageReportMutator,
                  setAlertOptions,
                  imageFileList,
                  damagePatchMutator,
                  completedPatchMutator,
                  saveButtonRef,
                });
              }}
            >
              Save
            </CommonButton>
          </div>
        </>
      )}

      {modalType === EDIT_MODE?.edit && isEditable && (
        <div>
          <CommonButton
            icon={
              isCompleted
                ? PATH_COMMON_BUTTON_ICON?.banRed
                : PATH_COMMON_BUTTON_ICON?.checkBlue
            }
            styles={isCompleted ? PRESET_RED : PRESET_PRIMARY}
            preventDupClick
            onClick={({ buttonRef }) => {
              completedPatchMutator({ seq, buttonRef });
            }}
          >
            {isCompleted ? 'Incomplete' : 'Completed'}
          </CommonButton>
        </div>
      )}
    </div>
  );
};

export default DamageFormModalButton;
