import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import postVANData from '../../../../service/Safety/VAN/Post/postVANData';
import safetyVANKeys from '../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const usePostVANData = ({ ...props }) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const { setAlertModalStatus, setAlertOpen, setModalOpen } = props;

  const mutator = useMutation({
    mutationFn: postVANData,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setModalOpen?.(prev => false);
      queryClient?.invalidateQueries({ queryKey: safetyVANKeys?.listData() });
      addToast({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setAlertOpen(prev => true);
    },
  });

  return { ...mutator };
};

export default usePostVANData;
