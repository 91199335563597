import styled from 'styled-components';

const StyledCustomControllerWrapper = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
`;

export default StyledCustomControllerWrapper;
