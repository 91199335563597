import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CommonTextInput from '../../../Common/Controller/Text';
import CommonDate from '../../../Common/Controller/Date';
import CommonTime from '../../../Common/Controller/Time/index';
import CommonSelect from '../../../Common/Controller/Select';
import {
  handleDateInputChange,
  handleInputChange,
  handleTimeChange,
} from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';

const LocationBasicInfoFields = ({
  yardOptions: originYardOptions,
  isCompleted,
  damageReport,
  divOptions,
  isEditable,
}) => {
  const { watch } = useFormContext();
  const [yardOptions, setYardOptions] = useState([]);

  const damageDiv = watch('div');

  useEffect(() => {
    const tempYardOptions = damageDiv
      ? originYardOptions?.filter(yard => yard?.div === damageDiv)
      : [];

    setYardOptions(tempYardOptions);
  }, [damageDiv]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between">
        <div className="w-[173px]">
          <CommonTextInput
            label="CREATED BY"
            inputName="created_by"
            disabled
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.created_by || ''}
          />
        </div>
        <div className="w-[173px]">
          <CommonDate
            inputName="created_date"
            disabled
            onChangeHandler={handleDateInputChange}
            defaultValue={damageReport?.created_date || ''}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[173px]">
          <CommonTextInput
            label="UPDATED BY"
            inputName="updated_by"
            disabled
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.updated_by || ''}
          />
        </div>
        <div className="w-[173px]">
          <CommonDate
            inputName="updated_date"
            disabled
            onChangeHandler={handleDateInputChange}
            defaultValue={damageReport?.updated_date || ''}
          />
        </div>
      </div>
      <div className="flex">
        <div className="mr-[8px] w-[134px]">
          <CommonSelect
            label="DIV"
            required
            option={divOptions}
            inputName="div"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.div || ''}
          />
        </div>
        <div className="mr-[3px] w-[114px]">
          <CommonDate
            label="DATE & TIME"
            inputName="report_date"
            required
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleDateInputChange}
            defaultValue={damageReport?.report_date || ''}
          />
        </div>
        <div className="w-[95px]">
          <CommonTime
            inputName="report_time"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleTimeChange}
            required
            defaultValue={
              damageReport?.report_date &&
              damageReport?.report_time &&
              dayjs(
                `${damageReport?.report_date} ${damageReport?.report_time}`,
              )?.isValid()
                ? dayjs(
                    `${damageReport?.report_date} ${damageReport?.report_time}`,
                  )
                : null
            }
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-[134px]">
          <CommonSelect
            label="YARD"
            option={yardOptions}
            inputName="yard"
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.yard || ''}
          />
        </div>
        <div className="w-[213px]">
          <CommonTextInput
            label="LOCATION"
            inputName="yard_location"
            maxLength={5}
            disabled={isCompleted || !isEditable}
            onChangeHandler={handleInputChange}
            defaultValue={damageReport?.yard_location || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationBasicInfoFields;
