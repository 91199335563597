import styled from 'styled-components';

const StyledDivisionSelectorOptionWrapper = styled.div`
  width: 90px;
  height: fit-content;

  background-color: white;
  border-radius: 0 0 5px 5px;
  position: absolute;
  top: 39px;
`;

export default StyledDivisionSelectorOptionWrapper;
