/**
 * @description
 * Function that handle the row add button click event.
 * @param {object} gridParam
 * Parameter that contains the grid object(API).
 * @param {function} onAddClicked
 * Function when add button clicked.
 */
const clickRowAdd = ({ gridParam, onAddClicked, ...props }) => {
  /** Call onAddClicked function when function exist */
  onAddClicked && onAddClicked({ gridParam, ...props });
};

export default clickRowAdd;
