import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import { DAMAGE_FORM_MODAL } from '../../../../style/Safety/DamageReport/styles';
import DamageFormModalHeader from '../DamageFormModalHeader';
import DamageFormModalContent from '../DamageFormModalContent';
import DamageFormModalButton from '../DamageFormModalButton';

const DamageFormModal = ({
  isOpenFormModal,
  setIsOpenFormModal,
  modalType,
  damagePatchMutator,
  completedPatchMutator,
  seq,
  isCompleted,
  damageReportController,
  postDamageReportMutator,
  damageReport,
  optionList,
  setAlertOptions,
  isEditable,
}) => {
  const [imageFileList, setImageFileList] = useState([]);
  const [fileKey, setFileKey] = useState(0);

  useEffect(() => {
    const existImageFileList = damageReport?.photo?.map((file, index) => ({
      fileKey: index,
      fileInfo: file,
    }));

    setImageFileList(existImageFileList || []);
    setFileKey(existImageFileList?.length || 0);
  }, [damageReport?.photo]);

  return (
    <FormProvider {...damageReportController}>
      <form>
        <CommonMessageModalFrame
          isOpen={isOpenFormModal}
          setOpen={setIsOpenFormModal}
          styles={DAMAGE_FORM_MODAL}
          onCloseButtonClick={() => setIsOpenFormModal(false)}
          header={<DamageFormModalHeader modalType={modalType} />}
          content={
            <DamageFormModalContent
              modalType={modalType}
              setAlertOptions={setAlertOptions}
              isCompleted={isCompleted}
              damageReport={damageReport}
              optionList={optionList}
              isEditable={isEditable}
              imageFileList={imageFileList}
              setImageFileList={setImageFileList}
              fileKey={fileKey}
              setFileKey={setFileKey}
            />
          }
          button={
            <DamageFormModalButton
              seq={seq}
              modalType={modalType}
              postDamageReportMutator={postDamageReportMutator}
              imageFileList={imageFileList}
              setImageFileList={setImageFileList}
              damagePatchMutator={damagePatchMutator}
              completedPatchMutator={completedPatchMutator}
              isCompleted={isCompleted}
              setIsOpenFormModal={setIsOpenFormModal}
              damageReport={damageReport}
              setAlertOptions={setAlertOptions}
              isEditable={isEditable}
              setFileKey={setFileKey}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default DamageFormModal;
