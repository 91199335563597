import {
  GRID_PINNED_ROW_HEIGHT,
  GRID_ROW_HEIGHT,
} from '../../../../constant/Common/DataGrid/setting';

/**
 * @description
 * Function to get row height.
 * @param {object} gridParam
 * Patameter that contains the grid object(API).
 * @returns
 * Row height.
 */
const getRowHeight = ({ gridParam, ...props }) => {
  if (gridParam?.node?.rowPinned) {
    const rowHeight = GRID_PINNED_ROW_HEIGHT;
    return rowHeight;
  }
  return GRID_ROW_HEIGHT;
};

export default getRowHeight;
