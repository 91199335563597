import React from 'react';
import ErrorNotFound from '../../../component/Common/Error/ErrorNotFound';

/**
 * @description
 * Component of the not found page.
 * @returns {React.ReactElement}
 */
const NotFound = () => {
  return <ErrorNotFound />;
};

export default NotFound;
