import React from 'react';
import StyledModalContentWrapper from '../../../../../style/Inventory/Modal/EditModal/StyledModalContentWrapper';
import UpdateInfoArea from './UpdateInfoArea';
import BasicInfo from './BasicInfo';
import createEquipField from '../../../../../util/Inventory/Common/Generator/createEquipField';

const ModalContent = ({ equip, ...props }) => {
  const EquipInfo = createEquipField({ type: equip });

  return (
    <StyledModalContentWrapper>
      <UpdateInfoArea {...props} />
      <BasicInfo equip={equip} {...props} />
      {EquipInfo && <EquipInfo {...props} />}
    </StyledModalContentWrapper>
  );
};

export default ModalContent;
