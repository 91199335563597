import { DAMAGE_STATUS } from './standardCodes';

export const DEFAULT_DAMAGE_DATA = {
  isNGLTruck: false,
  trk_no_auto_complete: undefined,
  trk_no_text: undefined,
  driver: undefined,
  status: DAMAGE_STATUS?.DAMAGED,
  trk_ordometer: undefined,
  trk_lights: false,
  trk_safety_eq: false,
  trk_air_compressor: false,
  trk_head_stop: false,
  trk_fire_ext: false,
  trk_battery: false,
  trk_tail_dash: false,
  trk_spare_tire: false,
  trk_brakes: false,
  trk_turn_indicators: false,
  trk_defroster: false,
  trk_clutch: false,
  trk_mirrors: false,
  trk_air_leak: false,
  trk_driveshaft: false,
  trk_coolant: false,
  trk_ac: false,
  trk_engine: false,
  trk_windshield: false,
  trk_horn: false,
  trk_fuild_level: false,
  trk_wipers: false,
  trk_fifth_wheel: false,
  trk_fuel_filter: false,
  trk_fuel_tanks: false,
  trk_release_lever: false,
  trk_oil_pressure: false,
  trk_power_steering: false,
  trk_hubcap: false,
  trk_radiator: false,
  trk_reflectors: false,
  trk_eld: false,
  trk_springs: false,
  trk_muffler: false,
  trk_dashcam: false,
  trk_transmission: false,
  body_condition: false,
  body_condition_remarks: undefined,
  registration: false,
  ifta: false,
  coi: false,
  trk_others: undefined,
  cont_no: undefined,
  cont_seal: undefined,
  isNGLChassis: false,
  ch_no_auto_complete: undefined,
  ch_no_text: undefined,
  ch_plate_no: undefined,
  ch_bit: undefined,
  ch_annual: undefined,
  ch_reg: false,
  ch_seal: undefined,
  ch_lights: false,
  ch_door: false,
  ch_brakes: false,
  ch_reflector: false,
  ch_side: false,
  ch_coupling: false,
  ch_mudflap: false,
  ch_roof: false,
  ch_suspension: false,
  ch_landing_gear: false,
  ch_kingpin: false,
  ch_hubcap: false,
  ch_others: undefined,
  div: '',
  yard: '',
  yard_location: '',
  st_left_dmg: false,
  st_right_dmg: false,
  dt_lfo_dmg: false,
  dt_lfi_dmg: false,
  dt_rfo_dmg: false,
  dt_rfi_dmg: false,
  dt_lro_dmg: false,
  dt_lri_dmg: false,
  dt_rro_dmg: false,
  dt_rri_dmg: false,
  tct_lfo_orig: false,
  tct_lfo_dmg: false,
  tct_lfi_orig: false,
  tct_lfi_dmg: false,
  tct_rfo_orig: false,
  tct_rfo_dmg: false,
  tct_rfi_orig: false,
  tct_rfi_dmg: false,
  tct_lro_orig: false,
  tct_lro_dmg: false,
  tct_lri_orig: false,
  tct_lri_dmg: false,
  tct_rro_orig: false,
  tct_rro_dmg: false,
  tct_rri_orig: false,
  tct_rri_dmg: false,
  completed: false,
  created_by: undefined,
  created_date: undefined,
  updated_by: undefined,
  updated_date: undefined,
  report_date: undefined,
  report_time: undefined,
  photo: [],
};
