import safetyDefaultAPI from '../../api';
import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';

const patchDamage = async ({ seq, patchData }) => {
  try {
    const response = await safetyDefaultAPI.patch(
      `damage-report/${seq}`,
      patchData,
    );
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchDamage;
