import styled from 'styled-components';

const StyledCommonBlurWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);

  /* Do not fix this value */
  z-index: 9999;
`;

export default StyledCommonBlurWrapper;
