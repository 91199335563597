import styled from 'styled-components';

const StyledFileTableHeaderWrapper = styled.div`
  width: 100%;
  height: 31px;
  background-color: transparent;

  /* border: 1px solid #eee; */
  display: flex;
  flex-direction: row;
`;

export default StyledFileTableHeaderWrapper;
