export const COMMON_STATE = [
  {
    seq: 0,
    label: 'AL',
    value: 'AL',
  },
  {
    seq: 1,
    label: 'AK',
    value: 'AK',
  },
  {
    seq: 2,
    label: 'AZ',
    value: 'AZ',
  },
  {
    seq: 3,
    label: 'AR',
    value: 'AR',
  },
  {
    seq: 4,
    label: 'CA',
    value: 'CA',
  },
  {
    seq: 5,
    label: 'CO',
    value: 'CO',
  },
  {
    seq: 6,
    label: 'CT',
    value: 'CT',
  },
  {
    seq: 7,
    label: 'DE',
    value: 'DE',
  },
  {
    seq: 8,
    label: 'FL',
    value: 'FL',
  },
  {
    seq: 9,
    label: 'GA',
    value: 'GA',
  },
  {
    seq: 10,
    label: 'HI',
    value: 'HI',
  },
  {
    seq: 11,
    label: 'ID',
    value: 'ID',
  },
  {
    seq: 12,
    label: 'IL',
    value: 'IL',
  },
  {
    seq: 13,
    label: 'IN',
    value: 'IN',
  },
  {
    seq: 14,
    label: 'IA',
    value: 'IA',
  },
  {
    seq: 15,
    label: 'KS',
    value: 'KS',
  },
  {
    seq: 16,
    label: 'KY',
    value: 'KY',
  },
  {
    seq: 17,
    label: 'LA',
    value: 'LA',
  },
  {
    seq: 18,
    label: 'ME',
    value: 'ME',
  },
  {
    seq: 19,
    label: 'MD',
    value: 'MD',
  },
  {
    seq: 20,
    label: 'MA',
    value: 'MA',
  },
  {
    seq: 21,
    label: 'MI',
    value: 'MI',
  },
  {
    seq: 22,
    label: 'MN',
    value: 'MN',
  },
  {
    seq: 23,
    label: 'MS',
    value: 'MS',
  },
  {
    seq: 24,
    label: 'MO',
    value: 'MO',
  },
  {
    seq: 25,
    label: 'MT',
    value: 'MT',
  },
  {
    seq: 26,
    label: 'NE',
    value: 'NE',
  },
  {
    seq: 27,
    label: 'NV',
    value: 'NV',
  },
  {
    seq: 28,
    label: 'NH',
    value: 'NH',
  },
  {
    seq: 29,
    label: 'NJ',
    value: 'NJ',
  },
  {
    seq: 30,
    label: 'NM',
    value: 'NM',
  },
  {
    seq: 31,
    label: 'NY',
    value: 'NY',
  },
  {
    seq: 32,
    label: 'NC',
    value: 'NC',
  },
  {
    seq: 33,
    label: 'ND',
    value: 'ND',
  },
  {
    seq: 34,
    label: 'OH',
    value: 'OH',
  },
  {
    seq: 35,
    label: 'OK',
    value: 'OK',
  },
  {
    seq: 36,
    label: 'OR',
    value: 'OR',
  },
  {
    seq: 37,
    label: 'PA',
    value: 'PA',
  },
  {
    seq: 38,
    label: 'RI',
    value: 'RI',
  },
  {
    seq: 39,
    label: 'SC',
    value: 'SC',
  },
  {
    seq: 40,
    label: 'SD',
    value: 'SD',
  },
  {
    seq: 41,
    label: 'TN',
    value: 'TN',
  },
  {
    seq: 42,
    label: 'TX',
    value: 'TX',
  },
  {
    seq: 43,
    label: 'UT',
    value: 'UT',
  },
  {
    seq: 44,
    label: 'VT',
    value: 'VT',
  },
  {
    seq: 45,
    label: 'VA',
    value: 'VA',
  },
  {
    seq: 46,
    label: 'WA',
    value: 'WA',
  },
  {
    seq: 47,
    label: 'WV',
    value: 'WV',
  },
  {
    seq: 48,
    label: 'WI',
    value: 'WI',
  },
  {
    seq: 49,
    label: 'WY',
    value: 'WY',
  },
];
