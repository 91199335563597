import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import SAFETY_VAN_MODAL_DEFAULT_VALUE from '../../../../constant/Safety/VAN/Modal/defaultValue';

const createModalDefaultValue = ({ specificData, editMode }) => {
  if (editMode === EDIT_MODE?.add) return { ...SAFETY_VAN_MODAL_DEFAULT_VALUE };

  if (specificData) {
    const specificDefaultData = {
      div: specificData?.div || '',
      size: specificData?.size || '',
      type: specificData?.type || '',
      trailer_no: specificData?.trailer_no || '',
      trailer_make: specificData?.trailer_make || '',
      make_year: specificData?.make_year,
      vin_no: specificData?.vin_no || '',
      plate_no: specificData?.plate_no || '',
      asset_tracker_no: specificData?.asset_tracker_no || '',
      state: specificData?.state || '',
      bank: specificData?.bank || '',
      purchase_amt: specificData?.purchase_amt || '',
      total_loan_amt: specificData?.total_loan_amt || '',
      sche_payment: specificData?.sche_payment || '',
      interest_rate: specificData?.interest_rate,
      loan_terms: specificData?.loan_terms,
      loan_status: specificData?.loan_status || '',
      loan_start_date: specificData?.loan_start_date || null,
      loan_end_date: specificData?.loan_end_date || null,
      extra_payment: specificData?.extra_payment || '',
      depreciation_amt: specificData?.depreciation_amt || '',
      depreciation_start_date: specificData?.depreciation_start_date || null,
      depreciation_end_date: specificData?.depreciation_end_date || null,
      depreciable_life: specificData?.depreciable_life,
      depreciation_status: specificData?.depreciation_status || '',
      remarks: specificData?.remarks || '',
      active: specificData?.active,
      created_by: specificData?.created_by || '',
      created_date: specificData?.created_date || null,
      updated_by: specificData?.updated_by || '',
      updated_date: specificData?.updated_date || null,
      doc: [
        {
          updated_date: null,
          document: '',
          exp_date: null,
          file_url: null,
          updated_by: '',
        },
        ...specificData?.doc,
      ],
    };

    return specificDefaultData;
  }

  return { ...SAFETY_VAN_MODAL_DEFAULT_VALUE };
};

export default createModalDefaultValue;
