import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  STYLE_CONTENT_WRAPPER,
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
} from '../../../../style/DailyInOut/StyledAddEditModalWrapper/style';
import { createMandatorySelectOption } from '../../../../util/Common/Option/Generator/selectOptionCreator';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import CommonSelect from '../../../Common/Controller/Select';
import CommonTextInput from '../../../Common/Controller/Text';

const Trailer = ({
  selectedDailyInOut,
  isAdd,
  isOut,
  inventoryTrailerList,
  sizeOption,
  typeOption,
  inputCategory,
  setInputCategory,
  selectedYard,
  isBare,
  isLoadingInventoryTrailerList,
  existingData,
}) => {
  const { setValue, getValues } = useFormContext();
  const [selectedItem, setSelectedItem] = useState({});

  const resetFieldsData = () => {
    setValue('trailer_no', '');
    setValue('trailer_size', '');
    setValue('trailer_type', '');
    setValue('trailer_seal', '');
    setValue('trailer_color', '');
    setValue('trailer_yard_location', '');
    setValue('trailer_remarks', '');
  };

  useEffect(() => {
    setSelectedItem({});
  }, [isOut]);

  useEffect(() => {
    if (inputCategory === 'container') {
      resetFieldsData();
    }
  }, [inputCategory]);

  return (
    <div className="w-full h-[203px] bg-[#f9f9f9] mt-[10px] pt-[20px] pl-[36px] pr-[36px] flex flex-col gap-[10px]">
      {/* Header */}
      <div
        className={`w-full h-fit ${
          inputCategory === 'container' || inputCategory === 'chassis' || isBare
            ? 'text-[#A7A7A7]'
            : 'text-[#264B9F]'
        } text-[14px] font-bold`}
      >
        Trailer
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            {isOut ? (
              <CommonAutoComplete
                label="TRAILER #"
                inputName="trailer_no"
                width="w-[140px]"
                disabled={
                  !isAdd ||
                  inputCategory === 'container' ||
                  inputCategory === 'chassis' ||
                  (isOut && !selectedYard) ||
                  isBare
                }
                defaultValue={
                  !isAdd
                    ? selectedDailyInOut?.trailer_no
                    : existingData?.trailer_no
                }
                option={createMandatorySelectOption({
                  items: inventoryTrailerList,
                  labelKey: 'trailer_no',
                  valueKey: 'trailer_no',
                })}
                onChangeHandler={({ e, onChange, selectProps }) => {
                  resetFieldsData();
                  onChange?.(selectProps?.value);
                  setSelectedItem(
                    inventoryTrailerList?.find(
                      item => item?.trailer_no === selectProps?.value,
                    ) || {},
                  );
                  if (selectProps?.value) {
                    setInputCategory('trailer');
                  } else {
                    setInputCategory('');
                  }
                }}
                isLoading={isLoadingInventoryTrailerList}
              />
            ) : (
              <CommonTextInput
                label="TRAILER #"
                inputName="trailer_no"
                width="w-[140px]"
                disabled={
                  !isAdd ||
                  isOut ||
                  inputCategory === 'container' ||
                  inputCategory === 'chassis' ||
                  isBare
                }
                defaultValue={
                  !isAdd
                    ? selectedDailyInOut?.trailer_no
                    : existingData?.trailer_no
                }
                maxLength={12}
                onChangeHandler={({ e, onChange }) => {
                  onChange(e?.target?.value?.toUpperCase());
                  setInputCategory('trailer');
                  if (!e?.target?.value) {
                    setInputCategory('');
                  }
                }}
              />
            )}
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SIZE"
              inputName="trailer_size"
              width="w-[140px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_size : '') ||
                (isOut ? selectedItem?.size : existingData?.trailer_size)
              }
              required={isAdd && getValues('trailer_no')}
              option={sizeOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="trailer_type"
              width="w-[140px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_type : '') ||
                (isOut ? selectedItem?.type : existingData?.trailer_type)
              }
              required={isAdd && getValues('trailer_no')}
              option={typeOption}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="SEAL #"
              inputName="trailer_seal"
              width="w-[140px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_seal : '') ||
                (isOut ? selectedItem?.seal : existingData?.trailer_seal)
              }
              maxLength={20}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_CONTENT_WRAPPER}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="COLOR"
              inputName="trailer_color"
              width="w-[140px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_color : '') ||
                (isOut ? selectedItem?.color : existingData?.trailer_color)
              }
              maxLength={10}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="LOCATION"
              inputName="trailer_yard_location"
              width="w-[140px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_yard_location : '') ||
                (isOut ? selectedItem?.yard_location : '')
              }
              maxLength={5}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="REMARKS"
              inputName="trailer_remarks"
              width="w-[293px]"
              disabled={
                !isAdd ||
                isOut ||
                inputCategory === 'container' ||
                inputCategory === 'chassis' ||
                isBare
              }
              defaultValue={
                (!isAdd ? selectedDailyInOut?.trailer_remarks : '') ||
                (isOut ? selectedItem?.remarks : existingData?.trailer_remarks)
              }
              maxLength={50}
              onChangeHandler={({ e, onChange }) => {
                onChange(e?.target?.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trailer;
