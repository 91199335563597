import formatPermission from './formatPermission';

const handleSaveClicked = ({ e, patchGroupMutation }) => {
  const {
    id,
    name,
    permissions,
    tms_group: tmsGroup,
    yms_group: ymsGroup,
    ...editData
  } = e?.gridParam?.data;

  const convertedPermissions = {};

  const permissionNameList = Object?.keys?.(permissions);
  permissionNameList.forEach(key => {
    convertedPermissions[key] =
      editData?.[key] === undefined
        ? permissions?.[key]
        : formatPermission(key, editData[key]);
  });

  patchGroupMutation({
    id,
    data: { name, permissions: convertedPermissions },
  });
};

export default handleSaveClicked;
