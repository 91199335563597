export const appendRow = ({ ...props }) => {
  const { append, getValues, setValue } = props;

  const addRowValue = getValues('doc.0');

  const targetObj = {
    ...addRowValue,
  };

  append({ ...targetObj });

  setValue('doc.0', {
    updated_date: null,
    updated_time: null,
    document: '',
    exp_date: null,
    updated_by: '',
    file_url: null,
  });
};

export const removeRow = ({ ...props }) => {
  const { remove, rowIdx, getValues } = props;

  remove?.(rowIdx);
};
