import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDivision from '../../../service/Common/Get/getDivision';
import commonDataKeys from './keys';

const useGetDivisionList = ({ ...props }) => {
  const { keyword } = props;

  const [divisionList, setDivisionList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys?.divisionList({ keyword }),
    queryFn: () => getDivision({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setDivisionList(prev => query?.data?.data);
    } else {
      setDivisionList(prev => []);
    }
  }, [query?.data]);

  return { divisionList, ...query };
};

export default useGetDivisionList;
