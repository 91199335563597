import styled, { css } from 'styled-components';

const StyledCommonButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props?.styles?.iconSize || '15px'};
  height: ${props => props?.styles?.iconSize || '15px'};

  /** Small size */
  ${props =>
    props?.styles?.size === 'small' &&
    css`
      width: 14px;
      min-width: 14px;
      height: 14px;
      min-height: 14px;
    `}
`;

export default StyledCommonButtonIconWrapper;
