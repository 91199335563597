import { useMutation } from '@tanstack/react-query';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchGroup from '../../../../service/Admin/Auth/Patch/patchGroup';
import useControlToast from '../../../Common/Toast/useControlToast';
import resetRow from '../../../../util/Common/Datagrid/Handler/resetRow';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchGroupMutation = ({
  gridRef,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
}) => {
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: patchGroup,
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { id: variables?.id, ...variables?.data },
        isUpdate: true,
        key: 'id',
      });
      addToast?.({ message: SUCCESS_UPDATE });
    },
    onError: error => {
      resetRow({ gridRef });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePatchGroupMutation;
