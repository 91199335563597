import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import inventoryKeys from '../../keys';
import getEquipList from '../../../../service/Inventory/Common/Get/getEquipList';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import generateQueryParams from '../../../../util/Inventory/Common/Generator/generateQueryParams';

const useGetEquipList = ({ equip, currDiv, yardQuery, ...props }) => {
  const { setAlertOpen, setAlertModalStatus } = props;

  const searchParams = generateQueryParams({
    yard: yardQuery,
    div: currDiv,
  });

  const [listData, setListData] = useState([]);

  const query = useQuery({
    queryKey: inventoryKeys.equipList({ equip, div: currDiv, yardQuery }),
    queryFn: () => getEquipList({ equip, params: searchParams }),
    refetchOnWindowFocus: false,
    enabled: !!searchParams.get('yard'),
  });

  useEffect(() => {
    if (query?.data) {
      setListData(prev => query?.data?.data);
    } else {
      setListData(prev => []);
    }
  }, [query?.data]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { ...query, listData };
};

export default useGetEquipList;
