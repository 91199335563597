const generateQueryParams = query => {
  const searchParams = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    if (_.isObject(value)) {
      const selectedList = Object.entries(value).reduce(
        (acc, [item, selected]) => {
          if (selected) acc.push(item);
          return acc;
        },
        [],
      );
      selectedList.forEach(item => searchParams.append(key, item));
    } else searchParams.append(key, value);
  });

  return searchParams;
};
export default generateQueryParams;
