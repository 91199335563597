import dayjs from 'dayjs';

const convertInputData = ({
  dateList,
  timeList,
  possibleEmptyStringList,
  autoCompleteList,
  inputData,
}) => {
  const convertedData = { ...inputData };

  [...dateList]?.forEach(key => {
    convertedData[key] =
      convertedData[key] && dayjs(convertedData[key])?.isValid()
        ? dayjs(convertedData[key])?.format('YYYY-MM-DD')
        : '';
  });

  [...timeList]?.forEach(key => {
    convertedData[key] =
      convertedData[key] && dayjs(convertedData[key])?.isValid()
        ? dayjs(convertedData[key])?.format('HH:mm:ss')
        : '';
  });

  [...autoCompleteList]?.forEach(key => {
    convertedData[key] = convertedData[key] || '';
  });

  Object?.keys?.(convertedData)?.forEach(key => {
    if (convertedData[key] === '') {
      convertedData[key] = possibleEmptyStringList?.includes(key)
        ? ''
        : undefined;
    }
  });

  return convertedData;
};

export default convertInputData;
