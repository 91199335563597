import React from 'react';
import StyledSidebarSubMenuWrapper from '../../../../../style/Common/Sidebar/StyledSidebarMenu/StyledSidebarSubMenu/StyledSidebarSubMenuWrapper';
import SidebarSubMenuItem from './SidebarSubMenuItem';

/**
 * @description
 * Sidebar sub menu component.
 * @param {Array} menu
 * Array of sub menu items.
 * @param {function} setOpen
 * Function to handle the sidebar open event.
 * @returns {React.ReactElement}
 */
const SidebarSubMenu = ({ ...props }) => {
  const { menu, setOpen } = props;
  return (
    <StyledSidebarSubMenuWrapper>
      {menu?.map((subMenu, index) => (
        <SidebarSubMenuItem key={subMenu?.seq} setOpen={setOpen} {...subMenu} />
      ))}
    </StyledSidebarSubMenuWrapper>
  );
};

export default SidebarSubMenu;
