import styled from 'styled-components';

const StyledDivisionSelectorItemWrapper = styled.div`
  width: 100%;
  height: 30px;

  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: bolder;

  cursor: pointer;

  transition-duration: 300ms;
  &:hover {
    background-color: #f2f4f8;
  }
`;

export default StyledDivisionSelectorItemWrapper;
