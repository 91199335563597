import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const getDailyInOutList = async ({ div, fromDate, toDate, yard }) => {
  try {
    const params = {
      ...(div && { div }),
      ...(fromDate && { from_date: fromDate }),
      ...(toDate && { to_date: toDate }),
      ...(yard && { yard }),
    };

    const response = await dailyInOutDefaultAPI.get('', { params });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDailyInOutList;
