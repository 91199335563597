import checkEmailFormat from '../../Validator/Handler/checkEmailFormat';

const validateEmail = ({ ...props }) => {
  return {
    email: v => {
      const email = v;
      const isEmailValid = checkEmailFormat({ email });
      if (isEmailValid) return true;
      return 'Please enter a valid email format.';
    },
  };
};

export default validateEmail;
