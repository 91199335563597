import { DatePicker } from '@mui/x-date-pickers';
import styled, { css } from 'styled-components';

const StyledCustomDate = styled(DatePicker)`
  /* Pure setting */
  width: ${props => props?.$styles?.width || '100%'};
  height: ${props => props?.$styles?.height || '30px'};

  .MuiSvgIcon-root {
    color: ${props => props?.$styles?.iconColor || '#264B9F'};
    width: 16px;
    height: 16px;
  }

  /** Suppress button button */
  ${props =>
    props?.$suppressUtilityButton &&
    css`
      .MuiButtonBase-root {
        display: none !important;
      }

      .MuiInputAdornment-positionEnd {
        display: none !important;
      }
    `}

  .MuiInputBase-root {
    width: 100%;
    height: 100%;
    background-color: ${props => props?.$styles?.backgroundColor || '#fff'};
    border-radius: ${props => props?.$styles?.borderRadius || '5px'};

    font-size: ${props => props?.$styles?.fontSize || '12px'};
    font-weight: ${props => props?.$styles?.fontWeight || '500'};
    font-style: ${props => props?.$styles?.fontStyle || 'normal'};
    color: ${props => props?.$styles?.color || '#383535'};

    /** Hover case */
    &:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error):hover fieldset {
      border-width: ${props => props?.$styles?.hoverBorderWidth || '1px'};
      border-color: ${props => props?.$styles?.hoverBorderColor || '#8E9396'};
      border-style: ${props => props?.$styles?.hoverBorderStyle || 'solid'};
      border: ${props => props?.$styles?.hoverBorder || '1px solid #8E9396'};
    }

    /** Focus case */
    &.Mui-focused fieldset {
      border-color: ${props => props?.$styles?.focusBorderColor || '#264B9F'};
      border-width: ${props => props?.$styles?.focusBorderWidth || '1.2px'};
      border-style: ${props => props?.$styles?.focusBorderStyle || 'solid'};
      border: ${props => props?.$styles?.focusBorder || '1.2px solid #264B9F'};
      box-shadow: ${props =>
        props?.$styles?.focusBoxShadow || '0px 0px 4px 0px #8BBCE9'};
    }

    /** Disabled case */
    &.Mui-disabled fieldset {
      border-color: ${props =>
        props?.$styles?.disabledBorderColor || '#D9D9D9'};
      border-width: ${props => props?.$styles?.disabledBorderWidth || '1px'};
      border-style: ${props => props?.$styles?.disabledBorderStyle || 'solid'};
      border: ${props => props?.$styles?.disabledBorder || '1px solid #D9D9D9'};
    }
    &.Mui-disabled {
      background-color: ${props =>
        props?.$styles?.disabledBackgroundColor || '#F9F9F9'};
    }

    /** Error case */
    &.Mui-error fieldset {
      border-color: ${props => props?.$styles?.errorBorderColor || '#C24D4D'};
      border-width: ${props => props?.$styles?.errorBorderWidth || '1.2px'};
      border-style: ${props => props?.$styles?.errorBorderStyle || 'solid'};
      border: ${props => props?.$styles?.errorBorder || '1.2px solid #C24D4D'};
    }
  }

  .Mui-disabled {
    -webkit-text-fill-color: ${props =>
      props?.$styles?.disabledTextFillColor || '#222'} !important;
  }

  fieldset {
    border-width: ${props => props?.$styles?.borderWidth || '1px'};
    border-color: ${props => props?.$styles?.borderColor || '#D9D9D9'};
    border-style: ${props => props?.$styles?.borderStyle || 'solid'};
    border: ${props => props?.$styles?.border || '1px solid #D9D9D9'};

    border-radius: ${props => props?.$styles?.borderRadius || '5px'};
  }
`;

export default StyledCustomDate;
