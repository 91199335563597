import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import commonDataKeys from './keys';
import getSSLList from '../../../service/Common/Get/getSSLList';

const useGetSSLList = ({ ...props }) => {
  const { keyword } = props;

  const [sslList, setSSLList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys?.sslList({ keyword }),
    queryFn: () => getSSLList({ keyword }),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setSSLList(prev => query?.data?.data);
    } else {
      setSSLList(prev => []);
    }
  }, [query?.data]);

  return { sslList, ...query };
};

export default useGetSSLList;
