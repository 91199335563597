import React, { useRef } from 'react';
import CommonDataGrid from '../../../Common/Datagrid';
import StyledSafetyDatagridWrapper from '../../../../style/Safety/Common/SafetyDatagrid/StyledSafetyDatagridWrapper';
import clickGridRow from '../../../../util/Safety/Common/Handler/clickGridRow';

const SafetyDatagrid = ({ ...props }) => {
  const {
    gridRef,
    columnDefs,
    data,
    setSpecificSeq,
    setModalOpen,
    setEditMode,
    preventTargetColumnID,
  } = props;

  return (
    <StyledSafetyDatagridWrapper>
      <CommonDataGrid
        gridRef={gridRef || null}
        columnDefs={columnDefs || []}
        data={data}
        onRowClicked={row =>
          clickGridRow?.({
            setSpecificSeq,
            setModalOpen,
            setEditMode,
            preventTargetColumnID,
            ...row,
          })
        }
      />
    </StyledSafetyDatagridWrapper>
  );
};

export default SafetyDatagrid;
