import { useContext } from 'react';
import { ToastContext } from '../../../context/Toast/ToastContext';

const useControlToast = () => {
  const toastContext = useContext(ToastContext);

  return { ...toastContext };
};

export default useControlToast;
