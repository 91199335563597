import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import inventoryKeys from '../../keys';
import getEquipCounts from '../../../../service/Inventory/Common/Get/getEquipCounts';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import generateQueryParams from '../../../../util/Inventory/Common/Generator/generateQueryParams';

const useGetEquipCounts = ({ equip, currDiv, yardQuery, ...props }) => {
  const { setAlertOpen, setAlertModalStatus } = props;

  const searchParams = generateQueryParams({
    yard: yardQuery,
    div: currDiv,
  });

  const [countData, setCountData] = useState([]);

  const query = useQuery({
    queryKey: inventoryKeys.equipCounts({ div: currDiv, yardQuery }),
    queryFn: () => getEquipCounts({ params: searchParams }),
    refetchOnWindowFocus: false,
    enabled: !!searchParams.get('yard'),
  });

  useEffect(() => {
    if (query?.data) setCountData(prev => query?.data?.data);
    else if (!query?.data && !query?.isLoading) setCountData({ disable: true });
    else setCountData({});
  }, [query?.data, query?.isLoading]);

  useEffect(() => {
    if (query?.isError) {
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: query?.error?.message,
        };
      });
      setAlertOpen(prev => true);
    }
  }, [query?.isError]);

  return { ...query, countData };
};

export default useGetEquipCounts;
