import { useMutation } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchCompleted from '../../../../service/Safety/DamageReport/Patch/patchCompleted';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchCompleted = ({
  gridRef,
  disabledRef,
  setAlertOptions,
  setIsOpenFormModal,
  setSeq,
}) => {
  const { setAlertInfo, setIsOpenAlert } = setAlertOptions;
  const { addToast } = useControlToast();

  const mutator = useMutation({
    mutationFn: patchCompleted,

    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { seq: variables?.seq, ...data?.data },
        isUpdate: true,
      });
      setSeq(null);
      addToast({ message: SUCCESS_UPDATE });
      setIsOpenFormModal(prev => false);
    },
    onError: (error, variables) => {
      if (variables?.setChecked) {
        variables?.setChecked(checked => !checked);
      }
      setAlertInfo(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setIsOpenAlert(prev => true);
    },
    onSettled: (data, error, variables) => {
      disabledRef.current.disabled = false;
      disabledRef.current.disabledRow = null;
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
  });

  return { ...mutator };
};

export default usePatchCompleted;
