import styled from 'styled-components';

const StyledModalContentArea = styled.div`
  width: ${props => props?.width || '100%'};
  min-width: ${props => props?.width || '100%'};
  height: ${props => props?.height || '100%'};
  min-height: ${props => props?.minHeight || props?.height || '100%'};
  background: #f9f9f9;

  padding-left: 37px;
  padding-top: 20px;
  padding-right: 36px;
`;
export default StyledModalContentArea;
