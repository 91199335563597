import { useMutation } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../constant/Common/Modal/Alert/status';
import login from '../../service/SignIn/Post/login';

const useLoginMutation = ({ setIsMessageOpen, setMessage, setMessageStatus }) =>
  useMutation({
    mutationFn: login,
    onSuccess: res => {
      const data = res.data;
      localStorage.setItem('accessToken', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('userId', data.username);
      localStorage.setItem('permission', data.permissions);
      localStorage.setItem('allDiv', data.all_div);
      localStorage.setItem('defaultDiv', data?.default_div);
      window.location.href = `/daily-in-out`;
    },
    onError: error => {
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });

export default useLoginMutation;
