import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame/index';
import AdvancedSearchModalContent from '../AdvancedSearchModalContent';
import AdvancedSearchModalButton from '../AdvancedSearchModalButton';

const AdvancedSearchModal = ({
  isOpenSearchModal,
  setIsSearchModal,
  queryObject,
  setQueryObject,
  setAlertOptions,
}) => {
  const searchController = useForm();
  return (
    <FormProvider {...searchController}>
      <form>
        <CommonMessageModalFrame
          isOpen={isOpenSearchModal}
          setOpen={setIsSearchModal}
          onCloseButtonClick={() => setIsSearchModal(false)}
          header="Advanced Search"
          content={<AdvancedSearchModalContent queryObject={queryObject} />}
          button={
            <AdvancedSearchModalButton
              setIsSearchModal={setIsSearchModal}
              setQueryObject={setQueryObject}
              setAlertOptions={setAlertOptions}
            />
          }
        />
      </form>
    </FormProvider>
  );
};

export default AdvancedSearchModal;
