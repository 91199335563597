export const LABS_GRID_DATA = [
  {
    first_name: 'James',
    last_name: 'Cha',
    phone_number: '+82)00-0000-0000',
    won: 100,
    add_text: 'James like H.G',
    add_auto_complete: 'YGB',
    add_select: 'YGB',
    add_date: '2024-02-20',
    add_time: '2024-02-20T17:04:00',
  },
  {
    first_name: 'Jayden',
    last_name: 'Lim',
    phone_number: '+82)11-1111-1111',
    won: 200,
    add_text: 'Jayden like W.H.Y',
    add_auto_complete: 'BCSD',
    add_select: 'BCSD',
    add_date: '2024-02-20',
    add_time: '2024-02-20T17:04:00',
  },
  {
    first_name: 'Hwee',
    last_name: 'Song',
    phone_number: '+82)22-2222-2222',
    won: 300,
    add_text: 'Hwee like A.L.B.R.T',
    add_auto_complete: 'JSG DDRT',
    add_select: 'JSG DDRT',
    add_date: '2024-02-20',
    add_time: '2024-02-20T17:04:00',
  },
  {
    first_name: 'Ted',
    last_name: 'Jeon',
    phone_number: '+82)33-3333-3333',
    won: 400,
    add_text: 'Ted like KOREA',
    add_auto_complete: 'YGB',
    add_select: 'YGB',
    add_date: '2024-02-20',
    add_time: '2024-02-20T17:04:00',
  },
];
