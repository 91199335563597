import { MenuItem } from '@mui/material';
import React from 'react';

const CountryCodeOption = ({ ...props }) => {
  const { item, index } = props;

  return (
    <MenuItem
      value={item?.phone}
      key={String(index + 1)}
      className="text-xs"
      {...item}
    >
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
        alt="Country Flag"
      />{' '}
      {item?.label} ({item?.code}) +{item?.phone}
    </MenuItem>
  );
};

export default CountryCodeOption;
