/**
 * @description
 * List the regular expressions we are likely to use most often.
 * @usage
 * <target-string>.match(<regex>)
 */

// Regular express with numeric.
export const REGEX_NUMBER_REGEX = /^\d*$/;

// Regular expression with numeric, only positive and decimal input.
export const REGEX_NUMBER_ONLY_POSITIVE_DECIMAL = /^(?:\d+\.\d*|\.\d+|\d*)?$/;

// Regular expression with numeric, only positive and decimal and two decimal point input.
export const REGEX_NUMBER_ONLY_POSITIVE_DECIMAL_POINT_TWO =
  /^(\d+(\.\d{0,2})?|\.\d{1,2})?$/;

// Regular expression with numeric, decimal and two decimal point input.
export const REGEX_NUMBER_DECIMAL_POINT_TWO =
  /^-?(\d+(\.\d{0,2})?|\.\d{1,2})?$/;

// Regular expression with numeric, decimal(max 9 length) and two decimal point input.
export const REGEX_NINE_NUMBER_DECIMAL_POINT_TWO =
  /^-?(\d{1,9}(\.\d{0,2})?|(\d{0,9})?\.\d{1,2})?$/;

// Regular expressions with numeric, negative, and decimal input.
export const REGEX_NUMBER_MINUS_DECIMAL = /^(-?(\d+\.\d*|\.\d+|\d*))?$/;

// Regular express with numeric, only negative.
export const REGEX_NUMBER_ONLY_MINUS = /^(-\d*)?$/;

// Regular express with numeric, alphabe.
export const REGEX_ONLY_NUMBER_ONLY_ALPHABET = /^[a-zA-Z0-9]*$/;

// Dry Container number regular expression.
export const REGEX_CONTAINER_NUMBER_DRY = /^[a-zA-Z]{3}U\d{7}$/;

// Container number regular expression.
export const REGEX_CONTAINER_NUMBER = /^[a-zA-Z]{4}\d{7}$/;

// Chassis number regular expression.
export const REGEX_CHASSIS_NUMBER = /^[a-zA-Z]{4}\d{6}$/;

// Email regular expression.
export const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Year regular expression.
export const REGEX_YEAR = /^(?:[1-9]\d{0,3})?$/;

// Month regular expression.
export const REGEX_MONTH = /^(1[0-2]?|[1-9]|)$/;

// Short date format regular expression. (MM/DD/YY)
export const REGEX_SHORT_DATE = /^\d{2}\/\d{2}\/\d{2}$/;

// Short dateTime format regular expression. (MM/DD/YY hh:mm)
export const REGEX_SHORT_DATE_TIME = /^\d{2}\/\d{2}\/\d{2} \d{2}:\d{2}$/;

// Only number regular expression.
export const REGEX_ONLY_NUMBER = /\D/;

// Only alphabet, number and space regular expression.
// export const REGEX_ALPHABET_NUMBER_SPACE = /^[a-zA-Z0-9 ]*$/;
export const REGEX_ALPHABET_NUMBER_SPACE = /^[a-zA-Z0-9\s]*$/;

// MM/DD/YY regular expression.
export const REGEX_MM_DD_YY =
  /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2})$/;

// HH:mm regular expression.
export const REGEX_HH_MM = /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

// Mobile device expression.
export const REGEX_MOBILE_DEVICE =
  /iPhone|iPad|iPod|Android|Window Phone|BlackBerry|IEMobile|Opera Mini/i;
