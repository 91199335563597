import React from 'react';
import CommonButton from '../../../Controller/Button';
import {
  GRID_MOD_BOTH,
  GRID_MOD_DELETE,
  GRID_MOD_EDIT,
} from '../../../../../constant/Common/DataGrid/setting';
import clickRowEdit from '../../../../../util/Common/Datagrid/Handler/clickRowEdit';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Button';

/**
 * @description
 * Component that appears when not editing
 * @param {object} gridParam
 * Patameter that contains the grid object(API)
 * @param {string} modType
 * Patameter that indicate the type of modification of the grid
 * GRID_MOD_BOTH: Enable edit and delete
 * GRID_MOD_EDIT: Enable edit only
 * GRID_MOD_DELETE: Enable delete only
 * @param {function} onDeleteClicked
 * Function that handle the delete button clicked
 * @returns
 * Component that appears when not editing
 */
const CurrentRowNotEditingCell = ({
  gridParam,
  modType,
  onDeleteClicked,
  ...props
}) => {
  return (
    <>
      {/* Render differently according to the modType */}
      {/* 1. Enable both edit and delete */}
      {modType === GRID_MOD_BOTH && (
        <>
          <CommonButton
            category="edit"
            icon={PATH_COMMON_BUTTON_ICON.editGrey}
            styles={{
              width: '85px',
              border: '1px solid #666666',
              color: '#666666',
              backgroundColor: '#ffffff',
              hoverBackgroundColor: '#666666',
              hoverColor: '#ffffff',
              size: 'small',
            }}
            onClick={() => clickRowEdit({ gridParam })}
          >
            Edit
          </CommonButton>
          <CommonButton
            category="delete"
            icon={PATH_COMMON_BUTTON_ICON.deleteGrey}
            styles={{
              width: '85px',
              border: '1px solid #666666',
              color: '#666666',
              backgroundColor: '#ffffff',
              hoverBackgroundColor: '#B74747',
              hoverColor: '#ffffff',
              size: 'small',
            }}
            onClick={() => onDeleteClicked({ gridParam })}
          >
            Delete
          </CommonButton>
        </>
      )}
      {/* 2. Enable edit only */}
      {modType === GRID_MOD_EDIT && (
        <CommonButton
          category="edit"
          icon={PATH_COMMON_BUTTON_ICON.editGrey}
          styles={{
            width: '85px',
            border: '1px solid #666666',
            color: '#666666',
            backgroundColor: '#ffffff',
            hoverBackgroundColor: '#666666',
            hoverColor: '#ffffff',
            size: 'small',
          }}
          onClick={() => clickRowEdit({ gridParam })}
        >
          Edit
        </CommonButton>
      )}
      {/* 3. Enable delete only */}
      {modType === GRID_MOD_DELETE && (
        <CommonButton
          category="delete"
          icon={PATH_COMMON_BUTTON_ICON.deleteGrey}
          styles={{
            width: '85px',
            border: '1px solid #666666',
            color: '#666666',
            backgroundColor: '#ffffff',
            hoverBackgroundColor: '#B74747',
            hoverColor: '#ffffff',
            size: 'small',
          }}
          onClick={() => onDeleteClicked({ gridParam })}
        >
          Delete
        </CommonButton>
      )}
    </>
  );
};

export default CurrentRowNotEditingCell;
