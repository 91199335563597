export const COMMON_EMODAL = [
  {
    seq: 0,
    label: 'No',
    value: 'ES0001',
  },
  {
    seq: 1,
    label: 'Register',
    value: 'ES0002',
  },
];
