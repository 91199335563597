export const CONTAINER_NO_PAIRS = [
  {
    letter: 'A',
    value: 10,
  },
  {
    letter: 'B',
    value: 12,
  },
  {
    letter: 'C',
    value: 13,
  },
  {
    letter: 'D',
    value: 14,
  },
  {
    letter: 'E',
    value: 15,
  },
  {
    letter: 'F',
    value: 16,
  },
  {
    letter: 'G',
    value: 17,
  },
  {
    letter: 'H',
    value: 18,
  },
  {
    letter: 'I',
    value: 19,
  },
  {
    letter: 'J',
    value: 20,
  },
  {
    letter: 'K',
    value: 21,
  },
  {
    letter: 'L',
    value: 23,
  },
  {
    letter: 'M',
    value: 24,
  },
  {
    letter: 'N',
    value: 25,
  },
  {
    letter: 'O',
    value: 26,
  },
  {
    letter: 'P',
    value: 27,
  },
  {
    letter: 'Q',
    value: 28,
  },
  {
    letter: 'R',
    value: 29,
  },
  {
    letter: 'S',
    value: 30,
  },
  {
    letter: 'T',
    value: 31,
  },
  {
    letter: 'U',
    value: 32,
  },
  {
    letter: 'V',
    value: 34,
  },
  {
    letter: 'W',
    value: 35,
  },
  {
    letter: 'X',
    value: 36,
  },
  {
    letter: 'Y',
    value: 37,
  },
  {
    letter: 'Z',
    value: 38,
  },
];
