import styled from 'styled-components';
import { APP_BACKGROUND_COLOR } from '../../../constant/Common/Style/color';

const StyledAppWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${APP_BACKGROUND_COLOR};
`;

export default StyledAppWrapper;
