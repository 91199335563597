import React from 'react';
import StyledHeaderWrapper from '../../../style/Common/Header/StyledHeaderWrapper';
import Hamburger from './Hamburger';
import Logo from './Logo';
import SessionInfo from './SessionInfo';

/**
 * @description
 * Component of Header in the application.
 * @returns {React.ReactElement}
 */
const Header = ({ ...props }) => {
  return (
    <StyledHeaderWrapper>
      <Hamburger {...props} />
      <Logo />
      <SessionInfo />
    </StyledHeaderWrapper>
  );
};

export default Header;
