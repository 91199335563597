import handleQueryError from '../../../../util/Common/Error/Handler/handleQueryError';
import safetyITTabletDefaultAPI from '../api';

const patchITTablet = async ({ seq, data }) => {
  try {
    const response = await safetyITTabletDefaultAPI.patch(`/${seq}`, data);
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default patchITTablet;
