import React, { useEffect, useState } from 'react';
import CommonSelect from '../../../Common/Controller/Select/index';
import CommonTextInput from '../../../Common/Controller/Text/index';
import CommonDate from '../../../Common/Controller/Date/index';
import CommonTextarea from '../../../Common/Controller/Textarea';
import {
  STATUS_OPTION_LIST,
  OP_OPTION_LIST,
  RESPONSIBILITY_OPTION_LIST,
} from '../../../../constant/Safety/Accident/inputOptions';
import {
  changeDate,
  changeSelect,
  changeText,
} from '../../../../util/Safety/Common/Handler/controllerChangeHandler';
import CommonAutoComplete from '../../../Common/Controller/AutoComplete';
import { createMandatorySelectOption } from '../../../../util/Common/Option/Generator/selectOptionCreator';

const BasicInfoContent = ({
  accident,
  nameOptionList,
  divisionList,
  isEditable,
}) => {
  const [divisionOptionList, setDivisionOptionList] = useState([]);

  useEffect(() => {
    if (divisionList) {
      setDivisionOptionList(
        createMandatorySelectOption({
          items: divisionList?.map(div => div?.div),
        }),
      );
    } else {
      setDivisionOptionList([]);
    }
  }, [divisionList]);

  return (
    <div className="h-full flex flex-col justify-between pt-[21px] pb-[23px]">
      <div className="w-full flex">
        <div className="mr-[16px]">
          <CommonSelect
            label="STATUS"
            width="w-[106px]"
            defaultValue={accident?.status || ''}
            option={STATUS_OPTION_LIST}
            required
            inputName="status"
            disabled={!isEditable}
            onChangeHandler={changeSelect}
          />
        </div>
        <div className="mr-[16px]">
          <CommonSelect
            label="DIV"
            width="w-[106px]"
            option={divisionOptionList}
            required
            defaultValue={accident?.div || ''}
            inputName="div"
            onChangeHandler={changeSelect}
            disabled={!isEditable}
          />
        </div>
        <div className="mr-[25px]">
          <CommonAutoComplete
            label="NAME"
            inputName="driver"
            width="w-[106px]"
            option={nameOptionList}
            defaultValue={accident?.driver || ''}
            onChangeHandler={({ e, onChange, selectProps }) => {
              onChange?.(selectProps?.value);
            }}
            maxLength={15}
            required
            disabled={!isEditable}
          />
        </div>
        <div className="mr-[10px]">
          <CommonTextInput
            inputName="created_by"
            width="w-[106px]"
            label="CREATED BY"
            disabled
            defaultValue={accident?.created_by || ''}
            onChangeHandler={changeText}
          />
        </div>
        <div>
          <CommonDate
            inputName="created_date"
            width="w-[106px]"
            disabled
            defaultValue={accident?.created_date || ''}
            onChangeHandler={changeDate}
          />
        </div>
      </div>
      <div className="w-full flex">
        <div className="mr-[16px]">
          <CommonSelect
            label="OP"
            width="w-[106px]"
            defaultValue={accident?.op || ''}
            option={OP_OPTION_LIST}
            required
            inputName="op"
            onChangeHandler={changeSelect}
            disabled={!isEditable}
          />
        </div>
        <div className="mr-[16px]">
          <CommonSelect
            label="RESPONSIBILITY"
            width="w-[106px]"
            defaultValue={accident?.responsibility || ''}
            option={RESPONSIBILITY_OPTION_LIST}
            required
            inputName="responsibility"
            onChangeHandler={changeSelect}
            disabled={!isEditable}
          />
        </div>
        <div className="mr-[25px]">
          <CommonDate
            inputName="accident_date"
            width="w-[106px]"
            label="DATE"
            defaultValue={accident?.accident_date || ''}
            onChangeHandler={changeDate}
            disabled={!isEditable}
          />
        </div>
        <div className="mr-[10px]">
          <CommonTextInput
            inputName="updated_by"
            width="w-[106px]"
            label="UPDATED BY"
            disabled
            defaultValue={accident?.updated_by || ''}
            onChangeHandler={changeText}
          />
        </div>
        <div>
          <CommonDate
            inputName="updated_date"
            width="w-[106px]"
            disabled
            defaultValue={accident?.updated_date || ''}
            onChangeHandler={changeDate}
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <CommonTextarea
          inputName="detail"
          label="DETAIL"
          defaultValue={accident?.detail || ''}
          maxLength={120}
          onChangeHandler={changeText}
          disabled={!isEditable}
        />
      </div>
    </div>
  );
};

export default BasicInfoContent;
