import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchDamage from '../../../../service/Safety/DamageReport/Patch/patchDamage';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchDamage = ({
  gridRef,
  setAlertOptions,
  setSeq,
  setIsOpenFormModal,
}) => {
  const { setAlertInfo, setIsOpenAlert } = setAlertOptions;
  const { addToast } = useControlToast();

  const queryClient = useQueryClient();
  const mutator = useMutation({
    mutationFn: patchDamage,
    onSuccess: data => {
      const { photo, ...successData } = data?.data;
      updateGridData({
        gridRef,
        newData: { ...successData },
        isUpdate: true,
      });
      setSeq(null);
      addToast({ message: SUCCESS_UPDATE });
      setIsOpenFormModal(prev => false);
    },
    onError: error => {
      setAlertInfo(prev => ({
        status: ALERT_MODAL_STATUS?.ERROR,
        message: error?.message,
      }));
      setIsOpenAlert(prev => true);
    },
  });

  return { ...mutator };
};

export default usePatchDamage;
