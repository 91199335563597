import React from 'react';
import { useSelector } from 'react-redux';
import StyledInfoWrapper from '../../../../../../style/Safety/Common/Modal/StyledInfoWrapper';
import {
  STYLE_EACH_ROW_WRAPPER,
  STYLE_ITEM_WRAPPER,
  STYLE_ROW_FIRST_CONTENT,
} from '../../../../../../style/Safety/Common/style';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import CommonDate from '../../../../../Common/Controller/Date';
import {
  changeSelect,
  changeText,
} from '../../../../../../util/Safety/Common/Handler/controllerChangeHandler';
import { COMMON_STATE } from '../../../../../../constant/Common/Option/State';
import { CHASSIS_BASIC_INFO_STATUS } from '../../../../../../constant/Common/Option/Status';
import { REGEX_NUMBER_DECIMAL_POINT_TWO } from '../../../../../../constant/Common/Regex';
import { createMandatorySelectOption } from '../../../../../../util/Common/Option/Generator/selectOptionCreator';

const BasicInfo = ({ ...props }) => {
  const { data, isEditable } = props;

  const divisionList = useSelector(state => state?.common?.allDiv);
  const chassisSizeList = useSelector(state => state?.common?.chassisSizeList);
  const chassisTypeList = useSelector(state => state?.common?.chassisTypeList);

  return (
    <StyledInfoWrapper>
      {/* Header */}
      <div className="w-full h-fit text-[#264B9F] text-[14px] font-bold pb-[20px]">
        Basic Info
      </div>
      {/* Content */}
      {/* First row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="DIV"
              inputName="div"
              option={divisionList?.map((division, index) => ({
                key: index,
                label: division,
                value: division,
              }))}
              required
              defaultValue={data?.div || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="SZ"
              inputName="size"
              option={createMandatorySelectOption({
                items: chassisSizeList,
                labelKey: 'size',
                valueKey: 'size',
              })}
              required
              defaultValue={data?.size || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="TYPE"
              inputName="type"
              option={createMandatorySelectOption({
                items: chassisTypeList,
                labelKey: 'type',
                valueKey: 'type',
              })}
              defaultValue={data?.type || 'REGULAR'}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="CREATED BY"
              inputName="created_by"
              disabled
              defaultValue={data?.created_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="created_date"
              disabled
              defaultValue={data?.created_date || null}
            />
          </div>
        </div>
      </div>
      {/* Second row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="TARE"
              inputName="tare"
              required
              regex={REGEX_NUMBER_DECIMAL_POINT_TWO}
              defaultValue={data?.tare || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              adornment={{
                position: 'end',
                content: 'lbs',
                styles: {
                  color: '#264B9F',
                  fontSize: '12px',
                  fontWeight: '500',
                },
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="CHASSIS #"
              inputName="ch_no"
              required
              maxLength={12}
              defaultValue={data?.ch_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              onBlurHandler={({ e, onChange, ...handlerProps }) => {
                onChange(e?.target?.value?.toUpperCase());
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="VIN #"
              inputName="vin_no"
              required
              maxLength={18}
              defaultValue={data?.vin_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="UPDATED BY"
              inputName="updated_by"
              disabled
              defaultValue={data?.updated_by || ''}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonDate
              label=""
              inputName="updated_date"
              disabled
              defaultValue={data?.updated_date || ''}
            />
          </div>
        </div>
      </div>
      {/* Third row */}
      <div className={STYLE_EACH_ROW_WRAPPER}>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="ASSET TRACKER #"
              inputName="asset_tracker_no"
              maxLength={10}
              defaultValue={data?.asset_tracker_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="PLATE #"
              inputName="plate_no"
              required
              maxLength={8}
              defaultValue={data?.plate_no || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
        <div className={STYLE_ROW_FIRST_CONTENT}>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATE"
              inputName="state"
              option={COMMON_STATE}
              required
              defaultValue={data?.state || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonSelect
              label="STATUS"
              inputName="status"
              option={CHASSIS_BASIC_INFO_STATUS}
              required
              defaultValue={data?.status || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeSelect({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
          <div className={STYLE_ITEM_WRAPPER}>
            <CommonTextInput
              label="SELLER"
              inputName="seller"
              required
              maxLength={20}
              defaultValue={data?.seller || ''}
              onChangeHandler={({ ...changeProps }) => {
                changeText({ ...changeProps });
              }}
              disabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </StyledInfoWrapper>
  );
};

export default BasicInfo;
