import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import postAccident from '../../../../service/Safety/Accident/Post/postAccident';
import accidentKeys from '../keys';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const usePostAccident = ({ setAlertOptions, setIsOpenFormModal, ...props }) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  const mutate = useMutation({
    mutationFn: postAccident,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      /** Refetch Logic */
      queryClient?.refetchQueries({
        queryKey: accidentKeys?.accidentList(),
      });
      addToast({ message: SUCCESS_SAVE });
      setIsOpenFormModal(prev => false);
    },
    onError: error => {
      setAlertInfo(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setIsOpenAlert(prev => true);
    },
  });

  return { ...mutate };
};

export default usePostAccident;
