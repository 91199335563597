import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Controller, useFormContext, get } from 'react-hook-form';

const ComponentController = ({
  Component,
  inputName,
  defaultValue,
  setCompValue,
  componentProps,
  ...props
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    unregister,
  } = useFormContext();
  const {
    type,
    styles,
    onChange: compOnChange,
    onClick: compOnClick,
    onKeyDown: compOnKeyDown,
    onBlur: compOnBlur,
    componentChildren,
    ...otherComponentProps
  } = componentProps;

  useEffect(() => {
    return () => {
      unregister(inputName);
    };
  }, []);

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={defaultValue}
      rules={{
        required: {
          value: otherComponentProps?.required || false,
          message: 'This field is required.',
        },
        maxLength: {
          value: otherComponentProps?.maxLength,
          message: `This field must be less than ${otherComponentProps?.maxLength} characters.`,
        },
        minLength: {
          value: otherComponentProps?.minLength,
          message: `This field must be more than ${otherComponentProps?.minLength} characters.`,
        },
      }}
      render={({ field: { ref, onChange, value, ...field } }) => {
        let tempValue = value;
        if (type === 'date' || type === 'time') {
          if (value && dayjs(value).isValid()) {
            tempValue = dayjs(value);
          } else tempValue = null;
        }

        return (
          <Component
            {...field}
            inputRef={ref}
            ref={ref}
            value={tempValue}
            $styles={styles}
            error={
              Boolean(errors?.[inputName]) || Boolean(get(errors, inputName))
            }
            onChange={(e, controllerProps) =>
              compOnChange?.({
                e,
                ref,
                onChange,
                value,
                setValue,
                setCompValue,
                controllerProps,
                ...field,
                ...props,
              })
            }
            onClick={(e, controllerProps) =>
              compOnClick?.({
                e,
                ref,
                onChange,
                value,
                setValue,
                setCompValue,
                controllerProps,
                ...field,
                ...props,
              })
            }
            onKeyDown={(e, controllerProps) =>
              compOnKeyDown?.({
                e,
                ref,
                onChange,
                value,
                setValue,
                setCompValue,
                controllerProps,
                ...field,
                ...props,
              })
            }
            onBlur={(e, controllerProps) =>
              compOnBlur?.({
                e,
                ref,
                onChange,
                value,
                setValue,
                setCompValue,
                controllerProps,
                ...field,
                ...props,
              })
            }
            {...otherComponentProps}
          >
            {componentChildren}
          </Component>
        );
      }}
    />
  );
};

export default ComponentController;
