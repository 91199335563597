import { useEffect, useState } from 'react';
import { generateDivisionSelectOption } from '../../../../util/Admin/User/Generator/generateOption';
import {
  generateInitialDivisionList,
  generateSelectedDivisionList,
} from '../../../../util/Admin/User/Generator/generateDivisionList';

const useGetDivisionOption = ({
  divList,
  userInfo,
  setValue,
  useWatch,
  control,
  getValues,
}) => {
  /** Number Of Divisions On The System */
  const [systemDivLength, setSystemDivLength] = useState(divList?.length);
  useEffect(() => {
    setSystemDivLength(divList?.length);
  }, [divList]);

  /** Division Data Selected By The User */
  const [userDivLength, setUserDivLength] = useState(null);
  const [selectedDivList, setSelectedDivList] = useState(
    generateInitialDivisionList({ divList, userInfo }),
  );

  /** Default Division Select Option */
  const [defaultDivOptionList, setDefaultDivOptionList] = useState([]);

  const watchDivList = useWatch({
    control,
    name: divList?.map(div => div?.div),
  });

  useEffect(() => {
    const newSelectedDivList = generateSelectedDivisionList({
      selectedDivList,
      getValues,
    });
    setSelectedDivList(newSelectedDivList);
  }, [watchDivList]);

  /** Handling Default Division Option List When Division Checkbox Changes */
  useEffect(() => {
    const newOptionList = generateDivisionSelectOption({ selectedDivList });
    setDefaultDivOptionList(newOptionList);
    setUserDivLength(newOptionList?.length);

    if (
      !newOptionList?.find(option => option?.value === getValues('default_div'))
    ) {
      setValue('default_div', null);
    }
  }, [selectedDivList]);

  /** Handle 'ALL' CheckBox Option */
  useEffect(() => {
    setValue('ALL', systemDivLength === userDivLength);
  }, [systemDivLength, userDivLength]);

  return {
    systemDivLength,
    userDivLength,
    setUserDivLength,
    defaultDivOptionList,
    setDefaultDivOptionList,
    selectedDivList,
    setSelectedDivList,
  };
};

export default useGetDivisionOption;
