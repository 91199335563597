import React from 'react';

const LabsContentsClassification = ({ className }) => {
  return (
    <div className="pb-[20px]">
      <div className="text-[25px] font-bold text-black p-[10px]">
        {className}
      </div>
      <hr />
    </div>
  );
};

export default LabsContentsClassification;
