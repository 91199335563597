import React from 'react';
import StyledErrorPageWrapper from '../../../../style/Error/StyledErrorPageWrapper';
import StyledErrorPageContentsWrapper from '../../../../style/Error/StyledErrorPageContentsWrapper';
import { PATH_ACCESS_DENIED_IMAGE } from '../../../../constant/Common/Path/Asset/Image/Error';

const ErrorAccessDenied = () => {
  return (
    <StyledErrorPageWrapper>
      <StyledErrorPageContentsWrapper>
        <div>
          <img
            src={PATH_ACCESS_DENIED_IMAGE}
            alt="Access denied"
            width="200px"
            height="200px"
          />
        </div>
        <div className="pt-[46px]">
          <p className="text-[#00235D] text-[35px] font-extrabold">
            YOU NEED ACCESS
          </p>
        </div>
        <div className="text-center pt-[30px] flex gap-[10px] flex-col">
          <p className="text-[#00235D] text-[22px] font-normal">
            You don’t have permission to view this page.
          </p>
          <p className="text-[#00235D] text-[22px] font-normal">
            Ask for access, or switch an account with access.
          </p>
        </div>
      </StyledErrorPageContentsWrapper>
    </StyledErrorPageWrapper>
  );
};

export default ErrorAccessDenied;
