import styled, { css, keyframes } from 'styled-components';
import { TOAST_TYPE } from '../../../constant/Common/Toast/toastTypes';

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const StyledCommonToastWrapper = styled.div`
  width: 411px;
  height: 88px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  color: #2b2c49;
  margin-bottom: 5px;

  ${props =>
    !props?.$suppressAutoFadeOut &&
    props?.$isStartFadeOut &&
    css`
      animation: ${fadeOut} ${props?.$FADE_OUT_TIME}ms ease-in-out;
      animation-fill-mode: forwards;
    `}

  ${props =>
    props?.$toastType === TOAST_TYPE?.SUCCESS &&
    css`
      background-color: rgba(200, 244, 206, 0.75);
    `}
  ${props =>
    props?.$toastType === TOAST_TYPE?.ERROR &&
    css`
      background-color: rgba(255, 114, 114, 0.75);
    `}
    ${props =>
    props?.$toastType === TOAST_TYPE?.CUSTOM &&
    css`
      background-color: rgba(152, 152, 152, 0.75);
    `}
    ${props =>
    props?.$toastType === TOAST_TYPE?.WARNING &&
    css`
      background-color: rgba(255, 227, 155, 0.75);
    `};
`;

export default StyledCommonToastWrapper;
