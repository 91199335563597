/** Admin permission */
export const PERM_ADMIN_USER_VIEW_EDIT = 'admin_user_rw';
export const PERM_ADMIN_AUTH_VIEW_EDIT = 'admin_auth_rw';
export const PERM_ADMIN_DIV_CONF_VIEW_EDIT = 'admin_div_conf_rw';

/** Daily In&Out permission */
export const PERM_DAILY_IN_OUT_DAILY_VIEW = 'in_out_daily_r';
export const PERM_DAILY_IN_OUT_DAILY_EDIT = 'in_out_daily_rw';
export const PERM_DAILY_IN_OUT_INVENTORY_VIEW = 'in_out_inventory_r';
export const PERM_DAILY_IN_OUT_INVENTORY_EDIT = 'in_out_inventory_rw';

/** Safety permission */
export const PERM_SAFETY_MR_VIEW = 'safety_mr_r';
export const PERM_SAFETY_MR_EDIT = 'safety_mr_rw';

export const PERM_SAFETY_TRUCK_VIEW = 'safety_truck_r';
export const PERM_SAFETY_TRUCK_EDIT = 'safety_truck_rw';

export const PERM_SAFETY_CHASSIS_VIEW = 'safety_ch_r';
export const PERM_SAFETY_CHASSIS_EDIT = 'safety_ch_rw';

export const PERM_SAFETY_VAN_VIEW = 'safety_van_r';
export const PERM_SAFETY_VAN_EDIT = 'safety_van_rw';

export const PERM_SAFETY_IT_TABLET_VIEW = 'safety_it_r';
export const PERM_SAFETY_IT_TABLET_EDIT = 'safety_it_rw';

export const PERM_SAFETY_ACCIDENT_VIEW = 'safety_accident_r';
export const PERM_SAFETY_ACCIDENT_EDIT = 'safety_accident_rw';

export const PERM_SAFETY_DAMAGE_REPORT_VIEW = 'safety_damage_r';
export const PERM_SAFETY_DAMAGE_REPORT_EDIT = 'safety_damage_rw';

export const PERM_SAFETY_COMPLETE_VIEW_EDIT = 'safety_complete_rw';

/** Mapping permission w/ page */

// Admin
export const PERM_ADMIN_USER = [PERM_ADMIN_USER_VIEW_EDIT];

export const PERM_ADMIN_AUTH = [PERM_ADMIN_AUTH_VIEW_EDIT];

export const PERM_ADMIN_DIV_CONF = [PERM_ADMIN_DIV_CONF_VIEW_EDIT];

export const PERM_ADMIN = [
  ...PERM_ADMIN_USER,
  ...PERM_ADMIN_AUTH,
  ...PERM_ADMIN_DIV_CONF,
];

// Daily In&Out
export const PERM_DAILY_IN_OUT_DAILY = [
  PERM_DAILY_IN_OUT_DAILY_VIEW,
  PERM_DAILY_IN_OUT_DAILY_EDIT,
];

export const PERM_DAILY_IN_OUT_INVENTORY = [
  PERM_DAILY_IN_OUT_INVENTORY_VIEW,
  PERM_DAILY_IN_OUT_INVENTORY_EDIT,
];

export const PERM_DAILY_IN_OUT = [
  ...PERM_DAILY_IN_OUT_DAILY,
  ...PERM_DAILY_IN_OUT_INVENTORY,
];

// Safety
export const PERM_SAFETY_MR = [PERM_SAFETY_MR_VIEW, PERM_SAFETY_MR_EDIT];

export const PERM_SAFETY_TRUCK = [
  PERM_SAFETY_TRUCK_VIEW,
  PERM_SAFETY_TRUCK_EDIT,
];

export const PERM_SAFETY_CHASSIS = [
  PERM_SAFETY_CHASSIS_VIEW,
  PERM_SAFETY_CHASSIS_EDIT,
];

export const PERM_SAFETY_VAN = [PERM_SAFETY_VAN_VIEW, PERM_SAFETY_VAN_EDIT];

export const PERM_SAFETY_IT_TABLET = [
  PERM_SAFETY_IT_TABLET_VIEW,
  PERM_SAFETY_IT_TABLET_EDIT,
];

export const PERM_SAFETY_ACCIDENT = [
  PERM_SAFETY_ACCIDENT_VIEW,
  PERM_SAFETY_ACCIDENT_EDIT,
];

export const PERM_SAFETY_DAMAGE_REPORT = [
  PERM_SAFETY_DAMAGE_REPORT_VIEW,
  PERM_SAFETY_DAMAGE_REPORT_EDIT,
];

export const PERM_SAFETY_COMPLETE = [PERM_SAFETY_COMPLETE_VIEW_EDIT];

export const PERM_SAFETY = [
  ...PERM_SAFETY_MR,
  ...PERM_SAFETY_TRUCK,
  ...PERM_SAFETY_CHASSIS,
  ...PERM_SAFETY_VAN,
  ...PERM_SAFETY_IT_TABLET,
  ...PERM_SAFETY_ACCIDENT,
  ...PERM_SAFETY_DAMAGE_REPORT,
  ...PERM_SAFETY_COMPLETE,
];

// All
export const PERM_ALL = [...PERM_ADMIN, ...PERM_DAILY_IN_OUT, ...PERM_SAFETY];
