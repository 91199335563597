import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  generateFormattedDay,
  generateFormattedTime,
} from '../../Common/Generator/formattedDayGenerator';
import {
  DAILY_IN_OUT_CATEGORY_TYPE,
  DAILY_IN_OUT_TYPE,
} from '../../../../constant/DailyInOut/elements';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createContainerColumnDefs = () => {
  return [
    {
      headerName: 'DATE',
      field: 'in_out_date',
      width: 62,
      editable: false,
      valueGetter: params =>
        generateFormattedDay(params?.data?.in_out_date, 'MM/DD/YY'),
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'TIME',
      field: 'in_out_time',
      width: 48,
      editable: false,
      valueGetter: params => generateFormattedTime(params?.data?.in_out_time),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'time',
        maxLength: 5,
        placeholder: 'HH:mm',
      },
    },
    {
      headerName: 'YARD',
      field: 'yard',
      width: 70,
      editable: false,
      floatingFilter: false,
    },
    {
      headerName: 'WO DIV',
      field: 'wo_div',
      width: 65,
      editable: false,
    },
    {
      headerName: 'CAT',
      field: 'category',
      width: 55,
      editable: false,
      valueGetter: params =>
        DAILY_IN_OUT_CATEGORY_TYPE?.[params?.data?.category] ||
        params?.data?.category,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      width: 80,
      editable: false,
      valueGetter: params =>
        DAILY_IN_OUT_TYPE?.[params?.data?.status] || params?.data?.status,
    },
    {
      headerName: 'LOC',
      field: 'yard_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'CONT #',
      field: 'cont_no',
      width: 92,
      editable: false,
    },
    {
      headerName: 'SZ',
      field: 'size',
      width: 42,
      editable: false,
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 77,
      editable: false,
    },
    {
      headerName: 'SSL',
      field: 'ssl',
      width: 50,
      editable: false,
    },
    {
      headerName: 'SEAL #',
      field: 'seal',
      width: 150,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createContainerColumnDefs;
