import React from 'react';
import { Navigate } from 'react-router-dom';
import DivConf from '../../../../../page/Admin/DivConf';
import Auth from '../../../../../page/Admin/Auth';
import User from '../../../../../page/Admin/User';
import AuthRouter from '../../../../../component/Common/Router/Common/AuthRouter';
import {
  PERM_ADMIN_AUTH,
  PERM_ADMIN_DIV_CONF,
  PERM_ADMIN_USER,
} from '../../../Permission';
import getAuthorizedMenu from '../../../../../util/Common/Sidebar/Handler/getAuthorizedMenu';
import { SIDEBAR_SUB_MENU } from '../../../Option/Sidebar';

/**
 * @description
 * Children routes of the admin page.
 */
const ADMIN_CHILDREN_ROUTE = [
  {
    index: true,
    element: (
      <Navigate
        to={
          getAuthorizedMenu({
            allMenu: SIDEBAR_SUB_MENU?.[3],
          })?.[0]?.to
        }
      />
    ),
  },
  {
    path: 'user/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_USER}>
        <User />
      </AuthRouter>
    ),
  },
  {
    path: 'auth/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_AUTH}>
        <Auth />
      </AuthRouter>
    ),
  },
  {
    path: 'div-conf/*',
    element: (
      <AuthRouter permission={PERM_ADMIN_DIV_CONF}>
        <DivConf />
      </AuthRouter>
    ),
  },
];

export default ADMIN_CHILDREN_ROUTE;
