/**
 * @description
 * Path of icon used in the sidebar component.
 */
const PATH_SIDEBAR_ICON = '/asset/Icon/Sidebar';

export const PATH_SIDEBAR_MENU_ICON = {
  dailyInOut: {
    default: `${PATH_SIDEBAR_ICON}/DailyInOut/dailyInOutWhite.svg`,
    active: `${PATH_SIDEBAR_ICON}/DailyInOut/dailyInOutBlue.svg`,
  },
  inventory: {
    default: `${PATH_SIDEBAR_ICON}/Inventory/inventoryWhite.svg`,
    active: `${PATH_SIDEBAR_ICON}/Inventory/inventoryBlue.svg`,
  },
  safety: {
    default: `${PATH_SIDEBAR_ICON}/Safety/safetyWhite.svg`,
    active: `${PATH_SIDEBAR_ICON}/Safety/safetyBlue.svg`,
  },
  admin: {
    default: `${PATH_SIDEBAR_ICON}/Admin/adminWhite.svg`,
    active: `${PATH_SIDEBAR_ICON}/Admin/adminBlue.svg`,
  },
};
