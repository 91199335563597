import React from 'react';
import StyledCommonMessageModalHeaderWrapper from '../../../../../../style/Common/Modal/Message/Frame/Header/StyledCommonMessageModalHeaderWrapper';
import StyledCommonMessageModalHeaderContent from '../../../../../../style/Common/Modal/Message/Frame/Header/StyledCommonMessageModalHeaderContent';

const CommonMessageModalFrameHeader = ({ children }) => {
  return (
    <StyledCommonMessageModalHeaderWrapper>
      <StyledCommonMessageModalHeaderContent>
        {children}
      </StyledCommonMessageModalHeaderContent>
    </StyledCommonMessageModalHeaderWrapper>
  );
};

export default CommonMessageModalFrameHeader;
