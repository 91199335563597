import React, { useState } from 'react';
import LabsContentsClassification from '../../LabsContentsClassification';
import CommonButton from '../../../Common/Controller/Button';
import CommonBlur from '../../../Common/Controller/Blur';
import handleTriggerActive from '../../../../util/Labs/Trigger/Handler/handleTriggerActive';
import CommonLoading from '../../../Common/Loading';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import CommonAlert from '../../../Common/Modal/Alert';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';

const ButtonTrigger = () => {
  const BUTTON_COMMON_STYLES = {
    backgroundColor: '#001E5F',
    color: '#fff',
    width: '100px',
    height: '40px',
    fontSize: '16px',
  };

  const [trigger, setTrigger] = useState({
    blur: false,
    loading: false,
    messageFrame: false,
    customMessageFrame: false,
    alertStable: false,
    alertError: false,
  });

  return (
    <>
      <div>
        <LabsContentsClassification className="Button Trigger" />
        <div className="flex items-center p-[10px] gap-[20px]">
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() => handleTriggerActive({ setTrigger, target: 'blur' })}
          >
            Blur
          </CommonButton>
          <CommonButton
            styles={{ ...BUTTON_COMMON_STYLES }}
            onClick={() =>
              handleTriggerActive({ setTrigger, target: 'loading' })
            }
          >
            Loading
          </CommonButton>
        </div>
      </div>

      {/* Target Components */}
      {trigger?.blur && <CommonBlur isOpen={trigger?.blur} />}
      {trigger?.loading && <CommonLoading isOpen={trigger?.loading} />}
    </>
  );
};

export default ButtonTrigger;
