import { useQuery } from '@tanstack/react-query';
import getITTabletList from '../../../../service/Safety/ITTablet/Get/getITTabletList';
import safetyITTabletKeys from '../keys';

const useGetITTabletList = () => {
  const {
    isLoading: isLoadingITTabletList,
    data,
    isRefetching: isRefetchingITTabletList,
    isError: isErrorITTabletList,
  } = useQuery({
    queryKey: safetyITTabletKeys?.itTabletList(),
    queryFn: getITTabletList,
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingITTabletList,
    data,
    isRefetchingITTabletList,
    isErrorITTabletList,
  };
};

export default useGetITTabletList;
