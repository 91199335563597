import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import postGroup from '../../../../service/Admin/Auth/Post/postGroup';
import useControlToast from '../../../Common/Toast/useControlToast';
import adminAuthKeys from '../keys';

const usePostGroupMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  gridRef,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postGroup,
    onSuccess: () => {
      const topRow = gridRef.current.api.getPinnedTopRow(0);
      topRow.setData({});
      gridRef.current.api.redrawRows({ rowNodes: [topRow] });
      queryClient.refetchQueries({ queryKey: adminAuthKeys?.group() });
      addToast?.({ message: SUCCESS_SAVE });
    },
    onError: error => {
      queryClient.refetchQueries({ queryKey: adminAuthKeys?.group() });
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostGroupMutation;
