import { useMutation } from '@tanstack/react-query';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import patchAccident from '../../../../service/Safety/Accident/Patch/patchAccident';
import useControlToast from '../../../Common/Toast/useControlToast';
import { SUCCESS_UPDATE } from '../../../../constant/Common/Message/success';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';
import updateGridData from '../../../../util/Common/Datagrid/Handler/updateGridData';

const usePatchAccident = ({ gridRef, setAlertOptions, setIsOpenFormModal }) => {
  const { addToast } = useControlToast();

  const { setIsOpenAlert, setAlertInfo } = setAlertOptions;

  const mutate = useMutation({
    mutationFn: patchAccident,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: (data, variables) => {
      updateGridData({
        gridRef,
        newData: { ...data?.data },
        isUpdate: true,
      });

      addToast({ message: SUCCESS_UPDATE });
      setIsOpenFormModal(prev => false);
    },
    onError: error => {
      setAlertInfo(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: error?.message,
        };
      });
      setIsOpenAlert(prev => true);
    },
  });

  return { ...mutate };
};

export default usePatchAccident;
