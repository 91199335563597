import dayjs from 'dayjs';

const createDefaultValue = ({ type, defaultValue, ...props }) => {
  if (type === 'date' || type === 'time') {
    if (!defaultValue) return null;
    if (defaultValue && dayjs(defaultValue)?.isValid()) {
      return dayjs(defaultValue);
    }
    return null;
  }

  if (type === 'select' || type === 'autoComplete') {
    const originOption = props?.option;
    const filteredOption = originOption?.find(
      item => item?.value === defaultValue,
    );
    if (filteredOption) return defaultValue;
    return '';
  }

  if (defaultValue) return defaultValue;
  return '';
};

export default createDefaultValue;
