import React, { useRef, useState } from 'react';
import CommonDataGrid from '../../../component/Common/Datagrid';
import createGridColumn from '../../../util/Labs/Grid/Generator/createGridColumn';
import { LABS_GRID_DATA } from '../../../constant/Labs/Data';
import generateDefaultColumnDefs from '../../../util/Common/Datagrid/Generator/generateDefaultColumnDefs';
import { GRID_MOD_BOTH } from '../../../constant/Common/DataGrid/setting';
import {
  clickAdd,
  clickCell,
  clickDelete,
  clickRow,
  clickSave,
  startRowEdit,
  stopRowEdit,
} from '../../../util/Labs/Grid/Handler/onClickHandler';
import FoldedContents from '../../../component/Labs/Grid/FoldedContents';
import CommonMessageModalFrame from '../../../component/Common/Modal/Message/Frame';

const Grid = () => {
  /** Test */
  const [isAlertOpen, setAlertOpen] = useState(false);

  /** Grid reference */
  const gridRef = useRef();
  /** Grid column */
  const gridColumn = createGridColumn();
  /** Grid default column definition */
  const gridDefaultColumnDefs = generateDefaultColumnDefs();
  /** Click handler */
  const onAddClicked = clickAdd;
  const onDeleteClicked = clickDelete;
  const onSaveClicked = clickSave;
  const onRowClicked = clickRow;
  const onCellClicked = clickCell;
  const onRowEditingStart = startRowEdit;
  const onRowEditingStop = stopRowEdit;

  /** Render datagrid */
  return (
    <>
      <div className="w-full h-full">
        <CommonDataGrid
          gridRef={gridRef}
          columnDefs={gridColumn}
          data={LABS_GRID_DATA}
          defaultColDef={gridDefaultColumnDefs}
          rowSelection="multiple"
          modType={GRID_MOD_BOTH}
          addable
          onAddClicked={onAddClicked}
          onDeleteClicked={onDeleteClicked}
          onSaveClicked={onSaveClicked}
          onRowClicked={onRowClicked}
          onCellClicked={onCellClicked}
          onRowEditingStart={onRowEditingStart}
          onRowEditingStop={onRowEditingStop}
          foldable
          foldableContent={FoldedContents}
        />
      </div>
      {isAlertOpen && (
        <CommonMessageModalFrame open={isAlertOpen} setOpen={setAlertOpen} />
      )}
    </>
  );
};

export default Grid;
