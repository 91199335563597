import React from 'react';
import CommonSelect from '../../../../../Common/Controller/Select';
import CommonTextInput from '../../../../../Common/Controller/Text';
import StyledModalInputContainer from '../../../../../../style/Inventory/Modal/EditModal/StyledModalInputContainer';
import {
  changeSelect,
  changeText,
  blurUpperCaseChange,
} from '../../../../../../util/Inventory/Common/Handler/controllerChangeHandler';

const TrailerInfo = ({ data, selectOptions, isEditable }) => {
  return (
    <StyledModalInputContainer>
      <div>
        <CommonTextInput
          label="TRAILER #"
          inputName="trailer_no"
          defaultValue={data?.trailer_no || null}
          onChangeHandler={changeText}
          onBlurHandler={blurUpperCaseChange}
          disabled={!isEditable}
          maxLength={12}
          required
        />
      </div>
      <div>
        <CommonSelect
          label="SZ"
          inputName="size"
          defaultValue={data?.size || ''}
          onChangeHandler={changeSelect}
          option={selectOptions.sizeOption}
          disabled={!isEditable}
        />
      </div>
      <div>
        <CommonSelect
          label="TYPE"
          inputName="type"
          defaultValue={data?.type || ''}
          onChangeHandler={changeSelect}
          option={selectOptions.typeOption}
          disabled={!isEditable}
        />
      </div>
      <div>
        <CommonTextInput
          label="COLOR"
          inputName="color"
          defaultValue={data?.color || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={10}
        />
      </div>
      <div className="col-span-2">
        <CommonTextInput
          label="SEAL #"
          inputName="seal"
          defaultValue={data?.seal || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={20}
        />
      </div>
      <div className="col-span-2">
        <CommonTextInput
          label="REMARKS"
          inputName="remarks"
          defaultValue={data?.remarks || null}
          onChangeHandler={changeText}
          disabled={!isEditable}
          maxLength={50}
        />
      </div>
    </StyledModalInputContainer>
  );
};

export default TrailerInfo;
