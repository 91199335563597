import styled from 'styled-components';

const StyledModalContentWrapper = styled.div`
  width: 542px;
  height: fit-content;
  gap: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default StyledModalContentWrapper;
