import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';

const handleRowClicked = ({
  props,
  setSeq,
  setIsOpenFormModal,
  setModalType,
}) => {
  const getColID = props?.gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (getColID !== 'completed') {
    const seq = props?.gridParam?.data?.seq;

    setSeq(seq);
    setIsOpenFormModal(true);
    setModalType(EDIT_MODE?.edit);
  }
};

export default handleRowClicked;
