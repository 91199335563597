/**
 * @description
 * Handler for autocomplete filter options
 * @param {list} options
 * Auto complete options
 * @param {object} params
 * Auto complete input parameter
 * @returns {list}
 * Filtered options
 */
const handleAutoCompleteFilterOptions = ({ options, params }) => {
  /** Get input value */
  const { inputValue } = params;

  if (!inputValue || inputValue === '')
    return options ? options?.slice(0, 10) : [];
  return options
    ?.filter(
      option =>
        option?.label?.includes(inputValue) ||
        option?.value?.includes(inputValue) ||
        option?.label?.includes(inputValue?.toLowerCase()) ||
        option?.value?.includes(inputValue?.toLowerCase()) ||
        option?.label?.includes(inputValue?.toUpperCase()) ||
        option?.value?.includes(inputValue?.toUpperCase()) ||
        option === inputValue,
    )
    ?.slice(0, 200);
};

export default handleAutoCompleteFilterOptions;
