import styled from 'styled-components';

const StyledLabsContentsWrapper = styled.div`
  width: 90%;
  height: 100%;
  border-radius: 10px;
  background-color: white;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
`;

export default StyledLabsContentsWrapper;
