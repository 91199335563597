import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import getDriver from '../../../service/Common/Get/getDriver';
import commonDataKeys from './keys';

const useGetDriverList = () => {
  const [driverList, setDriverList] = useState([]);

  const query = useQuery({
    queryKey: commonDataKeys?.driverList(),
    queryFn: () => getDriver(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data) {
      setDriverList(prev => query?.data?.data);
    } else {
      setDriverList(prev => []);
    }
  }, [query?.data]);

  return { driverList, ...query };
};

export default useGetDriverList;
