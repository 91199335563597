import COLUMN_ITEM_PAIR from '../../../../constant/Common/Error/column';
import { ERROR_UNKNOWN } from '../../../../constant/Common/Error/message';
import ERROR_MESSAGE_PAIR from '../../../../constant/Common/Error/messagePair';

const generateFieldRelatedErrorMessage = ({ error }) => {
  /** Get error from error response */
  const errors = error?.response?.data?.errors;
  const targetError = errors?.[0];

  /** Error property */
  const code = targetError?.code;
  const fields = targetError?.fields;

  /** Pair */
  const codePair = ERROR_MESSAGE_PAIR?.[code];
  const fieldPair = fields?.map(field => COLUMN_ITEM_PAIR?.[field]);

  /** Handle unknown column and message */
  if (fieldPair?.includes(undefined) || !codePair) return ERROR_UNKNOWN;

  const createErrorMessage = `${fieldPair?.join(', ')} ${codePair}`;
  return createErrorMessage;
};

export default generateFieldRelatedErrorMessage;
