import { CELL_TYPE_BUTTON } from '../../../../constant/Common/DataGrid/cellType';
import { PATH_COMMON_BUTTON_ICON } from '../../../../constant/Common/Path/Asset/Icon/Button';
import { PATH_DATAGRID_GROUP_FOLD_ICON } from '../../../../constant/Common/Path/Asset/Icon/Datagrid';
import { PRIMARY_COLOR } from '../../../../constant/Common/Style/color';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createButtonGroup = () => {
  return [
    {
      headerName: 'Group02 - Button',
      backgroundColor: '#b38bff',
      color: '#fff',
      icon: PATH_DATAGRID_GROUP_FOLD_ICON,
      isColumnGroup: true,
      children: [
        {
          headerName: 'Default',
          field: 'button_default',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Button',
              styles: {
                backgroundColor: '#0000ff',
                border: '1px solid #0000ff',
                color: 'white',
              },
            }),
        },
        {
          headerName: 'Success (Custom)',
          field: 'button_success',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Success',
              styles: {
                backgroundColor: '#14b814',
                border: '1px solid #14b814',
                color: 'white',
              },
            }),
        },
        {
          headerName: 'Error (Custom)',
          field: 'button_error',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Error',
              styles: {
                backgroundColor: '#e31d19',
                border: '1px solid #e31d19',
                color: 'white',
              },
            }),
        },
        {
          headerName: 'Disabled',
          field: 'button_disabled',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Disabled',
              disabled: true,
            }),
        },
        {
          headerName: 'Width 50%',
          field: 'button_width_half',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Width 50%',
              styles: {
                width: '50%',
                backgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Width 100%',
          field: 'button_width_full',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Width 100%',
              styles: {
                width: '100%',
                backgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Width 100px',
          field: 'button_width_100px',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Width 100px',
              styles: {
                width: '100px',
                backgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon Only',
          field: 'button_icon_only',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              // content: 'Width 100px',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                width: 'fit-content',
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon Only Width 50%',
          field: 'button_icon_only_width_half',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                width: '50%',
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon Only Width 100%',
          field: 'button_icon_only_width_full',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                width: '100%',
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon & Content',
          field: 'button_icon_content',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Button',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: PRIMARY_COLOR,
                hoverColor: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon & Content Width 50%',
          field: 'button_icon_content_width_half',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Button',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                width: '50%',
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: PRIMARY_COLOR,
                hoverColor: '#ffffff',
              },
            }),
        },
        {
          headerName: 'Icon & Content Width 100%',
          field: 'button_icon_content_width_full',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'Button',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              styles: {
                width: '100%',
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: PRIMARY_COLOR,
                hoverColor: '#ffffff',
              },
            }),
        },
        {
          headerName: 'On-Click',
          field: 'button_on_click',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'It is true!',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              onClick: () => {
                console.log('Button on-clicked! : ', gridParam);
                alert('James is Handsome!');
              },
              styles: {
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: PRIMARY_COLOR,
                hoverColor: '#ffffff',
              },
            }),
        },
        {
          headerName: 'On-Click',
          field: 'button_on_click',
          columnGroupShow: 'open',
          cellRendererSelector: gridParam =>
            generateCellRenderer({
              gridParam,
              cellType: CELL_TYPE_BUTTON,
              content: 'It is true!',
              icon: PATH_COMMON_BUTTON_ICON?.checkBlue,
              disabled: true,
              onClick: () => {
                console.log('Button on-clicked! : ', gridParam);
                alert('James is Handsome!');
              },
              styles: {
                border: `1px solid ${PRIMARY_COLOR}`,
                hoverBackgroundColor: PRIMARY_COLOR,
                color: PRIMARY_COLOR,
                hoverColor: '#ffffff',
              },
            }),
        },
      ],
    },
  ];
};

export default createButtonGroup;
