import React from 'react';
import CommonSelect from '../../../Common/Controller/Select/index';
import CommonDate from '../../../Common/Controller/Date/index';
import { STATUS_OPTION_LIST } from '../../../../constant/Safety/DamageReport/options';
import { DAMAGE_STATUS } from '../../../../constant/Safety/DamageReport/standardCodes';
import { handleDateInputChange } from '../../../../util/Safety/DamageReport/Handler/changedInputHandler';

const AdvancedSearchModalContent = ({ queryObject }) => {
  return (
    <div className="flex bg-[#F9F9F9] w-[488px] h-[91px] px-[30px] pt-[17px]  pb-[28px] justify-between">
      <div className="w-[153px]">
        <CommonSelect
          label="STATUS"
          option={STATUS_OPTION_LIST}
          inputName="status"
          defaultValue={queryObject?.status || 'ALL'}
          onChangeHandler={({ e, onChange }) => {
            onChange(e?.target?.value);
          }}
        />
      </div>
      <div className="w-[116px]">
        <CommonDate
          required
          label="FROM"
          inputName="from-date"
          defaultValue={queryObject?.['from-date'] || ''}
          onChangeHandler={handleDateInputChange}
        />
      </div>

      <div className="w-[116px]">
        <CommonDate
          required
          label="TO"
          inputName="to-date"
          defaultValue={queryObject?.['to-date'] || ''}
          onChangeHandler={handleDateInputChange}
        />
      </div>
    </div>
  );
};

export default AdvancedSearchModalContent;
